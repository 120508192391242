import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.css']
})
export class ChangeEmailComponent {



  public email : string = null;
  public emailRepetition : string = null;
  public emailError : boolean = false;
  public emailForm : FormGroup = null;
  public emailExist : boolean = false;
  public dataLoaded : boolean = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private formBuilder:FormBuilder
  ) {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]],
      emailRepetition: ['', [Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]]
    });
  }
  isSubmitDisabled() {
    return this.emailForm.invalid || !this.emailForm.dirty;
  }

  ngOnInit(): void {
    
  }

  resetEmail(){
    this.emailError = false;
    this.dataLoaded = false;

    if (this.email !== this.emailRepetition) {
      this.emailError = true;
      this.dataLoaded = true;
    } else {
      let data = {
        id_token : this.authService.getAuthenticatedUser().access_token,
        email : this.email
      };

      if (this.authService.me.personal_information.email == this.email) {
        this.dataLoaded = true;
        this.emailExist = true;
        return this.emailExist;
      }

      this.authService.changeEmail(data).subscribe((response) => {

        this.translate.get('toastr.emailChangedSuccesfully').subscribe((res: string) => {
          this.toastr.success(res);
        });

      },
      (error) => {
        this.dataLoaded = true;
        this.translate.get('toastr.EmailChangedError').subscribe((res: string) => {
          this.toastr.error(res);
        });
      }
      ,() => {
        this.dataLoaded = true;
        this.authService.logout();
      });
    }
  }

}
