<div class="d-flex g-2 pb-3" *ngIf="buttons">
  <button
    class="button"
    [ngClass]="{ 'button--active': !isListViewAll }"
    (click)="togglerListView(false)"
  >
    {{ "notifications.unread" | translate }}
  </button>
  <button
    class="button"
    [ngClass]="{ 'button--active': isListViewAll }"
    (click)="togglerListView(true)"
  >
    {{ "notifications.all" | translate }}
  </button>
</div>

<ng-container *ngIf="displayedNotifications?.length > 0; else noNotifications">
  <div *ngFor="let notification of displayedNotifications">
    <div
      class="list-item"
      [ngClass]="{ 'list-item--active': notification.status === 'unread' }"
    >
      <img
        src="../../../assets/img/svg/icons/notification-speaker.svg"
        class="list-item__image"
      />
      <div class="list-item__description">
        <span class="description--name">
          {{ notification.notification.title }}
        </span>
        <span class="description" *ngIf="!limit">
          {{ notification.notification.body }}
        </span>
        <div class="description description--grey">
          {{ relativeTime(notification.send_time,locale) }}
        </div>
      </div>
      
    </div>
  </div>
</ng-container>

<ng-template #noNotifications>
  <div class="list-item" style="border: 1px solid #eff7ff">
    <img src="../../../assets/img/svg/icons/notification-speaker.svg" />
    <span class="list-item__description list-item__description--none">
      {{ "notifications.none" | translate }}
    </span>
  </div>
</ng-template>
