import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from '@services/store.service';
import { cloneDeep } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  CalendarView,
} from 'angular-calendar';

import {
  getDateFromString,
  getDateInString,
  getFirstWeekday,
} from '@helpers/helper';

import {
  ChartConfiguration,
  ChartDataSets,
} from 'chart.js';

@Component({
  selector: 'app-survey-card',
  templateUrl: './survey-card.component.html',
  styleUrls: ['./survey-card.component.css'],
})
export class SurveyCardComponent {
  @Input() patientId: string;
  @Input() tab: 'daily' | 'weekly';
  @Output() viewReady = new EventEmitter<string>();
  @ViewChild('surveyResultsModal', { read: TemplateRef })
  surveyResultsModal: TemplateRef<any>;

  public activeDayIsOpen: boolean = false;
  public view: CalendarView;
  public viewDateSurvey: Date = new Date();
  public viewDateMonthlySurvey: Date = new Date();
  public monthlyView: CalendarView = CalendarView.Month;

  public barChartLabels: string[] = [];
  public barChartData: ChartDataSets[] = [];
  public language: string;
  public surveyResults: any[] = [];
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: any[];
  public weeklySurveyResults: any[] = [];
  public lineChartDataCopy: ChartDataSets[] = [];
  public monthlySurveyTotal: number = 0;
  public activeSurveyTab: number = 1;

  public weeklySurvey: any = {};
  public chosenBeforeSurvey: any = {};
  public chosenAfterSurvey: any = {};

  public dataLoadedSurveys: boolean = false;
  public monthlySurveyEmpty: boolean = false;
  public monthlySurveyLoaded: boolean = false;
  public dataLoaded: boolean = false;

  public lineChartColors: string[] = [
    '#e6194B',
    '#f58231',
    '#ffe119',
    '#bfef45',
    '#3cb44b',
    '#42d4f4',
    '#4363d8',
    '#911eb4',
  ];
  public lineChartColorsCopy: string[] = [
    '#e6194B',
    '#f58231',
    '#ffe119',
    '#bfef45',
    '#3cb44b',
    '#42d4f4',
    '#4363d8',
    '#911eb4',
  ];

  chartColors = [
    {
      backgroundColor: [],
    },
    {
      backgroundColor: [],
    },
  ];

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            offsetGridLines: true,
            color: 'rgba(0, 0, 0, 0)',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            offsetGridLines: true,
            color: 'rgba(0, 0, 0, 0)',
          },
          ticks: {
            max: 100,
            min: 0,
            autoSkip: false,
          },
        },
      ],
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx,
          ctx2 = chartInstance.ctx;
        ctx2.textAlign = 'center';
        ctx2.textBaseline = 'bottom';
        ctx2.font = '14px Nunito Sans, sans-serif';
        ctx2.fillStyle = '#ffffff';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = i % 2 ? 'A' : 'B';
            const img = new Image();
            ctx2.fillText(data, bar._view.x, bar._yScale.bottom - 5);
          });
        });
      },
    },
    legend: {
      display: false,
    },
  };

  public lineChartOptions: any = {
    responsive: true,
    aspectRatio: 2,
    legend: {
      display: false,
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: 'transparent',
          },
          ticks: {
            fontColor: '#718096',
          },
          scaleLabel: {
            labelString: '',
            display: true,
            fontColor: '#718096',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: '#718096',
          },
          scaleLabel: {
            labelString: '',
            display: true,
            fontColor: '#718096',
          },
        },
      ],
    },
  };

  constructor(
    private translate: TranslateService,
    private storeService: StoreService,
    private modalService: NgbModal
  ) {
    this.language = this.translate.currentLang;
    this.translate.onLangChange.subscribe((value) => {
      this.language = value.lang;
      this.processMonthlySurveyResults();
    });
    this.generateWeeklyDates();
  }

  ngOnInit() {
    this.view = CalendarView.Week;
    this.getSurveyResults(true);
    this.getMonthlySurveyResults();
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  changeViewClickSurvey() {
    this.dataLoadedSurveys = false;
    this.getSurveyResults(false);
    this.generateWeeklyDates();
  }

  generateWeeklyDates(): void {
    const weekStartDate = getFirstWeekday(this.viewDateSurvey);
    const weekEndDate = new Date(weekStartDate);
    weekEndDate.setDate(weekEndDate.getDate() + 6);
    this.barChartLabels = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(weekStartDate);
      currentDate.setDate(currentDate.getDate() + i);
      const formattedDate = this.formatDate(currentDate);
      this.barChartLabels.push(formattedDate);
    }
  }

  formatDate(date) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString(options).replaceAll('/', '.');
  }

  getSurveyResults(isInit?) {
    this.barChartData = [];
    this.surveyResults = [];
    const weekStartDate = getFirstWeekday(this.viewDateSurvey);
    const weekStartDateString = getDateInString(weekStartDate);
    let weekEndDate = weekStartDate;
    weekEndDate.setDate(weekEndDate.getDate() + 6);
    const weekEndDateString = getDateInString(weekEndDate);
    const requestData: any = {
      patient_uid: this.patientId,
      start_date: weekStartDateString,
      end_date: weekEndDateString,
    };
    this.storeService.getSurveyResultsOverview(requestData).subscribe(
      (data) => {
        this.surveyResults = data['data'];
      },
      (error) => {},
      () => {
        this.processSurveyResults();
        if (isInit) {
          setTimeout(() => {
            this.viewReady.emit();
          }, 100);
        }
      }
    );
  }

  processMonthlySurveyResults() {
    this.lineChartData = [];
    this.lineChartLabels = [];
    this.monthlySurveyEmpty = false;
    let questionCount = 0;

    this.weeklySurveyResults.sort((a, b) => {
      return (
        Number(a.startDate.split('.')[0]) - Number(b.startDate.split('.')[0])
      );
    });

    let someSurveyResult = false;
    const weeklyTotals = [];
    this.weeklySurveyResults.forEach((res) => {
      if (res.data) {
        someSurveyResult = true;
        this.lineChartLabels.push(res.startDate + ' - ' + res.endDate);
        questionCount = res.data.questions?.length;
        let weeklyTotal = 0;
        res.data.questions.forEach((question) => {
          weeklyTotal += question.answer;
        });
        weeklyTotals.push(weeklyTotal);
      } else {
        this.translate
          .get('patientDetails.performanceOverview.na')
          .subscribe((resString: string) => {
            this.lineChartLabels.push([
              res.startDate + ' - ' + res.endDate,
              resString,
            ]);
          });
        weeklyTotals.push(0);
      }
    });
    if (someSurveyResult) {
      this.translate
        .get('patientDetails.performanceOverview.totalScore')
        .subscribe((resString: string) => {
          this.lineChartData.push({
            data: weeklyTotals,
            label: resString,
            order: questionCount + 1,
            showLine: true,
            backgroundColor: 'rgba(54, 162, 235, 0.4)',
            borderColor: 'rgb(54, 162, 235)',
          });
        });
    }
    this.monthlySurveyEmpty = !someSurveyResult;
    this.lineChartColorsCopy = cloneDeep(this.lineChartColors);
    for (let j = 0; j < questionCount; j++) {
      const data = [];
      let label = '';
      let order = 999;
      this.weeklySurveyResults.forEach((res) => {
        if (res.data) {
          label = res.data.questions[j].lang[this.language];
          order = res.data.questions[j].order;
          data.push(res.data.questions[j].answer);
        } else {
          data.push(0);
        }
      });
      const randomColor = this.lineChartColorsCopy.pop();
      this.lineChartData.push({
        type: 'line',
        data,
        label,
        order,
        fill: false,
        showLine: true,
        backgroundColor: randomColor,
        borderColor: randomColor,
      });
      this.lineChartDataCopy = cloneDeep(this.lineChartData);
      this.monthlySurveyTotal = 0;
      this.lineChartData.forEach((dataItem) => {
        dataItem.data.forEach((dataScore) => {
          this.monthlySurveyTotal += dataScore;
        });
      });
    }
    setTimeout(() => {
      this.monthlySurveyLoaded = true;
    }, 500);
  }

  processSurveyResults() {
    const firstWeekday: Date = getFirstWeekday(this.viewDateSurvey);
    this.surveyResults.sort((a, b) =>
      getDateFromString(a.date) > getDateFromString(b.date)
        ? 1
        : getDateFromString(b.date) > getDateFromString(a.date)
        ? -1
        : 0
    );
    let beforeResults: number[] = [];
    let afterResults: number[] = [];

    this.weeklySurvey = this.surveyResults.find(
      (element) => element.survey_type === 'weekly_survey'
    );

    let currentDate = firstWeekday;

    for (let i = 0; i < 7; i++) {
      let beforeFound,
        afterFound = false;
      this.surveyResults.forEach((survey, index) => {
        let barValue: number;
        if (
          getDateFromString(survey.date).toDateString() ===
          currentDate.toDateString()
        ) {
          barValue = survey.borg_scale;
          if (survey.survey_type === 'before') {
            beforeResults.push(barValue);
            beforeFound = true;
          } else if (survey.survey_type === 'after') {
            afterResults.push(barValue);
            afterFound = true;
          }
        } else if (index === this.surveyResults.length - 1) {
          barValue = 0;
          if (!afterFound) {
            afterResults.push(barValue);
          }
          if (!beforeFound) {
            beforeResults.push(barValue);
          }
        }
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    this.barChartData.push({
      data: beforeResults,
      label: 'B',
    });
    this.barChartData.push({
      data: afterResults,
      label: 'A',
    });

    this.barChartData.forEach((dataset, setIndex) => {
      dataset.data.forEach((value, index) => {
        if (value < 10) {
          this.chartColors[setIndex].backgroundColor[index] = '#ba2100';
        } else if (value <= 20) {
          this.chartColors[setIndex].backgroundColor[index] = '#ba3800';
        } else if (value <= 30) {
          this.chartColors[setIndex].backgroundColor[index] = '#ba4e00';
        } else if (value <= 40) {
          this.chartColors[setIndex].backgroundColor[index] = '#ba6400';
        } else if (value <= 50) {
          this.chartColors[setIndex].backgroundColor[index] = '#ba8600';
        } else if (value <= 60) {
          this.chartColors[setIndex].backgroundColor[index] = '#dbc822';
        } else if (value <= 70) {
          this.chartColors[setIndex].backgroundColor[index] = '#a6b44e';
        } else if (value <= 80) {
          this.chartColors[setIndex].backgroundColor[index] = '#75b44e';
        } else if (value <= 90) {
          this.chartColors[setIndex].backgroundColor[index] = '#62b44e';
        } else {
          this.chartColors[setIndex].backgroundColor[index] = '#469e44';
        }
      });
    });
    this.dataLoadedSurveys = true;
    this.dataLoaded = true;
  }

  questionSelect(index) {
    this.lineChartDataCopy[index].showLine =
      !this.lineChartDataCopy[index].showLine;
    const order = this.lineChartDataCopy[index].order;
    const indexInData = this.lineChartData.findIndex(
      (question) => question.order === order
    );
    if (this.lineChartDataCopy[index].showLine) {
      const randomColor = this.lineChartColorsCopy.pop();
      this.lineChartData.push(
        Object.assign({}, this.lineChartDataCopy[index], {
          backgroundColor: randomColor,
          borderColor: randomColor,
        })
      );
    } else {
      this.lineChartColorsCopy.push(
        this.lineChartData[indexInData].backgroundColor.toString()
      );
      this.lineChartData.splice(indexInData, 1);
    }
    this.monthlySurveyTotal = 0;
    this.lineChartData.forEach((dataItem) => {
      dataItem.data.forEach((dataScore) => {
        this.monthlySurveyTotal += dataScore;
      });
    });
  }

  changeMonthlyViewClickSurvey() {
    this.getMonthlySurveyResults();
  }

  getMonthlySurveyResults() {
    this.monthlySurveyLoaded = false;
    this.weeklySurveyResults = [];
    const viewMonthIndex = this.viewDateMonthlySurvey.getMonth();
    // const viewDateOfMonth = this.viewDateMonthlySurvey.getDate();
    const referenceDate = new Date(this.viewDateMonthlySurvey);

    referenceDate.setHours(0, 0, 0, 0);
    referenceDate.setDate(1);
    let i = 0;
    for (; i < 4; i++) {
      // if (weekStartDate.getDate() + 6 > viewDateOfMonth) {
      //   const weekEndDate = new Date(weekStartDate);
      //   weekEndDate.setDate(viewDateOfMonth);
      //   const requestData: any = {
      //     patient_uid: this.patientId,
      //     start_date: getDateInString(weekStartDate),
      //     end_date: getDateInString(weekEndDate),
      //   };
      //   this.storeService.getSurveyResultsOverview(requestData).subscribe(
      //     (data) => {
      //     },
      //     (error) => {},
      //     () => {}
      //   );
      //   break;
      // }
      if (i !== 3) {
        const weekStartDate = new Date(referenceDate);
        const weekEndDate = new Date(referenceDate);
        weekEndDate.setDate(weekStartDate.getDate() + 6);
        const requestData: any = {
          patient_uid: this.patientId,
          start_date: getDateInString(weekStartDate),
          end_date: getDateInString(weekEndDate),
        };
        this.storeService.getSurveyResultsOverview(requestData).subscribe(
          (data) => {
            const temp = {
              startDate: getDateInString(weekStartDate),
              endDate: getDateInString(weekEndDate),
              data: data['data'].find(
                (element) => element.survey_type === 'weekly_survey'
              ),
            };
            this.weeklySurveyResults.push(temp);
          },
          (error) => {},
          () => {}
        );
        referenceDate.setDate(referenceDate.getDate() + 7);
      } else {
        const weekStartDate = new Date(referenceDate);
        const weekEndDate = new Date(referenceDate);
        if (viewMonthIndex === 1) {
          if (this.viewDateMonthlySurvey.getFullYear() % 4 === 0) {
            weekEndDate.setDate(29);
          } else {
            weekEndDate.setDate(28);
          }
        } else if ([3, 5, 8, 10].includes(viewMonthIndex)) {
          weekEndDate.setDate(30);
        } else {
          weekEndDate.setDate(31);
        }
        const requestData: any = {
          patient_uid: this.patientId,
          start_date: getDateInString(weekStartDate),
          end_date: getDateInString(weekEndDate),
        };
        this.storeService.getSurveyResultsOverview(requestData).subscribe(
          (data) => {
            const temp = {
              startDate: getDateInString(weekStartDate),
              endDate: getDateInString(weekEndDate),
              data: data['data'].find(
                (element) => element.survey_type === 'weekly_survey'
              ),
            };
            this.weeklySurveyResults.push(temp);
          },
          (error) => {},
          () => {
            this.processMonthlySurveyResults();
          }
        );
      }
    }
  }

  public chartClicked(e: any): void {
    const firstWeekday: Date = getFirstWeekday(this.viewDateSurvey);
    let chosenDate: Date = firstWeekday;
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        let value: number;
        let surveyType: string;
        if (activePoints[0]._view.datasetLabel === 'B') {
          value = chart.data.datasets[0].data[clickedElementIndex];
          surveyType = 'before';
        } else {
          value = chart.data.datasets[1].data[clickedElementIndex];
          surveyType = 'after';
        }
        chosenDate = new Date(
          chosenDate.setDate(chosenDate.getDate() + clickedElementIndex)
        );
        const chosenSurveys: any[] = this.surveyResults.filter(
          (element) =>
            getDateFromString(element.date).toDateString() ===
            chosenDate.toDateString()
        );
        this.chosenBeforeSurvey = chosenSurveys.find(
          (element) => element.survey_type === 'before'
        );
        this.chosenAfterSurvey = chosenSurveys.find(
          (element) => element.survey_type === 'after'
        );
        this.modalService.open(this.surveyResultsModal, { size: 'lg' });
      }
    }
  }
}
