import {TextBoxQuestion} from '@schemas/survey/survey-question-fields/survey-input-field';
import {DropdownQuestion} from '@schemas/survey/survey-question-fields/survey-dropdown-field';
import {RadioQuestion} from '@schemas/survey/survey-question-fields/survey-radio-field';
import {ScaleQuestion} from '@schemas/survey/survey-question-fields/survey-scale-field';
import {YesNoQuestion} from '@schemas/survey/survey-question-fields/survey-yes-no-field';

export class SurveySettings {

  /**
   * Possible|Allowed html input types to be created for the Survey
   */
  public static CUSTOMIZABLE_INPUT_TYPES = {
    'free text': 'text',
    scale: 'scale',
    'yes/no': 'yes/no',
    radio: 'radio'
  };

  /**
   * Allowed options for select and radio buttons
   */
  public static ALLOWED_INPUT_OPTIONS = [
    'radio',
    'scale',
    'yes/no'
  ];

  public static INPUT_TYPES = {
    input: TextBoxQuestion,
    text: TextBoxQuestion,
    select: DropdownQuestion,
    radio: RadioQuestion,
    scale: ScaleQuestion,
    'yes/no': YesNoQuestion
  };

  public static SUPPORTED_LANGUAGES = {
    en: 'English',
    de: 'Deutsch'
  };

  /**
   * Scalar Default values
   */
  public static SCALE_OPTIONS = {
    de: [
      {value: '10'},
      {value: '20'},
      {value: '30'},
      {value: '40'},
      {value: '50'},
      {value: '60'},
      {value: '70'},
      {value: '80'},
      {value: '90'},
      {value: '100'}
    ],
    en: [
      {value: '10'},
      {value: '20'},
      {value: '30'},
      {value: '40'},
      {value: '50'},
      {value: '60'},
      {value: '70'},
      {value: '80'},
      {value: '90'},
      {value: '100'}
    ]
  };

  /**
   * Scalar Default values
   */
  public static YES_NO_OPTIONS = {
    de: [
      {value: 'Ja'},
      {value: 'Nein'}
    ],
    en: [
      {value: 'Yes'},
      {value: 'No'},
    ]
  };

}

