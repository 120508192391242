import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  getDateFromString,
  getDateInString,
  getFirstWeekday,
} from '@helpers/helper';
import {
  ChartConfiguration,
  ChartData,
  ChartDataSets,
  ChartType,
} from 'chart.js';
import { cloneDeep } from 'lodash';
import { StoreService } from '@services/store.service';

@Component({
  selector: 'app-performance-card',
  templateUrl: './performance-card.component.html',
  styleUrls: ['./performance-card.component.css'],
})
export class PerformanceCardComponent implements OnInit {
  @Input() performanceOverview: any;
  @Input() adherence: any;
  @Input() activeTreatmentSummary: any;
  @Input() todayExercises: any;
  @Input() patientId: string;

  public problematicExercises: any[] = [];
  public donePercentage: number;
  public language: string;
  public subtitle: string;

  public lineChartData: ChartDataSets[] = [];
  public lineChartDataCopy: ChartDataSets[] = [];
  public lineChartLabels: any[];
  public monthlySurveyEmpty: boolean = false;
  public weeklySurveyResults: any[] = [];
  public monthlySurveyTotal: number = 0;
  public monthlySurveyLoaded: boolean = false;
  public viewDateSurvey: Date = new Date();
  public viewDateMonthlySurvey: Date = new Date();
  public barChartLabels: string[] = [];

  public lineChartColors: string[] = [
    '#e6194B',
    '#f58231',
    '#ffe119',
    '#bfef45',
    '#3cb44b',
    '#42d4f4',
    '#4363d8',
    '#911eb4',
  ];
  public lineChartColorsCopy: string[] = [
    '#e6194B',
    '#f58231',
    '#ffe119',
    '#bfef45',
    '#3cb44b',
    '#42d4f4',
    '#4363d8',
    '#911eb4',
  ];

  constructor(
    private translate: TranslateService,
    private storeService: StoreService
  ) {
    this.language = this.translate.currentLang;
    this.translate.onLangChange.subscribe((value) => {
      this.language = value.lang;
      this.processMonthlySurveyResults();
    });
    this.generateWeeklyDates();
  }

  ngOnInit(): void {
    this.getMonthlySurveyResults();
    this.processTreatmentSummary();
    this.processProblematicExercises();
  }

  processProblematicExercises() {
    this.performanceOverview?.exercises_with_low_performance?.forEach(
      (exercise) => {
        const found = this.problematicExercises.find(
          (element) => element.exercise_id === exercise.exercise_id
        );
        if (!found && this.problematicExercises.length < 3) {
          this.problematicExercises.push(exercise);
        }
      }
    );
  }

  processTreatmentSummary() {
    this.donePercentage =
      ((this.activeTreatmentSummary.total_days -
        this.activeTreatmentSummary.days_left) /
        this.activeTreatmentSummary.total_days) *
      100;
    this.doTranslattions();
    this.translate.onLangChange.subscribe((value) => {
      this.doTranslattions();
    });
  }

  doTranslattions() {
    let translationString: string = 'patient.daysLeft';
    if (this.activeTreatmentSummary.days_left >= 0) {
      if (this.todayExercises.length > 0) {
        if (this.activeTreatmentSummary.days_left === 0) {
          translationString = 'patient.dayLeft';
          this.translate.get(translationString).subscribe((res: string) => {
            this.subtitle = '' + '1' + '\n' + res;
          });
        } else {
          translationString = 'patient.daysLeft';
          this.translate.get(translationString).subscribe((res: string) => {
            this.subtitle =
              '' + this.activeTreatmentSummary.days_left + '\n' + res;
          });
        }
      } else {
        if (this.activeTreatmentSummary.days_left === 0) {
          this.donePercentage = 100;
          this.translate.get('patient.done').subscribe((res: string) => {
            this.subtitle = res;
          });
        } else {
          translationString = 'patient.daysLeft';
          this.translate.get(translationString).subscribe((res: string) => {
            this.subtitle =
              '' + this.activeTreatmentSummary.days_left + '\n' + res;
          });
        }
      }
    }
  }

  processMonthlySurveyResults() {
    this.lineChartData = [];
    this.lineChartLabels = [];
    this.monthlySurveyEmpty = false;
    let questionCount = 0;

    this.weeklySurveyResults.sort((a, b) => {
      return (
        Number(a.startDate.split('.')[0]) - Number(b.startDate.split('.')[0])
      );
    });

    let someSurveyResult = false;
    const weeklyTotals = [];
    this.weeklySurveyResults.forEach((res) => {
      if (res.data) {
        someSurveyResult = true;
        this.lineChartLabels.push(res.startDate + ' - ' + res.endDate);
        questionCount = res.data.questions?.length;
        let weeklyTotal = 0;
        res.data.questions.forEach((question) => {
          weeklyTotal += question.answer;
        });
        weeklyTotals.push(weeklyTotal);
      } else {
        this.translate
          .get('patientDetails.performanceOverview.na')
          .subscribe((resString: string) => {
            this.lineChartLabels.push([
              res.startDate + ' - ' + res.endDate,
              resString,
            ]);
          });
        weeklyTotals.push(0);
      }
    });
    if (someSurveyResult) {
      this.translate
        .get('patientDetails.performanceOverview.totalScore')
        .subscribe((resString: string) => {
          this.lineChartData.push({
            data: weeklyTotals,
            label: resString,
            order: questionCount + 1,
            showLine: true,
            backgroundColor: 'rgba(54, 162, 235, 0.4)',
            borderColor: 'rgb(54, 162, 235)',
          });
        });
    }
    this.monthlySurveyEmpty = !someSurveyResult;
    this.lineChartColorsCopy = cloneDeep(this.lineChartColors);
    for (let j = 0; j < questionCount; j++) {
      const data = [];
      let label = '';
      let order = 999;
      this.weeklySurveyResults.forEach((res) => {
        if (res.data) {
          label = res.data.questions[j].lang[this.language];
          order = res.data.questions[j].order;
          data.push(res.data.questions[j].answer);
        } else {
          data.push(0);
        }
      });
      const randomColor = this.lineChartColorsCopy.pop();
      this.lineChartData.push({
        type: 'line',
        data,
        label,
        order,
        fill: false,
        showLine: true,
        backgroundColor: randomColor,
        borderColor: randomColor,
      });
      this.lineChartDataCopy = cloneDeep(this.lineChartData);
      this.monthlySurveyTotal = 0;
      this.lineChartData.forEach((dataItem) => {
        dataItem.data.forEach((dataScore) => {
          this.monthlySurveyTotal += dataScore;
        });
      });
    }
    setTimeout(() => {
      this.monthlySurveyLoaded = true;
    }, 500);
  }

  formatDate(date) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString(options).replaceAll('/', '.');
  }
  public generateWeeklyDates(): void {
    const weekStartDate = getFirstWeekday(this.viewDateSurvey);
    const weekEndDate = new Date(weekStartDate);
    weekEndDate.setDate(weekEndDate.getDate() + 6);
    this.barChartLabels = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(weekStartDate);
      currentDate.setDate(currentDate.getDate() + i);
      const formattedDate = this.formatDate(currentDate);
      this.barChartLabels.push(formattedDate);
    }
  }

  getMonthlySurveyResults() {
    this.monthlySurveyLoaded = false;
    this.weeklySurveyResults = [];
    const viewMonthIndex = this.viewDateMonthlySurvey.getMonth();
    const referenceDate = new Date(this.viewDateMonthlySurvey);

    referenceDate.setHours(0, 0, 0, 0);
    referenceDate.setDate(1);
    let i = 0;
    for (; i < 4; i++) {
      if (i !== 3) {
        const weekStartDate = new Date(referenceDate);
        const weekEndDate = new Date(referenceDate);
        weekEndDate.setDate(weekStartDate.getDate() + 6);
        const requestData: any = {
          patient_uid: this.patientId,
          start_date: getDateInString(weekStartDate),
          end_date: getDateInString(weekEndDate),
        };
        this.storeService.getSurveyResultsOverview(requestData).subscribe(
          (data) => {
            const temp = {
              startDate: getDateInString(weekStartDate),
              endDate: getDateInString(weekEndDate),
              data: data['data'].find(
                (element) => element.survey_type === 'weekly_survey'
              ),
            };
            this.weeklySurveyResults.push(temp);
          },
          (error) => {},
          () => {}
        );
        referenceDate.setDate(referenceDate.getDate() + 7);
      } else {
        const weekStartDate = new Date(referenceDate);
        const weekEndDate = new Date(referenceDate);
        if (viewMonthIndex === 1) {
          if (this.viewDateMonthlySurvey.getFullYear() % 4 === 0) {
            weekEndDate.setDate(29);
          } else {
            weekEndDate.setDate(28);
          }
        } else if ([3, 5, 8, 10].includes(viewMonthIndex)) {
          weekEndDate.setDate(30);
        } else {
          weekEndDate.setDate(31);
        }
        const requestData: any = {
          patient_uid: this.patientId,
          start_date: getDateInString(weekStartDate),
          end_date: getDateInString(weekEndDate),
        };
        this.storeService.getSurveyResultsOverview(requestData).subscribe(
          (data) => {
            const temp = {
              startDate: getDateInString(weekStartDate),
              endDate: getDateInString(weekEndDate),
              data: data['data'].find(
                (element) => element.survey_type === 'weekly_survey'
              ),
            };
            this.weeklySurveyResults.push(temp);
          },
          (error) => {},
          () => {
            this.processMonthlySurveyResults();
          }
        );
      }
    }
  }
}
