import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, NavigationError, NavigationSkipped, NavigationStart, Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from '@services/auth.service';
import { StoreService } from '@services/store.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit {
  public title = 'Breathment';
  loading: BehaviorSubject<boolean> = this.storeService.displaySpinner;
  constructor(public translate: TranslateService, private router: Router, private auth: AuthService,
    private storeService: StoreService
  ) {


    let defaultLanguage = localStorage.getItem('language')
    if (!defaultLanguage) {
      defaultLanguage = this.translate.getBrowserLang()
      if (!['en','de','tr','it'].includes(defaultLanguage))
        defaultLanguage = 'en';
    }
    localStorage.setItem('language', defaultLanguage);
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);

  }
  ngOnInit(): void {
    // console.log('app start')
    this.router.events.subscribe(event => {
      if (event instanceof NavigationSkipped) {

      }
      if (event instanceof NavigationStart) {

      }
      if (event instanceof NavigationEnd) {
      }
      if (event instanceof NavigationCancel) {

      }
      if (event instanceof NavigationError) {

      }
    })
    
    
  }

}
