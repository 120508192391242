import {Component, Input} from '@angular/core';
import {ChartDataSets} from "chart.js";
import { el } from 'date-fns/locale';
import {Color} from "ng2-charts";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent {
  @Input() plotData: any;
  @Input() type: string;

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: string[] ;
  public lineChartOptions: any = {
    responsive: true,
    aspectRatio: 2,
    legend: {
      display: false
    },
    elements: {
      line: {
        tension: 0
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          color: "transparent",
          // color: "white",

        },
        // display: false,
        ticks: {
          // fontColor: 'transparent',
          fontColor: 'white',
        },
        scaleLabel: {
          labelString: '',
          display: true,
          fontColor: 'white',
        }
      }],
      yAxes: [{
        ticks: {
          // fontColor: 'transparent',
          fontColor: 'white',
        },
        scaleLabel: {
          labelString: '',
          display: true,
          fontColor: 'white',
        }
        // display: false
      }]
    }
  };

  public lineChartColors: Color[] = [
    {
      borderColor: '#fff',
      backgroundColor: '#fff'
    }
  ];
  public lineChartType = 'line';

  constructor(
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.type === 'steps') {
      this.processChartData();
    } else if (this.type === 'spirometry1') {
      this.lineChartOptions.aspectRatio = 1;
      this.processSpirometryData();
    } else if (this.type === 'spirometry2') {
      this.lineChartOptions.aspectRatio = 1;
      this.processSpirometryData2();
    }
  }

  ngOnChanges(): void {
    this.lineChartData = [];
    if (this.type === 'spirometry1') {
      this.processSpirometryData();
    } else if (this.type === 'spirometry2') {
      this.processSpirometryData2();
    }
  }

  processChartData() { 
    let chartData: number[] = [];
    let chartLabels: string[] = [];
    if (this.plotData && this.plotData.length > 0) {
      this.plotData.forEach(element => {
        chartData.push(element.steps);
        chartLabels.push(element.date);
      });
    } else {
      chartData = [0,0,0,0,0,0,0];
      chartLabels = ['28.05.2023', '29.05.2023', '30.05.2023', '31.05.2023', '01.06.2023', '02.06.2023', '03.06.2023']
    }

    this.lineChartData.push({data: chartData, fill: false});
    this.lineChartLabels = chartLabels;
  }

  processSpirometryData() {
    let chartData: {x, y}[] = [];
    let chartLabels: number[] = [];
    this.plotData.x.forEach((element, index) => {
      chartData.push({x: (Math.round(element * 100) / 100).toFixed(2), y: this.plotData.y[index]});
    })
    this.lineChartData.push({data: chartData, fill: false});
    this.lineChartOptions.scales.xAxes[0]['type'] = 'linear';
    this.lineChartOptions.scales.xAxes[0]['ticks'] = { fontColor: 'white', stepSize: 1, }
    this.translate.get('patientDetails.performanceOverview.spirometry.v').subscribe((res: string) => {
      this.lineChartOptions.scales.yAxes[0]['scaleLabel'].labelString = res;
    });
    this.translate.get('patientDetails.performanceOverview.spirometry.t').subscribe((res: string) => {
      this.lineChartOptions.scales.xAxes[0]['scaleLabel'].labelString = res;
    });
  }

  processSpirometryData2() {
    let chartData: {x, y}[] = [];
    let chartLabels: number[] = [];
    if (this.plotData) {
      this.plotData.x.forEach((element, index) => {
        chartData.push({x: element, y: this.plotData.y[index]});
      });
    } else {
      // chartData = [0,0,0,0,0,0,0];
      // chartLabels = ['28.05.2023', '29.05.2023', '30.05.2023', '31.05.2023', '01.06.2023', '02.06.2023', '03.06.2023']
    }
    this.lineChartData.push({data: chartData, fill: false});
    this.lineChartOptions.scales.xAxes[0]['type'] = 'linear';
    this.lineChartOptions.scales.xAxes[0]['position'] = 'bottom';
    this.translate.get('patientDetails.performanceOverview.spirometry.f').subscribe((res: string) => {
      this.lineChartOptions.scales.yAxes[0]['scaleLabel'].labelString = res;
    });
    this.translate.get('patientDetails.performanceOverview.spirometry.t').subscribe((res: string) => {
      this.lineChartOptions.scales.xAxes[0]['scaleLabel'].labelString = res;
    });
  }

}
