import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import { ChartDataSets, ChartOptions, ChartConfiguration } from 'chart.js';
import { Color, Label, BaseChartDirective } from 'ng2-charts';
import { TranslateService } from "@ngx-translate/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { compareDateValidation } from 'src/app/shared/custom-validators/compareDateValidation';
import { ExerciseService } from "../../../services/exercise/exercise.service";
import { ToastrService } from 'ngx-toastr';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { Chart } from '../../../schemas/chart/chart.model';
import { AnnotationRange } from "../../../schemas/chart/annotation-range";
import { CHART_CONFIG } from '../../../schemas/chart/chart.config';
import { StoreService } from '@services/store.service';
import { cloneDeep, head, last, map, union, uniqBy, unzip, zip } from 'lodash';

@Component({
  selector: 'app-breathing-graph',
  templateUrl: './breathing-graph.component.html',
  styleUrls: ['./breathing-graph.component.css']
})
export class BreathingGraphComponent implements OnInit {

  @Input() plotData: any;
  @Input() assignedDates: any;
  @Input() patientUid: any;
  @Input() exerciseId: any;
  @Input() choosenDate: any;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  public exerciseCategory: string = '';

  public plotArray: any[] = [];
  public timeDifference: number = 1;
  public inputDate;
  public choosenDates:String[] =[];

  public breathingPlotData: any;
  public breathInstruction: string;
  public displayInstructionRanges: boolean = true;
  public temporaryAnnotations: any;

  graphInputs: UntypedFormGroup;

  allAssignedDates : Date[] =[];

  public newProcessedBellyData: number[] = [];
  public newProcessedTimestamps: number[] = [];
  public newProcessedIndexes: number[] = [];

  public processedBellyData: number[] = [];
  public processedChestData: number[] = [];
  public processedTimestamps: number[] = [];

  public noExercise: boolean = false;

  public lineChartData: ChartDataSets[] = [];

  public lineChartLabels: number[] ;

  public lineChartOptions = {
    responsive: true,
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true
        },
        type: 'linear',
        ticks: {
          stepSize: 1
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true
        },
        ticks: {
          min: 0,
          max: 100
        }
      }]
    },
    annotation: {
      display: true,
      annotations: []
    },
    plugins: [ChartAnnotation]
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#384f6b',
      backgroundColor: 'rgba(255,255,255,0)',
    },
    {
      borderColor: '#6A4050',
      backgroundColor: '#6A4050',
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [ChartAnnotation];

  public dataLoaded: boolean = false;


  // NEW
  public chartOptions = cloneDeep(
    CHART_CONFIG.OPTIONS
  ) as ChartConfiguration['options'];
  //
  public showBackgroundColors = true;
  public ranges: AnnotationRange[] = [];
  public rangesReference = [];
  public plotSelected: string;
  public plotOptions = [];
  public displayPlotDropdown: boolean = false;
  public chosenPlot: any;
  // NEW

  constructor(
    private translate: TranslateService,
    private exerciseService: ExerciseService,
    private toastr: ToastrService,
    private readonly _changeDetector: ChangeDetectorRef,
    private storeService: StoreService,
    ) {}

  ngOnInit(): void {
    this.exerciseCategory = this.storeService.getExerciseCategory(this.exerciseId);
    for (const key of Object.keys(this.plotData)) {
      this.plotArray.push(this.plotData[key]);
    }
    this.processPlotSets();
  }

  processPlotSets() {
    let deviceOptionTranslation: string;
    this.translate.get('generalTerms.set').subscribe((res: string) => {
      deviceOptionTranslation = res;
    });
    this.chosenPlot = this.plotArray[0];
    this.plotSelected = deviceOptionTranslation + ' ' + 1;
    if (this.plotArray.length > 1) {
      this.plotArray.forEach((data, i) => {
        this.plotOptions.push(deviceOptionTranslation);
      });
    }
    this.processChartData(this.chosenPlot, true);
  }

  createPlot(chartData, clearFlag?, date?) {
    const unzippedMap = unzip(chartData);
    const processedChestData = unzippedMap[2] as number[];
    const processedBellyData = unzippedMap[1] as number[];
    const processedTimestamps = unzippedMap[0] as number[];
    let bellyData: {x, y}[] = [];
    let chestData: {x, y}[] = [];
    if (this.chosenPlot) {
      if(clearFlag){
        this.lineChartData = [];
      }
      let bellyExpansion: string;
      let chestExpansion: string;
      this.translate.get('exercise.bellyExpansion').subscribe((res: string) => {
        bellyExpansion = res;
      });
      this.translate.get('exercise.chestExpansion').subscribe((res: string) => {
        chestExpansion = res;
      });
      this.chosenPlot.belly_data.forEach((element, index) => {
        //if (index%4 === 0) {
          bellyData.push({x: this.chosenPlot.timestamp[index], y: element});
          chestData.push({x: this.chosenPlot.timestamp[index], y: this.chosenPlot.chest_data[index]});
        //}
      });
      this.translate.get('patientDetails.breathingPattern').subscribe((res: string) => {
        this.lineChartData.push({ data: bellyData, label: bellyExpansion, fill:false })
        this.lineChartData.push({ data: chestData, label: chestExpansion, fill:false })
        this.lineChartOptions.scales.yAxes[0].scaleLabel['labelString'] = res;
      });
      const lastTimestamp = Math.round(processedTimestamps[processedTimestamps.length-1]);
      this.lineChartLabels = Array.from(Array(lastTimestamp).keys());
      this.translate.get('patientDetails.time').subscribe((res: string) => {
        this.lineChartOptions.scales.xAxes[0].scaleLabel['labelString'] = res;
      });
      this.dataLoaded = true;
    }
  }

  changePlot(i: number) {
    this.dataLoaded = false;
    this.chosenPlot = this.plotArray[i];
    this.processChartData(this.chosenPlot, true);
  }

  intializeComparisonDate() {
  this.graphInputs = new UntypedFormGroup({
    comparisonDate: new UntypedFormControl(this.inputDate, [
      compareDateValidation(this.allAssignedDates),
    ]),

  });

  }

  // NEW

  private processChartData(chart: Chart, drawAnnotations: boolean): void {
    let chartDataMap = zip(
      chart.timestamp,
      chart.belly_data,
      chart.chest_data,
      chart.instruction
    );

    chartDataMap = uniqBy(
      chartDataMap,
      (element: [number, number, number, string]) => element[0]
    );
    this.sketchChart(chartDataMap as [number, number, number, string][], drawAnnotations);
  }

  private sketchChart(
    chartData: [number, number, number, string][],
    drawAnnotations: boolean
  ): void {
    Promise.resolve()
      .then(() => this._buildRanges(chartData))
      .then(() => this.createPlot(chartData, true, this.choosenDate))
      .then(() => this._buildAnnotations(drawAnnotations, chartData));
  }
  //
  private _buildRanges(chartData: [number, number, number, string][]): void {
    this.ranges = [];
    if (chartData?.length > 0) {
      let start = head(chartData);
      chartData.forEach((element: [number, number, number, string]): void => {
        if (
          element &&
          (element[3] !== start[3] || element === last(chartData)) //&&
        ) {
          this.ranges.push({
            xMin: start[0],
            xMax: element[0],
            instruction: start[3]
          });
          start = element;
        }
      });
    }
  }

  private _buildAnnotations(drawAnnotations: boolean, chartData: [number, number, number, string][]): void {
    const unzippedMap = unzip(chartData);
    this.processedBellyData = unzippedMap[1] as number[];
    this.processedChestData = unzippedMap[2] as number[];
    this.rangesReference = map(this.ranges, (range: AnnotationRange) => ({
      ...CHART_CONFIG.ANNOTATIONS.GENERAL,
      ...CHART_CONFIG.ANNOTATIONS[range.instruction],
      xMin: range.xMin,
      xMax: range.xMax,
    }));
    this.showBackgroundAction(drawAnnotations);
  }

  public showBackgroundAction(value: boolean): void {
    this.showBackgroundColors = value;
    this.lineChartOptions.annotation.annotations = [];
    if (value) {
      this.rangesReference.forEach(ref => {
        ref.canHide = true;
        ref.xScaleID = "x-axis-0";
        ref.yScaleID = "y-axis-0";
        this.lineChartOptions.annotation.annotations.push(ref);
      });
      let maxValue = Math.max(...this.processedBellyData) + 5;
      if (Math.max(...this.processedChestData) > Math.max(...this.processedBellyData)) {
        maxValue = Math.max(...this.processedChestData) + 5;
      }
      let minValue = Math.min(...this.processedBellyData) - 5;
      if (Math.min(...this.processedChestData) < Math.min(...this.processedBellyData)) {
        minValue = Math.min(...this.processedChestData) - 5;
      }
      this.lineChartOptions.annotation.annotations.forEach(annotation => {
        if (minValue < 0) {
          minValue = 0;
        }
        annotation.yMin = minValue;
        annotation.yMax = maxValue;
      });
      this.lineChartOptions.scales.yAxes[0].ticks.min = minValue;
      this.lineChartOptions.scales.yAxes[0].ticks.max = maxValue;
    } else {
      this.lineChartOptions.annotation.annotations = [];
    }
    this.lineChartOptions = cloneDeep(this.lineChartOptions);
  }
}
