<div class="d-flex responsive justify-content-between g-3">
  <div class="card w-100" style="background-color: #cbcedf; flex: 3">
    <div class="card-header">
      <h3>
        {{
          "patientDetails.performanceOverview.performanceOverview" | translate
        }}
      </h3>
    </div>
    <div class="card-body d-flex align-items-center">
      <div class="d-flex g-3">
        <div class="d-flex" style="max-height: 140px">
          <div
            class="card w-100 h-100 has-bg-img mr-1 text-dark text-center"
            style="
              background-color: #e9ecf3;
              background-image: url('/assets/images/correct-exercises.svg');
            "
          >
            <p style="font-size: 32px">
              {{ performanceOverview.number_of_performed_exercises }}
            </p>
            <p class="mt-auto">
              {{
                "patientDetails.performanceOverview.performedExercises"
                  | translate
              }}
            </p>
          </div>
          <div
            class="card w-100 h-100 has-bg-img ml-1 text-dark text-center"
            style="
              background-color: #e9ecf3;
              background-image: url('/assets/images/wrong-exercises.svg');
            "
          >
            <p style="font-size: 32px">
              {{ performanceOverview.number_of_missed_exercises }}
            </p>
            <p class="mt-auto">
              {{
                "patientDetails.performanceOverview.missedExercises" | translate
              }}
            </p>
          </div>
        </div>
        <div
          class="d-flex flex-column"
          style="border-left: 1px solid #ffffffa9"
        >
          <p class="pl-3 text-dark">
            {{ "patientDetails.performanceOverview.problemsWith" | translate }}:
          </p>
          <p *ngIf="problematicExercises.length === 0" class="pl-3">
            {{ "generalTerms.none" | translate }}
          </p>
          <ul *ngFor="let exercise of problematicExercises">
            <li>
              {{
                exercise.display_name[language] ?? exercise.display_name["en"]
              }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column" style="flex: 2">
    <div class="d-flex justify-content-between g-3">
      <div class="card w-100" style="background-color: #e9ecf3; height: 80%">
        <h4 class="pt-3 text-center">
          {{ "patientDetails.performanceOverview.activeDays" | translate }}
        </h4>
        <p class="text-center mb-0" style="font-size: 32px">
          {{ adherence.activeDays.active_days }}
        </p>
        <span class="ml-auto">
          <img
            src="assets/images/active_days.svg"
            alt="Image placeholder"
            style="
              height: 30px;
              width: 30px;
              position: absolute;
              bottom: 10px;
              right: 10px;
            "
          />
        </span>
      </div>

      <div class="card w-100" style="background-color: #cbcedf; height: 80%">
        <h4 class="pt-3 text-center">
          {{ "patientDetails.performanceOverview.longestStreak" | translate }}
        </h4>
        <p class="text-center mb-0" style="font-size: 32px">
          {{ adherence.longestStreak.streak }}
        </p>
        <span class="ml-auto">
          <img
            src="assets/images/streak.svg"
            alt="Image placeholder"
            style="
              height: 30px;
              width: 30px;
              position: absolute;
              bottom: 10px;
              right: 10px;
            "
          />
        </span>
      </div>
    </div>

    <div class="card" style="background-color: #e9ecf3">
      <div class="card-header">
        <h3>{{ "patientDetails.treatmentProgress" | translate }}</h3>
      </div>
      <div class="mx-auto" style="height: 120px; width: 120px">
        <circle-progress
          [percent]="this.donePercentage"
          [maxPercent]="100"
          [subtitleFontSize]="this.subtitle.length > 12 ? 15 : 25"
          [subtitle]="this.subtitle"
          [outerStrokeColor]="'#8D9EB0'"
          [innerStrokeColor]="'#fff'"
          [innerStrokeWidth]="20"
          [outerStrokeWidth]="20"
          [space]="-20"
        ></circle-progress>
      </div>
    </div>
  </div>
</div>
