import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { Http2Service } from '@services/http2.service';
import { IUserProfile } from 'src/app/resolvers';

@Component({
  selector: 'app-health-tab',
  templateUrl: './health-tab.component.html',
  styleUrls: ['./health-tab.component.css']
})
export class HealthTabComponent implements OnInit, AfterViewInit {

  activeView: string = 'activity';
  me: IUserProfile;

  locale: any;
  patientId: string;
  patientDetails: any;

  setActiveView(view: string): void {
    this.activeView = view;
    this.router.navigate([], {
      queryParams: { action: view }, // New query parameter value
      queryParamsHandling: 'merge', // Merge with existing query params
    });
  }
  loading: boolean = true;
  private _counter: number = 0;
  IncreaseCounter() {

    this._counter++;
    if (this._counter == 1) {
      this.loading = false
      this._counter = 0;
    }
  }
  constructor(
    private http: Http2Service,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
  ) {
    this.me = this.authService.me;
    this.locale = this.translate.currentLang
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      this.requestPatientDetails()

    });

  }
  ngAfterViewInit(): void {
    this.route.queryParamMap.subscribe(q => {
      let view = q?.get('action')
      if (['activity', 'survey-results'].includes(view)) {
        this.setActiveView(view)
      }

    });
  }
  ngOnInit(): void {
    this.patientId = this.route.parent?.snapshot.paramMap.get('id');
    this.requestPatientDetails()
  }
  requestPatientDetails() {
    this.http.fetch('get_user_details', { patient_uid: this.patientId }).subscribe(data => {
      this.patientDetails = data
      this.IncreaseCounter()
      
    })
  }

}
