import { Component, NgZone, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute, Router } from '@angular/router';
import { AuthService } from "../../services/auth.service";
import { GeneralService } from '@services/general/general.service';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public password: string = null;
  public passwordRepetition: string = null;
  public passwordError: boolean = false;
  public passwordControl: boolean = false;
  public isPasswordSame: boolean = false;
  public barLabel: string = "Password strength:";

  // public mode: string;
  public actionCode: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private generalService: GeneralService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private ngZone: NgZone,
  ) {

  }
  dataLoaded: boolean = false
  ngOnInit(): void {
    this.translate
      .get('patientDetails.averageDegree')
      .subscribe((res: string) => {
        this.ngZone.run(() => {
          this.dataLoaded = true

        })
      });
  }

  resetPassword() {
    this.passwordError = false;


    if (this.password !== this.passwordRepetition) {
      this.passwordError = true;
    }
    else {

      if (!this.isValidPassword(this.password)) {
        return this.passwordControl = true;
      }

      const hash = this.activatedRoute.snapshot.paramMap.get('hash');
      let data: any = {
        password: this.password
      };
      if (hash)
        data.token = hash
      else
        data.id_token = this.authService?.getAuthenticatedUser?.()?.access_token

      if (hash) {
        this.generalService.resetPassword(data).subscribe(data => {

          this.translate.get('toastr.passwordChanged').subscribe((res: string) => {
            this.toastr.success(res);
          });
          this.router.navigate(['/login'])
        },
          error => {
            this.translate.get('toastr.passwordChangeError').subscribe((res: string) => {
              this.toastr.error(res);
            });
          }, () => {
            //this.authService.logout();
          });

      } else {

        this.generalService.changePassword(data).subscribe(data => {

          this.translate.get('toastr.passwordChanged').subscribe((res: string) => {
            this.toastr.success(res);
          });

        },
          error => {
            this.translate.get('toastr.passwordChangeError').subscribe((res: string) => {
              this.toastr.error(res);
            });
          }, () => {
            this.authService.logout();
          });
      }
    }
  }

  // public hasNumber(pass) {
  //   return /\d/.test(pass);

  // }

  // public hasCaptialAlphabet(pass) {
  //   return /[A-Z]/.test(pass);

  // }

  // public hasSpecialCharacter(pass) {
  //   var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  //   return format.test(pass);

  // }
  isValidPassword = (pwd: string) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?;&#\.])[A-Za-z\d\.@$!%*?&;#]{8,}$/

    return passwordRegex.test(pwd)
  }

}
