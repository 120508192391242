<div class="form-group" [formGroup]="form">

  <label>
    {{question.lang[lang]}} <span class="small errorMessage" *ngIf="!isValid">({{ 'generalTerms.required' | translate }})</span>
  </label>


  <div [ngSwitch]="question.type">

    <input class="form-control" *ngSwitchCase="'text'" [formControlName]="question.key"
           [id]="question.key" [type]="question.type">

    <input class="form-control" *ngSwitchCase="'input'" [formControlName]="question.key"
    [id]="question.key" [type]="question.type">

    <select class="form-control" [id]="question.key" *ngSwitchCase="'dropdown'" [formControlName]="question.key">
      <option *ngFor="let opt of question.options[lang]" [value]="opt.value">{{opt.value}}</option>
    </select>

    <div *ngSwitchCase="'radio'">
      <div class="form-check form-check-inline radio-toolbar" *ngFor="let opt of question.options[lang]">
        <input class="form-check-input" type="radio" [id]="opt.value + '_' + question.key" [value]="opt.value"
               [formControlName]="question.key">
        <label class="form-check-label" [for]="opt.value + '_' + question.key"
               [ngClass]="['_' + opt.value.toString()]">{{opt.value}}</label>
      </div>
    </div>

    <div *ngSwitchCase="'scale'">
      <div class="d-flex">
        <div class="text-sm mr-2">{{ question.helper_description?.negative[lang] ?? question.helper_description?.negative['en'] }}</div>
        <div class="form-check form-check-inline radio-toolbar" *ngFor="let opt of (question.options[lang] ?? question.options['en'])">
          <input class="form-check-input" type="radio" [id]="opt.value + '_' + question.key" [value]="opt.value"
                 [formControlName]="question.key">
          <label class="form-check-label" [for]="opt.value + '_' + question.key"
                 [ngClass]="['_' + opt.value.toString()]"></label>
        </div>
        <div class="text-sm">{{ question.helper_description?.positive[lang] ?? question.helper_description?.positive['en'] }}</div>
      </div>
    </div>

    <div *ngSwitchCase="'yes/no'">
      <div class="form-check form-check-inline radio-toolbar" *ngFor="let opt of question.options[lang]">
        <input class="form-check-input" type="radio" [id]="opt.value + '_' + question.key" [value]="opt.value"
               [formControlName]="question.key">
        <label class="form-check-label" [for]="opt.value + '_' + question.key"
               [ngClass]="['_' + opt.value.toString()]">{{opt.value}}</label>
      </div>
    </div>

  </div>

</div>
