import { ElementRef } from "@angular/core";

export class SoundPlayer {
    private _isPlaying: boolean;

    constructor(private id: string) { }
    pause() {
        const audioElement: HTMLAudioElement = document.getElementById(this.id) as HTMLAudioElement;
        if (!audioElement) return;
        audioElement.pause();
        this._isPlaying = false;
    }
    playFromUrl(src: string, onStarted, onEnded, prio: boolean = false) {
        if (!prio && this._isPlaying) {
            return;
        }
        if (prio && this._isPlaying) {
            this.pause()
        }
        const audioElement: HTMLAudioElement = document.getElementById(this.id) as HTMLAudioElement;
        if (!audioElement) return;

        audioElement.src = src;
        audioElement.load();
        onStarted();
        audioElement.play();
        this._isPlaying = true;
        audioElement.addEventListener('ended', (e) => {
            onEnded()
            this._isPlaying = false;
        })
        this.logAudioInfo('playFromUrl:', audioElement)
    }
    playFromBase64(b64String: string, onStarted, onEnded, prio: boolean = false) {
        if (!prio && this._isPlaying) {
            return;
        }
        if (prio && this._isPlaying) {
            this.pause()
        }
        const audioElement: HTMLAudioElement = document.getElementById(this.id) as HTMLAudioElement;
        if (!audioElement) return;
        const binary = this.convertDataURIToBinary(b64String);
        if (!binary) return;
        const blob = new Blob([binary], { type: 'audio/ogg' });
        const blobUrl = URL.createObjectURL(blob);
        audioElement.src = blobUrl;
        audioElement.load();
        onStarted();
        this._isPlaying = true;
        audioElement.play();
        audioElement.addEventListener('ended', (e) => {
            this._isPlaying = false;
            onEnded()
        })
        this.logAudioInfo('playFromBase64', audioElement)
    }
    private convertDataURIToBinary(dataURI) {
        try {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));

            for (let i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        } catch (error) {

        }
        return null;
    }
    private logAudioInfo(fnName: string, el: HTMLAudioElement) {

    }
}
export class WarningTracker {
    private key: string = '';
    private last_play_time: number;
    private continuous_play_count: number;
    private next_valid_play_time: number;
    shouldPlay(key): boolean {
        if (key != this.key) this.setLastKey(key)
        const ret = Date.now() >= this.next_valid_play_time;
        if (ret)
            this.setLastKey(key)
        return ret;
    }
    private setLastKey(key) {
        if (key != this.key) {
            this.key = key
            this.continuous_play_count = 0
            this.last_play_time = Date.now()
            this.next_valid_play_time = this.last_play_time
        } else {
            this.continuous_play_count += 1;
            this.next_valid_play_time = (Date.now() + 3000) + (this.continuous_play_count * 1300)
        }


    }
}