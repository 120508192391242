import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from '@services/store.service';

import {
  CalendarEvent,
  CalendarView,
  CalendarDateFormatter,
} from 'angular-calendar';
import {
  getDateFromString,
  getDateInString,
  getFirstWeekday,
} from '@helpers/helper';

@Component({
  selector: 'app-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.css'],
})
export class ActivityCardComponent implements OnInit {
  @Input() stepGoal: number;
  @Input() patientId: string;

  public activeDayIsOpen: boolean = false;
  public stepEdit: boolean = false;
  public viewDateSurvey: Date = new Date();
  public viewDateActivity: Date = new Date();
  public view: CalendarView;
  public averageWeeklySteps: number;
  public language: string;
  public dataLoadedWeekly: boolean = false;
  public weeklySteps: any[] = [];
  public stepGoalCopy: number = 0;
  public selectedDeviceStepCountWeekly: any = [];
  public devices: any[];
  public selectedDevice:number= 1;

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private toastr: ToastrService,
    private storeService: StoreService
  ) {
    this.language = this.translate.currentLang;
    this.translate.onLangChange.subscribe((value) => {
      this.language = value.lang;
      // this.processMonthlySurveyResults();
    });
    // this.generateWeeklyDates();
  }

  ngOnInit() {
    this.view = CalendarView.Week;
    this.stepGoalCopy = this.stepGoal;
    this.getWeeklySteps();
  }

  editStepGoal() {
    this.stepEdit = true;
  }

  cancelStepEdit() {
    this.stepGoalCopy = this.stepGoal;
    this.stepEdit = false;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  changeViewClickActivity() {
    this.dataLoadedWeekly = false;
    this.getWeeklySteps();
  }

  changeDevice() {
    this.selectedDeviceStepCountWeekly = this.devices[this.selectedDevice - 1];
    this.dataLoadedWeekly = false;
    setTimeout(() => {
      this.dataLoadedWeekly = true;
    }, 250);
    this.weeklySteps = this.selectedDeviceStepCountWeekly;
  }

  saveStepEdit() {
    if (this.stepGoalCopy > 100000) {
      return;
    }
    const payload = {
      step_goal: this.stepGoalCopy,
      patient_uid: this.patientId,
    };
    this.userService.updateStepGoal(payload).subscribe(
      () => {
        this.stepGoal = this.stepGoalCopy;
        this.translate
          .get('toastr.stepGoalUpdateSuccess')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
      },
      (err) => {
        this.stepGoalCopy = this.stepGoal;
        this.translate
          .get('toastr.stepGoalUpdateError')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
      },
      () => {
        this.stepEdit = false;
      }
    );
  }

  getWeeklySteps() {
    this.weeklySteps = [];
    const weekStartDate = getFirstWeekday(this.viewDateActivity);
    const weekStartDateString = getDateInString(weekStartDate);
    let weekEndDate = weekStartDate;
    weekEndDate.setDate(weekEndDate.getDate() + 6);
    const weekEndDateString = getDateInString(weekEndDate);

    const requestData: any = {
      patient_uid: this.patientId,
      start_date: weekStartDateString,
      end_date: weekEndDateString,
    };
    this.storeService.getStepCountsByRange(requestData).subscribe(
      (data) => {
        if (data && typeof data === 'object') {
          this.devices = Object.values(data['data']);
          this.selectedDeviceStepCountWeekly =
            this.devices[this.selectedDevice - 1];
          this.weeklySteps = this.selectedDeviceStepCountWeekly;
        }
      },
      (error) => {},
      () => {
        this.getWeeklyStepAverage();
      }
    );
  }

  getWeeklyStepAverage() {
    let totalSteps: number = 0;
    let totalDays: number = 0;
    if (this.weeklySteps && this.weeklySteps.length > 0) {
      this.weeklySteps.forEach((element) => {
        if (element.steps) {
          totalSteps += element.steps;
          totalDays++;
        }
      });
      if (totalDays === 0) {
        this.averageWeeklySteps = 0;
      } else {
        this.averageWeeklySteps = Math.round(totalSteps / totalDays);
      }
    } else {
      this.averageWeeklySteps = 0;
    }
    this.dataLoadedWeekly = true;
  }
}
