<div *ngIf="dataLoaded" class="row">
  <div class="col-lg-7 col-12">
    <div class="card" [tippy]="'patientDetails.performanceOverview.tooltips.surveyResults' | translate" placement="auto" className="tooltip-sm" #survey>
      <div class="card-header">
        <div class="card-title w-100" style="display: inline-flex; margin-bottom: 0;">
          <h3 class="w-50">{{ "patientDetails.performanceOverview.surveyResults" | translate }}</h3>
        </div>
      </div>
      <div *ngIf="dataLoadedSurveys" class="card-body pt-0">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeSurveyTab" class="nav-tabs">
          <li [ngbNavItem]="1">
            <button ngbNavLink>{{ "patientDetails.performanceOverview.dailySurvey" | translate }}</button>
            <ng-template ngbNavContent>
              <div class="d-flex w-100 justify-content-between align-items-center my-2">
                <div class="d-flex flex-column">
                  <p class="font-weight-bold" style="margin-top: 1rem;">B: <span class="font-weight-light">{{ 'patientDetails.beforeExercise' | translate }}</span></p>
                  <p class="font-weight-bold">A: <span class="font-weight-light">{{ 'patientDetails.afterExercise' | translate }}</span></p>
                </div>
                <div>
                  <div>
                    <button class="btn p-2" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDateSurvey"
                            (viewDateChange)="closeOpenMonthViewDay()" (click)="changeViewClickSurvey();">
                      <span>
                        <img src="assets/images/icons/left_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                      </span>
                    </button>
                    <button class="btn btn-secondary btn-xs rounded-pill" mwlCalendarToday [(viewDate)]="viewDateSurvey" (click)="changeViewClickSurvey();">
                      {{ 'generalTerms.today' | translate }}
                    </button>
                    <button class="btn p-2" mwlCalendarNextView [view]="view" [(viewDate)]="viewDateSurvey"
                            (viewDateChange)="closeOpenMonthViewDay()" (click)="changeViewClickSurvey();">
                      <span>
                        <img class="my-auto" src="assets/images/icons/right_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                      </span>
                    </button>
                  </div>
                  <p class="text-center m-0">{{ viewDateSurvey | calendarDate:(view + 'ViewTitle'):(language) }}</p>
                </div>
              </div>

              <canvas   baseChart class="chart"
                [datasets]="barChartData"
                [options]="barChartOptions"
                [labels]="barChartLabels"
                [chartType]="'bar'"
                [colors]="chartColors"
                (chartClick)="chartClicked($event)"
              >
              </canvas>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <button ngbNavLink>{{ "patientDetails.performanceOverview.weeklySurvey" | translate }}</button>
            <ng-template ngbNavContent>
              <div *ngIf="!monthlySurveyLoaded" class="d-flex w-100 justify-content-center">
                <div class="spinner-border text-primary my-8" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div *ngIf="monthlySurveyLoaded">
                <div [class]="(!monthlySurveyEmpty ? 'd-flex w-100 align-items-center mt-2 mb-1 justify-content-between' : 'd-flex w-100 align-items-center mt-2 mb-1 justify-content-end')">
                  <div *ngIf="!monthlySurveyEmpty" ngbDropdown autoClose="outside">
                    <button
                      class="btn btn-sm btn-secondary"
                      id="dropdownQuestionsButton"
                      ngbDropdownToggle
                    >
                      {{ "patientDetails.performanceOverview.selectQuestions" | translate }}
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownQuestionsButton" style="max-height: 18rem; overflow-y: auto; padding: 0.2rem 0.1rem;">
                      <div
                        ngbDropdownItem
                        *ngFor="let question of lineChartDataCopy, index as i"
                        style="cursor: pointer;"
                        class="d-flex rounded my-2 py-1 px-2"
                        (click)="questionSelect(i)"
                      >
                        <input type="checkbox" [(ngModel)]="question.showLine" />
                        <span class="ml-2" style="color: #4a5568 !important">{{ question.label }}</span>
                    </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <button class="btn p-2" mwlCalendarPreviousView [view]="monthlyView" [(viewDate)]="viewDateMonthlySurvey"
                              (click)="changeMonthlyViewClickSurvey();">
                        <span>
                          <img src="assets/images/icons/left_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                        </span>
                      </button>
                      <button class="btn btn-secondary btn-xs rounded-pill" mwlCalendarToday [(viewDate)]="viewDateMonthlySurvey" (click)="changeMonthlyViewClickSurvey();">
                        {{ 'generalTerms.today' | translate }}
                      </button>
                      <button class="btn p-2" mwlCalendarNextView [view]="monthlyView" [(viewDate)]="viewDateMonthlySurvey"
                              (click)="changeMonthlyViewClickSurvey();">
                        <span>
                          <img class="my-auto" src="assets/images/icons/right_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                        </span>
                      </button>
                    </div>
                    <p class="text-center m-0">{{ viewDateMonthlySurvey | calendarDate:(monthlyView + 'ViewTitle'):(language) }}</p>
                  </div>
                </div>
                <div *ngIf="!monthlySurveyEmpty" class="d-flex flex-column mb-2" style="gap: 0.3rem">
                  <div *ngFor="let dataset of lineChartData" class="d-flex align-items-center">
                    <div style="width: 0.5rem; height: 0.5rem; border-radius: 20rem; margin-right: 1rem;" [ngStyle]="{'background-color': dataset.backgroundColor}"></div>
                    <span class="text-sm">{{ dataset.label }}</span>
                  </div>
                </div>
                <canvas baseChart class="chart"
                  [datasets]="lineChartData"
                  [labels]="lineChartLabels"
                  [options]="lineChartOptions"
                  [colors]="lineChartColors"
                  [chartType]="'bar'">
                </canvas>
              </div>
              <!-- <app-survey *ngIf="weeklySurvey" [surveyData]="weeklySurvey" [isWeeklySurvey]="true"></app-survey>
              <div *ngIf="!weeklySurvey"  class="text-center">
                <p class="h5 text-muted mt-3 mb-0">
                  {{ "patientDetails.performanceOverview.weeklySurveyNA" | translate }}
                </p>
              </div> -->
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
      <div *ngIf="!dataLoadedSurveys" class="centered preloader d-flex justify-content-center align-items-center h-100 pb-5">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div class="card" style="background-color: #CBCEDF;" [tippy]="'patientDetails.performanceOverview.tooltips.performanceOverview' | translate" placement="auto" className="tooltip-sm" #overview>
      <div class="card-header">
        <h3>{{ "patientDetails.performanceOverview.performanceOverview" | translate }}</h3>
      </div>
      <div class="card-body row">
        <div class="col-6 d-flex">
          <div class="card w-100 h-100 has-bg-img mr-1 text-dark text-center" style="background-color: #E9ECF3; background-image: url('/assets/images/correct-exercises.svg');">
            <p style="font-size: 32px;">{{ performanceOverview.number_of_performed_exercises }}</p>
            <p class="mt-auto">{{ "patientDetails.performanceOverview.performedExercises" | translate }}</p>

          </div>
          <div class="card w-100 h-100 has-bg-img ml-1 text-dark text-center" style="background-color: #E9ECF3; background-image: url('/assets/images/wrong-exercises.svg')">
            <p style="font-size: 32px;">{{ performanceOverview.number_of_missed_exercises }}</p>
            <p class="mt-auto">{{ "patientDetails.performanceOverview.missedExercises" | translate }}</p>
          </div>

        </div>
        <div class="col-6 border-left">
          <p class="pl-3 text-dark">{{ "patientDetails.performanceOverview.problemsWith" | translate }}:</p>
          <p *ngIf="problematicExercises.length === 0" class="pl-3">{{ 'generalTerms.none' | translate }}</p>
          <ul *ngFor="let exercise of problematicExercises">
            <li>{{ exercise.display_name[language] ?? exercise.display_name['en'] }}</li>
          </ul>
        </div>

      </div>
    </div>
  </div>
  <div class="col-lg-5 col-12">
    <div class="card bg-dark p-2" [tippy]="'patientDetails.performanceOverview.tooltips.activity' | translate" placement="auto" className="tooltip-sm" #activity>
      <div class="card-header">
    <div class="card-title w-100" style="display: flex; justify-content: space-between;">
          <div style="width: 35%;">
            <h3 class="text-white">{{ "patientDetails.performanceOverview.activity" | translate }}</h3>
            <div class="d-flex align-items-center">
              <p class="text-white mb-0 text-nowrap text-sm">{{ "patientDetails.performanceOverview.stepGoal" | translate }}: </p>
              <p *ngIf="!stepEdit" class="text-white mb-0 ml-1">{{ stepGoal }}</p>
              <input *ngIf="stepEdit" [(ngModel)]="stepGoalCopy" type="text" style="box-sizing: border-box; max-width: 4rem; padding: 0; line-height: 0px; border-radius: 0.5rem; border: 0; box-shadow: none;" class="ml-1 mr-2" />
              <div *ngIf="!stepEdit" class="d-flex justify-content-center align-items-center rounded ml-1" style="padding: 0.2rem; cursor: pointer; margin-bottom: 0.2rem;" (click)="editStepGoal()">
                <i class="fas fa-edit" style="color: white; pointer-events: none;"></i>
              </div>
              <div *ngIf="stepEdit" class="d-flex justify-content-center align-items-center rounded" style="padding: 0.2rem; cursor: pointer; margin-bottom: 0.1rem;" (click)="saveStepEdit()">
                <i class="fas fa-save" style="color: white; pointer-events: none;"></i>
              </div>
              <div *ngIf="stepEdit" class="d-flex justify-content-center align-items-center rounded ml-1" style="padding: 0.2rem; cursor: pointer; margin-bottom: 0.1rem;" (click)="cancelStepEdit()">
                <i class="fas fa-times" style="color: white; pointer-events: none;"></i>
              </div>
            </div>
            <p class="text-white mb-0 text-sm">{{ "patientDetails.performanceOverview.average" | translate }} {{ "patientDetails.performanceOverview.steps" | translate }}: {{ averageWeeklySteps }}</p>
          </div>
       <div class="float-right">
          <div >
            <div >
              <div>
                <button class="btn p-2" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDateActivity"
                        (viewDateChange)="closeOpenMonthViewDay()" (click)="changeViewClickActivity();">
                    <span>
                      <img src="assets/images/icons/left_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                    </span>
                </button>
                <button class="btn btn-secondary btn-xs rounded-pill" mwlCalendarToday [(viewDate)]="viewDateActivity" (click)="changeViewClickActivity();">
                  {{ 'generalTerms.today' | translate }}
                </button>
                <button class="btn p-2" mwlCalendarNextView [view]="view" [(viewDate)]="viewDateActivity"
                        (viewDateChange)="closeOpenMonthViewDay()" (click)="changeViewClickActivity();">
                    <span>
                      <img class="my-auto" src="assets/images/icons/right_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                    </span>
                </button>
              </div>
              <p class="text-white text-center">{{ viewDateActivity | calendarDate:(view + 'ViewTitle'):(language) }}</p>
            </div>
          </div>

            <div *ngIf="devices && devices.length > 1" ngbDropdown  style="margin-left: 38px;">
              <button
                class="btn btn-xs btn-secondary"
                id="dropdownMenuButton"
                ngbDropdownToggle
              >
                {{ "patientDetails.performanceOverview.device" | translate }} {{selectedDevice}}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton" style="max-height: 12rem; overflow-y: auto;">
                <button
                  ngbDropdownItem
                  *ngFor="let device of devices, index as i"
                  (click)="selectedDevice = i + 1; changeDevice();"
                >
                 {{ "patientDetails.performanceOverview.device" | translate }} {{i + 1}}
                </button>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div *ngIf="dataLoadedWeekly">
        <app-line-chart [plotData]="weeklySteps" [type]="'steps'"></app-line-chart>
      </div>
      <div *ngIf="!dataLoadedWeekly" class="centered preloader d-flex justify-content-center align-items-center pb-5">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <span class="ml-auto mt-4">
        <img src="assets/images/steps.svg" alt="Image placeholder" style="height: 30px; width: 30px; position: absolute; bottom: 10px; right: 10px;"/>
      </span>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="card" style="background-color: #E9ECF3; height: 80%;" [tippy]="'patientDetails.performanceOverview.tooltips.activeDays' | translate" placement="auto" className="tooltip-sm">
          <h4 class="pt-3 text-center">{{ "patientDetails.performanceOverview.activeDays" | translate }}</h4>
          <p class="text-center mb-0" style="font-size: 32px;">{{ adherence.activeDays.active_days }}</p>
          <span class="ml-auto">
            <img src="assets/images/active_days.svg" alt="Image placeholder" style="height: 30px; width: 30px; position: absolute; bottom: 10px; right: 10px;"/>
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="card" style="background-color: #CBCEDF; height: 80%;" [tippy]="'patientDetails.performanceOverview.tooltips.longestStreak' | translate" placement="auto" className="tooltip-sm">
          <h4 class="pt-3 text-center">{{ "patientDetails.performanceOverview.longestStreak" | translate }}</h4>
          <p class="text-center mb-0" style="font-size: 32px;">{{ adherence.longestStreak.streak }}</p>
          <span class="ml-auto">
            <img src="assets/images/streak.svg" alt="Image placeholder" style="height: 30px; width: 30px; position: absolute; bottom: 10px; right: 10px;"/>
          </span>
        </div>
      </div>
    </div>
    <div class="card" style="background-color: #E9ECF3;" [tippy]="'patientDetails.performanceOverview.tooltips.treatmentProgress' | translate" placement="auto" className="tooltip-sm">
      <div class="card-header">
        <h3>{{ "patientDetails.treatmentProgress" | translate }}</h3>
      </div>
      <!--      <div class="ml-auto p-2 row" style="background-color: #CBCEDF;">-->
      <!--        <div class="col-6">-->
      <!--          <p class="text-center text-dark">8 <br/>days remaining</p>-->
      <!--        </div>-->
      <!--        <div class="col-6 border-left">-->
      <!--          <p class="text-center text-dark">24 <br/> days done</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="mx-auto" style="height: 120px; width: 120px;">
        <circle-progress [percent]="this.donePercentage" [maxPercent]="100" [subtitleFontSize]="this.subtitle.length > 12 ? 15 : 25" [subtitle]="this.subtitle" [outerStrokeColor]="'#8D9EB0'" [innerStrokeColor]="'#fff'"
                         [innerStrokeWidth]="20" [outerStrokeWidth]="20" [space]="-20"></circle-progress>
      </div>
    </div>
</div>

<!--<h2 class="ml-2">{{ "patientDetails.performanceOverview.spirometry.title" | translate }}</h2>-->
<!--<div class="row w-100 m-0 px-2">-->
<!--  <div class="card bg-dark p-2" style="width: 40%;">-->
<!--    <div class="card-header">-->
<!--      <div class="card-title w-100" style="display: inline-flex">-->
<!--        <div class="w-100">-->
<!--          <h3 class="text-white">{{ "patientDetails.performanceOverview.spirometry.vt" | translate }}</h3>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div>-->
<!--      <app-line-chart [plotData]="vtData" [type]="'spirometry1'"></app-line-chart>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="card bg-dark p-2 ml-4" style="width: 40%;">-->
<!--    <div class="card-header">-->
<!--      <div class="card-title w-100" style="display: inline-flex">-->
<!--        <div class="w-100">-->
<!--          <h3 class="text-white">{{ "patientDetails.performanceOverview.spirometry.fv" | translate }}</h3>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div>-->
<!--      <app-line-chart [plotData]="fvData" [type]="'spirometry2'"></app-line-chart>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="flex flex-row ml-2 w-100 align-items-center">-->
<!--  <p class="d-inline-block text-lg font mb-2 mr-2">{{ "patientDetails.performanceOverview.spirometry.select" | translate }}:</p>-->
<!--  <div *ngIf="processingSpirometry" class="spinner-border text-primary pt-1 mr-2" role="status">-->
<!--    <span class="sr-only">Loading...</span>-->
<!--  </div>-->
<!--  <input id="spirometry-xml" type="file" accept=".xml" (change)="processSpirometry()">-->
<!--</div>-->

 
<!-- survey card -->
<ng-template #surveyCard let-modal>
  <div class="flex-row py-2 mx-3" style="align-items: center; justify-content: flex-end;">
    <button type="button" class="close" aria-label="Close" style="height: 2rem; width: 2rem;" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true" style="font-size: xx-large;">×</span>
    </button>
  </div>
  <div class="card mb-4 mx-3">
    <div class="card-header">
      <div class="card-title w-100" style="display: inline-flex">
        <h3 class="w-50">{{ "patientDetails.performanceOverview.surveyResults" | translate }}</h3>
        <div class="w-50">
          <div class="float-right">
            <div class="">
              <button class="btn p-2" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDateSurvey"
                      (viewDateChange)="closeOpenMonthViewDay()" (click)="changeViewClickSurvey();">
                <span>
                  <img src="assets/images/icons/left_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                </span>
              </button>
              <button class="btn btn-secondary btn-xs rounded-pill" mwlCalendarToday [(viewDate)]="viewDateSurvey" (click)="changeViewClickSurvey();">
                {{ 'generalTerms.today' | translate }}
              </button>
              <button class="btn p-2" mwlCalendarNextView [view]="view" [(viewDate)]="viewDateSurvey"
                      (viewDateChange)="closeOpenMonthViewDay()" (click)="changeViewClickSurvey();">
                <span>
                  <img class="my-auto" src="assets/images/icons/right_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                </span>
              </button>
            </div>
            <p class="text-center">{{ viewDateSurvey | calendarDate:(view + 'ViewTitle'):language }}</p>
          </div>

        </div>
      </div>
    </div>
    <div *ngIf="dataLoadedSurveys" class="card-body pt-0">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeSurveyTab" class="nav-tabs">
        <li [ngbNavItem]="1">
          <button ngbNavLink>{{ "patientDetails.performanceOverview.dailySurvey" | translate }}</button>
          <ng-template ngbNavContent>
            <p class="font-weight-bold" style="margin-top: 1rem;">B: <span class="font-weight-light">{{ 'patientDetails.beforeExercise' | translate }}</span></p>
            <p class="font-weight-bold">A: <span class="font-weight-light">{{ 'patientDetails.afterExercise' | translate }}</span></p>

            <canvas   baseChart class="chart"
              [datasets]="barChartData"
              [options]="barChartOptions"
              [labels]="barChartLabels"
              [chartType]="'bar'"
              [colors]="chartColors"
              (chartClick)="chartClicked($event)"
            >
            </canvas>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <button ngbNavLink>{{ "patientDetails.performanceOverview.weeklySurvey" | translate }}</button>
          <ng-template ngbNavContent>
            <div *ngIf="!monthlySurveyLoaded" class="d-flex w-100 justify-content-center">
              <div class="spinner-border text-primary my-8" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div *ngIf="monthlySurveyLoaded">
              <div class="d-flex w-100 justify-content-between align-items-center mt-2 mb-1">
                <div ngbDropdown autoClose="outside">
                  <button
                    class="btn btn-sm btn-secondary"
                    id="dropdownQuestionsButton"
                    ngbDropdownToggle
                  >
                    {{ "patientDetails.performanceOverview.selectQuestions" | translate }}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownQuestionsButton" style="max-height: 18rem; overflow-y: auto; padding: 0.2rem 0.1rem;">
                    <div
                      ngbDropdownItem
                      *ngFor="let question of lineChartDataCopy, index as i"
                      style="cursor: pointer;"
                      class="d-flex rounded my-2 py-1 px-2"
                      (click)="questionSelect(i)"
                    >
                      <input type="checkbox" [(ngModel)]="question.showLine" />
                      <span class="ml-2" style="color: #4a5568 !important">{{ question.label }}</span>
                  </div>
                  </div>
                </div>
                <div>
                  <div>
                    <button class="btn p-2" mwlCalendarPreviousView [view]="monthlyView" [(viewDate)]="viewDateMonthlySurvey"
                            (click)="changeMonthlyViewClickSurvey();">
                      <span>
                        <img src="assets/images/icons/left_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                      </span>
                    </button>
                    <button class="btn btn-secondary btn-xs rounded-pill" mwlCalendarToday [(viewDate)]="viewDateMonthlySurvey" (click)="changeViewClickSurvey();">
                      {{ 'generalTerms.today' | translate }}
                    </button>
                    <button class="btn p-2" mwlCalendarNextView [view]="monthlyView" [(viewDate)]="viewDateMonthlySurvey"
                            (click)="changeMonthlyViewClickSurvey();">
                      <span>
                        <img class="my-auto" src="assets/images/icons/right_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                      </span>
                    </button>
                  </div>
                  <p class="text-center m-0">{{ viewDateMonthlySurvey | calendarDate:(monthlyView + 'ViewTitle'):(language) }}</p>
                </div>
              </div>
              <div class="d-flex flex-column mb-2" style="gap: 0.3rem">
                <div *ngFor="let dataset of lineChartData" class="d-flex align-items-center">
                  <div style="width: 0.5rem; height: 0.5rem; border-radius: 20rem; margin-right: 1rem;" [ngStyle]="{'background-color': dataset.backgroundColor}"></div>
                  <span class="text-sm">{{ dataset.label }}</span>
                </div>
              </div>
              <div class="mt-1 mb-2 text-lg">
                {{ "patientDetails.performanceOverview.totalScore" | translate }}: {{ monthlySurveyTotal }}
              </div>
              <canvas baseChart class="chart"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [chartType]="'line'">
              </canvas>
            </div>
            <!-- <app-survey *ngIf="weeklySurvey" [surveyData]="weeklySurvey" [isWeeklySurvey]="true"></app-survey>
            <div *ngIf="!weeklySurvey"  class="text-center">
              <p class="h5 text-muted mt-3 mb-0">
                {{ "patientDetails.performanceOverview.weeklySurveyNA" | translate }}
              </p>
            </div> -->
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
    <div *ngIf="!dataLoadedSurveys" class="centered preloader d-flex justify-content-center align-items-center h-100 pb-5">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</ng-template>

<!-- performance overview card -->
<ng-template #perfOverviewCard let-modal>
  <div class="flex-row py-2 mx-3" style="align-items: center; justify-content: flex-end;">
    <button type="button" class="close" aria-label="Close" style="height: 2rem; width: 2rem;" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true" style="font-size: xx-large;">×</span>
    </button>
  </div>
  <div class="card mx-3" style="background-color: #CBCEDF;">
    <div class="card-header">
      <h3>{{ "patientDetails.performanceOverview.performanceOverview" | translate }}</h3>
    </div>
    <div class="card-body row">
      <div class="col-6 d-flex">
        <div class="card w-100 h-100 has-bg-img mr-1 text-dark text-center" style="background-color: #E9ECF3; background-image: url('/assets/images/correct-exercises.svg');">
          <p style="font-size: 32px;">{{ performanceOverview.number_of_performed_exercises }}</p>
          <p class="mt-auto">{{ "patientDetails.performanceOverview.performedExercises" | translate }}</p>

        </div>
        <div class="card w-100 h-100 has-bg-img ml-1 text-dark text-center" style="background-color: #E9ECF3; background-image: url('/assets/images/wrong-exercises.svg')">
          <p style="font-size: 32px;">{{ performanceOverview.number_of_missed_exercises }}</p>
          <p class="mt-auto">{{ "patientDetails.performanceOverview.missedExercises" | translate }}</p>
        </div>

      </div>
      <div class="col-6 border-left">
        <p class="pl-3 text-dark">{{ "patientDetails.performanceOverview.problemsWith" | translate }}:</p>
        <p *ngIf="problematicExercises.length === 0" class="pl-3">{{ 'generalTerms.none' | translate }}</p>
        <ul *ngFor="let exercise of problematicExercises">
          <li>{{ exercise.display_name[language] ?? exercise.display_name['en'] }}</li>
        </ul>
      </div>

    </div>
  </div>
  <div class="row mt-1.5 mx-3">
    <div class="col-6">
      <div class="card" style="background-color: #E9ECF3; height: 80%;">
        <h4 class="pt-3 text-center">{{ "patientDetails.performanceOverview.activeDays" | translate }}</h4>
        <p class="text-center mb-0" style="font-size: 32px;">{{ adherence.activeDays.active_days }}</p>
        <span class="ml-auto">
          <img src="assets/images/active_days.svg" alt="Image placeholder" style="height: 30px; width: 30px; position: absolute; bottom: 10px; right: 10px;"/>
        </span>
      </div>
    </div>
    <div class="col-6">
      <div class="card" style="background-color: #CBCEDF; height: 80%;">
        <h4 class="pt-3 text-center">{{ "patientDetails.performanceOverview.longestStreak" | translate }}</h4>
        <p class="text-center mb-0" style="font-size: 32px;">{{ adherence.longestStreak.streak }}</p>
        <span class="ml-auto">
          <img src="assets/images/streak.svg" alt="Image placeholder" style="height: 30px; width: 30px; position: absolute; bottom: 10px; right: 10px;"/>
        </span>
      </div>
    </div>
  </div>
  <div class="card mx-3" style="background-color: #E9ECF3;">
    <div class="card-header">
      <h3>{{ "patientDetails.treatmentProgress" | translate }}</h3>
    </div>
    <!--      <div class="ml-auto p-2 row" style="background-color: #CBCEDF;">-->
    <!--        <div class="col-6">-->
    <!--          <p class="text-center text-dark">8 <br/>days remaining</p>-->
    <!--        </div>-->
    <!--        <div class="col-6 border-left">-->
    <!--          <p class="text-center text-dark">24 <br/> days done</p>-->
    <!--        </div>-->
    <!--      </div>-->
    <div class="mx-auto" style="height: 120px; width: 120px;">
      <circle-progress [percent]="this.donePercentage" [maxPercent]="100" [subtitleFontSize]="this.subtitle.length > 12 ? 15 : 25" [subtitle]="this.subtitle" [outerStrokeColor]="'#8D9EB0'" [innerStrokeColor]="'#fff'"
                       [innerStrokeWidth]="20" [outerStrokeWidth]="20" [space]="-20"></circle-progress>
    </div>
  </div>
</ng-template>

<!-- patient activity card -->
<ng-template #activityCard let-modal>
  <div class="flex-row py-2 mx-3" style="align-items: center; justify-content: flex-end;">
    <button type="button" class="close" aria-label="Close" style="height: 2rem; width: 2rem;" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true" style="font-size: xx-large;">×</span>
    </button>
  </div>
  <div class="card bg-dark p-2 mb-4 mx-3">
    <div class="card-header">
      <div class="card-title w-100" style="display: inline-flex">
        <div style="width: 35%;">
          <h3 class="text-white">{{ "patientDetails.performanceOverview.activity" | translate }}</h3>
          <div class="d-flex align-items-center">
            <p class="text-white mb-0 text-nowrap">{{ "patientDetails.performanceOverview.stepGoal" | translate }}: </p>
            <p *ngIf="!stepEdit" class="text-white mb-0 ml-1">{{ stepGoal }}</p>
            <input *ngIf="stepEdit" [(ngModel)]="stepGoalCopy" type="text" style="box-sizing: border-box; max-width: 4rem; padding: 0; line-height: 0px; border-radius: 0.5rem; border: 0; box-shadow: none;" class="ml-1 mr-2" />
            <div *ngIf="!stepEdit" class="d-flex justify-content-center align-items-center rounded ml-3" style="padding: 0.2rem; cursor: pointer; margin-bottom: 0.2rem;" (click)="editStepGoal()">
              <i class="fas fa-edit" style="color: white; pointer-events: none;"></i>
            </div>
            <div *ngIf="stepEdit" class="d-flex justify-content-center align-items-center rounded ml-1" style="padding: 0.2rem; cursor: pointer; margin-bottom: 0.1rem;" (click)="saveStepEdit()">
              <i class="fas fa-save" style="color: white; pointer-events: none;"></i>
            </div>
            <div *ngIf="stepEdit" class="d-flex justify-content-center align-items-center rounded ml-1" style="padding: 0.2rem; cursor: pointer; margin-bottom: 0.1rem;" (click)="cancelStepEdit()">
              <i class="fas fa-times" style="color: white; pointer-events: none;"></i>
            </div>
          </div>
          <p class="text-white mb-0">{{ "patientDetails.performanceOverview.average" | translate }} {{ "patientDetails.performanceOverview.steps" | translate }}: {{ averageWeeklySteps }}</p>
        </div>
        <div style="width: 65%;">
          <div class="float-right">
            <div>
              <button class="btn p-2" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDateActivity"
                      (viewDateChange)="closeOpenMonthViewDay()" (click)="changeViewClickActivity();">
                  <span>
                    <img src="assets/images/icons/left_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                  </span>
              </button>
              <button class="btn btn-secondary btn-xs rounded-pill" mwlCalendarToday [(viewDate)]="viewDateActivity" (click)="changeViewClickActivity();">
                {{ 'generalTerms.today' | translate }}
              </button>
              <button class="btn p-2" mwlCalendarNextView [view]="view" [(viewDate)]="viewDateActivity"
                      (viewDateChange)="closeOpenMonthViewDay()" (click)="changeViewClickActivity();">
                  <span>
                    <img class="my-auto" src="assets/images/icons/right_angle.svg" alt="Image placeholder" style="height: 30px; width: 30px;"/>
                  </span>
              </button>
            </div>
            <p class="text-white text-center">{{ viewDateActivity | calendarDate:(view + 'ViewTitle'):language }}</p>
          </div>

        </div>
      </div>
    </div>
    <div *ngIf="dataLoadedWeekly">
      <app-line-chart [plotData]="weeklySteps" [type]="'steps'"></app-line-chart>
    </div>
    <div *ngIf="!dataLoadedWeekly" class="centered preloader d-flex justify-content-center align-items-center pb-5">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <span class="ml-auto mt-4">
      <img src="assets/images/steps.svg" alt="Image placeholder" style="height: 30px; width: 30px; position: absolute; bottom: 10px; right: 10px;"/>
    </span>
  </div>
</ng-template>
