import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { DatePipe } from '@angular/common';


export function compareDateValidation(allAssignedDates): ValidatorFn {
    return function validate(control: UntypedFormControl) {
        if (control.parent && control.parent.get('comparisonDate') && control.parent.get('comparisonDate').value) {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            let enteredDate = control.value + "";
            let date = enteredDate.split("-");

            if (control.value == null || Number(date[0]) > yyyy) {
                return {
                    comparableDateValidation: true,
                };

            }
            if (Number(date[0]) == yyyy) {
                if (Number(date[1]) > Number(mm)) {
                    return {
                        comparableDateValidation: true,
                    };
                }
            }

            if (Number(date[0]) == yyyy) {
                if (Number(date[1]) == Number(mm)) {
                    if (Number(date[2]) > Number(dd))
                        return {
                            comparableDateValidation: true,
                        };
                }
            }
            var found = false;
            allAssignedDates.forEach(assignDate =>{

                var dateDay = String(new Date(assignDate).getDate()).padStart(2, '0');
                var choosenDateDay = String (new Date(control.value).getDate()).padStart(2, '0');

                var dateMonth = String (new Date(assignDate).getMonth() + 1).padStart(2, '0');;
                var choosenDateMonth = String(new Date(control.value).getMonth() + 1).padStart(2, '0');

                var dateYear = new Date(assignDate).getUTCFullYear();
                var choosenDateYear = new Date(control.value).getUTCFullYear()

        
                if (choosenDateDay === dateDay && choosenDateMonth === dateMonth && choosenDateYear === dateYear ){
                    found = true;

                }
            })
            if(!found){
                return {
                    comparableDateValidation: true,
                };

            }
        }

        return null;
    };
}