import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';


import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';

// Models
import { CalendarEvent } from 'angular-calendar';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { IUserProfile } from 'src/app/resolvers';
import { ProfileTabComponent } from './tabs/profile-tab/profile-tab.component';

import { CustomCalendarTabComponent } from './tabs/custom-calendar-tab/custom-calendar-tab.component';
import { HealthTabComponent } from './tabs/health-tab/health-tab.component';
import { NotificationsTabComponent } from './tabs/notifications-tab/notifications-tab.component';
import { PerformanceTabComponent } from './tabs/performance-tab/performance-tab.component';
import { ProgramsTabComponent } from './tabs/programs-tab/programs-tab.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.css'],

})
export class PatientDetailsComponent implements OnDestroy, OnInit {
  @Input() patient: any;



  public isDropdownOpen: boolean = false;




  modalData: {
    action: string;
    event: CalendarEvent;
  };
  public locale: string;

  // patient details
  public patientDetails: any;

  tabs = [
    { name: 'profile', component: ProfileTabComponent, translationKey: 'patientDetails.profile',title:"" },
    { name: 'calendar', component: CustomCalendarTabComponent, translationKey: "patientDetails.calendar",title:"" },
    { name: 'programs', component: ProgramsTabComponent, translationKey: "patientDetails.exercisePrograms", title:""},
    { name: 'performance', component: PerformanceTabComponent, translationKey: "patientDetails.performanceOverview.performanceOverview" ,title:""},
    { name: 'health', component: HealthTabComponent, translationKey: "patientDetails.healthData" ,title:""},
    { name: 'notifications', component: NotificationsTabComponent, translationKey: "notifications.title" ,title:""},
  ]
  selectedTab: any = this.tabs[0]

  public tempActiveTab = 0;
  patientId: string;
  
  me: IUserProfile;
  appointments: any;

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService,
    private authService: AuthService,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.me = this.authService.me;
    this.locale = this.translate.currentLang;
    
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      this.translateTabs()
    });
    this.route.data.subscribe(({ data }) => {

      this.patientDetails = JSON.parse(JSON.stringify(data.patientDetails));
    });



  }

  ngOnDestroy() {


  }
  translateTabs(){
    this.tabs = this.tabs.map( t=>{
      t.title =  this.translate.instant(t.translationKey)
      return t
    })
  }
  ngOnInit(): void {
    this.translateTabs()
    //this.patientId = this.route.snapshot.paramMap.get('id');
    this.route.firstChild?.url.subscribe(urlSegments => {
      const tab = urlSegments[0]?.path || 'profile'; // Default to 'profile' if no tab is found
      this.selectedTab = this.tabs.find(t => t.name == tab);
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)  // Filter for NavigationEnd event
    ).subscribe((event: NavigationEnd) => {
      let url = event.urlAfterRedirects
      if (url.includes('?')) {
        let parts = url.split('?')
        parts = parts[0].split('/')
        let tab = parts[parts.length-1]
        this.selectedTab = this.tabs.find(t => t.name == tab);
      }
      // You can perform any other action here based on the URL change
    });

  }


  navigateTo(path) {
    let cfg: any = { relativeTo: this.route }
    if (path == 'calendar') {
      cfg = { ...cfg, queryParams: { action: 'new-appointment',ts: Date.now() } }
    }
    this.router.navigate([path], cfg);
  }


  // Helper functions
  selectTab(index: number) {
    this.selectedTab = this.tabs[index]

    this.router.navigate([this.selectedTab.name], { relativeTo: this.route });
  }


  goBack(exercise?) {
    this.router.navigate(['dashboard']);

  }


  openModal(content ) {
     return this.modalService.open(content, { size: 'lg' });
  }


}
