<!--<div class="form-group">-->
<!--  <div *ngFor="let question of survey; let i = index">-->
<!--    <label for="answers" class="mt-5">{{i + 1}}. {{question.questionText}}</label>-->
<!--    <div id="answers" class="d-flex justify-content-center">-->
<!--      <button *ngFor="let answer of question.answers; let j = index" class="btn btn-xs {{answer.color}}"-->
<!--              [ngClass]="{'my-border-width': question.choice === survey[i].answers[j].value}"-->
<!--              (click)="answerSelected(i, j)"><span *ngIf="answer.text">{{answer.text}}</span></button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<div *ngIf="dataLoaded" class="form-group">
  <div *ngFor="let question of surveyData.questions; let i = index">
    <label for="answers" class="mt-4">{{i + 1}}. {{question.lang[currentLanguage]}}</label>
    <div [ngSwitch]="question.type" id="answers">
      <div *ngSwitchCase="'scale'" class="d-flex w-100">
        <button *ngFor="let answer of question.options[currentLanguage]; let j = index" class="btn btn-xs button-color-{{j}}"
                [ngClass]="{'my-border-width': answer.value === question.answer}"
        ></button>
        <ng-container *ngFor="let answer of question.options[currentLanguage]; let j = index">
          <button *ngIf="answer.value === question.answer" class="btn btn-xs button-color-{{j}} text-white ml-auto">{{ isWeeklySurvey ? question.answer * 10 : question.answer }}%</button>
        </ng-container>
      </div>
      <div *ngSwitchCase="'input'" class="d-flex w-100">
        <textarea type="textarea" disabled="true" class="w-100 pl-2">{{question.answer ? question.answer : ''}}</textarea>
      </div>
      <div *ngSwitchCase="'radio'" class="d-flex justify-content-center w-100">
        <div *ngIf="question.answer" class="d-flex">
          <div class="p-2">
            <img src="assets/images/emojis/confused-face.svg" alt="Image placeholder"/>
            <p class="text-center">{{ 'generalTerms.no' | translate }}</p>
          </div>
          <div class="p-2">
            <img src="assets/images/emojis/smiling-face-with-squinting-eyes.svg" alt="Image placeholder"/>
            <p class="text-center">{{ 'generalTerms.yes' | translate }}</p>
          </div>
        </div>
        <div *ngIf="!question.answer" class="d-flex">
          <div class="p-2">
            <img src="assets/images/emojis/confused-face-red.svg" alt="Image placeholder"/>
            <p class="text-center">{{ 'generalTerms.no' | translate }}</p>
          </div>
          <div class="p-2">
            <img src="assets/images/emojis/smiling-face-with-squinting-eyes-blue.svg" alt="Image placeholder"/>
            <p class="text-center">{{ 'generalTerms.yes' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
