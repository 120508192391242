<div *ngIf="control?.invalid" class="errors-container">
    <br>
    <div class="alert alert-danger">
        <div *ngIf="control.errors.required">
            <div>{{ 'errorMessages.requiredField' | translate }}</div>
        </div>
        <div *ngIf="control.errors.min">
            <div>{{ 'errorMessages.validValue' | translate }}</div>
        </div>
        <div *ngIf="control.errors.max">
            <div>{{ 'errorMessages.validValue' | translate }}</div>
        </div>
        <div *ngIf="control.errors.pattern && control.errors.pattern.requiredPattern == alphabetPattern">
            <div>{{ 'errorMessages.onlyLetters' | translate }}</div>
        </div>
        <div *ngIf="control.errors && control.errors.birthdateValidation">
            <div>{{ 'errorMessages.ageRange' | translate }}</div>
        </div>
        <div *ngIf="control.errors.pattern && control.errors.pattern.requiredPattern == emailPattern">
            <div>{{ 'errorMessages.validEmail' | translate }}</div>
        </div>
        <div *ngIf="control.errors.pattern && control.errors.pattern.requiredPattern == phonePattern">
          <div>{{ 'errorMessages.validPhone' | translate }}</div>
        </div>
        <div *ngIf="control.errors && control.errors.requireCheckboxesToBeChecked">
            <div>{{ 'errorMessages.minOneCheck' | translate }} </div>
        </div>
        <div *ngIf="control.errors && control.errors.nicotineMinimumAmountToBeChecked">
            <div>{{ 'errorMessages.onlyPositiveNumbers' | translate }}</div>
        </div>
        <div *ngIf="control.errors && control.errors.requiredAuxiliaryToBeChecked">
            <div>{{ 'errorMessages.minOneCheck' | translate }} </div>
        </div>
        <div *ngIf="control.errors && control.errors.requireMedicationToBeChecked">
            <div>{{ 'errorMessages.selectMedication' | translate }} </div>
        </div>
        <div *ngIf="control.errors && control.errors.requireInhalationToBeChecked">
            <div>{{ 'errorMessages.selectInhalationType' | translate }} </div>
        </div>
        <div *ngIf="control.errors && control.errors.oxygenDateValidation">
            <div>{{ 'errorMessages.validDate' | translate }}</div>
        </div>
        <div *ngIf="control.errors && control.errors.oxygenLitreValidation">
            <div>{{ 'errorMessages.onlyPositiveNumbers' | translate }}</div>
        </div>
        <div *ngIf="control.errors && control.errors.requireBreathSoundToBeChecked">
            <div>{{ 'errorMessages.minOneCheck' | translate }}</div>
        </div>
        <div *ngIf="control.errors && control.errors.requireBreathRhymeToBeChecked">
            <div>{{ 'errorMessages.minOneCheck' | translate }}</div>
        </div>
        <div *ngIf="control.errors && control.errors.comparableDateValidation">
            <div>{{ 'errorMessages.comparableDate' | translate }}</div>
        </div>
        <div *ngIf="control.errors && control.errors.timeAndDateRequired">
            <div>{{ 'errorMessages.timeAndDate' | translate }}</div>
        </div>
        <div *ngIf="control.errors && control.errors.invalidDate">
            <div>{{ 'errorMessages.validValue' | translate }}</div>
        </div>
        <div *ngIf="control.errors && control.errors.invalidTime">
            <div>{{ 'errorMessages.validValue' | translate }}</div>
        </div>

    </div>
</div>
