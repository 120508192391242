import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDeletedExercises',
  pure: false
})
export class FilterDeletedExercisesPipe implements PipeTransform {

  transform(items: any[], activeTab?: number): any[] {
    if (!items) {
      return [];
    }
    if (activeTab === 2) {
      return items;
    }
    return items.filter(it => !it.deleted);
  }
}
