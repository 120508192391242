<div class="container-fluid p-0 m-0" (window:resize)="getVideoDimensions()" style="background-color: #111111;">
  <div *ngIf="!dataLoaded" class="spinner-container">
    <div class=" spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
  
</div>
  <span class="top-right pr-5 pt-5">
    <i class="fas fa-times" style="font-size: 40px; color: #D8F3FF; cursor: pointer;" (click)="closeTestView()"></i>
  </span>
  <div [ngClass]="{'d-none': !displayAITrainerInstructionVideo}" class="instruction-video-display">
    <video autoplay playsinline controls src="{{videoInstructionUrl}}" style="height: 70vh; width: auto; object-fit: contain;" id="main-instruction-video" #mainInstructionVideo></video>
  </div>
  <video id="video" class="exercise-display mb-0" autoplay playsinline #cameraVideo></video>
  <canvas id="c1" style="display: none;" #c1></canvas>

  <div *ngIf="exerciseFinished && !serverError" style="height: 100vh; width: 100%; top: 0px; position: fixed;">
    <video autoplay muted loop style="position: relative; height: 100%; min-width: 100%; width: auto;">
      <source src="../../../assets/videos/congratulations_video.mp4" type="video/mp4">
    </video>
  </div>

  <div class="top-left">
    <div *ngIf="exerciseStarted" style="height: 200px; width: auto;" [style.left.px]="timerPositionLeft" [style.top.px]="timerPositionTop" [style.position]="'relative'">
      <circle-progress [percent]="countdownPercentage" [maxPercent]="100" [subtitleFontSize]="80" [subtitle]="this.countdownNumber ? this.countdownNumber : ''" [subtitleColor]="'#384f6b'" [outerStrokeColor]="'#384f6b'" [outerStrokeWidth]="15" [innerStrokeColor]="'transparent'" [startFromZero]="false" [clockwise]="true" [renderOnClick]="false"></circle-progress>
    </div>
  </div>

  <div  *ngIf="dataLoaded" class="center-middle">
    <div *ngIf="beforeExerciseDisplay">
      <div class="card w-100 border-0 p-5 shadow-lg rounded">
        <div class="row">
          <div  class="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center"style="height: 150px;">
            <img  alt="Image placeholder"
            [src]="gifUrl ? gifUrl : '../../../assets/images/Breathment Logo_rev_white.png'"
            style="height: 150px;max-height: 150px; width: auto;" id="navbar-logo">
          </div>
          <div class="col-lg-8 col-sm-12 my-auto">
            <h3 class="text-center">{{ exercise.display_name[language] ?? exercise.display_name['en'] }}</h3>
            <div class="d-flex justify-content-center align-items-center">
              <button *ngIf="!firstExercise" class="btn btn-outline-primary" (click)="playInstructionVideo('ai-trainer')">{{ 'exercise.aiTrainerIntro' | translate }}</button>
              <button class="btn btn-outline-primary" (click)="playInstructionVideo('exercise')">{{ 'patient.startExercise' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="countdown" style="height: 250px; width: auto;">
      <circle-progress [percent]="countdownPercentage" [innerStrokeColor]="'transparent'" [class]="'rounded-circle bg-translucent-secondary'" [maxPercent]="100" [subtitleFontSize]="80" [subtitle]="this.countdownNumber ? this.countdownNumber : ''" [subtitleColor]="'#384f6b'" [outerStrokeColor]="'#384f6b'" [outerStrokeWidth]="15" [startFromZero]="false" [clockwise]="true" [renderOnClick]="false"></circle-progress>
    </div>
    <div *ngIf="exerciseFinished && !serverError">
      <div class="card border-0 shadow-lg z-depth" style="background-color: #374f6b; min-width: 60vw;">
        <div class="card-body pb-5">
          <h1 class="text-white text-center" style="font-family: 'Outfit', sans-serif; font-size: 75px; font-weight: 500;">{{ 'exercise.greatWork' | translate }}</h1>
          <div *ngIf="user.role === 'patient' && showHints">
            <h4 class="text-white p-1"><span><i class="fas fa-award" style="color: white;"></i></span> {{ 'exercise.' + positiveFeedback | translate }}</h4>
            <h2 *ngIf="negativeFeedback.length > 0" class="text-white mt-4">{{ 'exercise.hints' | translate }}:</h2>
            <div *ngFor="let feedback of negativeFeedback; let i = index;">
              <h4 class="text-white p-1"><span><i class="fas fa-angle-double-up" style="color: white;"></i></span> {{ 'exercise.' + feedback | translate }}</h4>
            </div>
          </div>
<!--          <button class="btn btn-secondary mt-5 float-right" (click)="closeTestView()">{{ 'generalTerms.close' | translate}}</button>-->
        </div>
      </div>
    </div>
    <div *ngIf="serverError">
      <div class="card background-primary w-100 border-0 p-5 shadow-lg rounded">
        <h1 class="text-white text-center mb-3" style="font-size: 75px;">{{ 'exercise.aiTrainerNotAvailable' | translate }}</h1>

        <div class="d-flex justify-content-center align-items-center">
          <img alt="Image placeholder" [src]="'../../../assets/Logo/Breathment_Logo_rev_white.png'" style="height: 180px;max-height: 180px;" id="navbar-logo">
          <div class="pl-3">
            <h2 class="text-white text-center">{{ 'exercise.aiTrainerNotAvailableText' | translate}}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <audio  id="mp3AudioPlayer">
    <source type="audio/mp3" />
  </audio>
  <audio  id="beepAudioPlayer" >
    <source type="audio/mp4" />
  </audio>

  <div *ngIf="!exerciseStarted && !exerciseFinished && !displayAITrainerInstructionVideo && !startGestureTriggered && !beforeExerciseDisplay" class="silhouette-position">
    <img *ngIf="initialPose" src="{{initialPoses.correct ? initialPoses.correct : '../../../assets/images/initial-pose/correct-{{exercise.initial_pose.pose}}.png'}}" alt="Image placeholder" [ngStyle]="{'height.px': videoDimensions, 'margin-bottom.px': this.videoMarginBottom}" class="silhouette" [ngClass]="{'initial-pose-margin-small-top': exercise.initial_pose.margin === 'small_top', 'initial-pose-margin-top': exercise.initial_pose.margin === 'top'}">
    <img *ngIf="!initialPose" src="{{initialPoses.wrong ? initialPoses.wrong : '../../../assets/images/initial-pose/wrong-{{exercise.initial_pose.pose}}.png'}}" alt="Image placeholder" [ngStyle]="{'height.px': videoDimensions, 'margin-bottom.px': this.videoMarginBottom}" class="silhouette" [ngClass]="{'initial-pose-margin-small-top': exercise.initial_pose.margin === 'small_top', 'initial-pose-margin-top': exercise.initial_pose.margin === 'top'}">
  </div>
  <div *ngIf="!displayAITrainerInstructionVideo && !exerciseFinished && !beforeExerciseDisplay" class="feedback-background">
    <img src="../../../assets/images/exercise-icons/feedback-background.png" alt="Image placeholder" id="feedback-background" style="height: 100%;">
    <div *ngIf="exerciseStarted" class="feedback-icons-center">
      <img *ngIf="poseWarning" src="../../../assets/images/exercise-icons/warning-icon.png" alt="empty" class="d-block mx-auto pb-3 feedback-icon">
      <!-- <h3 *ngIf="poseWarning" class="text-center" style="color:#7B3939;">{{ 'exercise.incorrectPose' | translate }}</h3> -->
<!--      <img *ngIf="!shoulderEvaluation && !poseWarning" src="../../../assets/images/exercise-icons/ShoulderRaisedWarning.png" alt="empty" class="d-block mx-auto pb-3 feedback-icon">-->
<!--      <img *ngIf="postureEvaluation === 1 && !poseWarning" src="../../../assets/images/exercise-icons/PostureWarning1.png" alt="empty" class="d-block mx-auto pt-3 feedback-icon">-->
<!--      <img *ngIf="postureEvaluation === -1 && !poseWarning" src="../../../assets/images/exercise-icons/PostureWarning-1.png" alt="empty" class="d-block mx-auto pt-3 feedback-icon">-->
      <div *ngIf="!poseWarning">
        <div *ngFor="let warning of exerciseWarnings">
          <div *ngIf="warning.display && !poseWarning">
            <!-- <h3 *ngIf="!showWarningImage" class="text-white text-center">
              {{ 'exercise.' + warning.warning.display_name | translate}}
            </h3> -->
            <div *ngIf="showWarningImage">
              <img *ngIf="warning.warning.unit === 'direction'" alt="Image placeholder" [src]="'../../assets/images/exercise-icons/' + warning.warning.display_name + warning.warning.value + '.png'" (error)="getWarningImage($event, exercise)" id="navbar-logo" class="d-block mx-auto pb-3 feedback-icon">
              <img *ngIf="warning.warning.unit === 'boolean'" alt="Image placeholder" [src]="'../../assets/images/exercise-icons/' + warning.warning.display_name + '.png'" (error)="getWarningImage($event, exercise)" id="navbar-logo" class="d-block mx-auto pb-3 feedback-icon">
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="exerciseStarted && !countdown && !exerciseFinished" class="custom-progress-bar">
    <div style="width: 50%">
      <h3 class="text-white">{{ 'generalTerms.chest' | translate }}</h3>
      <mat-progress-bar mode="determinate" class="primary-color-progress" [value]="breathChestValue" style="height: 20px;"></mat-progress-bar>
      <h3 class="text-white">{{ 'generalTerms.belly' | translate }}</h3>
      <mat-progress-bar mode="determinate" class="primary-color-progress" [ngClass]="{'success-color-progress': correctBreathing, 'danger-color-progress': !correctBreathing}" [value]="breathValue" style="height: 20px;"></mat-progress-bar>
    </div>
  </div>
  <div  class="bottom-center">

<!--    <div class="text-center d-none d-lg-block">-->
<!--      <h4 *ngIf="!exerciseStarted && !countdown && !exerciseFinished && !displayAITrainerInstructionVideo && !initialPose && !beforeExerciseDisplay" class="background-danger p-1">{{ 'exercise.initialPoseError' | translate }}</h4>-->
<!--      <h4 *ngIf="!exerciseStarted && !countdown && !exerciseFinished && !displayAITrainerInstructionVideo && initialPose && !startGesture" class="background-danger p-1">{{ 'exercise.raiseArmStart' | translate }}</h4>-->
<!--      <h4 *ngIf="exerciseStarted && !countdown && !exerciseFinished" class="background-primary p-1">{{exercise.actions[instructionCounter%exercise.actions.length].instruction[language]}}</h4>-->
<!--      <h4 *ngIf="exerciseFinished && !serverError" class="background-primary p-1">{{ 'exercise.exerciseCompleted' | translate }}</h4>-->
<!--    </div>-->
    <button *ngIf="displayAITrainerInstructionVideo" class="btn text-white" style="background-color: #374f6b;" (click)="nextClicked()" (dblclick)="preventDoubleClick()">{{ 'generalTerms.next' | translate }}</button>
  </div>
</div>
