<div *ngIf="!hideSpinner" class="full-screen-center">
  <div class="text-primary" role="status">
    <img src="../../../assets/Logo/spinner-border.gif" alt="Spinner" id="spinner">
  </div>
</div>
<div *ngIf="hideSpinner">
  <app-navbar></app-navbar>

    
  <!-- Main content -->
  <section   class="mt-5" >
    <div class="container d-flex flex-column">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-6 col-lg-5 col-xl-5 py-6 py-md-0">
          <div class="card shadow zindex-100 mb-0">
            <div class="card-body px-md-5 py-5">
              <div class="mb-5">
                <h6 class="h3">{{ 'home.navigation.login' | translate }}</h6>
                <p class="text-muted mb-0">{{ 'login.signinToContinue' | translate }}</p>
              </div>
              <span class="clearfix"></span>
              <form>
                <div class="form-group">
                  <label class="form-control-label">{{ 'login.emailAddress' | translate }}</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-user"></i></span>
                    </div>
                    <input type="email" class="form-control" id="input-email" name="email"
                      placeholder="name@example.com" [(ngModel)]="email">
                  </div>
                </div>
                <div class="form-group mb-0">
                  <div class="d-flex align-items-center justify-content-between">
                    <div>
                      <label class="form-control-label">{{ 'login.password' | translate }}</label>
                    </div>
                    <div class="mb-2">
                      <!--<a href="#" class="small text-muted text-underline&#45;&#45;dashed border-primary" data-toggle="password-text" data-target="#input-password">Show password</a>-->
                    </div>
                  </div>
                  <div class="input-group" (keyup.enter)="login();">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-key"></i></span>
                    </div>
                    <input type="password" class="form-control" id="input-password" name="password"
                      placeholder="Password" [(ngModel)]="password">
                  </div>
                </div>
                <div class="mt-4">
                  <!--                <p *ngIf="authError" class="alert alert-danger">{{ authError?.message | translate}}</p>-->
                  <p *ngIf="authError" class="alert alert-danger">{{ 'login.error' | translate}}</p>
                  <button type="button" class="btn btn-block btn-primary" [disabled]="loggingIn" (click)="login();">
                    <span *ngIf="!loggingIn">{{ 'home.navigation.login' | translate }}</span>
                    <div *ngIf="loggingIn"
                      style="color: white !important; height: 1.3rem !important; width: 1.3rem !important; border: 0.2em solid currentColor; border-right-color: transparent;"
                      class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                  <div class="mt-2 text-center">
                    <small><a class="text-primary" style="cursor: pointer;" (click)="openModal(forgotPasswordModal)">{{
                        'login.forgotPassword' | translate }}</a></small>
                  </div>
                  <div class="mt-3" style="line-height: 1;">
                    <small>{{ 'login.termsText1' | translate }} <span><a href="https://breathment.com/legal/terms.html"
                          target="_blank" class="text-primary">{{ 'home.bottomNavigation.terms' | translate
                          }}</a></span>. {{ 'login.termsText2' | translate
                      }} <span><a href="https://breathment.com/legal/privacy-policy/website.html" target="_blank"
                          class="text-primary">{{
                          'home.bottomNavigation.privacyForWeb' | translate }}</a></span>.</small>
                  </div>
                </div>
              </form>
            </div>
            <!--<div class="card-footer px-md-5"><small>Not registered?</small>-->
            <!--<a href="#" class="small font-weight-bold"> Create account</a>-->
            <!--</div>-->
          </div>
        </div>
      </div>
    </div>
  </section>

</div>

<ng-template #forgotPasswordModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ 'login.forgotPasswordModalTitle' | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!emailSent">
      <p>{{ 'login.forgotPasswordModalText' | translate }}</p>
      <form [formGroup]="emailValidationGroup">
        <div class="form-group">
          <input type="email" class="form-control" id="input-email" name="email" placeholder="john@doe.com"
            formControlName="email">
          <app-error-messages *ngIf="checkEmail" [control]="emailValidationGroup.get('email')"></app-error-messages>
        </div>
      </form>
    </div>
    <div *ngIf="emailSent">
      <p>{{ 'login.resetPasswordEmailSent' | translate }}</p>
    </div>
    <div *ngIf="emailNotFound" class="text-center">
      <small class="text-danger">{{ 'login.emailNotFound' | translate }}</small>
    </div>

  </div>
  <div class="modal-footer">
    <button *ngIf="!emailSent" type="button" class="btn btn-sm btn-primary" (click)="resetPassword();">{{
      'generalTerms.send' | translate }}</button>
    <button *ngIf="!emailSent" type="button" class="btn btn-sm btn-secondary"
      (click)="clearVariables(); modal.close();">{{ 'generalTerms.cancel' | translate }}</button>
    <button *ngIf="emailSent" type="button" class="btn btn-sm btn-secondary"
      (click)="clearVariables(); modal.close();">{{ 'generalTerms.close' | translate }}</button>
  </div>
</ng-template>