<div *ngIf="loading" class="full-screen-center" ngClass="loading">
  <div class="text-primary" role="status">
      <img [src]="'assets/Logo/spinner-border.gif'" alt="Spinner" id="spinner">
  </div>
</div>
<div *ngIf="!loading" class="container-fluid d-flex g-4 helth-card-container flex-wrap">
  <ul class="list d-flex g-2 flex-column" style="width: 250px; flex: 1">
    <li>
      <button
        (click)="setActiveView('activity')"
        style="
          width: 100%;
          padding: 12px 24px;
          border-radius: 16px;
          background-color: #fff;
        "
        [ngClass]="{
          'active-border': activeView === 'activity',
          'inactive-border': activeView !== 'activity'
        }"
      >
        {{ "patientDetails.performanceOverview.activity" | translate }}
      </button>
    </li>
    <li>
      <button
        (click)="setActiveView('survey-results')"
        style="
          width: 100%;
          padding: 12px 24px;
          border-radius: 16px;
          background-color: #fff;
        "
        [ngClass]="{
          'active-border': activeView === 'survey-results',
          'inactive-border': activeView !== 'survey-results'
        }"
      >
        {{ "patientDetails.performanceOverview.surveyResults" | translate }}
      </button>
    </li>
  </ul>

  <div
    *ngIf="activeView === 'activity'"
    class="d-flex justify-content-center"
    style="min-width: 250px; max-width: 900px; flex: 3"
  >
    <app-activity-card
      [patientId]="patientId"
      [stepGoal]="patientDetails.step_goal"
      style="width: 100%"
    ></app-activity-card>
  </div>

  <div
    *ngIf="activeView === 'survey-results'"
    class="d-flex justify-content-center"
    style="min-width: 250px; max-width: 900px; flex: 3"
  >
    <app-survey-card
      [patientId]="patientId"
      [tab]="'daily'"
      (viewReady)="0"
      style="width: 100%"
    ></app-survey-card>
  </div>
</div>
