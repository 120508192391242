<div *ngIf="loading" class="full-screen-center" ngClass="loading">
    <div class="text-primary" role="status">
        <img [src]="'assets/Logo/spinner-border.gif'" alt="Spinner" id="spinner">
    </div>
</div>
<div class="container-fluid" #topSection>
    <div class="card container-fluid" style="margin: 0;">
        <div class="card-header">
            <div class="card-title w-100" style="display: inline-flex">
                <h3 *ngIf="me.medical">
                    {{ "patientDetails.patientInformation" | translate }}
                </h3>
                <h3 *ngIf="!me.medical">
                    {{ "patientDetails.clientInformation" | translate }}
                </h3>
                <div *ngIf="!edit && old_system==false" class="d-flex col-3 ">
                    <div ngbDropdown aria-labelledby="dropdownMenuButton">
                        <button class="form-control btn btn-xs btn-secondary" ngbDropdownToggle>
                            {{selectedVersion??'Select a version'}}</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="min-width: 100%;">
                            <button ngbDropdownItem *ngFor="let v of patientVersions;count as versionsCount"
                                style="font-size: 0.85rem; text-align: left;" (click)="onSelectedVersionChanged(v   )">
                                {{ v.title }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-end flex-grow-1">
                    <div *ngIf="!edit">
                        <div ngbDropdown aria-labelledby="dropdownMenuButton">
                            <button style="padding: 2px 12px 0.5px 12px" class="form-control btn btn-xs btn-secondary"
                                ngbDropdownToggle>
                                <i class="fas fa-cog" style="font-size: 1.3rem; color: rgb(56, 79, 107, 0.6)"></i>
                                <i class="fas fa-angle-down"
                                    style="font-size: 1.3rem; color: rgb(56, 79, 107, 0.6); margin-left: 8px; margin-right: 0;"></i>
                                <i class="fas fa-angle-up"
                                    style="font-size: 1.3rem; color: rgb(56, 79, 107, 0.6); margin-left: 8px;"></i>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="min-width: 100%;">
                                <button ngbDropdownItem style="text-align: left;" (click)="patientResetPassword()">
                                    {{ 'resetPassword.title' | translate }}
                                </button>
                                <button ngbDropdownItem style="text-align: left;"
                                    (click)="openChangeAccessModal(changeAccessModal)">
                                    {{ 'patientDetails.changeAccess' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="old_system==false && patientVersions?.length>0 && selectedVersion===patientVersions[0].title"
                        style="padding: 2px 10px 0.5px 10px; cursor: pointer; height: 44px; width: unset"
                        class="icon icon-shape bg-primary text-white ml-2" (click)="editModeOn(activeTab)">
                        <i class="fas fa-edit" style="font-size: 1.25rem;"></i>
                    </div>
                </div>
            </div>
            <div class="card-subtitle">
                <ul class="nav nav-pills nav-fill mr-auto" style="width: 100%">
                    <li class="nav-item" style="width: 17%; height: 3%"
                        *ngFor="let card of allCards;let cardIndex = index;">
                        <a class="nav-link" [ngClass]="{ active: activeTab === cardIndex }"
                            (click)="activeTab = cardIndex">{{
                            old_system==false?card.title[locale]:card.title
                            }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card-body px-4 pb-1">
            <div class="card-text">
                <div>
                    <div *ngIf="!edit && old_system==false">
                        <div class="row" *ngIf="allCards?.length>0">

                            <div class="col-lg-6 col-md-8 col-sm-10">
                                <ng-container
                                    *ngFor="let row of allCards[activeTab].questions;let rowIndex=index;let rowsCount=count;">
                                    <ng-container *ngIf="rowIndex<(rowsCount/2)">
                                        <ng-container [ngTemplateOutlet]="answeredQuestion"
                                            [ngTemplateOutletContext]="{row:row}">
                                        </ng-container>
                                    </ng-container>
                                </ng-container>


                            </div>
                            <div class="col-lg-6 col-md-8 col-sm-10">
                                <ng-container
                                    *ngFor="let row of allCards[activeTab].questions;let rowIndex=index;let rowsCount=count;">
                                    <ng-container *ngIf="rowIndex>=(rowsCount/2)">
                                        <ng-container [ngTemplateOutlet]="answeredQuestion"
                                            [ngTemplateOutletContext]="{row:row}">
                                        </ng-container>
                                    </ng-container>
                                </ng-container>


                            </div>

                        </div>
                    </div>

                    <!-- backward combatability -->
                    <div *ngIf="!edit && old_system==true">
                        <div class="row" *ngIf="allCards?.length>0">
                            <div class="col-lg-6 col-md-8 col-sm-10">
                                <ng-container
                                    *ngFor="let row of allCards[activeTab].questions;let rowIndex=index;let rowsCount=count;">
                                    <ng-container *ngIf="rowIndex<(rowsCount/2)">
                                        <ng-container [ngTemplateOutlet]="answeredQuestionOld"
                                            [ngTemplateOutletContext]="{row:row}">
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="col-lg-6 col-md-8 col-sm-10">
                                <ng-container
                                    *ngFor="let row of allCards[activeTab].questions;let rowIndex=index;let rowsCount=count;">
                                    <ng-container *ngIf="rowIndex>=(rowsCount/2)">
                                        <ng-container [ngTemplateOutlet]="answeredQuestionOld"
                                            [ngTemplateOutletContext]="{row:row}">
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="edit">
                        <app-custom-form-builder [forUpdate]="true" [activeCardIndex]="activeTab"
                            [totalCards]="allCards?.length" (onChange)="onSomethingChanged($event)"
                            (onCancel)="onCancelEditing()" [inprogress]="saving"
                            [options]="allCards[activeTab].questions"
                            (onSubmit)="openSaveModal(confirmUpdatePatientDetails,$event)"></app-custom-form-builder>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<ng-template #answeredQuestion let-row='row'>
    <ng-container *ngFor="let el of row">
        <div class="d-flex" *ngIf="el.type=='dropdown' && el.show">
            <h6 class="text-body center-text">{{el.label}}:
                <span class="badge bg-secondary m-1">{{el.value}}</span>
            </h6>
        </div>
        <div class="d-flex" *ngIf="el.type=='radio' && el.show">
            <h6 class="text-body">{{el.label}}:
                <span class="badge bg-secondary m-1">{{getRadioValue(el)}}</span>
            </h6>
        </div>
        <div class="d-flex" *ngIf="el.type=='text' && el.show">
            <h6 class="text-body">{{el.label}}:
                <span class="text-body m-1">{{el.value}}</span>
            </h6>
        </div>
        <div class="d-flex" *ngIf="el.type=='textarea' && el.show">
            <h6 class="text-body" style="display: block; height: fit-content;">{{el.label}}:
                <span class="text-body m-1">{{el.value}}</span>
            </h6>
        </div>
        <div class="d-flex" *ngIf="el.type=='number' && el.show">
            <h6 class="text-body">{{el.label}}:
                <span class="text-body m-1">{{el.value}}</span>
            </h6>
        </div>
        <div class="d-flex" *ngIf="el.type=='date' && el.show">
            <h6 class="text-body">{{el.label}}:
                <span class="badge text-body m-1">{{el.value}}</span>
            </h6>
        </div>
        <div class="row mb-1" *ngIf="el.type=='checkbox' && el.show">
            <div class="col-lg-12">
                <h6 class="inline text-body">{{el.label}}:
                </h6>
            </div>
            <div class="col-lg-12">

                <span class="badge bg-secondary m-1 checkbox-elem"
                    *ngFor="let opt of getCheckboxValue(el)">{{opt.label}}</span>
            </div>

        </div>


    </ng-container>
</ng-template>


<ng-template #answeredQuestionOld let-row='row'>
    <ng-container *ngFor="let q of row">
        <div class="row" *ngIf="q.show">
            <h6 class="text-body row ml-0 mr-2 mb-2 question" style="max-width: -webkit-fill-available;">{{q.label}}
                <span *ngIf="q.value" class="badge bg-secondary m-1">{{q.value}}</span>
            </h6>
            <div class="row ml-0 mr-2 mb-2" *ngIf="q.values" style="max-width: -webkit-fill-available;">
                <span *ngFor="let val of q.values" class="badge bg-secondary m-1">{{val | async}}</span>
            </div>
        </div>
    </ng-container>
</ng-template>


<ng-template #changeAccessModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'patientDetails.changeAccess' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group mb-0">
            <form action="" class="user-access-form">
                <div class="d-flex align-items-center">
                  <input type="radio" [(ngModel)]="newUserAccessLevel" name="accessLevel" value="limited_access">
                  <span class="ml-2 text-lg">
                    {{ 'patientDetails.limitedAccess' | translate }}
                  </span>
                </div>

                <div class="d-flex align-items-center">
                  <input type="radio" [(ngModel)]="newUserAccessLevel" name="accessLevel" value="full_access">
                  <span class="ml-2 text-lg">{{ 'patientDetails.fullAccess' | translate }}</span>
                </div>

                <div class="d-flex align-items-center">
                  <input type="radio" [(ngModel)]="newUserAccessLevel" name="accessLevel" value="contract_not_signed">
                  <span class="ml-2 text-lg">{{ 'patientDetails.contractNotSigned' | translate }}</span>
                </div>

                <div class="d-flex align-items-center">
                  <input type="radio" [(ngModel)]="newUserAccessLevel" name="accessLevel" value="book_a_meeting_with_physio">
                  <span class="ml-2 text-lg">{{ 'patientDetails.bookAMeetingWithPhysio' | translate }}</span>
                </div>

                <div class="d-flex align-items-center">
                  <input type="radio" [(ngModel)]="newUserAccessLevel" name="accessLevel" value="in_trial">
                  <span class="ml-2 text-lg">{{ 'patientDetails.inTrial' | translate }}</span>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary text-white" (click)="changeAccess(); modal.close()">
            {{ 'generalTerms.save' | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
            {{ 'generalTerms.cancel' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #confirmUpdatePatientDetails let-modal>
    <div class="modal-header">
      <h5 class="modal-title">
        {{ "patientDetails.updatePatientInformation.title" | translate }}
      </h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        {{
        "patientDetails.updatePatientInformation.updateQuestionText" | translate
        }}
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-sm btn-primary" (click)="modal.close(); updatePatientDetails();">
        {{ "generalTerms.yes" | translate }}
      </button>
      <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close(); cancelEditPersonalInfo()">
        {{ "generalTerms.no" | translate }}
      </button>
    </div>
  </ng-template>
