<app-navbar></app-navbar>
<div *ngIf="!dataLoaded" class="full-screen-center">
  <div class="text-primary" role="status">
    <img src="../../../assets/Logo/spinner-border.gif" alt="Spinner" id="spinner">
  </div>
</div>
<!-- Main content -->
<section class="mt-2">
  <div class="container d-flex flex-column">
    <i routerLink="/settings" class="fa fa-arrow-left" aria-hidden="true" style="font-size: 30px; cursor: pointer; color: rgb(9, 66, 92);"></i>
    <div class="row align-items-center justify-content-center">
        <div class="col-md-6 col-lg-5 col-xl-5 py-6 py-md-0">
        <div class="card shadow zindex-100 mb-0">
          
          <div class="card-body px-md-5 py-5">
            <div   class="mb-5  text-center">
                <img class="mb-3" src="assets\images\changeEmail\password_lock_480.png" alt="lockimage" style="width: 160px; height: auto;">
              <h6 class="h3">{{ 'home.navigation.changePassword' | translate }}</h6>
              <p class="text-muted mb-0">{{ 'home.navigation.writePassword' | translate }}</p>
            </div>
            <span class="clearfix"></span>
            <form  (keyup.enter)="confirm()">
              <div class="form-group mb-0">
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <label class="form-control-label">{{ 'login.oldPassword' | translate }}</label>
                  </div>
                  <div class="mb-2">
                    <!--<a href="#" class="small text-muted text-underline&#45;&#45;dashed border-primary" data-toggle="password-text" data-target="#input-password">Show password</a>-->
                  </div>
                </div>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-key"></i></span>
                  </div>
                  <input type="password" class="form-control" id="input-password" name="password"  [(ngModel)]="password">
                </div>
              </div>
              <div class="mt-4">
<!--                <p *ngIf="authError" class="alert alert-danger">{{ authError?.message | translate}}</p>-->
                <p *ngIf="!isCorrectPassword" class="alert alert-danger">{{ 'resetPassword.error' | translate}}</p>
                <button [disabled]="password == ''" (click)="confirm()" type="button" class="btn btn-block btn-primary"  >{{ 'home.navigation.confirm' | translate }}</button>


              </div>
            </form>
          </div>
          <!--<div class="card-footer px-md-5"><small>Not registered?</small>-->
            <!--<a href="#" class="small font-weight-bold"> Create account</a>-->
          <!--</div>-->
        </div>
      </div>
    </div>
  </div>
</section>
