import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from "../../environments/environment";
import fetchData from '@helpers/fetchData';

@Injectable({
  providedIn: 'root'
})
export class Http2Service {
  private baseUrl: string;
  constructor(private authService: AuthService
    ) {
    this.baseUrl = environment.baseCpuUrl;
  }
  fetch(url: string, body: any) {
    const uri = this.buildUrl(url);
    return fetchData(this.authService,uri, body)
  }
  
  private buildUrl(url: string): string {
    return new URL(url, this.baseUrl).href;
  }
}
