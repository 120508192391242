<!-- <div
  [tippy]="
    'patientDetails.performanceOverview.tooltips.surveyResults' | translate
  "
  placement="auto"
  #survey
> -->
<div class="card p-2 mb-4 mx-3">
  <div class="card-header">
    <div
      class="card-title w-100"
      style="display: inline-flex; margin-bottom: 0"
    >
      <h3 class="w-50" *ngIf="tab === 'daily'">
        {{ "patientDetails.performanceOverview.dailySurvey" | translate }}
      </h3>
      <h3 class="w-50" *ngIf="tab === 'weekly'">
        {{ "patientDetails.performanceOverview.weeklySurvey" | translate }}
      </h3>
    </div>
  </div>

  <div *ngIf="dataLoadedSurveys" class="card-body pt-0">
    <div class="d-flex g-2" style="background-color: #f7f7f7">
      <button
        (click)="tab = 'daily'"
        class="btn-custom"
        [ngClass]="{ active: tab === 'daily' }"
      >
        {{ "patientDetails.performanceOverview.dailySurvey" | translate }}
      </button>
      <button
        (click)="tab = 'weekly'"
        class="btn-custom"
        [ngClass]="{ active: tab === 'weekly' }"
      >
        {{ "patientDetails.performanceOverview.weeklySurvey" | translate }}
      </button>
    </div>
    <ul class="nav-tabs m-0" style="border-bottom: none">
      <li *ngIf="tab === 'daily'">
        <div class="d-flex flex-column w-100 mb-4">
          <span class="font-weight-bold" style="margin-top: 1rem">
            B:
            <span class="font-weight-light">
              {{ "patientDetails.beforeExercise" | translate }}</span
            >
          </span>
          <span class="font-weight-bold">
            A:
            <span class="font-weight-light">
              {{ "patientDetails.afterExercise" | translate }}
            </span>
          </span>
        </div>

        <canvas
          baseChart
          class="chart"
          [datasets]="barChartData"
          [options]="barChartOptions"
          [labels]="barChartLabels"
          [chartType]="'bar'"
          [colors]="chartColors"
          (chartClick)="chartClicked($event)"
        ></canvas>

        <div class="d-flex justify-content-center mt-4">
          <div class="d-flex">
            <button
              class="btn p-2"
              mwlCalendarPreviousView
              [view]="view"
              [(viewDate)]="viewDateSurvey"
              (viewDateChange)="closeOpenMonthViewDay()"
              (click)="changeViewClickSurvey()"
            >
              <span>
                <img
                  src="assets/images/icons/left_angle.svg"
                  alt="Image placeholder"
                  style="height: 30px; width: 30px"
                />
              </span>
            </button>

            <button
              class="btn"
              mwlCalendarToday
              [(viewDate)]="viewDateSurvey"
              (click)="changeViewClickSurvey()"
            >
              <p class="text-center m-0">
                {{
                  viewDateSurvey | calendarDate : view + "ViewTitle" : language
                }}
              </p>
            </button>

            <button
              class="btn p-2"
              mwlCalendarNextView
              [view]="view"
              [(viewDate)]="viewDateSurvey"
              (viewDateChange)="closeOpenMonthViewDay()"
              (click)="changeViewClickSurvey()"
            >
              <span>
                <img
                  class="my-auto"
                  src="assets/images/icons/right_angle.svg"
                  alt="Image placeholder"
                  style="height: 30px; width: 30px"
                />
              </span>
            </button>
          </div>
        </div>
      </li>
      <li *ngIf="tab === 'weekly'">
        <div
          *ngIf="!monthlySurveyLoaded"
          class="d-flex w-100 justify-content-center"
        >
          <div class="spinner-border text-primary my-8" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div *ngIf="monthlySurveyLoaded">
          <div
            [class]="
              !monthlySurveyEmpty
                ? 'd-flex w-100 align-items-center mt-2 mb-1 justify-content-between'
                : 'd-flex w-100 align-items-center mt-2 mb-1 justify-content-end'
            "
          >
            <div *ngIf="!monthlySurveyEmpty" ngbDropdown autoClose="outside">
              <button
                class="btn btn-sm btn-secondary"
                id="dropdownQuestionsButton"
                ngbDropdownToggle
              >
                {{
                  "patientDetails.performanceOverview.selectQuestions"
                    | translate
                }}
              </button>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdownQuestionsButton"
                style="
                  max-height: 18rem;
                  overflow-y: auto;
                  padding: 0.2rem 0.1rem;
                "
              >
                <div
                  ngbDropdownItem
                  *ngFor="let question of lineChartDataCopy; index as i"
                  style="cursor: pointer"
                  class="d-flex rounded my-2 py-1 px-2"
                  (click)="questionSelect(i)"
                >
                  <input type="checkbox" [(ngModel)]="question.showLine" />
                  <span class="ml-2" style="color: #4a5568 !important">{{
                    question.label
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="!monthlySurveyEmpty"
            class="d-flex flex-column mb-2"
            style="gap: 0.3rem"
          >
            <div
              *ngFor="let dataset of lineChartData"
              class="d-flex align-items-center"
            >
              <div
                style="
                  width: 0.5rem;
                  height: 0.5rem;
                  border-radius: 20rem;
                  margin-right: 1rem;
                "
                [ngStyle]="{ 'background-color': dataset.backgroundColor }"
              ></div>
              <span class="text-sm">{{ dataset.label }}</span>
            </div>
          </div>

          <canvas
            baseChart
            class="chart"
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [options]="lineChartOptions"
            [colors]="lineChartColors"
            [chartType]="'bar'"
            class="mt-4"
          >
          </canvas>

          <div class="d-flex justify-content-center">
            <div class="d-flex">
              <button
                class="btn p-2"
                mwlCalendarPreviousView
                [view]="monthlyView"
                [(viewDate)]="viewDateMonthlySurvey"
                (click)="changeMonthlyViewClickSurvey()"
              >
                <span>
                  <img
                    src="assets/images/icons/left_angle.svg"
                    alt="Image placeholder"
                    style="height: 30px; width: 30px"
                  />
                </span>
              </button>
              <button
                class="btn"
                mwlCalendarToday
                [(viewDate)]="viewDateMonthlySurvey"
                (click)="changeMonthlyViewClickSurvey()"
              >
                <p class="text-center m-0">
                  {{
                    viewDateMonthlySurvey
                      | calendarDate : monthlyView + "ViewTitle" : language
                  }}
                </p>
              </button>
              <button
                class="btn p-2"
                mwlCalendarNextView
                [view]="monthlyView"
                [(viewDate)]="viewDateMonthlySurvey"
                (click)="changeMonthlyViewClickSurvey()"
              >
                <span>
                  <img
                    class="my-auto"
                    src="assets/images/icons/right_angle.svg"
                    alt="Image placeholder"
                    style="height: 30px; width: 30px"
                  />
                </span>
              </button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div
    *ngIf="!dataLoadedSurveys"
    class="centered preloader d-flex justify-content-center align-items-center h-100 pb-5"
  >
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<ng-template #surveyResultsModal let-modal>
  <div class="modal-body">
    <h3>
      {{ "patientDetails.performanceOverview.preProgramSurvey" | translate }}
    </h3>
    <p *ngIf="!chosenBeforeSurvey">
      {{ "patientDetails.performanceOverview.surveyNotFilled" | translate }}
    </p>
    <div *ngIf="chosenBeforeSurvey">
      <app-survey [surveyData]="chosenBeforeSurvey"></app-survey>
    </div>
    <h3 class="mt-5">
      {{ "patientDetails.performanceOverview.postProgramSurvey" | translate }}
    </h3>
    <p *ngIf="!chosenAfterSurvey">
      {{ "patientDetails.performanceOverview.surveyNotFilled" | translate }}
    </p>

    <div *ngIf="chosenAfterSurvey">
      <app-survey [surveyData]="chosenAfterSurvey"></app-survey>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-sm btn-secondary"
      (click)="modal.close()"
    >
      {{ "generalTerms.close" | translate }}
    </button>
  </div>
</ng-template>
