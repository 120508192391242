export const CHART_CONFIG = {
  OPTIONS: {
    responsive: true,
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Time (s)',
        },
        ticks: {
          precision: 0,
          autoSkip: true,
          stepSize: 2,
          maxTicksLimit: 27,
        },
      },
      y: {
        suggestedMin: 60,
        suggestedMax: 100,
      },
    },
    plugins: {
      legend: { display: true,  },
      annotation: {
        annotations: [],
      },
    },
  },
  FILES: {
    FIRST: 'first',
    SECOND: 'second',
  },
  COMMON: {
    label: '03.02.2022',
    spanGaps: true,
    backgroundColor: 'rgba(92,111, 139,0)',
    borderColor: 'rgba(92,111, 139,1)',
    pointStyle: 'circle',
    pointRadius: 3,
    pointBackgroundColor: 'rgba(92,111, 139,0)',
    pointBorderColor: 'rgba(92,111, 139,1)',
    pointBorderWidth: 1,
    fill: 'origin',
  },
  ANNOTATIONS: {
    GENERAL: {
      type: 'box',
      yMin: 0,
      yMax: 100,
      borderColor: 'rgba(92,111, 139,1)',
    },
    inhale: {
      backgroundColor: 'rgba(181,210,246,0.25)',
    },
    exhale: {
      backgroundColor: 'rgba(182,128,175,0.25)',
    },
    hold: {
      backgroundColor: 'rgba(255,255,255,0.25)',
    },
    LEGEND: [
      {
        label: 'Inhale',
        backgroundColor: 'rgba(237, 244, 252, 1)',
        borderWidth: 0,
        data: []
      },
      {
        label: 'Exhale',
        backgroundColor: 'rgba(235, 223, 235, 1)',
        borderWidth: 0,
        data: []
      }
    ]
  },
};
