import { Component, OnInit } from '@angular/core';
import { StoreService } from '@services/store.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-physiotherapist',
  templateUrl: './physiotherapist.component.html',
  styleUrls: ['./physiotherapist.component.css']
})
export class PhysiotherapistComponent implements OnInit {
 
  constructor(private storeService: StoreService) { }
  ngOnInit(): void {

  }

}
