import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { environment } from "../../../environments/environment";
import { Http2Service } from '@services/http2.service';

@Injectable({
  providedIn: 'root'
})
export class ExerciseService {

  public baseUrl: string;

  constructor(
    private afs: AngularFirestore,
    private http: Http2Service
  ) {
    this.baseUrl = environment.baseCpuUrl;
    // this.baseUrl = environment.baseUrl;
  }

  getExercises(formData?) {
    let requestData;
    if (formData) {
      requestData = formData;
    } else {
      requestData = {};
    }
    return this.http.fetch(this.baseUrl + 'get_exercise_list', requestData).pipe();
  }

  createExerciseProgram(requestData) {
    return this.http.fetch(this.baseUrl + 'create_exercise_program', requestData).pipe();
  }


  updateExerciseProgram(requestData) {
    return this.http.fetch(this.baseUrl + 'edit_exercise_program', requestData).pipe();
  }

  deleteExerciseProgram(requestData) {
    return this.http.fetch(this.baseUrl + 'delete_exercise_program', requestData).pipe();
  }

  uploadResults(requestData) {
    return this.http.fetch(this.baseUrl + 'flush_session', requestData).pipe();
  }

  getSessionResults(requestData) {
    return this.http.fetch(this.baseUrl + 'get_session_analysis', requestData).pipe();
  }

  getBreathingPlot(requestData) {
    return this.http.fetch(this.baseUrl + 'get_breathing_plot', requestData).pipe();
  }

  getActiveTreatmentDetails(requestData) {
    return this.http.fetch(this.baseUrl + 'get_exercise_program_completion_summary', requestData).pipe();
  }

  // createExercise(exercise: Exercise) {
  //   return this.afs.doc<Exercise>(`exercises/${exercise.exercise_id}`).set(exercise);
  // }

  // deleteExercise(exercise: Exercise) {
  //   return this.afs.doc<Exercise>(`exercises/${exercise.exercise_id}`).delete();
  // }

  // updateExercise(exercise: Exercise) {
  //   return this.afs.doc<Exercise>(`exercises/${exercise.exercise_id}`).set(exercise, { merge: true });
  // }

  getProgramTemplates() {
    return this.http.fetch(this.baseUrl + 'get_exercise_program_template', {}).pipe();
  }

  createProgramTemplate(requestData) {
    return this.http.fetch(this.baseUrl + 'create_exercise_program_template', requestData).pipe();
  }

  deleteProgramTemplate(requestData) {
    return this.http.fetch(this.baseUrl + 'delete_exercise_program_template', requestData).pipe();
  }

  updateProgramTemplate(requestData) {
    return this.http.fetch(this.baseUrl + 'update_exercise_program_template', requestData).pipe();
  }

}
