
import { Injectable, ViewChild } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { OldEmailComponent } from '../views/old-email/old-email.component';

@Injectable({
  providedIn: 'root'
})
export class passwordChangeGuard implements CanActivate {
   
  constructor(
    private auth: AuthService,
    protected readonly router: Router
    
  ) {
   
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (this.auth.getCurrentPassword()) {
      this.auth.setCurrentPassword('');
      return true;
    }
    this.router.navigate(['/dashboard']);
    return false;
  }

}
