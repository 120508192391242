import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { environment } from '@environments/environment';
import { UserService } from '@services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-settings-screen',
  templateUrl: './settings-screen.component.html',
  styleUrls: ['./settings-screen.component.css']
})
export class SettingsScreenComponent implements OnInit {

  version: string = '';

  public role: string = '';
  public problemMessage: string = '';
  constructor(public auth: AuthService, private userService: UserService, private modalService: NgbModal,) {
    try {
      this.version = environment.APP_VERSION_ABC ?? ',/';
      if (environment.production)
        this.version = this.version.split(',')[1].split('/')[1]
      else this.version = this.version.substring(0, 7)
    } catch (error) {

    }
    this.role = this.auth.me.role;
  }
  ngOnInit(): void { }
  logout() {
    this.auth.logout();

  }

  openModal(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  deleteAccount() {
    this.userService.deleteUser({ uid: this.auth.me.id }).subscribe(val => {

      this.auth.logout();
    });
  }

  sendProblemMessage() {
    this.userService.sendCustomerFeedback({ message: this.problemMessage, is_technical: false }).subscribe(val => {

    });
  }
}
