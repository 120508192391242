import { Injectable } from '@angular/core';
import { ProgramManager } from '.';

@Injectable({
  providedIn: 'root'
})
export class Pm2Service {
  setLanguage(language: string) {
    this.pm2.setLanguage(language)
  }
  autoSelect(dates: Date[]) {
    this.pm2.autoSelect(dates)
  }
  pm2: ProgramManager = new ProgramManager()
  get all_events$() {
    return this.pm2.events$
  }
  constructor() { }
  setPrograms(all: any[]) {
    this.pm2.setPrograms(all)
  }
  setSelectedProgram(pid: string) {
    this.pm2.setSelectedProgram(pid)
  }
  initProgram(items: any[]) {
    const list = items.reduce((p, c, i) => {
      c.exercise_id = c._id
      c.periods.forEach((period, index) => {
        if (period) {
          p.push({ ...c, periods: null, period: index ,performed:false})
        }
      })
      return p;
    }, [])
    this.pm2.initProgram(list)
  }
  getExercisesList(): any[] {
    return this.pm2.getExercisesList()
  }
  getEvents(): any[] {
    return this.pm2.getEvents()
  }
  deleteProgram(pid: string) { 
    this.pm2.deleteProgram(pid)
  }
  toggleEvent(date: Date) {
    this.pm2.toggleProgramDateEvents(date)
  }
}
