import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Http2Service } from '@services/http2.service';
import { map, tap } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { BehaviorSubject, of } from 'rxjs';

export interface Notification {
  _id: string;
  messageId: string;
  data: {
    program_id: string;
  };
  from: string;
  notification: {
    title: string;
    body: string;
  };
  sentTime: string;
  status: 'read' | 'unread';
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public baseUrl: string;
  public onChange$: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  constructor(private http: Http2Service) {
    this.baseUrl = environment.baseCpuUrl;
  }

  getPhysioNotificationAboutPatient(patientId: string, language?: string) {
    language = (language || 'en').toLocaleLowerCase();
    let requestData: any = {
      patient_uid: patientId,
      language: language,
    };

    return this.http
      .fetch(`${this.baseUrl}get_notifications_by_patient`, requestData)
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        map((list) => {
          return list.map((n) => ({
            ...n,
            send_time: DateTime.fromISO(n.send_time, { zone: 'utc' }).toLocal(),
          }));
        })
      );
  }
  checkUnreadPatientsNotifications(patientsIds: string[]) {
    let requestData: any = {
      patient_list: patientsIds,
    };
    return this.http
      .fetch(`${this.baseUrl}check_unread_patient_notification`, requestData)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getNotifications(lang: string, isRead?: boolean) {
    lang = lang || 'en';
    const requestData: any = { language: lang.toLowerCase() };
    if (isRead !== undefined) {
      requestData['isRead'] = isRead;
    }

    return this.http
      .fetch(`${this.baseUrl}get_notifications`, requestData)
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        map((list) => {
          return list
            ? list.map((n) => ({
              ...n,
              send_time: DateTime.fromISO(n.send_time, {
                zone: 'utc',
              }).toLocal(),
            }))
            : of([]);
        })
      );
  }

  markAllAsRead(id_token: string) {
    const requestData = {
      id_token: id_token,
    };

    return this.http.fetch(this.baseUrl + 'mark_all_as_read', requestData)
      .pipe(tap(a => {
        this.onChange$.next(Date.now())
      }));;
  }

  markAsReadByPatient(patient_uid: string) {
    const requestData = {
      patient_uid,
    };

    return this.http.fetch(
      this.baseUrl + 'mark_as_read_by_patient',
      requestData
    ).pipe(tap(a => {
      this.onChange$.next(Date.now())
    }));
  }

  createNotification(
    id_token: string,
    sender: string,
    user_id: string,
    type: string,
    message: {},
    data: {}
  ) {
    const requestData = {
      id_token: id_token,
      sender: sender,
      user_id: user_id,
      type: type,
      message: message || {},
      data: data || {},
    };

    return this.http.fetch(this.baseUrl + 'create_notification', requestData);
  }

  deleteNotification(id_token, notificationId: string) {
    const requestData = {
      id_token: id_token,
      notificationId: notificationId,
    };

    return this.http.fetch(this.baseUrl + 'delete_notification', requestData);
  }
}
