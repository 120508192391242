import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit, AfterViewInit {

  public currentUser: any;
  constructor(private auth: AuthService) { }

  @ViewChild('overflow', { static: true }) overflowRef: ElementRef;
  ngOnInit(): void {
    this.auth.me$.subscribe(value=>{
      this.currentUser = value
    })
    





  }
  ngAfterViewInit(): void {
    if (this.overflowRef.nativeElement.offsetHeight < 700 && this.overflowRef.nativeElement.offsetWidth < 500) {
      this.overflowRef.nativeElement.style.overflow = 'auto';
    } else {
      this.overflowRef.nativeElement.style.overflow = 'hidden';
    }


  }



}
