import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-old-password',
  templateUrl: './old-password.component.html',
  styleUrls: ['./old-password.component.css']
})
export class OldPasswordComponent {
  public isCorrectPassword: boolean = true;
  public tokenParams : any;
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')};
  public password: string = '';
  public currentUser: any = null;
  public control: any ='';
  public dataLoaded:boolean = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private http: HttpClient,
    
  ) {}

  ngOnInit(): void {
   this.currentUser = this.authService.me
    this.dataLoaded = true;
  }

   confirm(){
    if (this.currentUser.role == 'physiotherapist') {
       this.tokenParams = new URLSearchParams({
        grant_type: "password",
        client_id: environment.KEYCLOAK_CLIENT_ID,
        client_secret: environment.KEYCLOAK_CLIENT_SECRET_KEY,
        username: this.currentUser.email,
        password: this.password
      });
    } else {
       this.tokenParams = new URLSearchParams({
        grant_type: "password",
        client_id: environment.KEYCLOAK_CLIENT_ID,
        client_secret: environment.KEYCLOAK_CLIENT_SECRET_KEY,
        username: this.currentUser.personal_information.email,
        password: this.password
      });
    }


    this.http.post(`${environment.baseKeycloakUrl}token`, this.tokenParams.toString(), this.options).subscribe(userCredentials => {
      setTimeout(a =>{
        this.authService.setCurrentPassword(this.password)
        this.router.navigate(['reset-password']);
      },300)

    },
    error => {
      this.isCorrectPassword = false;
    });
   }



}