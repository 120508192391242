import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TreatmentSummary } from '@schemas/exercise.model';
import { AuthService } from '@services/auth.service';
import { StoreService } from '@services/store.service';
import { IUserProfile } from 'src/app/resolvers';

@Component({
  selector: 'app-performance-tab',
  templateUrl: './performance-tab.component.html',
  styleUrls: ['./performance-tab.component.css']
})
export class PerformanceTabComponent implements OnInit {

  locale = "en"
  me: IUserProfile;
  patientId: any;
  activeTreatmentSummary: TreatmentSummary;
  performanceOverview: any;
  adherence: any = null;

  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private translate: TranslateService,) {
    this.me = this.authService.me;
    this.locale = this.translate.currentLang
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;

    });

  }
  loading: boolean = true;
  private _counter: number = 0;
  IncreaseCounter() {

    this._counter++;
    if (this._counter == 4) {
      this.loading = false
      this._counter = 0;
    }
  }
  ngOnInit(): void {
    this.patientId = this.route.parent?.snapshot.paramMap.get('id');
    this.storeService.getPatientExerciseOverview({
      patient_uid: this.patientId,
    }).subscribe(data => {
      this.performanceOverview = data
      this.IncreaseCounter()
    })
    this.storeService.getTreatmentProgress(this.patientId).subscribe(data => {
      this.activeTreatmentSummary = data
      this.IncreaseCounter()
    })
    this.adherence = {}
    this.storeService.getActiveDays(this.patientId).subscribe(data => {
      this.adherence.activeDays = data
      this.IncreaseCounter()
    })
    this.storeService.getLongestStreak(this.patientId).subscribe(data => {
      this.adherence.longestStreak = data
      this.IncreaseCounter()

    })
  }
}
