<app-navbar></app-navbar>
<section> 
    <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center ">
            <div class="col-md-6 col-lg-5 col-xl-5 py-6 py-md-0">
                <div class="card shadow zindex-100 mb-0">
                    <div class="card-body px-md-5 py-5">
                        <div  class="mb-5  text-center">
                            <img class="mb-3" src="assets\images\changeEmail\email_change_480.png" alt="lockimage" style="width: 160px; height: auto;">
                            <h6 class="h3">{{ 'home.navigation.changeEmail' | translate}}</h6>
                            <p class="text-muted mb-0">{{ 'home.navigation.newEmail' | translate }}</p>
                        </div>
                        <span class="clearfix"></span>
                        <form (keyup.enter)="resetEmail()">
                            <div class="form-group mt-4 mb-0">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                        <label class="form-control-label">{{ 'changeEmail.newEmail' | translate}}</label>
                                    </div>
                                </div>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-key"></i></span>
                                    </div>
                                   
                                    <input type="email" class="form-control" id="input-password" name="email"  [(ngModel)]="email" [formControl]="emailForm.controls.email">
                                </div>
                                <div *ngIf="emailForm.controls['email'].invalid && (emailForm.controls['email'].dirty || emailForm.controls['email'].touched)" class="alert text-danger">
                                    <p *ngIf="emailForm.controls['email'].errors?.required">{{ 'changeEmail.emailRequired' | translate }}</p>
                                    <p *ngIf="emailForm.controls['email'].errors?.pattern">{{ 'changeEmail.emailInvalid' | translate }}</p>
                                  </div>
                                <div class="mt-3">
                                    <label class="form-control-label">{{ 'changeEmail.confirmEmail' | translate}}</label>
                                </div>
                            
                            </div>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-key"></i></span>
                                </div>
                                
                                <input type="email" class="form-control" id="input-password-repetition"  name="email"  [(ngModel)]="emailRepetition" [formControl]="emailForm.controls.emailRepetition">
                            </div>
                            <div *ngIf="emailForm.controls['emailRepetition'].invalid && (emailForm.controls['emailRepetition'].dirty || emailForm.controls['emailRepetition'].touched)" class="alert text-danger">
                                <p *ngIf="emailForm.controls['emailRepetition'].errors?.required">{{ 'changeEmail.emailRequired' | translate }}</p>
                                <p *ngIf="emailForm.controls['emailRepetition'].errors?.pattern">{{ 'changeEmail.emailInvalid' | translate }}</p>
                              </div>
                            <div class="mt-4 text-center">
                                <p *ngIf="emailExist" class="alert alert-danger">{{ 'toastr.userExists' | translate}}</p>
                                <p *ngIf="emailError" class="alert alert-danger">{{ 'changeEmail.emailError' | translate}}</p>
                                <button *ngIf="dataLoaded" type="button" class="btn btn-block btn-primary"
                                   [disabled]="isSubmitDisabled()"
                                  (click)="resetEmail()">{{ 'changeEmail.confirm' | translate}}</button>
                                  <div *ngIf="!dataLoaded"  type="button" class="btn btn-block btn-primary">
                                    <div class="spinner-border text-primary" role="status">
                                      <span class="sr-only">Loading...</span>
                                    </div>
                                  </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>