import {SurveyQuestionBase} from '@schemas/survey/survey-question-base';

export class DropdownQuestion extends SurveyQuestionBase<string> {
  controlType = 'dropdown';
  options: { value: string }[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options[`options`] || [];
  }
}
