<div>
  <form (submit)="onSubmit()" [formGroup]="form">

    <div *ngFor="let question of questions; index as i" class="form-row">
      <app-question
        [question]="question"
        [form]="form"
        [editMode]="editMode"
        [lang]="currentLanguage"
      >
      </app-question>
    </div>

    <div class="form-row">
      <button type="submit" class="btn btn-sm btn-success ml-auto" [disabled]="!form.valid">{{ 'generalTerms.next' | translate }}</button>
    </div>
  </form>
</div>
