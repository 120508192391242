<section class="slice slice-lg pt-0 pb-0 mb-0" *ngIf="!!physiotherapist && !testView" style="position: relative;">
  <div class="container-fluid">
    <div class="row row-grid ml-1 mr-1">
      <div class="col-lg-4" id="m10">
        <div class="mt-1 mb-3">
          <h3>{{ "physiotherapist.overview" | translate }}</h3>
        </div>


        <div class="card p-2" style="background-color: #009ca2">
          <div class="card-body">
            <h2 style="color: white" class="mb-3">
              <i class="fas fa-user-md"></i> &nbsp;&nbsp;<span class="font-weight-bold">{{
                physiotherapistDetails.first_name }}
                {{ physiotherapistDetails.last_name }}</span>
            </h2>
            <div class="ml-4">
              <h4 *ngIf="physiotherapistDetails.medical" style="color: white">
                <i class="fas fa-users"></i> &nbsp;&nbsp;&nbsp;{{
                patientsList.length
                }}
                <span class="font-weight-lighter" style="font-size: large;">{{
                  (patientsList.length === 1 ? 'physiotherapist.patientsTitleSingular' :
                  'physiotherapist.patientsTitle')
                  | translate
                  }}</span>
              </h4>

              <h4 *ngIf="!physiotherapistDetails.medical" style="color: white">
                <i class="fas fa-users"></i> &nbsp;&nbsp;&nbsp;{{
                patientsList.length
                }}
                <span class="font-weight-lighter" style="font-size: large;">{{
                  "physiotherapist.clientsTitle" | translate
                  }}</span>
              </h4>
              <h4 style="color: white">
                <i class="fas fa-chalkboard-teacher"></i> &nbsp;&nbsp;&nbsp;{{
                exercisesList.length
                }}
                <span class="font-weight-lighter" style="font-size: large;">{{
                  "createExerciseProgram.availableExercises" | translate
                  }}</span>
              </h4>
            </div>
          </div>
        </div>
        <div class="d-none d-lg-block mt-3 mb-3">
          <h3>{{ "physiotherapist.exercisesTitle" | translate }}</h3>
        </div>
        <div class="d-none d-lg-block card pt-4">
          <div class="d-flex justify-content-center align-items-center">
            <p class="pl-2 my-auto">{{ "generalTerms.search" | translate }}:</p>
            <input type="email" class="form-control w-25 ml-2 my-auto" id="search-text-1" aria-describedby="search-text"
              style="height: 40px" [(ngModel)]="searchExerciseText"
              placeholder="{{ 'generalTerms.search' | translate }}" autofocus />
            <p class="pl-2 my-auto">{{ "generalTerms.filter" | translate }}:</p>

            <div ngbDropdown class="ml-2 my-auto">
              <button *ngIf="exerciseFilterSelected === 'none'" class="btn btn-xs btn-secondary" id="dropdownMenuButton"
                ngbDropdownToggle>
                {{ "generalTerms.select" | translate }}
              </button>
              <button *ngIf="exerciseFilterSelected !== 'none'" class="btn btn-xs btn-secondary" id="dropdownMenuButton"
                ngbDropdownToggle>
                {{ "generalTerms." + exerciseFilterSelected | translate }}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                <button ngbDropdownItem *ngFor="let option of exerciseFilterOptions"
                  (click)="exerciseFilterSelected = option">
                  {{ "generalTerms." + option | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">

            <ng-directive *ngIf="exercisesList
            | searchFilter
              : 'exercise'
              : searchExerciseText
              : exerciseFilterSelected as filteredExerciseItems">
              <table class="table border-top-0" id="custom-table" aria-label="Exercises">
                <thead>
                  <tr>
                    <th style="width: 50%" scope="col">
                      <h5>{{ "physiotherapist.name" | translate }}</h5>
                    </th>
                    <th style="width: 50%" scope="col">
                      <h5>{{ "physiotherapist.category" | translate }}</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="
                    let exercise of filteredExerciseItems
                      | slice
                        : (exercisePage - 1) * exercisePageSize
                        : exercisePage * exercisePageSize;
                    let i = index
                  " (mouseover)="hoverExerciseRow = i" (mouseleave)="hoverExerciseRow = null" [ngClass]="{
                    'bg-secondary text-primary': hoverExerciseRow === i
                  }" style="cursor: pointer" (click)="openModal(exerciseDetailsModalRef, exercise, true)">
                    <td>
                      <p class="my-2">{{ (exercise["display_name"][locale] ?? exercise["display_name"]['en']) |
                        translate }}</p>
                    </td>
                    <td>
                      <p class="my-2">{{ "generalTerms." + exercise.category | translate }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="(filteredExerciseItems).length===0">

                <p class="font-italic text-center mt-3">
                  {{ "exercise.exerciseNotFound" | translate }}
                </p>

              </div>
            </ng-directive>




          </div>
          <div class="d-flex justify-content-center mt-2 mb-2">
            <ngb-pagination [(page)]="exercisePage" [pageSize]="exercisePageSize" [collectionSize]="
                (
                  exercisesList
                  | searchFilter
                    : 'exercise'
                    : searchExerciseText
                    : exerciseFilterSelected
                )?.length
              "></ngb-pagination>
          </div>
        </div>
      </div>

      <div class="col-lg-8 col-md-12 col-sm-12" id="m11">
        <div class="d-flex align-items-center justify-content-between">
          <div class="mt-1 mb-2">
            <h3 *ngIf="physiotherapistDetails.medical">
              {{ "physiotherapist.patientsTitle" | translate }}
            </h3>
            <h3 *ngIf="!physiotherapistDetails.medical">
              {{ "physiotherapist.clientsTitle" | translate }}
            </h3>
          </div>
          <button type="button" class="btn btn-warning btn-circle btn-sm mr-2 mb-4"
            (click)="openModal(InformationModal);">
            <i class="fas fa-question" style="color: white"></i>
          </button>
        </div>
        <div class="card pt-4">
          <div class="d-block d-sm-none float-right ml-auto pr-3 mb-2">
            <button *ngIf="physiotherapistDetails.medical" class="btn btn-sm text-white"
              style="background-color: #009ca2" (click)="openModal(createPatientConfirmationModal)">
              <i class="fas fa-plus-circle"></i> &nbsp;
              {{ "physiotherapist.createPatient" | translate }}
            </button>
            <button *ngIf="!physiotherapistDetails.medical" class="btn btn-sm text-white"
              (click)="openModal(createPatientConfirmationModal)" style="background-color: #009ca2">
              <i class="fas fa-plus-circle"></i> &nbsp;
              {{ "physiotherapist.createClient" | translate }}
            </button>
          </div>
          <div class="d-flex ml-sm-0 align-items-center pl-3 pr-3">
            <p class="my-auto">{{ "generalTerms.search" | translate }}:</p>

            <div ngbDropdown class="ml-2 my-auto">
              <button *ngIf="patientsFilterSelected === 'none'" class="btn btn-xs btn-secondary" [ngClass]="{
                  'border border-danger': patientFilterWarning,
                  'border-0': !patientFilterWarning
                }" id="dropdownMenuButton" ngbDropdownToggle>
                {{ "generalTerms.select" | translate }}
              </button>
              <button *ngIf="patientsFilterSelected !== 'none'" class="btn btn-xs btn-secondary" id="dropdownMenuButton"
                ngbDropdownToggle>
                {{ "generalTerms." + patientsFilterSelected | translate }}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                <button ngbDropdownItem *ngFor="let option of patientsFilterOptions"
                  (click)="patientsFilterSelected = option">
                  {{ "generalTerms." + option | translate }}
                </button>
              </div>
            </div>
            <input type="email" class="form-control w-25 ml-2 my-auto" id="search-text-2" aria-describedby="search-text"
              style="height: 40px" [(ngModel)]="searchPatientText" placeholder="{{ 'generalTerms.search' | translate }}"
              autofocus />
            <div class="d-none d-sm-block float-right ml-auto">
              <button *ngIf="physiotherapistDetails.medical" class="btn btn-sm text-white"
                style="background-color: #009ca2" (click)="navigateTo('create-patient')">
                <i class="fas fa-plus-circle"></i> &nbsp;
                {{ "physiotherapist.createPatient" | translate }}
              </button>
              <button *ngIf="!physiotherapistDetails.medical" class="btn btn-sm text-white"
                (click)="navigateTo('create-patient')" style="background-color: #009ca2">
                <i class="fas fa-plus-circle"></i> &nbsp;
                {{ "physiotherapist.createClient" | translate }}
              </button>
            </div>
          </div>
          <div class="table-responsive mt-3" style="overflow: visible;">
            <ng-directive *ngIf="patientsList | searchFilter
                : 'patient'
                : searchPatientText
                : patientsFilterSelected
                : patientListSorted
                : arrowIconName
                : arrowIconBirthdate
                : arrowIconProgramStatus
                : arrowIconIllness
                : arrowIconLastActivityDate
                : creationDate as filteredItems
              ">
              <table class="table border-top-0" id="custom-table" aria-label="Patients">
                <thead class="w-100">
                  <tr>
                    <th scope="col" class="patient-notification">
                      <div style="display: flex; align-items: center; width: max-content;"></div>
                    </th>
                    <th scope="col" class="patient-name">
                      <div style="display: flex; align-items: center;" [title]="'generalTerms.name' | translate">
                        <h5 class="mr-2 mt-1 truncate">
                          {{ "generalTerms.name" | translate }}
                        </h5>
                        <i class="fa mb-2" [ngClass]="arrowIconName" (click)="toggleSortingDirection('name')"
                          style="cursor: pointer;"></i>
                      </div>
                    </th>
                    <th scope="col" class="patient-dob">
                      <div style="display: flex; align-items: center;" [title]="'generalTerms.birthdate' | translate">
                        <h5 class="mr-2 mt-1 truncate">
                          {{ "generalTerms.birthdate" | translate }}
                        </h5>
                        <i class="fa mb-2" [ngClass]="arrowIconBirthdate" (click)="toggleSortingDirection('birthdate')"
                          style="cursor: pointer;  vertical-align: middle;"></i>
                      </div>
                    </th>
                    <th *ngIf="physiotherapistDetails.medical" scope="col" class="patient-illness">
                      <div style="display: flex; align-items: center;" [title]="'generalTerms.illness' | translate">
                        <h5 class="mr-2 mt-1 truncate">
                          {{ "physiotherapist.illness" | translate }}
                        </h5>
                        <i class="fa mb-2" [ngClass]="arrowIconIllness" (click)="toggleSortingDirection('illness')"
                          style="cursor: pointer;"></i>
                      </div>
                    </th>
                    <th scope="col" class="patient-last-seen">
                      <div style="display: flex; align-items: center;" [title]="'generalTerms.lastActiveDate' | translate">
                        <h5 class="mr-2 mt-1 truncate">
                          {{ "generalTerms.lastActiveDate" | translate }}
                        </h5>
                        <i class="fa mb-2" [ngClass]="arrowIconLastActivityDate"
                          (click)="toggleSortingDirection('last_activity_date')"
                          style="cursor: pointer;  vertical-align: middle;"></i>
                      </div>
                    </th>

                    <th scope="col" class="patient-progress">
                      <div style="display: flex; align-items: center;" [title]="'generalTerms.programStatus' | translate">
                        <h5 class="mr-2 mt-1 truncate">
                          {{ "generalTerms.programStatus" | translate }}
                        </h5>
                        <i class="fa mb-2" [ngClass]="arrowIconProgramStatus"
                          (click)="toggleSortingDirection('done_percentage')" style="cursor: pointer;"></i>
                      </div>
                    </th>
                    <th scope="col" class="patient-menu">
                      <div style="display: flex; align-items: center; width: max-content;"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="
                    let patient of filteredItems
                        | slice
                        : (patientPage - 1) * patientPageSize
                        : patientPage * patientPageSize;
                    let i = index
                  " style="cursor: pointer" (mouseover)="hoverPatientRow = i" (mouseleave)="hoverPatientRow = null"
                    [ngClass]="{ 'bg-secondary': hoverPatientRow === i }"
                    (click)="navigateToPatientDetails(patient.id)"
                    >
                    <td style="vertical-align: middle; cursor: default;" class="patient-notification">
                      <div
                        *ngIf="patient.hasUnreadNoti"
                        class="d-flex justify-content-center"
                        style="width: 100%; cursor: pointer;"
                        (click)="$event.stopPropagation();toggleModal(patient)"
                      >
                        <img [src]="'assets/img/svg/icons/notification-alert.svg'">
                      </div>
                    </td>
                    <td style="vertical-align: middle;" class="patient-name" >
                      <p class="my-2">{{ patient.last_name }}, {{ patient.first_name }}</p>
                    </td>
                    <td style="vertical-align: middle;"  >
                      <p class="my-2 text-center">{{ patient.birthdate | customDate }}</p>
                    </td>
                    <td style="vertical-align: middle;"  >
                      <p *ngIf="patient.illness" class="my-2 text-center">{{ patient.illness }}</p>
                      <p *ngIf="patient.contact_reason" class="my-2 text-center">
                        {{ patient.contact_reason }}
                      </p>
                    </td>
                    <td style="vertical-align: middle;" >
                      <p class="my-2 text-center" [title]="patient.last_active_date">{{
                        patient.last_active_date!="N/A"?(patient.last_activity_date | customDate):
                        patient.last_active_date}}</p>
                    </td>

                    <td class="my-2" style="max-width: 100px; height: 100px; vertical-align: middle;">
                      <circle-progress [percent]="patient?.program_summary?.done_percentage" [maxPercent]="100"
                        [subtitleFontSize]="30"

                        [subtitle]="
                          patient?.program_summary?.days_left <= 0
                          ? ('patient.done'| translate)
                          : patient?.program_summary?.days_left === 1
                          ? ([patient?.program_summary?.days_left,('patient.dayLeft'|translate)])
                          : ([patient?.program_summary?.days_left,('patient.daysLeft'|translate)])
                        " [outerStrokeColor]="'#000000'" [innerStrokeColor]="'#CCCCCC'" [innerStrokeWidth]="20"
                        [outerStrokeWidth]="20" [space]="-20">1</circle-progress>
                    </td>
                    <td style="vertical-align: middle; cursor: default;" class="patient-menu">
                      <div ngbDropdown class="patient-actions mt-1 pt-1 px-2" (click)="$event.stopPropagation()">
                        <i class="fa fa-ellipsis-h fa-2x" aria-hidden="true" style="color: #384f6b; cursor: pointer"
                          id="dropdownPatientActions" ngbDropdownToggle></i>
                        <div ngbDropdownMenu aria-labelledby="dropdownPatientActions">
                          <a routerLink="{{ patient.id }}">
                            <button ngbDropdownItem>
                              {{ "patientDetails.patientDetails" | translate }}
                            </button>
                            <!-- (click)="getPatientDetails(patient)" -->
                          </a>
                          <a [routerLink]="[patient.id,'health']" [queryParams]="{ action: 'survey-results' }">
                            <button ngbDropdownItem>
                              {{ 'patientDetails.performanceOverview.surveyResultsNoCapital' | translate }}
                            </button>
                            <!-- (click)="getPatientDetails(patient)" -->
                          </a>
                          <a [routerLink]="[patient.id,'health']" [queryParams]="{ action: 'activity' }">
                            <button ngbDropdownItem>
                              {{ 'patientDetails.performanceOverview.activity' | translate }}
                            </button>
                            <!-- (click)="getPatientDetails(patient)" -->
                          </a>
                          <a [routerLink]="[patient.id,'performance']" >
                            <button ngbDropdownItem>
                              {{ 'patientDetails.performanceOverview.performanceOverviewNotCapital' | translate }}
                            </button>
                            <!-- (click)="getPatientDetails(patient)" -->
                          </a>
                          <a [routerLink]="[patient.id,'create-program']" >
                            <button ngbDropdownItem>
                              {{ 'createExerciseProgram.createExerciseProgramText' | translate }}
                            </button>
                            <!-- (click)="getPatientDetails(patient)" -->
                          </a>
                          <a [routerLink]="[patient.id,'programs']" >
                            <button ngbDropdownItem>
                              {{ 'patientDetails.performanceOverview.exercisePrograms' | translate }}
                            </button>
                            <!-- (click)="getPatientDetails(patient)" -->
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div *ngIf="filteredItems.length===0">
                <p *ngIf="physiotherapistDetails.medical" class="font-italic text-center mt-3">
                  {{ "physiotherapist.noPatients" | translate }}
              </div>


            </ng-directive>







          </div>
          <div *ngIf="patientsList.length > 0" class="mx-auto mt-2 mb-2">
            <ngb-pagination [(page)]="patientPage" [pageSize]="patientPageSize" [collectionSize]="
                (
                  patientsList
                  | searchFilter
                    : 'patient'
                    : searchPatientText
                    : patientsFilterSelected
                )?.length
              "></ngb-pagination>
          </div>
        </div>
        <div class="d-lg-none mt-3 mb-3">

          <h3>{{ "physiotherapist.exercisesTitle" | translate }}</h3>

        </div>
        <div class="d-lg-none card pt-4">

          <div class="d-flex justify-content-center align-items-center">
            <p class="pl-2 my-auto">{{ "generalTerms.search" | translate }}:</p>
            <input type="email" class="form-control w-25 ml-2 my-auto" id="search-text-3" aria-describedby="search-text"
              style="height: 40px" [(ngModel)]="searchExerciseText"
              placeholder="{{ 'generalTerms.search' | translate }}" autofocus />
            <p class="pl-2 my-auto">{{ "generalTerms.filter" | translate }}:</p>

            <div ngbDropdown class="ml-2 my-auto">

              <button *ngIf="exerciseFilterSelected === 'none'" class="btn btn-xs btn-secondary" id="dropdownMenuButton"
                ngbDropdownToggle>
                {{ "generalTerms.select" | translate }}
              </button>
              <button *ngIf="exerciseFilterSelected !== 'none'" class="btn btn-xs btn-secondary" id="dropdownMenuButton"
                ngbDropdownToggle>
                {{ "generalTerms." + exerciseFilterSelected | translate }}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                <button ngbDropdownItem *ngFor="let option of exerciseFilterOptions"
                  (click)="exerciseFilterSelected = option">
                  {{ "generalTerms." + option | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table border-top-0" id="custom-table" aria-label="Exercises">
              <thead>
                <tr>
                  <th style="width: 50%" scope="col">
                    <h5>{{ "physiotherapist.name" | translate }}</h5>
                  </th>
                  <th style="width: 50%" scope="col">
                    <h5>{{ "physiotherapist.category" | translate }}</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="
                    let exercise of exercisesList
                      | searchFilter
                        : 'exercise'
                        : searchExerciseText
                        : exerciseFilterSelected
                      | slice
                        : (exercisePage - 1) * exercisePageSize
                        : exercisePage * exercisePageSize;
                    let i = index
                  " (mouseover)="hoverExerciseRow = i" (mouseleave)="hoverExerciseRow = null" [ngClass]="{
                    'bg-secondary text-primary': hoverExerciseRow === i
                  }" style="cursor: pointer" (click)="openModal(exerciseDetailsModalRef, exercise, true)">
                  <td>
                    <p>{{ (exercise["display_name"][locale] ?? exercise["display_name"]['en']) | translate }}</p>
                  </td>
                  <td>
                    <p>{{ "generalTerms." + exercise.category | translate }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex flex-row mt-auto mx-auto mt-2 mb-2">
            <ngb-pagination [(page)]="exercisePage" [pageSize]="exercisePageSize" [collectionSize]="
                (
                  exercisesList
                  | searchFilter
                    : 'exercise'
                    : searchExerciseText
                    : exerciseFilterSelected
                )?.length
              "></ngb-pagination>
          </div>
        </div>
        <!--                <div class="float-right">-->
        <!--                    <button class="btn btn-sm btn-secondary" (click)="newPatientOnboarding = true;">{{ 'physiotherapist.createPatient' | translate }}</button>-->
        <!--                </div>-->
      </div>
    </div>
  </div>
</section>

<div *ngIf="isModalNotificationOpen" class="notification-modal">
    <div class="notification-content">
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column ">
          <span style="font-size: 16px; font-weight: 500; color: #6B8096">
            {{ pacientNotifications.first_name }} {{ pacientNotifications.last_name }}
          </span>
          <h3 style="font-size: 20px; font-weight: 500; color: #152C5B; margin: 0;">
            {{ 'notifications.patientNotifications' | translate }}
          </h3>
        </div>

        <div class="close-button" (click)="toggleModal(pacientNotifications)" style="cursor: pointer;">
          <img src="../../../../assets/img/svg/icons/close-cross.svg">
        </div>
      </div>

      <ng-container *ngIf="notifications$ | async as notificationsList">
        <app-notification-list
          [notificationsList]="notificationsList"
          [limit]='notificationLimit'
          [isListViewAll]="isListViewAll"
        ></app-notification-list>
      </ng-container>

      <div class="d-flex justify-content-end">
        <span (click)="navigateToPatientDetails(pacientNotifications._id, true)" class="d-flex g-1" style="border-bottom: 1px solid #314F6D; cursor: pointer;">
          <span class="d-flex" style="color: #314F6D; font-size: 16px; font-weight: 700;">
            {{ "notifications.seeAll" | translate }}
          </span>
          <img src="../../../../assets/img/svg/icons/right-arrow.svg">
        </span>
      </div>
  </div>
</div>
<div *ngIf="testView">
  <app-breathing-exercise *ngIf="chosenTestExercise?.category === 'breathing'"
    (closeTestViewEvent)="closeExerciseView()" [exercise]="chosenTestExercise"></app-breathing-exercise>

  <app-breathing-time-exercise *ngIf="chosenTestExercise?.category=='breathing_time'"
    (closeTestViewEvent)="closeExerciseView()" [exercise]="chosenTestExercise"></app-breathing-time-exercise>

  <app-relaxation-exercise *ngIf="chosenTestExercise?.category=='relaxation'"
    (closeExerciseViewEvent)="closeExerciseView()" [exercise]="chosenTestExercise"></app-relaxation-exercise>

  <app-physical-exercise *ngIf="!['breathing', 'breathing_time', 'relaxation'].includes(chosenTestExercise?.category)"
    (closeExerciseViewEvent)="closeExerciseView()" [exercise]="chosenTestExercise"></app-physical-exercise>
</div>

<!--  MODALS  -->

<!--EXERCISE DETAILS MODAL-->

<ng-template #exerciseDetailsModal let-modal>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ "patientDetails.exerciseDetails" | translate }} -
      {{ chosenExercise?.display_name[locale] }}
    </h3>
    <button type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click'); closeExerciseModal(); ">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex align-items-center">
      <h5 class="mt-auto mb-0">
        {{ "physiotherapist.category" | translate }}:
      </h5>
      <p class="mt-auto mb-0">
        &nbsp;&nbsp;{{ "generalTerms." + chosenExercise?.category | translate }}
      </p>
    </div>
    <h5 class="mt-3">{{ "patientDetails.description" | translate }}:</h5>
    <p class="my-auto">{{ chosenExercise?.description[locale] }}</p>
    <div *ngIf="chosenExercise?.disabled" class="mt-5 mx-auto text-center">
      <p class="font-italic">{{ "physiotherapist.comingSoon" | translate }}</p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="startExercise(chosenExercise); modal.close()"
      [disabled]="chosenExercise?.disabled">
      {{ "physiotherapist.startExercise" | translate }}
    </button>
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close(); closeExerciseModal();">
      {{ "generalTerms.close" | translate }}
    </button>
  </div>
</ng-template>

<!--EXERCISE DETAILS MODAL-->

<!--Reset password modal-->
<ng-template #resetPasswordModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ "home.navigation.resetPassword" | translate }}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!emailSent">
      <p>{{ "home.navigation.resetPasswordQuestion" | translate }}</p>
      <p>
        {{ "home.navigation.resetPasswordText1" | translate }}
        <span class="font-italic text-primary">{{
          "generalTerms.yes" | translate
          }}</span>{{ "home.navigation.resetPasswordText2" | translate }}
      </p>
    </div>
    <div *ngIf="emailSent">
      <p>{{ "login.resetPasswordEmailSent" | translate }}</p>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="!emailSent" type="button" class="btn btn-sm btn-primary" (click)="resetPassword()">
      {{ "generalTerms.yes" | translate }}
    </button>
    <button *ngIf="!emailSent" type="button" class="btn btn-sm btn-secondary"
      (click)="emailSent = false; modal.close()">
      {{ "generalTerms.no" | translate }}
    </button>
    <button *ngIf="emailSent" type="button" class="btn btn-sm btn-secondary" (click)="emailSent = false; modal.close()">
      {{ "generalTerms.close" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #softDeletePatientModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-danger">
      {{ "physiotherapist.deletePatientModal.title" | translate }}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <p>
        {{ "physiotherapist.deletePatientModal.deletePatientText" | translate }}
        <span class="font-italic">{{ patientToBeDeleted.first_name }}
          {{ patientToBeDeleted.last_name }}</span>?
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-danger" (click)="softDeletePatient(); modal.close()">
      {{ "generalTerms.delete" | translate }}
    </button>
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
      {{ "generalTerms.cancel" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #InformationModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title"><img class="mr-2" alt="Image placeholder"
        src="../../../assets/Logo/Breathment Logo_rev_white.jpg" style="height: 50px;" id="navbar-mobilr-logo"></h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>{{'tutorial.welcomeToBreathment' | translate}}</h5>
    <p>{{'tutorial.welcomeToBreathmentExplanation' | translate}} </p>
    <p>{{'tutorial.dashboardPart1' | translate}} </p>
    <p>{{'tutorial.dashboardPart2' | translate}} </p>
    <p>{{'tutorial.dashboardPart3' | translate}} </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close();">{{ 'generalTerms.close' | translate
      }}</button>
  </div>
</ng-template>

<!-- Notification Modal -->
