import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  public success: boolean = false;
  public dataLoaded: boolean = false;
  public currentUser: any;

  constructor(
    private router: Router,
    private authService: AuthService,

  ) {
    this.currentUser = this.authService.me
  }

  ngOnInit(): void {
    if (this.router.url === '/payment_success') {
      this.success = true;
    }
    this.dataLoaded = true;
  }

  navigateDashboard() {
    if (this.currentUser) {
      this.router.navigate([this.currentUser.role]);
    } else {
      this.router.navigate(['login'])
    }
  }

}
