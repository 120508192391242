<nav class="navbar navbar-expand-lg navbar-light bg-white">
<!--  <div [ngClass]="isPhysioTherapist ? 'container-fluid ml-3 mr-3' : 'container'">-->
  <div class="container-fluid ml-1 mr-1">

  <!-- Brand -->
    <div class="navbar-brand d-none d-sm-block"   *ngIf="currentRoute == '/dashboard/physiotherapist' || currentRoute == '/dashboard/patient'; else elseBlock" >

          <a >
            <img class="mr-2" alt="Image placeholder" src="../../../assets/Logo/Breathment_logo_rev_blue.png" style="height: 80px;" id="navbar-logo">
          </a>
    </div>
    <ng-template #elseBlock>
      <div class="navbar-brand d-none d-sm-block">
        <a [routerLink]="currentUser ? ['/dashboard'] : ['/']">
          <img class="mr-2" alt="Image placeholder" src="../../../assets/Logo/Breathment_logo_rev_blue.png" style="height: 80px;" id="navbar-logo">
        </a>
      </div>
    </ng-template>
    <a class="navbar-brand d-block d-sm-none">
      <img class="mr-2" alt="Image placeholder" src="../../../assets/Logo/Breathment_logo_rev_blue.png" style="height: 70px;" id="navbar-mobilr-logo">
    </a>
    <!-- Toggler -->
    <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Collapse -->
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mt-4 mt-lg-0 ml-auto align-items-center">

        <li class="nav-item dropdown dropdown-animate" data-toggle="hover">
          <div class="d-flex align-items-center mr-4" style="gap: 8px; cursor: pointer;" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="../../../../assets/img/svg/icons/globe.svg" alt="World" style="height: 1.3rem; pointer-events: none;">
            <div style="color: #152c5b; pointer-events: none;" class="mt-1">{{ locale }}</div>
            <img src="../../../../assets/img/svg/icons/angle-down.svg" alt="drop down" style="height: 1rem; pointer-events: none;">
          </div>
          <div class="dropdown-menu dropdown-menu-single">
            <a class="dropdown-item" (click)="useLanguage('en')">
              <img src="../../../../assets/images/uk-flag.png" alt="Image placeholder" class="mr-3"/>
              English
            </a>
            <a class="dropdown-item" (click)="useLanguage('de')">
              <img src="../../../../assets/images/germany-flag.png" alt="Image placeholder" class="mr-3"/>
              Deutsch
            </a>
            <a class="dropdown-item" (click)="useLanguage('tr')">
              <img src="../../../../assets/images/tr-flag.png" alt="Image placeholder" class="mr-3" style="height: 1rem; border-radius: 0.15rem;"/>
              Türkçe
            </a>
            <a class="dropdown-item" (click)="useLanguage('it')">
              <img src="../../../../assets/images/italy-flag.png" alt="Image placeholder" class="mr-3" style="height: 1rem; border-radius: 0.15rem;"/>
              Italiano
            </a>
          </div>
        </li>

        <li *ngIf="currentUser" class="nav-item dropdown dropdown-animate ml-auto" data-toggle="hover">
          <a class="nav-link avatar rounded-circle bg-primary text-white" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" (click)="navigateToDashboard();">
            {{userInitial}}
          </a>
          <div *ngIf="isNewMessagePresent" style="position: absolute; top: -7px; left: 35px;">
            <img style="width: 16px; height: 16px;" src="../../../../assets/img/svg/icons/notification-ellipse.svg">
          </div>
          <div class="dropdown-menu dropdown-menu-single">
            <a routerLink="/notifications" class="dropdown-item" style="position: relative; font-size: 14px;">
              <img style="width: 20px; height: 20px;" src="../../../../assets/img/svg/icons/notification-bell.svg">
               {{ 'notifications.title' | translate }}

              <div *ngIf="isNewMessagePresent" style="position: absolute; top: -2px; left: 10px;">
                <img style="width: 10px; height: 10px;" src="../../../../assets/img/svg/icons/notification-ellipse.svg">
              </div>
            </a>
            <a *ngIf="isDashboardActiveRoute && currentUser.role != 'patient'" class="dropdown-item" (click)="navigateToDashboard();" style="font-size: 14px;">
              <img style="width: 20px; height: 20px;" src="../../../../assets/img/svg/icons/dashboard.svg">
              {{ 'home.navigation.dashboard' | translate }}
            </a>
            <a routerLink="/settings" class="dropdown-item" style="font-size: 14px;">
              <img style="width: 20px; height: 20px;" src="../../../../assets/img/svg/icons/settings.svg">
              {{ 'settingsScreen.settings' | translate }}
            </a>
            <a class="dropdown-item" style="color: red; font-size: 14px;" (click)="logout();">
              <img style="width: 20px; height: 20px;" src="../../../../assets/img/svg/icons/logout.svg">
              {{ 'home.navigation.logout' | translate }}
            </a>
          </div>
        </li>
        <li *ngIf="!currentUser" class="nav-item ml-auto">
          <a class="nav-link" href="https://meetings-eu1.hubspot.com/baturay" target="_blank"><button class="btn btn-primary rounded-pill">{{ 'generalTerms.requestDemo' | translate }}</button></a>
        </li>
      </ul>
    </div>
  </div>
</nav>


<!--Reset password modal-->
<ng-template #resetPasswordModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ 'home.navigation.resetPassword' | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{'home.navigation.resetPasswordQuestion' | translate}}</p>
    <p>{{'home.navigation.resetPasswordText1' | translate}} <span
      class="font-italic text-primary">{{ 'generalTerms.yes' | translate }}</span>{{ 'home.navigation.resetPasswordText2' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary"
            (click)="modal.close(); resetPassword();">{{ 'generalTerms.yes' | translate }}</button>
    <button type="button" class="btn btn-sm btn-secondary"
            (click)="modal.close();">{{ 'generalTerms.no' | translate }}</button>
  </div>
</ng-template>

<!--   <li *ngIf="currentUser" class="nav-item dropdown dropdown-animate ml-auto mr-1" data-toggle="hover">
          <a class="nav-link avatar rounded-circle  text-black" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false" ><i class="fa fa-cog" aria-hidden="true"></i></a>
          <div class="dropdown-menu dropdown-menu-single">
            <a class="dropdown-item"
               (click)="openModal(resetPasswordModal);"> <i class="fa fa-lock" aria-hidden="true"></i> {{ 'home.navigation.resetPassword' | translate }}</a>

            <a class="dropdown-item"
            [routerLink]="['/confirm-password']" > <i class="fa fa-envelope" aria-hidden="true" ></i>  {{ 'home.navigation.changeEmail' | translate }}</a>
          </div>
        </li>-->
