import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import {SurveyQuestionBase} from '@schemas/survey/survey-question-base';

@Injectable()
export class QuestionControlService {

  toFormGroup(questions: SurveyQuestionBase<any>[]) {
    const group = {};
    questions.forEach(question => {
      group[question.key] = question.required ? new UntypedFormControl(question.value || '', Validators.required)
        : new UntypedFormControl(question.value || '');
    });

    return new UntypedFormGroup(group);
  }
}
