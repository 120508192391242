export class DashboardSettings {

  /**
   * Dashboard Constants
   */
  public static DASHBOARDS = [
    'physiotherapist',
    'patient'
  ];

}
