import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from "@environments/environment";
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private router: Router
  ) {
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let AuthRequest = httpRequest;
    let access_token = '';
    try {
      const user = JSON.parse(localStorage.getItem('__auth'));
      access_token = user.access_token;
    } catch {

    }
    //AuthRequest.headers.append('Content-Type', 'text/html')
    if (httpRequest.url.includes(environment.baseCpuUrl) && !httpRequest.url.includes(environment.baseKeycloakUrl) && localStorage.getItem('currentUser') !== null && !httpRequest.url.includes('send_email') && !httpRequest.url.includes('send_reset_password_email')) {
      AuthRequest = httpRequest.clone({
        //headers: AuthRequest.headers.set('Accept', 'application/json'),
        body: { ...httpRequest.body, id_token: access_token }
      });
      return next.handle(AuthRequest).pipe(
        catchError((err: any) => {
          if (err.error?.error === 'operationNotPermitted') { 
            //window.document.location = '/'
          }
          return of(err);
        }))
      // return from(access_token).pipe(
      //   switchMap(token => {
      //     AuthRequest = httpRequest.clone({
      //       headers: AuthRequest.headers.set('Accept', 'application/json'),
      //       body: { ...httpRequest.body, id_token: token }
      //     });
      //     return next.handle(AuthRequest).pipe(
      //       catchError((err: any) => {
      //         return of(err);
      //       })
      //       // catchError((err: any) => {
      //       //   if (err.error?.error === 'operationNotPermitted') {
      //       //     this.authService.logout();
      //       //   } else if (err.status === 401) {
      //       //     this.router.navigate(['/login']).then(r => r);
      //       //   } else if (err.status !== 400) {
      //       //     this.router.navigate(['/404']);
      //       //   }
      //       //   return throwError(err);
      //       // })
      //     );
      //   })
      // );
    } else {
      return next.handle(httpRequest);
    }
  }
}


