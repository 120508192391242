import {Injectable} from '@angular/core';

import {SurveyQuestionBase} from '@schemas/survey/survey-question-base';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {SurveySettings} from '@schemas/settings/SurveySettings';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import { Http2Service } from '@services/http2.service';

@Injectable()

export class SurveyQuestionService {

  private readonly types = SurveySettings.INPUT_TYPES;
  private readonly getSurveyURL = `${environment.baseCpuUrl}get_survey`;
  private readonly createSurveyURL = `${environment.baseCpuUrl}create_survey`;
  private readonly updateSurveyURL = `${environment.baseCpuUrl}edit_survey`;
  private readonly postSurveyResultsURL = `${environment.baseCpuUrl}upload_survey_results`;
  private readonly getSurveyResultsURL = `${environment.baseCpuUrl}get_survey_results`;

  constructor(
    private http: Http2Service,
    private translate: TranslateService
  ) {
  }

  getQuestions(type): Observable<SurveyQuestionBase<any>[]> {
    return this.http.fetch(this.getSurveyURL, {survey_type: type}).pipe();
  }

  prepareQuestion(question, index, newItem = false) {
    try {
      return new this.types[question.type]({
        label: newItem ? question.label : question.lang[this.translate.currentLang],
        key: index,
        value: '',
        options: question.options,
        required: question.required,
        order: question.order,
        lang: question.lang,
        controlType: question.type
      });
    } catch (e) {
      console.error('Input type not supported', e);
    }
  }

  storeSurveyResults(requestData) {
    return this.http.fetch(this.postSurveyResultsURL, requestData).pipe();
  }

  submittedSurveyChecker(requestData) {
    return this.http.fetch(this.getSurveyResultsURL, requestData).pipe();
  }

  createSurvey(requestData) {
    return this.http.fetch(this.createSurveyURL, requestData).pipe();
  }

  updateSurvey(requestData) {
    return this.http.fetch(this.updateSurveyURL, requestData).pipe();
  }

  reOrder(questions) {
    return questions.sort((a, b) => a.order - b.order);
  }

}
