import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@services/auth.service";
import { StoreService } from "@services/store.service";

export interface IPatientDashboardData {

 }
export interface IAuthenticatedUser {
    id: string;
    role: 'patient' | 'physiotherapist';
    locale: 'en' | 'de' | 'tr' | 'it';
    ts: number;
    access_token: string;
    refresh_token: string;
}
export interface IUserProfile {
    id: string;
    locale: 'en' | 'de' | 'tr' | 'it';
    role: string;
    email: string;
    medical:  boolean;
    physiotherapist?:{
        _id:string;
    };
    onboarding_form?:any;
    personal_information?:any;
}
export interface IPhysioDashboardData {
    exercises: any[];
    summary: any[];
    patients: any[];
    selectedExercise:any;
}
export interface IPatientDetailsDashboardData{

}
export const patientDashboardDataResolver: ResolveFn<IPatientDashboardData> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(StoreService).getPatientDashboardData();
};
export const patientDetailsDashboardDataResolver: ResolveFn<IPatientDashboardData> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

    return inject(StoreService).getPatientDetailsDashboardDataResolver(route.paramMap.get('id')!);
};
export const createPatientProgram_DataResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(StoreService).createPatientProgram_DataResolver(route.paramMap.get('id')!);
};

export const physioDashboardDataResolver: ResolveFn<IPhysioDashboardData> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

    return inject(StoreService).getPhysioDashboardData(null, route.paramMap.get('id')!);
};

export const DashboardDataResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(AuthService).getUserDetails(true)
};
export const SettingsDataResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(AuthService).getUserDetails(true)
};
export const ChangeEmailDataResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(AuthService).getUserDetails(true)
};
export const OldPasswordDataResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(AuthService).getUserDetails(true)
};
