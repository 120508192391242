import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { transformOnboardingFormData } from '@helpers/helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { GeneralService } from '@services/general/general.service';
import { Http2Service } from '@services/http2.service';
import { StoreService } from '@services/store.service';
import { UserService } from '@services/user.service';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { IUserProfile } from 'src/app/resolvers';

@Component({
  selector: 'app-profile-tab',
  templateUrl: './profile-tab.component.html',
  styleUrls: ['./profile-tab.component.css']
})
export class ProfileTabComponent implements OnInit {
  locale = "en"
  me: IUserProfile;
  edit = false;
  patientVersions: any[] = []
  old_system = false;
  allCards: any;
  orginalCards: any;
  patientId: any;
  selectedVersion: any;
  patientDetails: any;
  newUserAccessLevel: string = '';
  orginalPatientDetails: any;
  loading: boolean = true
  private _activeTab: number = 0;
  modifiedPatientInfo: any;
  set activeTab(tab: number) {
    this._activeTab = tab;
  }
  get activeTab() {
    return this._activeTab;
  }
  constructor(private http: Http2Service,
    private generalService: GeneralService,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private userService: UserService,
    private toastr: ToastrService,
    private authService: AuthService) {
    this.me = this.authService.me;
    this.locale = this.translate.currentLang
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;

      this.requestPatientDetails()

    });

  }

  onSelectedVersionChanged(v) {
    this.selectedVersion = v.title;
    this.patientDetails = v;
    this.patientDetails.personal_information = {
      ...this.orginalPatientDetails.personal_information,
      ...this.patientDetails.personal_information,
    };
    transformOnboardingFormData(
      JSON.parse(JSON.stringify(this.orginalCards)),
      this.locale,
      this.translate,
      v
    ).then((cards) => {
      this.allCards = cards;

    });
  }
  onDataReceived(data: any) {
    this.orginalPatientDetails = JSON.parse(
      JSON.stringify(data)
    );


    this.patientDetails = JSON.parse(JSON.stringify(data));






    this.getPatientVersions();
    this.old_system = true;
    if (
      this.patientDetails &&
      this.patientDetails.about_copd &&
      Object.keys(this.patientDetails.about_copd)?.length == 0
    ) {
      this.old_system = false;
      this.storeService.getPatientOnBoarding(this.patientId).subscribe(data => {
        try {
          this.orginalCards = JSON.parse(
            JSON.stringify(data?.cards)
          );
          transformOnboardingFormData(
            JSON.parse(JSON.stringify(this.orginalCards)),
            this.locale,
            this.translate,
            this.patientDetails
          ).then((cards) => {
            this.allCards = cards;

          });
        } catch (error) {
          this.orginalCards = [];
        }
      })

    } else {
      this.handleOldPatientDetails();
    }
  }
  ngOnInit(): void {

    // Access the `id` from the parent route
    this.patientId = this.route.parent?.snapshot.paramMap.get('id');

    this.requestPatientDetails()

  }
  onSomethingChanged(change) {

    this.modifiedPatientInfo = { ...this.modifiedPatientInfo, ...change };
  }
  openSaveModal(content, data) {
    this.modifiedPatientInfo = { ...this.modifiedPatientInfo, ...data };
    if (this.modifiedPatientInfo['personal_information.email']) {
      const userEmail = this.modifiedPatientInfo['personal_information.email'];
      this.userService.isEmailActive({ email: userEmail }).subscribe(data => {
        if (data['data']) {
          this.translate.get('toastr.userExists').subscribe((res: string) => {
            this.toastr.error(res);
          });
        } else {
          this.modalService.open(content, { size: 'lg' });
        }
      })
    } else {
      this.modalService.open(content, { size: 'lg' });
    }
  }
  editModeOn(activeTab) {
    this.patientDetails = JSON.parse(
      JSON.stringify(this.orginalPatientDetails)
    );
    this.exetractPatientDetails();
    this.edit = true

  }
  exetractPatientDetails() {


    // if (this.patientDetails.disease_history?.previous_illness) {
    //   if (this.patientDetails.disease_history.previous_treatment === 'Clinic') {
    //     this.clinic = true;
    //   } else if (
    //     this.patientDetails.disease_history.previous_treatment ===
    //     'Rehabilitation Center'
    //   ) {
    //     this.rehabilitationCenter = true;
    //   } else if (
    //     this.patientDetails.disease_history.previous_treatment ===
    //     'Previous Operation'
    //   ) {
    //     this.previousOperation = true;
    //   }
    // }
  }
  requestPatientDetails() {
    this.http.fetch('get_user_details', { patient_uid: this.patientId }).subscribe(data => {
      this.onDataReceived(data)
      this.loading = false
    })
  }
  getPatientVersions() {
    this.storeService
      .getPatientVersions(this.patientId)
      .subscribe(({ data }) => {
        this.selectedVersion = '';
        this.patientVersions = [];
        this.translate.get('generalTerms.latestVersion').subscribe((value) => {
          this.patientVersions.push({
            ...this.orginalPatientDetails,
            title: value,
          });
          this.selectedVersion = value;
        });
        this.translate
          .get(['generalTerms.createdAt', 'generalTerms.editedAt', 'generalTerms.latestVersion'])
          .subscribe((t) => {
            this.patientVersions = []

            if (data.length > 1)
              data = data.sort((a, b) =>
                DateTime.fromISO(b.created_at).toMillis() - DateTime.fromISO(a.created_at).toMillis()
              );
            if (data.length > 0) {
              const initVersion = data.splice(-1, 1)[0]
              if (data.length > 0) {
                data.forEach(v => {
                  this.patientVersions.unshift(
                    {
                      ...v,
                      title:
                        t['generalTerms.editedAt'] + ' ' + DateTime.fromISO(v.updated_at).toFormat('dd-MM-yyyy'),
                    }
                  )
                })

              }
              let creational_date = initVersion.original_created_at;
              if (!creational_date)
                creational_date = initVersion.updated_at;
              const formated_creational_date = DateTime.fromISO(creational_date).toFormat('dd-MM-yyyy')
              this.patientVersions.push({
                ...initVersion, title: t['generalTerms.createdAt'] + ' ' + formated_creational_date
              })
            }
            this.patientVersions.unshift({
              ...this.orginalPatientDetails,
              title: t['generalTerms.latestVersion'],
            });
            this.selectedVersion = t['generalTerms.latestVersion']
          });
      });
  }
  generatePersonalInformationCard = async () => {
    return {
      title: await this.translate
        .get('createPatientForm.personalInformationCard.personalInformation')
        .toPromise(),
      questions: [
        [
          {
            show:
              typeof this.patientDetails.personal_information.gender !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalInformationCard.salutation.title')
              .toPromise(),
            value: await this.translate
              .get(
                `createPatientForm.personalInformationCard.salutation.${this.patientDetails.personal_information.gender}`,
                { default: 'Mr.' }
              )
              .toPromise(),
            type: 'text',
          },
          {
            show: true,
            label: await this.translate
              .get('createPatientForm.personalInformationCard.firstName')
              .toPromise(),
            value: this.patientDetails.personal_information.first_name,
            type: 'text',
          },
          {
            show: true,
            label: await this.translate
              .get('createPatientForm.personalInformationCard.lastName')
              .toPromise(),
            value: this.patientDetails.personal_information.last_name,
            type: 'text',
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_information.age !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalInformationCard.birthdate')
              .toPromise(),
            value: this.patientDetails.personal_information.age,
            type: 'date',
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_information.email !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalInformationCard.emailAddress')
              .toPromise(),
            value: this.patientDetails.personal_information.email,
            type: 'text',
          },
          {
            show:
              typeof this.patientDetails.personal_information.phone !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalInformationCard.phone')
              .toPromise(),
            value: this.patientDetails.personal_information.phone,
            type: 'text',
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_information.contact_reason !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalInformationCard.contactReason')
              .toPromise(),
            value: this.patientDetails.personal_information.contact_reason,
            type: 'text',
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_information
                .reason_explanation != 'undefined',
            label: await this.translate
              .get(
                'createPatientForm.personalInformationCard.reasonExplanation'
              )
              .toPromise(),
            value: this.patientDetails.personal_information.reason_explanation,
            type: 'textarea',
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_information
                .health_insurance != 'undefined',
            label: await this.translate
              .get('createPatientForm.personalInformationCard.healthInsurance')
              .toPromise(),
            value: this.patientDetails.personal_information.health_insurance,
            type: 'text',
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_information.weight_kg !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalInformationCard.weight')
              .toPromise(),
            value: `${this.patientDetails.personal_information.weight_kg} Kg.`,
          },
          {
            show:
              typeof this.patientDetails.personal_information.height_m !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalInformationCard.height')
              .toPromise(),
            value: `${this.patientDetails.personal_information.height_m} m.`,
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_information.status !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalInformationCard.status')
              .toPromise(),
            value: await this.translate
              .get(
                `createPatientForm.personalInformationCard.${this.patientDetails.personal_information.status}`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_information.smoking !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalInformationCard.smoking')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails.personal_information.smoking
                  ? 'yes'
                  : 'no'
                }`
              )
              .toPromise(),
          },
        ],
      ],
    };
  };
  generateAboutCopdCard = async () => {
    return {
      title: await this.translate
        .get('createPatientForm.copdCard.title')
        .toPromise(),
      questions: [
        [
          {
            show:
              typeof this.patientDetails?.about_copd?.classification !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.copdCard.classification')
              .toPromise(),
            value: {
              gold_1: 'GOLD l',
              gold_2: 'GOLD ll',
              gold_3: 'GOLD lll',
              gold_4: 'GOLD V',
              gold_5: 'GOLD Vl',
            }[this.patientDetails.about_copd.classification],
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.about_copd?.duration_years !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.copdCard.duration')
              .toPromise(),
            value: this.patientDetails.about_copd.duration_years,
          },
        ],
        [
          {
            show:
              typeof this.patientDetails?.about_copd?.o2_requirement !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.copdCard.oxygen')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails.about_copd.o2_requirement ? 'yes' : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails?.about_copd?.ongoing_treatment !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.copdCard.currentTreatment')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails.about_copd.ongoing_treatment
                  ? 'yes'
                  : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails?.about_copd?.actions != 'undefined',
            label: await this.translate
              .get('createPatientForm.copdCard.actions.title')
              .toPromise(),
            values: await this.patientDetails.about_copd.actions.map(
              async (act) => {
                return await this.translate
                  .get(`createPatientForm.copdCard.actions.${act}`)
                  .toPromise();
              }
            ),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.about_copd?.medical_aids !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.copdCard.medicalAids.title')
              .toPromise(),
            values: this.patientDetails.about_copd.medical_aids.map(
              async (act) => {
                return await this.translate
                  .get(`createPatientForm.copdCard.medicalAids.${act}`)
                  .toPromise();
              }
            ),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails?.about_copd?.symptoms != 'undefined',
            label: await this.translate
              .get('createPatientForm.copdCard.symptoms.title')
              .toPromise(),
            values: this.patientDetails?.about_copd?.symptoms?.map(
              async (act) => {
                return await this.translate
                  .get(`createPatientForm.copdCard.symptoms.${act}`, {
                    default: act,
                  })
                  .toPromise();
              }
            ),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails?.personal_story?.other_symptoms !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.copdCard.extraSymptoms.title')
              .toPromise(),
            value: this.patientDetails.personal_story?.other_symptoms
              ? await this.translate
                .get(
                  `generalTerms.${this.patientDetails.personal_story.other_symptoms
                    ?.otherSymptoms
                    ? 'yes'
                    : 'no'
                  }`
                )
                .toPromise()
              : '',
          },
        ],
      ],
    };
  };
  onCancelEditing() {
    this.edit = false;
    transformOnboardingFormData(JSON.parse(JSON.stringify(this.orginalCards)), this.locale, this.translate, this.patientDetails)
      .then(cards => {
        this.allCards = cards
        //this.getPatientVersions();
      })
  }

updatePatientDetails() {
  const data = this.modifiedPatientInfo;
  const personalInfo: any = {};
  Object.keys(data).forEach(k => {
    if (k.startsWith('personal_information')) {
      const prop = k.split('.')[1];
      personalInfo[prop] = data[k];
    }
  });
  delete data['personal_information.email'];
  delete data['personal_information.first_name'];
  delete data['personal_information.last_name'];
  delete data['personal_information.gender'];
  delete data['personal_information.contact_reason'];
  delete data['personal_information.reason_explanation'];
  delete data['personal_information.dob'];
  delete data['personal_information.phone'];
  const patient: any = {
    personal_information: personalInfo,
    other_info: { ...data },
    about_copd: {}
  };
  const requestData = {
    ...patient,
    patient_uid: this.patientId,
  };

  this.userService.editPersonalInfo(requestData).subscribe(
    (dd) => {
      this.translate
        .get('toastr.patientDetailsUpdated')
        .subscribe((res: string) => {
          this.toastr.success(res);
        });
    },
    (err) => {
      this.translate.get('toastr.patientDetailsError').subscribe((res: string) => {
        this.toastr.error(res);
      });
    },
    () => {
      this.edit = false;
      this.requestPatientDetails();
    }
  );
}

  generateOtherInfoCard = async () => {
    return {
      title: await this.translate
        .get('createPatientForm.otherCard.title')
        .toPromise(),
      questions: [
        [
          {
            show:
              typeof this.patientDetails.other_info.cardiovascular_disease !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.otherCard.cardiovascularDisease')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails.other_info.cardiovascular_disease
                  ? 'yes'
                  : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.other_info.fluid_consumption !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.otherCard.fluids')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails.other_info.fluid_consumption
                  ? 'yes'
                  : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.other_info.recent_surgery !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.otherCard.surgery')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails.other_info.recent_surgery ? 'yes' : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.other_info.sensitive_veins !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.otherCard.sensitiveVeins')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails.other_info.sensitive_veins ? 'yes' : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.other_info.chronic_disease !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.otherCard.chronicDisease')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails.other_info?.chronic_disease?.diagnosed
                  ? 'yes'
                  : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.other_info.difficult_acts !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.otherCard.activities.title')
              .toPromise(),
            values: this.patientDetails.other_info.difficult_acts.map(
              async (act) => {
                return await this.translate
                  .get(`createPatientForm.otherCard.activities.${act}`)
                  .toPromise();
              }
            ),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.other_info.medical_aids != 'undefined',
            label: await this.translate
              .get('createPatientForm.otherCard.medicalAids.title')
              .toPromise(),
            values: this.patientDetails.other_info.medical_aids.map(
              async (act) => {
                return await this.translate
                  .get(`createPatientForm.otherCard.medicalAids.${act}`)
                  .toPromise();
              }
            ),
          },
        ],
      ],
    };
  };
  generateLifeStyleCard = async () => {
    return {
      title: await this.translate
        .get('createPatientForm.lifeStyleCard.lifeStyle')
        .toPromise(),
      questions: [
        [
          {
            show:
              typeof this.patientDetails.lifestyle.work_condition !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.lifeStyleCard.employment')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails?.lifestyle?.work_condition?.employed
                  ? 'yes'
                  : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show: typeof this.patientDetails.lifestyle.smoking != 'undefined',
            label: await this.translate
              .get('createPatientForm.lifeStyleCard.smoking')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails.lifestyle.smoking ? 'yes' : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.lifestyle.regular_breaks !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.lifeStyleCard.regularBreaks')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails.lifestyle.regular_breaks ? 'yes' : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.lifestyle.lunch_break != 'undefined',
            label: await this.translate
              .get('createPatientForm.lifeStyleCard.lunchBreak')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails.lifestyle.lunch_break ? 'yes' : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show: this.patientDetails.lifestyle?.active_sports?.active,
            label: await this.translate
              .get('createPatientForm.lifeStyleCard.activeSports')
              .toPromise(),
            value: await this.translate
              .get(
                `createPatientForm.lifeStyleCard.${this.patientDetails.lifestyle?.active_sports?.type}`,
                { default: this.patientDetails.lifestyle?.active_sports?.type }
              )
              .toPromise(),
          },
        ],
      ],
    };
  };
  generatePersonalStoryCard = async () => {
    return {
      title: await this.translate
        .get('createPatientForm.personalStoryCard.title')
        .toPromise(),
      questions: [
        [
          {
            show:
              typeof this.patientDetails?.personal_story?.chronic_disease !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalStoryCard.chronicDiseaseText')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails?.personal_story?.chronic_disease
                  ?.chronicDisease
                  ? 'yes'
                  : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_story?.back_pain !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalStoryCard.backPainText')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails?.personal_story?.back_pain?.backPain
                  ? 'yes'
                  : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_story
                ?.cardiovascular_disease != 'undefined',
            label: await this.translate
              .get(
                'createPatientForm.personalStoryCard.cardiovascularDiseaseText'
              )
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails?.personal_story?.cardiovascular_disease
                  ? 'yes'
                  : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_story
                ?.concentration_problems != 'undefined',
            label: await this.translate
              .get(
                'createPatientForm.personalStoryCard.concentrationProblemsText'
              )
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails?.personal_story?.concentration_problems
                  ? 'yes'
                  : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_story?.diabetes !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalStoryCard.diabetes')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails?.personal_story?.diabetes ? 'yes' : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_story?.ongoing_treatment !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalStoryCard.currentTreatmentText')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails?.personal_story?.ongoing_treatment
                  ? 'yes'
                  : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_story?.sensitive_veins !=
              'undefined',
            label: await this.translate
              .get('createPatientForm.personalStoryCard.sensitiveVeinsText')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails?.personal_story?.sensitive_veins
                  ? 'yes'
                  : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_story?.sleep != 'undefined',
            label: await this.translate
              .get('createPatientForm.personalStoryCard.sleepText')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails?.personal_story?.sleep ? 'yes' : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_story?.stress != 'undefined',
            label: await this.translate
              .get('createPatientForm.personalStoryCard.stressText')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails?.personal_story?.stress ? 'yes' : 'no'
                }`
              )
              .toPromise(),
          },
        ],
        [
          {
            show:
              typeof this.patientDetails.personal_story?.surgery != 'undefined',
            label: await this.translate
              .get('createPatientForm.personalStoryCard.surgery')
              .toPromise(),
            value: await this.translate
              .get(
                `generalTerms.${this.patientDetails?.personal_story?.surgery ? 'yes' : 'no'
                }`
              )
              .toPromise(),
          },
        ],
      ],
    };
  };
  async handleOldPatientDetails() {
    this.old_system = true;
    this.allCards = [];

    let cardIndex = 0;

    if (
      this.patientDetails.personal_information &&
      Object.keys(this.patientDetails.personal_information)?.length > 0
    ) {
      this.allCards[cardIndex++] = await this.generatePersonalInformationCard();
    }
    if (
      this.patientDetails.about_copd &&
      Object.keys(this.patientDetails.about_copd)?.length > 0
    ) {
      this.allCards[cardIndex++] = await this.generateAboutCopdCard();
    }
    if (
      this.patientDetails.other_info &&
      Object.keys(this.patientDetails.other_info)?.length > 0
    ) {
      this.allCards[cardIndex++] = await this.generateOtherInfoCard();
    }
    if (
      this.patientDetails.lifestyle &&
      Object.keys(this.patientDetails.lifestyle)?.length > 0
    ) {
      this.allCards[cardIndex++] = await this.generateLifeStyleCard();
    }
    if (
      this.patientDetails.personal_story &&
      Object.keys(this.patientDetails.personal_story)?.length > 0
    ) {
      this.allCards[cardIndex++] = await this.generatePersonalStoryCard();
    }

  }
  getRadioValue(el) {
    for (const opt of el.options) {
      if (opt.value == el.value) return opt.label;
    }
    if (el.value === undefined || null) return '';
    return `Not Found ${el.value}`;
  }
  getCheckboxValue(el) {
    const values = el.options
      .map((opt) => {
        const item = el.value?.filter((v) => v.name == opt.name)?.[0];
        opt.value = item?.value ?? false;
        return opt;
      })
      .filter((el) => el.value);

    return values;
  }
  patientResetPassword() {
    const requestData = {
      email: this.patientDetails.personal_information.email,
    };
    this.generalService.sendPasswordResetEmail(requestData).subscribe(
      (data) => {
        this.translate
          .get('toastr.passwordResetEmailSent')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
      },
      (error) => {
        this.translate
          .get('toastr.passwordResetEmailError')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
      }
    );
  }
  openChangeAccessModal(content) {
    this.newUserAccessLevel = this.patientDetails.user_access || 'full_access';
    this.modalService.open(content, { centered: true });
  }

  changeAccess() {
    if (this.newUserAccessLevel !== this.patientDetails.user_access) {
      const requestData = {
        patient_uid: this.patientId,
        user_access: this.newUserAccessLevel,
      };
      this.storeService.changePatientAccessLevel(requestData).subscribe(() => {
        this.patientDetails.user_access = this.newUserAccessLevel;
      });
    }
  }

}
