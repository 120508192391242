import { BehaviorSubject, Observable, Subscriber, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';

let refreshing = false;
const isRefreshingToken: BehaviorSubject<boolean> = new BehaviorSubject(false);

const sendPostRequest = (url: string, body: any, auth?: AuthService): Observable<any> => {
  return new Observable((obs: Subscriber<unknown>) => {
    const token = JSON.parse(localStorage.getItem('__auth'))?.access_token;
    const reqBody: any = body;
    const headers: any = {};

    if (
      token &&
      url.includes(environment.baseCpuUrl) &&
      !url.includes(environment.baseKeycloakUrl) &&
      !url.includes('send_email') &&
      !url.includes('send_reset_password_email')
    ) {
      reqBody.id_token = token;
      headers['Content-Type'] = 'application/json';
    } else if (url.includes('send_reset_password_email')) {
      headers['Content-Type'] = 'application/json';
    } else {
      headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    if (url.includes('reset_password') && body.token) {
      headers['Content-Type'] = 'application/json';
    }

    fetch(url, { method: 'POST', body: JSON.stringify(reqBody), headers })
      .then(res => res.json())
      .then(data => {
        if (data?.error === 'ExpiredSignatureError') {
          if (!refreshing) {
            refreshing = true;
            isRefreshingToken.next(true);
            auth.refreshToken().pipe(
              switchMap(() => {
                refreshing = false;
                isRefreshingToken.next(false);
                return sendPostRequest(url, body, auth); // Retry the original request
              }),
              catchError(err => {
                refreshing = false;
                isRefreshingToken.next(false);
                window.location.href = '/login'; // Redirect to login if token refresh fails
                return throwError(err);
              })
            ).subscribe(obs);
          } else {
            isRefreshingToken.pipe(
              switchMap(() => sendPostRequest(url, body, auth))
            ).subscribe(obs);
          }
        } else {
          if (data?.error) {
            obs.error(data);
          } else {
            obs.next(data);
          }
          obs.complete();
        }
      })
      .catch(error => {
        obs.error(error);
        obs.complete();
      });
  });
};

export default function fetchData(
  authService: AuthService,
  url: string,
  body: any
): Observable<any> {
  if (refreshing) {
    return new Observable<any>(observer => {
      const subscription = isRefreshingToken.subscribe(() => {
        sendPostRequest(url, body, authService).subscribe(observer);
        subscription.unsubscribe();
      });
    });
  } else {
    return sendPostRequest(url, body, authService);
  }
}
