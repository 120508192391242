export class SurveyQuestionBase<T> {
  value: T;
  key: number;
  label: string;
  lang: object;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  options: any;
  answer?: any;

  constructor(options: {
    value?: T,
    key?: number,
    label?: string,
    lang?: object,
    required?: boolean,
    order?: number,
    controlType?: string
  } = {}) {
    this.value = options.value;
    this.key = options.key;
    this.label = options.label || '';
    this.lang = options.lang || {};
    this.required = !!options.required;
    this.order = !options.hasOwnProperty('order') ? 1 : options.order;
    this.controlType = options.controlType || '';
  }
}
