import { Time } from "@angular/common";

export class newTaskModel {
    title: String;
    details: String;
    date: Date;
    time: Time;
    reminder: boolean;
    completed: boolean;
    edit:boolean;

}