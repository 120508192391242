enum Activity {
  walking = 'walking',
  household = 'household',
  hygiene = 'hygiene',
  stairs = 'stairs',
  dressing = 'dressing',
  communication = 'communication',
  leisure = 'leisure',
  shopping = 'shopping',
  society = 'society',
}

enum MedicalAid {
  stick = 'walking_stick',
  rollator = 'rollator',
  wheelchair = 'wheelchair',
  lift = 'stair_lift',
  visual = 'visual_aid',
  hearing = 'hearing_aid',
  grasping = 'grasping_arms',
}

export class ChronicDisease {
  diagnosed: boolean;
  optionalText: string;
}

export class OtherModel {
  sensitiveVeins: boolean;
  cardiovascularDisease: boolean;
  chronicDisease: ChronicDisease;
  recentSurgery: boolean;
  difficultActs: { [key: string]: boolean };
  aids: { [key: string]: boolean };
  fluidsRequirement: boolean;
  personalGoal: string;
}
