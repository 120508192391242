import { EventColor } from './EventColor';

export const colors: {
  green: EventColor;
  red: EventColor;
  blue: EventColor;
  yellow: EventColor;
} = {
  red: {
    primary: '#823335',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#2FC19A',
    secondary: '#77edc2',
  },
};
