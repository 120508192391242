enum CopdClassification {
  g1 = 'gold_1',
  g2 = 'gold_2',
  g3 = 'gold_3',
  g4 = 'gold_4',
}

enum MedicalAid {
  inhaler = 'inhaler',
  flowMeter = 'peak_flow_meter',
  lungFunction = 'lung_function',
  oximeter = 'pulse_oximeter',
  trainer = 'breathing_trainer',
}

enum Actions {
  pulSport = 'pulm_sport',
  rehab = 'rehab',
  therapy = 'therapy',
  group = 'self_help_group',
  sport = 'sport',
}

export class CopdModel {
  classification: CopdClassification;
  durationInYears: number;
  durationInMonths: number;
  ongoingTreatment: boolean;
  o2Requirement: boolean;
  medicalAids: { [key: string]: boolean };
  actions: { [key: string]: boolean };
  symptoms: { [key: string]: boolean };
  extraSymptoms: { [key: string]: boolean };
}
