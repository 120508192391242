<app-navbar></app-navbar>
<div class="container">
  <div class="notifications">
    <div class="back-button">
      <a class="d-flex g-2" (click)="goBack()">
        <img src="../../../assets/img/svg/icons/left-arrow.svg" />
        <span>{{ "notifications.back" | translate }}</span>
      </a>
    </div>

    <div class="content g-3">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h2 class="m-0">{{ "notifications.title" | translate }}</h2>
        <img src="../../../assets/img/svg/icons/notification-bell.svg" />
      </div>

      <ng-container *ngIf="notificationsList.length > 0; else noNotifications">
        <app-notification-list
          [notificationsList]="notificationsList"
          [buttons]="true"
          [isListViewAll]="isListViewAll"
          (toggleView)="togglerListView($event)"
        ></app-notification-list>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noNotifications>
  <div class="list-item">
    <img src="../../../assets/img/svg/icons/notification-speaker.svg" />
    <span class="list-item__description list-item__description--none">
      {{ "notifications.none" | translate }}
    </span>
  </div>
</ng-template>
