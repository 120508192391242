import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

import {SurveyQuestionBase} from '@schemas/survey/survey-question-base';
import {QuestionControlService} from '@services/survey/survey-question-control.service';
import BreathMentHelper from '@helpers/helper';
import {TranslateService} from '@ngx-translate/core';
import {SurveyQuestionService} from '@services/survey/survey-question.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [QuestionControlService]
})
export class DynamicFormComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter<string>();
  @Input() questions: SurveyQuestionBase<object>[] = [];
  @Input() editMode: boolean;
  @Input() type: string;
  form: UntypedFormGroup;

  constructor(
    private qcs: QuestionControlService,
    private translate: TranslateService,
    private surveyService: SurveyQuestionService
  ) {
  }

  ngOnInit() {
    this.generateFormFields();
  }

  generateFormFields() {
    this.form = this.qcs.toFormGroup(this.questions);
  }

  onSubmit() {
    const requestData = this.prepareSubmitRequest();
    this.surveyService.storeSurveyResults(requestData).subscribe(() => {
      this.newItemEvent.emit('survey-completed');
      return false;
    });
  }

  prepareSubmitRequest() {
    const currentDate = BreathMentHelper.currentDate();
    const currentTime = BreathMentHelper.currentTime();

    const requestData = {
      date: currentDate,
      iso_datetime: new Date().toISOString(),
      time: currentTime,
      survey_type: this.type,
      questions: [],
    };

    this.questions.forEach((question, index) => {
      question.answer = this.form.value[index];
      delete question.key;
      delete question.value;
      requestData.questions.push(question);
    });
    return requestData;
  }

  get currentLanguage() {
    return this.translate.currentLang;
  }

}
