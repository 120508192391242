
<div *ngIf="loading" class="full-screen-center" ngClass="loading">
  <div class="text-primary" role="status">
      <img [src]="'assets/Logo/spinner-border.gif'" alt="Spinner" id="spinner">
  </div>
</div>
<div class="container-fluid   g-3 ">
    <div class="content g-3">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h2 class="m-0">{{ "notifications.title" | translate }}</h2>
        <img [src]="'assets/img/svg/icons/notification-bell.svg'" />
      </div>

        <app-notification-list [notificationsList]="notificationsList" [buttons]="true"
          [isListViewAll]="isListViewAll" (toggleView)="togglerListView($event)"></app-notification-list>
      
    </div>
  </div>


  