import {SurveyQuestionBase} from '@schemas/survey/survey-question-base';

export class TextBoxQuestion extends SurveyQuestionBase<string> {
  controlType = 'text';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options[`type`] || '';
  }
}
