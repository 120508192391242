<app-navbar></app-navbar>
<div class="container mt-4">
    <div class="d-flex align-items-center">
        <h2> {{ "settingsScreen.settings" | translate }}</h2>
        <i class="fa fa-cog fa-lg" aria-hidden="true"></i>
    </div>
    <hr>
    <div class="mt-4">
        <div class="d-flex align-items-center">
            <a routerLink="/confirm-password" class="change-option">
                <h4>{{ "settingsScreen.changeEmail" | translate }}</h4>
                <p class="mt-2">{{ "settingsScreen.changeEmailDesc" | translate }}</p>
                <div class="overlay"></div> <!-- Yeni öğe -->
            </a>
            
            <i routerLink="/confirm-password" class="fa fa-envelope "   style="cursor: pointer;"aria-hidden="true"></i>

        </div>
 
       
    </div>
    <hr>
    <div class="mt-4 mb-4">
        <div class="d-flex align-items-center">
            <a routerLink="/old-password" class="change-option">
                <h4>{{ "settingsScreen.changePassword" | translate }}</h4>
                <p class="mt-2">{{ "settingsScreen.changePasswordDesc" | translate }}</p>
                <div class="overlay"></div> <!-- Yeni öğe -->
            </a>
            
            <i routerLink="/old-password" class="fa fa-unlock-alt"   style="cursor: pointer;" aria-hidden="true"></i>

        </div>
       
    </div>
    <hr>
    <div class="mt-4 mb-4">
        <div class="d-flex align-items-center">
            <a (click)="openModal(reportProblemModal);" style="color: #ffb117;" class="change-option">
                <h4 style="color: #ffb117; cursor: pointer;" >{{ "settingsScreen.reportProblem" | translate }}</h4>
                <div class="overlay"></div> <!-- Yeni öğe -->
            </a>
            
            <i (click)="openModal(reportProblemModal);" class="fa fa-exclamation-triangle" style="color: #ffb117; cursor: pointer;" aria-hidden="true"></i>

        </div>
       
    </div>
    <hr>
    <div class="mt-4 mb-4">
        <div class="d-flex align-items-center">
            <a style="cursor: pointer;" (click)="logout();" class="change-option">
                <h4 style="color: #912020;">{{ "settingsScreen.logout" | translate }}</h4>
            
                <div class="overlay"></div> <!-- Yeni öğe -->
            </a>
            
            <i (click)="logout();" class="fas fa-sign-out-alt" style="color: #912020; cursor: pointer;" ></i>
        </div>
       
    </div>
    <hr>
    
    <div class="mt-4 mb-4">
        <div class="d-flex align-items-center">
            <a style="cursor: pointer;" (click)="openModal(deleteAccountModal);" class="change-option">
                <h4 style="color: #630d0d;">{{ "settingsScreen.deleteTitle" | translate }}</h4>
            
                <div class="overlay"></div>
            </a>
            
            <i (click)="openModal(deleteAccountModal);" class="fa fa-trash" style="color: #630d0d; cursor: pointer;" ></i>
        </div>
       
    </div>
    <hr *ngIf="role === 'patient'">
    <div class="version">
        <span>{{ "settingsScreen.current_version" | translate }} {{version}}</span>
    </div>
</div>

<ng-template #deleteAccountModal let-modal>
	<div class="modal-header">
		<h5 class="modal-title" style="color: #630d0d;">{{ "settingsScreen.deleteTitle" | translate }}</h5>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body">
		<p style="color: #630d0d; font-size: 1.1rem; font-weight: 600;">{{ "settingsScreen.deletePrompt" | translate }}</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-sm btn-primary" style="background-color: #630d0d;" (click)="modal.close(); deleteAccount()">
			{{ "generalTerms.yes" | translate }}
		</button>
		<button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
			{{ "generalTerms.no" | translate }}
		</button>
	</div>
</ng-template>

<ng-template #reportProblemModal let-modal>
	<div class="modal-header">
		<h5 class="modal-title">{{ "settingsScreen.reportProblem" | translate }}</h5>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body">
		<p style="font-size: 1.1rem; font-weight: 600;">{{ "settingsScreen.reportProblemPrompt" | translate }}</p>
        <textarea [(ngModel)]="problemMessage" rows="5" style="width: 100%;"></textarea>
	</div>
	<div class="modal-footer">
		<button [disabled]="!problemMessage" type="button" class="btn btn-sm btn-primary" (click)="modal.close(); sendProblemMessage();">
			{{ "generalTerms.submit" | translate }}
		</button>
	</div>
</ng-template>

