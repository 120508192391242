export class Constants {

  //Patterns
  static readonly alphabetPattern: string = '^[a-zA-ZÀ-ž ]*$';
  static readonly emailPattern: string = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  // static readonly phonePattern: string = '[- +()0-9]+';
  // static readonly phonePattern: string = '^[+][0-9 ]{13}$';
  static readonly phonePattern: string = '^[+][0-9 ]{8,}$';



  //Constants
  static readonly minAge: number = 18;
  static readonly maxAge: number = 99;
  static readonly minNicotine: number = 1;
  static readonly minOxygen: number = 1;

  static readonly diagnosisOptions = [
    "Asthma",
    "Bronchitis",
    "COPD-1",
    "COPD-2",
    "COPD-3",
    "COPD-4",
    "COVID"
  ]

  static readonly inhalationOptions = [
    "Spray",
    "Pulver",
    "Feucht"
  ]

  static readonly employmentTypeOptions = [
    'sittingActivity',
    'frontScreen',
    'office',
    'homeOffice',
    'car',
    'standingActivity'
  ]

  static readonly activeSportsOptions = [
    'jogging',
    'muscleTraining',
    'yoga',
    'lungSport',
    'other'
  ]




}
