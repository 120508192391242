<app-navbar></app-navbar>

<div *ngIf="dataLoaded" class="container-fluid">
  <div *ngIf="success" class="text-center align-items-center">
    <h1>{{ 'payment.paymentSuccess' | translate }}</h1>
    <p>{{ 'payment.paymentSuccessText' | translate }}</p>
    <button class="btn btn-primary" (click)="navigateDashboard()">Dashboard</button>
  </div>
  <div *ngIf="!success" class="text-center align-items-center">
    <h1>{{ 'payment.paymentFailure' | translate }}</h1>
    <p>{{ 'payment.paymentFailureText' | translate }}</p>
    <button class="btn btn-primary" (click)="navigateDashboard()">Dashboard</button>
  </div>
</div>
