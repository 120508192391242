import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

// Services
import { GeneralService } from "@services/general/general.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {

  public currentRoute: string;
  public token: any;

  constructor(
    private generalService: GeneralService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getCurrentRouter();
  }

  unsubscribe() {
    this.generalService.unsubscribeFromNotifications(this.token).subscribe(() => {

    },
      err => {
        this.translate.get('toastr.unsubscribeError').subscribe((res: string) => {
          this.toastr.error(res);
        });
      },
      () => {
        this.translate.get('toastr.unsubscribeSuccess').subscribe((res: string) => {
          this.toastr.success(res);
        });
      });
  }

  getCurrentRouter() {
    this.token = this.route.snapshot.params.token;
  }

}
