import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { passwordChangeGuard } from '@services/passwordChangeGuard';

@Component({
  selector: 'app-old-email',
  templateUrl: './old-email.component.html',
  styleUrls: ['./old-email.component.css']
})
export class OldEmailComponent implements OnInit {

  public isCorrectPassword: boolean = true;
  public password: string = '';

  public control: any = '';


  public currentUser: any;
  public userInitial: string;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,

  ) {

  }


  ngOnInit(): void {
    this.authService.me$.subscribe(val => {
      this.currentUser = val
    })
  }


  confirm() {
    let tokenParams: any = null;
    if (this.currentUser.role == 'physiotherapist') {
      tokenParams = new URLSearchParams({
        grant_type: "password",
        client_id: environment.KEYCLOAK_CLIENT_ID,
        client_secret: environment.KEYCLOAK_CLIENT_SECRET_KEY,
        username: this.currentUser.email,
        password: this.password
      });
    } else {
      tokenParams = new URLSearchParams({
        grant_type: "password",
        client_id: environment.KEYCLOAK_CLIENT_ID,
        client_secret: environment.KEYCLOAK_CLIENT_SECRET_KEY,
        username: this.currentUser.personal_information.email,
        password: this.password
      });
    }


    this.authService.checkPassword(tokenParams).subscribe(userCredentials => {
      this.authService.setCurrentPassword(this.password)
      setTimeout(a => {
        this.router.navigate(['change-email']);
      }, 300)

    },
      error => {
        this.isCorrectPassword = false;
      });
  }



}



