import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { DatePipe } from '@angular/common';


export function birthdateValidation(): ValidatorFn {
    return function validate(control: UntypedFormControl) {
        if (control.parent && control.parent.get('age') && control.parent.get('age').value) {
            var birthdate = control.parent.get('age').value;
            var birthday = new Date(birthdate)

            var ageDifMs = Date.now() - birthday.getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            var age = Math.abs(ageDate.getUTCFullYear() - 1970);
            if (age < 18 || age > 99){
                return {
                    birthdateValidation: true,
                };
            }

        }

        return null;
    };
}