<form class="col" id="top">


    <div *ngFor="let row of options;index as rowIndex;trackBy:trackField;count as rowsCount;" class="row pb-2">

        <ng-container *ngFor="let c of row;count as colsCount;index as colIndex">

            <label *ngIf="c.type=='label'" class="form-label  pr-2 font-weight-bold">{{
                c.label | translate
                }}</label>

            <ng-container *ngIf="c.type=='checkbox' && c.show==true">

                <ng-container [ngTemplateOutlet]="checkbox" [ngTemplateOutletContext]="{field:c,colsCount,colIndex}">
                </ng-container>

            </ng-container>

            <ng-container *ngIf="c.type=='radio' && c.show==true">
                <ng-container [ngTemplateOutlet]="radio" [ngTemplateOutletContext]="{field:c,colsCount,colIndex}">
                </ng-container>
            </ng-container>
            <ng-container *ngIf="c.type=='text' && c.show==true">

                <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{field:c,colsCount,colIndex}">
                </ng-container>

            </ng-container>
            <ng-container *ngIf="c.type=='textarea'">

                <ng-container [ngTemplateOutlet]="textarea" [ngTemplateOutletContext]="{field:c,colsCount,colIndex}">
                </ng-container>

            </ng-container>
            <ng-container *ngIf="c.type=='number' && c.show==true">
                <ng-container [ngTemplateOutlet]="number" [ngTemplateOutletContext]="{field:c,colsCount,colIndex}">
                </ng-container>
            </ng-container>
            <ng-container *ngIf="c.type=='date' && c.show==true">
                <ng-container [ngTemplateOutlet]="date" [ngTemplateOutletContext]="{field:c,colsCount,colIndex}">
                </ng-container>
            </ng-container>
            <ng-container *ngIf="c.type=='dropdown'">
                <ng-container [ngTemplateOutlet]="dropdown" [ngTemplateOutletContext]="{field:c,colsCount,colIndex}">
                </ng-container>
            </ng-container>

        </ng-container>

    </div>

    <div *ngIf="forUpdate" class="ml-auto mt-4" style="text-align: end">
        <button class="btn btn-sm btn-primary" [disabled]="shouldBeDisabled()" (click)="doSubmit()">
            <div *ngIf="!inprogress">{{ "generalTerms.save" | translate }}</div>
            <div *ngIf="inprogress"
                style="color: white !important; height: 1.3rem !important; width: 1.3rem !important; border: 0.2em solid currentColor; border-right-color: transparent;"
                class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </button>
        <button class="btn btn-sm btn-secondary" [disabled]="inprogress" (click)="doCancel()">
            {{ "generalTerms.cancel" | translate }}
        </button>
    </div>
    <div *ngIf="forCreate" class="ml-auto mt-4" style="text-align: end">
        <button *ngIf="activeCardIndex>0" class="btn btn-sm btn-primary" (click)="changeCard('previous')">
            <div *ngIf="!inprogress">{{ "generalTerms.previous" | translate }}</div>

        </button>
        <button *ngIf="activeCardIndex< totalCards" class="btn btn-sm btn-primary"
            (click)="activeCardIndex< totalCards-1?changeCard('next'):doSubmit()">
            {{ (activeCardIndex< totalCards-1 ?"generalTerms.next":"generalTerms.save") | translate }} </button>
    </div>
</form>

<ng-template #checkbox let-field='field'>
    <div :id="{{ field.id }}" class="form-field col-sm-12">
        <label class="form-label font-weight-bold">
          {{field.label}}{{field.required?'\u00a0*':''}}
        </label>
        <span *ngIf="showErrors && field.required &&  (!field.selectedItemsCount || field.selectedItemsCount==0 )"
            class="error alert-danger" role="alert">{{"errorMessages.minOneCheck"|translate}}</span>
        <ng-container *ngFor="let opt of field.options">
            <div class="col ml-3" style="display:block;">
                <input class="form-check-input position-relative" [name]="opt.name" style="margin-bottom: 0.35rem;"
                    type="checkbox" [id]="opt.name" [(ngModel)]="opt.value"
                    (ngModelChange)="checkboxValueChanged(field.name,field.options,field)" />
                <label class="form-check-label ml-2" [for]="opt.name">
                    {{ opt.label }}
                </label>
            </div>
        </ng-container>

    </div>
</ng-template>

<ng-template #text let-field='field' let-cols="colsCount" let-colIndex="colIndex">


    <div :id="{{ field.id }}" [class]="(colIndex==2?'form-field col':'form-field col-lg-5  col-md-12 col-sm-12 ')+' form-group'">
        <label [for]="'txt-'+field.name" class="font-weight-bold">{{ field.label |
            translate }} {{field.required?'*':''}}</label>

        <div *ngIf="field.prefix" class="input-group-prepend">
            <span class="input-group-text">{{field.prefix}}</span>
        </div>
        <input [(ngModel)]="field.value" (ngModelChange)="textValueChanged(field.name,$event,field)"
            [placeholder]="field?.placeholder ?? ''" [id]="'txt-'+field.name" [name]="field.name" type="text"
            class="form-control" aria-label="Amount (to the nearest dollar)">
        <div *ngIf="field.postfix" class="input-group-append">
            <span class="input-group-text">{{field.postfix}}</span>
        </div>

        <span *ngIf="showErrors && field.required && isEmptyOrNull(field.value)" class="error alert-danger"
            role="alert">{{"errorMessages.requiredField"|translate}}</span>
        <span *ngIf="showErrors && !isEmptyOrNull(field.value) && field.valid==false" class="error alert-danger"
            role="alert">{{field.errorMessage ?? 'invalid value!' }}</span>
    </div>
</ng-template>
<ng-template #textarea let-field='field'>


    <div :id="{{ field.id }}" class="form-field col-lg-6 col-md-8 col-sm-12 ml-0 mb-3" style="flex: 0 0 100%; max-width: none;">
        <label [for]="'txt-'+field.name" class="font-weight-bold">{{ field.label |
            translate }} {{field.required?'*':''}}</label>

        <div class="d-flex input-group input-group-sm">

            <textarea cols="40" rows="5" [(ngModel)]="field.value" [placeholder]="field?.placeholder ?? ''"
                (ngModelChange)="textValueChanged(field.name,$event,field)" [id]="'txt-'+field.name" [name]="field.name"
                class="form-control" aria-label="Amount (to the nearest dollar)">
                {{field.value}}
                </textarea>
            <span *ngIf="showErrors && field.required && isEmptyOrNull(field.value)" class="error alert-danger"
                role="alert">{{"errorMessages.requiredField"|translate}}</span>
            <span *ngIf="showErrors && !isEmptyOrNull(field.value) && field.valid==false" class="error alert-danger"
                role="alert">{{field.errorMessage ?? 'invalid value!' }}</span>
        </div>
    </div>
</ng-template>
<ng-template #dropdown let-field='field'>
    <div :id="{{ field.id }}" class="form-field ml-0">
        <label [for]="'dropdown-'+field.name" class="font-weight-bold">{{ field.label | translate }} </label>

        <div class="">
            <div ngbDropdown aria-labelledby="dropdownMenuButton">
                <button class="form-control btn btn-xs btn-secondary" [id]="'dropdown-'+field.name"
                    ngbDropdownToggle>{{field.value}}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="min-width: 100%;">
                    <button ngbDropdownItem *ngFor="let el of field.items"
                        style="font-size: 0.85rem; text-align: left;"
                        (click)="field.value=el.value;selectedValueChanged(field.name,el.id);">
                        {{ el.label }}
                    </button>
                </div>
            </div>
        </div>
        <span *ngIf="showErrors && field.required && field.value==field.defaultValue" class="error alert-danger"
            role="alert">{{"errorMessages.requiredField"|translate}}</span>
    </div>


</ng-template>
<ng-template #number let-field='field'>

    <div :id="{{ field.id }}" class="form-field col-lg-5 col-md-12 col-sm-12 form-group">
        <label [for]="'num-'+field.name" class="font-weight-bold">{{ field.label |
            translate }}{{field.required?'\u00a0*':''}}</label>

        <div *ngIf="field.prefix" class="input-group-prepend">
            <span class="input-group-text">{{field.prefix}}</span>
        </div>
        <input (ngModelChange)="numberValueChanged(field.name,$event)" [(ngModel)]="field.value" [min]="field.minValue"
            [max]="field.maxValue" [placeholder]="field?.placeholder ?? '0'" [id]="'num-'+field.name"
            [name]="field.name" type="number" class="form-control" aria-label="Amount (to the nearest dollar)">
        <div *ngIf="field.postfix" class="input-group-append">
            <span class="input-group-text">{{field.postfix}}</span>
        </div>
        <span *ngIf="showErrors && field.required && isEmptyOrNull(field.value)" class="error alert-danger"
            role="alert">{{"errorMessages.requiredField"|translate}}</span>
    </div>
</ng-template>

<ng-template #date let-field='field'>
    <div :id="{{ field.id }}" class="form-field col-lg-5 col-md-5 col-sm-12 form-group">

        <label class="form-label pr-2 font-weight-bold" [for]="'date-'+field.name">{{
            field.label | translate
            }}{{field.required?'\u00a0*':''}}</label>


        <input class="form-control" type="date" useValueAsDate
            (ngModelChange)="dateValueChanged(field.name,$event,field)" [(ngModel)]="field.value" />


        <span *ngIf="showErrors && field.required && isEmptyOrNull(field.value)" class="error alert-danger"
            role="alert">{{"errorMessages.requiredField"|translate}}</span>
        <span *ngIf="showErrors && !isEmptyOrNull(field.value) && field.valid==false" class="error alert-danger"
            role="alert">{{field.errorMessage ?? 'invalid value!' }}</span>
    </div>

</ng-template>
<ng-template #radio let-field='field'>
    <!-- <span style="background-color: red;color:#fff;">name:{{field.name}}</span> -->
    <!-- <label for="basic-url" class="form-label">{{field.label}}</label> -->
    <div :id="{{ field.id }}" class="form-field col-sm-12">
        <label class="form-label  font-weight-bold">
          {{field.label}}{{field.required?'\u00a0*':''}}
        </label>
        <div class="input-group ml-3" style="display:block;">
            <div class="form-check col-sm-10 col-lg-2 col-md-3 col-sm-6" *ngFor="let opt of field.options;index as i">
                <input class="form-check-input" type="radio" [name]="field.name" [id]="'radio-'+field.name+i"
                    (ngModelChange)="radioValueChanged(field.id,$event,field,opt);" [(ngModel)]="field.value"
                    [value]="opt.value" [checked]="field.value == opt.value" />
                <label class="form-check-label" [for]="'radio-'+field.name+i">{{ opt.label
                    }}</label>
            </div>

        </div>
        <span *ngIf="showErrors && field.required && (field.value==null || field.value==undefined)"
            class="error alert-danger" role="alert">{{"errorMessages.requiredField"|translate}}</span>
    </div>
</ng-template>
