export enum Status {
    employed = "employed",
    unableToWork = "unable_to_work",
    retired = "retired",
}

export enum Gender {
    man = "male",
    woman = "female",
    other = "diverse",
}

export class CareDiagnosis {
    diagnosed: boolean;
    degree: number;
}

export class PersonalInfoModel {
    firstName: string;
    lastName: string;
    contactReason: string;
    reasonExplanation: string;
    age: string;
    email: string;
    phone: string;
    height: number;
    weight: number;
    gender: Gender;
    smoking: boolean;
    status: Status;
    careDiagnosis: CareDiagnosis;
}
