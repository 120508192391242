import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';


import { Observable } from 'rxjs';
import * as url from "url";
import { fromPromise } from "rxjs/internal-compatibility";
import { environment } from "../../environments/environment";
import { error } from "selenium-webdriver";

@Injectable({
  providedIn: 'root',
})
export class VideoService {

  public baseUrl: string;

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    private storage: AngularFireStorage
  ) {
    this.baseUrl = environment.baseSamuraiUrl;
  }

  public getVideo(): any {
    return this.http.get(this.baseUrl + 'video_feed');
  }

  public send(data: any): any {
    return this.http.post(this.baseUrl + 'stream_video', data).pipe(map(response => response));
  }

  public getStart(): any {
    return this.http.get(this.baseUrl + 'success').pipe(map(response => response));
  }

  // public getCalculations(): any {
  //   return this.http.get(this.baseUrl + 'calculations').pipe(map(response => response));
  // }

  public getCalculations(requestData: any): any {
    return this.http.post(this.baseUrl + 'get_calculations', requestData).pipe(map(response => response));
  }

  public getPlot(): any {
    return this.http.get(this.baseUrl + 'graph', { responseType: 'blob' });
  }

  public getExercisePerformance(): any {
    return this.http.get(this.baseUrl + 'exercise_performance').pipe(map(response => response));
  }



  public getBodyPix(img): any {
    return this.http.get(`https://us-central1-breathment.cloudfunctions.net/getBodyPix/${img}`);

  }

  public sendFrame(data): any {
    // return this.http.post(this.baseUrl + 'upload-image', data).pipe(map(response => response));
    return this.http.post(this.baseUrl + 'upload-image', data);
  }

  public checkPose(data): Observable<any> {
    return this.http.post(this.baseUrl + 'check_initial_pose', data);
  }

  public checkStartGesture(data): any {
    return this.http.post(this.baseUrl + 'exercise_start', data);
  }

  public getGraph(): any {
    return this.http.get(this.baseUrl + 'graph').pipe(map(response => response));
  }


  // Audio
  public getAllAudios(folder: string, language: string): Observable<any> {
    let inhaleRef, exhaleRef;

    if (language === 'de') {
      inhaleRef = this.storage.ref(`/german-instructions/${folder}/inhale.m4a`);
      exhaleRef = this.storage.ref(`/german-instructions/${folder}/exhale.m4a`);
    } else if (language === 'en') {
      inhaleRef = this.storage.ref(`/turkish-instructions/${folder}/inhale.m4a`);
      exhaleRef = this.storage.ref(`/turkish-instructions/${folder}/exhale.m4a`);
    }
    // const inhaleRef = this.storage.ref(`/german-instructions/${folder}/inhale.m4a`);
    const inhaleUrl = inhaleRef.getDownloadURL();

    // const exhaleRef = this.storage.ref(`/german-instructions/${folder}/exhale.m4a`);
    const exhaleUrl = exhaleRef.getDownloadURL();

    return forkJoin([
      inhaleUrl,
      exhaleUrl
    ]).pipe(map(result => {
      return {
        inhale: result[0],
        exhale: result[1]
      };
    }));
  }

  public getInstructionVideo(physiotherapistId: string, language: string, folder: string): Observable<any> {
    let videoRef, videoUrl;
    if (physiotherapistId === 'breathment') {
      videoRef = this.storage.ref(`${physiotherapistId}/${language}/${folder}`);
    } else {
      videoRef = this.storage.ref(`customers/${physiotherapistId}/${language}/${folder}`);
    }

    videoUrl = videoRef.getDownloadURL();

    return videoUrl.pipe(map(result => {
      return result;
    }));

  }


}
