<div class="vh-100" #overflow  >
  <app-navbar></app-navbar>
  <div class="container text-center"  style="overflow-y: auto;">
    <div class="d-flex justify-content-center align-items-center">
      <img alt="Image placeholder" src="assets/images/landing-page/404.svg" class="justify-content-center" style="max-width: 19%;">
    </div>
    <h4 style="color: #314f6d;">{{'pageNotFound' | translate}}</h4>
  <div *ngIf="currentUser">
    <button type="button" class="btn btn-primary btn-lg" routerLink="/dashboard">{{'home.navigation.dashboard' | translate}}</button>
  </div>
   <div *ngIf="!currentUser">
    <button type="button" class="btn btn-primary btn-lg" routerLink="/login">{{'home.navigation.login' | translate}}</button>
   </div>
   
  </div>
  

</div>



