import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification/notification.service';

@Component({
  selector: 'app-notifications-tab',
  templateUrl: './notifications-tab.component.html',
  styleUrls: ['./notifications-tab.component.css'],
})
export class NotificationsTabComponent implements OnInit {
  isListViewAll: boolean;
  notificationsList: Notification[] = [];
  patientId: any;
  locale: string;
  /**
   *
   */
  constructor(
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.locale = this.translate.currentLang;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      this.loadNotifications();
    });
  }
  ngOnInit(): void {
    this.patientId = this.route.parent?.snapshot.paramMap.get('id');
    this.loadNotifications();
  }

  loading: boolean = true;
  private _counter: number = 0;
  IncreaseCounter() {
    this._counter++;
    if (this._counter == 1) {
      this.loading = false;
      this._counter = 0;
    }
  }
  loadNotifications(): void {
    this.notificationService
      .getPhysioNotificationAboutPatient(this.patientId, this.locale)
      .subscribe(
        (notifications: Notification[]) => {
          this.notificationsList = notifications;

          this.notificationService
            .markAsReadByPatient(this.patientId)
            .subscribe((data) => {});
        },
        (error) => {
          console.error('Error retrieving notifications:', error);
        },
        () => {
          this.IncreaseCounter();
        }
      );
  }

  togglerListView(showAll: boolean) {
    this.isListViewAll = showAll;
  }
}
