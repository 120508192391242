<ng-template #modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ modalConfig.modalTitle }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-secondary"
            (click)="dismiss()"
            *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
            [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()">
      {{ modalConfig.dismissButtonLabel }}
    </button>
    <button type="button"
            class="btn btn-primary"
            (click)="proceed()"
            *ngIf="modalConfig.hideProcessButton === undefined || !modalConfig.hideProcessButton()"
            [disabled]="modalConfig.disableProcessButton !== undefined && modalConfig.disableProcessButton()">
      {{ modalConfig.processButton }}
    </button>
  </div>
</ng-template>
