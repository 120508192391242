<section class="slice slice-lg pt-0 pb-0 mb-0">
  <div class="container">
    <section class="slice slice-lg pt-3">
      <div class="col-lg-8 col-md-12 col-sm-12">
        <div class="d-flex align-items-center justify-content-between">
          <a class="btn btn-transparent btn-icon pl-0" (click)="openModal(cancelModal)">
            <i class="fas fa-arrow-left"></i> MyClinic
          </a>
          <button type="button" class="btn btn-warning btn-circle btn-sm" (click)="openModal(InformationModal);">
            <i class="fas fa-question" style="color: white"></i>
          </button>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-8">



          <!-- PERSONAL INFO -->
          <div *ngIf="allCards?.length>0" class="card p-4">
            <div class="mt-3 mb-3 pl-4">
              <h3>
                {{
                allCards[activeCardIndex].title[locale]
                }}
              </h3>
            </div>
            <app-custom-form-builder [forCreate]="true" [activeCardIndex]="activeCardIndex"
              [totalCards]="allCards?.length" (onChange)="onSomethingChanged($event)"
              (onChangeCard)="changeCard($event)" (onCancel)="oncancel()" [inprogress]="saving"
              [options]="allCards[activeCardIndex].questions" (onSubmit)="doSubmit($event)"></app-custom-form-builder>
            <!-- <form class="pl-4 pr-4" [formGroup]="personalInformationGroup">

              <div class="row">
                <div class="col-lg-2 col-md-6 col-sm-12 form-group salutation">
                  <label for="input-name" class="font-weight-bold">{{
                    "createPatientForm.personalInformationCard.salutation.title"
                      | translate
                  }}</label>
                  <div ngbDropdown style="margin-top: 0.1rem;" class="md-d-block">
                    <button
                      *ngIf="personalInformationGroup.get('gender').value === null"
                      class="form-control btn btn-xs btn-secondary"
                      style="font-size: 0.85rem;"
                      id="dropdownMenuButton"
                      ngbDropdownToggle
                    >
                      {{ "generalTerms.select" | translate }}
                    </button>
                    <button
                      *ngIf="personalInformationGroup.get('gender').value !== null"
                      class="form-control btn btn-xs btn-secondary"
                      style="font-size: 0.85rem;"
                      id="dropdownMenuButton"
                      ngbDropdownToggle
                    >
                      {{ "createPatientForm.personalInformationCard.salutation." + personalInformationGroup.get('gender').value
                        | translate }}
                    </button>
                    <div ngbDropdownMenu style="min-width: 100%" aria-labelledby="dropdownMenuButton">
                      <button
                        ngbDropdownItem
                        *ngFor="let gender of genderOptions"
                        style="font-size: 0.85rem; text-align: center;"
                        (click)="personalInformationGroup.get('gender').setValue(gender)"
                      >
                        {{ "createPatientForm.personalInformationCard.salutation." + gender
                          | translate }}
                      </button>
                    </div>
                  </div>
                  <app-error-messages
                    *ngIf="checkPersonalInfo"
                    [control]="personalInformationGroup.get('gender')"
                  >
                  </app-error-messages>
                </div>

                
                <div class="col-lg-5 col-md-12 col-sm-12 form-group name-field">
                  <label for="input-name" class="font-weight-bold">{{
                    "createPatientForm.personalInformationCard.firstName"
                      | translate
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    name="firstName"
                    placeholder="John"
                    formControlName="firstName"
                    trim="blur"
                  />
                  <app-error-messages
                    *ngIf="checkPersonalInfo"
                    [control]="personalInformationGroup.get('firstName')"
                  >
                  </app-error-messages>
                </div>

                 
                <div class="col-lg-5 col-md-12 col-sm-12 form-group name-field">
                  <label for="input-lastname" class="font-weight-bold">{{
                    "createPatientForm.personalInformationCard.lastName"
                      | translate
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="input-lastname"
                    name="lastname"
                    placeholder="Doe"
                    formControlName="lastName"
                    trim="blur"
                  />
                  <app-error-messages
                    *ngIf="checkPersonalInfo"
                    [control]="personalInformationGroup.get('lastName')"
                  >
                  </app-error-messages>
                </div>
              </div>


             
              <div class="form-group">
                <label for="input-contact-reason" class="font-weight-bold">{{
                  "createPatientForm.personalInformationCard.contactReason"
                    | translate
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="input-contact-reason"
                  name="contactReason"
                  formControlName="contactReason"
                  trim="blur"
                />
                <app-error-messages
                  *ngIf="checkPersonalInfo"
                  [control]="personalInformationGroup.get('contactReason')"
                >
                </app-error-messages>
              </div>

             
              <div class="form-group">
                <label
                  for="input-reason-explanation"
                  class="font-weight-bold"
                  >{{
                    "createPatientForm.personalInformationCard.reasonExplanation"
                      | translate
                  }}</label
                >
                <textarea
                  type="text"
                  class="form-control"
                  id="input-reason-explanation"
                  name="reasonExplanation"
                  formControlName="reasonExplanation"
                  trim="blur"
                  rows="3"
                ></textarea>
                <app-error-messages
                  *ngIf="checkPersonalInfo"
                  [control]="personalInformationGroup.get('reasonExplanation')"
                >
                </app-error-messages>
              </div>

              <div class="row">
                
                <div class="col-lg-5 col-md-5 col-sm-12 form-group">
                  <label for="input-age" class="font-weight-bold">{{
                    "createPatientForm.personalInformationCard.birthdate"
                      | translate
                  }}</label>
                  <input
                    type="date"
                    class="form-control"
                    id="input-age"
                    name="age"
                    placeholder="50"
                    style="width: 100%"
                    formControlName="age"
                    trim="blur"
                  />
                  <app-error-messages
                    *ngIf="checkPersonalInfo"
                    [control]="personalInformationGroup.get('age')"
                  >
                  </app-error-messages>
                </div>
                
                <div class="col-lg-7 col-md-7 col-sm-12 form-group">
                  <label for="input-email" class="font-weight-bold">{{
                    "createPatientForm.personalInformationCard.emailAddress"
                      | translate
                  }}</label>
                  <input
                    type="email"
                    class="form-control"
                    id="input-email"
                    formControlName="email"
                    trim="blur"
                    placeholder="john@breathment.com"
                  />
                  <div *ngIf="userExists" class="pt-3">
                    <div class="alert alert-danger">{{ 'toastr.userExists' | translate }}</div>
                  </div>
                  <app-error-messages
                    *ngIf="checkPersonalInfo"
                    [control]="personalInformationGroup.get('email')"
                  >
                  </app-error-messages>
                </div>
              </div>

              <div class="row">
                
                <div class="form-group col-lg-5 col-md-7 col-sm-12">
                  <label for="input-phone" class="font-weight-bold">{{
                    "createPatientForm.personalInformationCard.phone" | translate
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="input-phone"
                    formControlName="phone"
                    trim="blur"
                    placeholder="+4912345678900"
                  />
                  <app-error-messages
                    *ngIf="checkPersonalInfo"
                    [control]="personalInformationGroup.get('phone')"
                  >
                  </app-error-messages>
                </div>
                
                <div class="form-group col-lg-3 col-md-5 col-sm-4">
                  <label for="input-height" class="font-weight-bold d-block">{{
                    "createPatientForm.personalInformationCard.height" | translate
                  }}</label>
                  <input
                    type="number"
                    class="form-control w-75 d-inline-block"
                    id="input-height"
                    formControlName="height"
                    trim="blur"
                    placeholder="0"
                  /> cm
                  <app-error-messages
                    *ngIf="checkPersonalInfo"
                    [control]="personalInformationGroup.get('height')"
                  >
                  </app-error-messages>
                </div>
                
                <div class="form-group col-lg-3 col-md-5 col-sm-4">
                  <label for="input-weight" class="font-weight-bold d-block">{{
                    "createPatientForm.personalInformationCard.weight" | translate
                  }}</label>
                  <input
                    type="number"
                    class="form-control w-75 d-inline-block"
                    id="input-weight"
                    formControlName="weight"
                    trim="blur"
                    placeholder="0"
                  /> Kg
                  <app-error-messages
                    *ngIf="checkPersonalInfo"
                    [control]="personalInformationGroup.get('weight')"
                  >
                  </app-error-messages>
                </div>
              </div>

              
              <div class="form-group mt-4">
                <label for="input-smoking" class="font-weight-bold">{{
                  "createPatientForm.personalInformationCard.smoking" | translate
                }}</label>
                <div class="d-flex">
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input position-relative"
                      style="margin-bottom: 0.35rem;"
                      type="radio"
                      id="input-smoking-yes"
                      formControlName="smoking"
                      [value]="true"
                    />
                    <label class="form-check-label ml-2" for="input-smoking-yes">{{
                      "generalTerms.yes" | translate
                    }}</label>
                  </div>
                  <div class="form-check ml-5 d-flex align-items-center">
                    <input
                      class="form-check-input position-relative"
                      style="margin-bottom: 0.35rem;"
                      type="radio"
                      id="input-smoking-no"
                      formControlName="smoking"
                      [value]="false"
                    />
                    <label class="form-check-label ml-2" for="input-smoking-no">{{
                      "generalTerms.no" | translate
                    }}</label>
                  </div>
                </div>
                <app-error-messages
                  *ngIf="checkPersonalInfo"
                  [control]="personalInformationGroup.get('smoking')"
                >
                </app-error-messages>
              </div>

              
              <div class="form-group mt-4">
                <label for="input-status" class="font-weight-bold">{{
                  "createPatientForm.personalInformationCard.status" | translate
                }}:</label>
                <div class="d-flex">
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input position-relative"
                      style="margin-bottom: 0.35rem;"
                      type="radio"
                      id="input-status-employed"
                      formControlName="status"
                      value="employed"
                    />
                    <label class="form-check-label ml-2" for="input-status-employed">{{
                      "createPatientForm.personalInformationCard.employed" | translate
                    }}</label>
                  </div>
                  <div class="form-check ml-5 d-flex align-items-center">
                    <input
                      class="form-check-input position-relative"
                      style="margin-bottom: 0.35rem;"
                      type="radio"
                      id="input-status-unable"
                      formControlName="status"
                      value="unable"
                    />
                    <label class="form-check-label ml-2" for="input-status-unable">{{
                      "createPatientForm.personalInformationCard.unable" | translate
                    }}</label>
                  </div>
                  <div class="form-check ml-5 d-flex align-items-center">
                    <input
                      class="form-check-input position-relative"
                      style="margin-bottom: 0.35rem;"
                      type="radio"
                      id="input-status-retired"
                      formControlName="status"
                      value="retired"
                    />
                    <label class="form-check-label ml-2" for="input-status-retired">{{
                      "createPatientForm.personalInformationCard.retired" | translate
                    }}</label>
                  </div>
                </div>
                <app-error-messages
                  *ngIf="checkPersonalInfo"
                  [control]="personalInformationGroup.get('status')"
                >
                </app-error-messages>
              </div>

              
              <div class="form-group mt-4">
                <label for="input-care" class="font-weight-bold">{{
                  "createPatientForm.personalInformationCard.careDiagnosis" | translate
                }}</label>
                <div class="d-flex">
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input position-relative"
                      style="margin-bottom: 0.35rem;"
                      type="radio"
                      id="input-care-yes"
                      formControlName="careDiagnosis"
                      [value]="true"
                    />
                    <label class="form-check-label ml-2" for="input-care-yes">{{
                      "generalTerms.yes" | translate
                    }}</label>
                  </div>
                  <div class="form-check ml-5 d-flex align-items-center">
                    <input
                      class="form-check-input position-relative"
                      style="margin-bottom: 0.35rem;"
                      type="radio"
                      id="input-care-no"
                      formControlName="careDiagnosis"
                      [value]="false"
                    />
                    <label class="form-check-label ml-2" for="input-care-no">{{
                      "generalTerms.no" | translate
                    }}</label>
                  </div>
                </div>
                <div *ngIf="personalInformationGroup.get('careDiagnosis').value" class="form-group mt-1">
                  <div class="d-flex align-items-center">
                    <label for="input-care-degree" class="font-weight-bold mb-0 mr-3">{{
                      "createPatientForm.personalInformationCard.careDegree" | translate
                    }}:</label>
                    <div class="d-flex">
                      <div class="form-check d-flex align-items-center">
                        <input
                          class="form-check-input position-relative"
                          style="margin-bottom: 0.35rem;"
                          type="radio"
                          id="input-care-degree-1"
                          formControlName="careDiagnosisDeg"
                          [value]="1"
                        />
                        <label class="form-check-label ml-2" for="input-care-degree-1">1</label>
                      </div>
                      <div class="form-check d-flex align-items-center ml-3">
                        <input
                          class="form-check-input position-relative"
                          style="margin-bottom: 0.35rem;"
                          type="radio"
                          id="input-care-degree-2"
                          formControlName="careDiagnosisDeg"
                          [value]="2"
                        />
                        <label class="form-check-label ml-2" for="input-care-degree-2">2</label>
                      </div>
                      <div class="form-check d-flex align-items-center ml-3">
                        <input
                          class="form-check-input position-relative"
                          style="margin-bottom: 0.35rem;"
                          type="radio"
                          id="input-care-degree-3"
                          formControlName="careDiagnosisDeg"
                          [value]="3"
                        />
                        <label class="form-check-label ml-2" for="input-care-degree-3">3</label>
                      </div>
                      <div class="form-check d-flex align-items-center ml-3">
                        <input
                          class="form-check-input position-relative"
                          style="margin-bottom: 0.35rem;"
                          type="radio"
                          id="input-care-degree-4"
                          formControlName="careDiagnosisDeg"
                          [value]="4"
                        />
                        <label class="form-check-label ml-2" for="input-care-degree-4">4</label>
                      </div>
                      <div class="form-check d-flex align-items-center ml-3">
                        <input
                          class="form-check-input position-relative"
                          style="margin-bottom: 0.35rem;"
                          type="radio"
                          id="input-care-degree-5"
                          formControlName="careDiagnosisDeg"
                          [value]="5"
                        />
                        <label class="form-check-label ml-2" for="input-care-degree-5">5</label>
                      </div>
                    </div>
                  </div>
                  <app-error-messages
                  *ngIf="checkPersonalInfo"
                  [control]="personalInformationGroup.get('careDiagnosisDeg')"
                  >
                  </app-error-messages>
                </div>
                <app-error-messages
                  *ngIf="checkPersonalInfo"
                  [control]="personalInformationGroup.get('careDiagnosis')"
                >
                </app-error-messages>
              </div>

            </form> -->
            <!-- <div class="ml-auto mt-4">
              <button
                class="btn btn-sm btn-primary"
                (click)="personalInfoNext()"
              >
                {{ "generalTerms.next" | translate }}
              </button>
            </div> -->
          </div>


          <!-- COPD -->
          <div *ngIf="checkCardExistence('copdCard', 'main-card')" class="card p-4">
            <div class="mt-3 mb-3 pl-4">
              <h3>
                {{
                "createPatientForm.copdCard.title"
                | translate
                }}
              </h3>
            </div>
            <form class="pl-4 pr-4" [formGroup]="copdGroup">

              <!-- Classification -->
              <div class="form-group">
                <label for="input-classification" class="font-weight-bold">{{
                  "createPatientForm.copdCard.classification" | translate
                  }}:</label>
                <div class="d-flex align-items-center">
                  <div class="form-check d-flex align-items-center">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.35rem;" type="radio"
                      id="input-class-1" formControlName="classification" value="gold_1" />
                    <label class="form-check-label ml-2" for="input-class-1">GOLD I</label>
                  </div>
                  <div class="form-check d-flex align-items-center ml-4">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.35rem;" type="radio"
                      id="input-class-2" formControlName="classification" value="gold_2" />
                    <label class="form-check-label ml-2" for="input-class-2">GOLD II</label>
                  </div>
                  <div class="form-check d-flex align-items-center ml-4">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.35rem;" type="radio"
                      id="input-class-3" formControlName="classification" value="gold_3" />
                    <label class="form-check-label ml-2" for="input-class-3">GOLD III</label>
                  </div>
                  <div class="form-check d-flex align-items-center ml-4">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.35rem;" type="radio"
                      id="input-class-4" formControlName="classification" value="gold_4" />
                    <label class="form-check-label ml-2" for="input-class-1">GOLD IV</label>
                  </div>
                </div>
                <app-error-messages *ngIf="checkCopd" [control]="copdGroup.get('classification')">
                </app-error-messages>
              </div>

              <!-- Diagnosis duration -->
              <div class="form-group mt-4">
                <label for="input-duration" class="font-weight-bold d-block">{{
                  "createPatientForm.copdCard.duration" | translate
                  }}</label>
                <div class="d-flex align-items-center">
                  <input type="number" style="width: 4.5rem; height: 2.5rem"
                    class="form-control d-inline-block py-2 px-3" id="input-duration-years"
                    formControlName="durationYears" trim="blur" placeholder="0" min="1"
                    (input)="validateCopdDuration()" />
                  <label for="input-duration-years" class="font-weight-bold ml-1 mb-0">{{
                    "generalTerms.years" | translate
                    }}</label>
                  <input type="number" style="width: 4.5rem; height: 2.5rem" class="form-control ml-4 py-2 px-3"
                    id="input-duration-months" formControlName="durationMonths" trim="blur" placeholder="0" min="1"
                    max="11" (input)="validateCopdDuration()" />
                  <label for="input-duration-months" class="font-weight-bold ml-1 mb-0">{{
                    "generalTerms.months" | translate
                    }}</label>
                </div>
                <app-error-messages *ngIf="checkCopd" [control]="copdDurationError">
                </app-error-messages>
              </div>

              <!-- Current treatment -->
              <div class="form-group mt-4">
                <label class="font-weight-bold pr-2">{{
                  "createPatientForm.copdCard.currentTreatment"
                  | translate
                  }}</label>
                <div class="d-flex">
                  <div class="form-check">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.35rem;" type="radio"
                      name="ongoingTreatment" id="input-ongoing-treatment-yes" formControlName="ongoingTreatment"
                      [value]="true" />
                    <label class="form-check-label ml-2" for="input-ongoing-treatment-yes">{{ "generalTerms.yes" |
                      translate }}</label>
                  </div>
                  <div class="form-check ml-5">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.35rem;" type="radio"
                      name="ongoingTreatment" id="input-ongoing-treatment-no" formControlName="ongoingTreatment"
                      [value]="false" />
                    <label class="form-check-label ml-2" for="input-ongoing-treatment-no">{{ "generalTerms.no" |
                      translate }}</label>
                  </div>
                </div>
                <app-error-messages *ngIf="checkCopd"
                  [control]="copdGroup.get('ongoingTreatment')"></app-error-messages>
              </div>

              <!-- Oxygen requirement -->
              <div class="form-group mt-4">
                <label class="font-weight-bold pr-2">{{
                  "createPatientForm.copdCard.oxygen"
                  | translate
                  }}</label>
                <div class="d-flex">
                  <div class="form-check">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.3rem;" type="radio"
                      name="oxygen" id="input-oxygen-yes" formControlName="oxygen" [value]="true" />
                    <label class="form-check-label ml-2" for="input-oxygen-yes">{{ "generalTerms.yes" | translate
                      }}</label>
                  </div>
                  <div class="form-check ml-5">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.35rem;" type="radio"
                      name="oxygen" id="input-oxygen-no" formControlName="oxygen" [value]="false" />
                    <label class="form-check-label ml-2" for="input-oxygen-no">{{ "generalTerms.no" | translate
                      }}</label>
                  </div>
                </div>
                <app-error-messages *ngIf="checkCopd" [control]="copdGroup.get('oxygen')"></app-error-messages>
              </div>

              <!-- Medical aids -->
              <div class="form-group mt-4">
                <label class="font-weight-bold pr-2 mb-1">{{
                  "createPatientForm.copdCard.medicalAids.title"
                  | translate
                  }}</label>
                <div class="form-check d-flex align-items-center">
                  <input class="form-check-input position-relative" style="margin-bottom: 0.35rem;" type="checkbox"
                    [id]="'input-' + copMedicalAids[0].name" [formControl]="copMedicalAids[0].control"
                    [value]="copMedicalAids[0].name" (change)="handleCopdAidChange($event)" />
                  <label class="form-check-label ml-2" [for]="'input-' + copMedicalAids[0].name">
                    {{ "createPatientForm.copdCard.medicalAids." + copMedicalAids[0].name | translate }}
                  </label>
                </div>
                <div class="form-check d-flex align-items-center">
                  <input class="form-check-input position-relative" style="margin-bottom: 0.35rem;" type="checkbox"
                    [id]="'input-' + copMedicalAids[1].name" [formControl]="copMedicalAids[1].control"
                    [value]="copMedicalAids[1].name" (change)="handleCopdAidChange($event)" />
                  <label class="form-check-label ml-2" [for]="'input-' + copMedicalAids[1].name">
                    {{ "createPatientForm.copdCard.medicalAids." + copMedicalAids[1].name | translate }}
                  </label>
                </div>
                <label class="form-check-label mt-1">
                  {{ "createPatientForm.copdCard.medicalAids.devices" | translate }}:
                </label>
                <div class="d-flex align-items-center">
                  <div class="form-check d-flex align-items-center">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.35rem;" type="checkbox"
                      [id]="'input-' + copMedicalAids[2].name" [formControl]="copMedicalAids[2].control"
                      [value]="copMedicalAids[2].name" (change)="handleCopdAidChange($event)" />
                    <label class="form-check-label ml-2" [for]="'input-' + copMedicalAids[2].name">
                      {{ "createPatientForm.copdCard.medicalAids." + copMedicalAids[2].name | translate }}
                    </label>
                  </div>
                  <div class="form-check d-flex align-items-center ml-3">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.35rem;" type="checkbox"
                      [id]="'input-' + copMedicalAids[3].name" [formControl]="copMedicalAids[3].control"
                      [value]="copMedicalAids[3].name" (change)="handleCopdAidChange($event)" />
                    <label class="form-check-label ml-2" [for]="'input-' + copMedicalAids[3].name">
                      {{ "createPatientForm.copdCard.medicalAids." + copMedicalAids[3].name | translate }}
                    </label>
                  </div>
                  <div class="form-check d-flex align-items-center ml-3">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.35rem;" type="checkbox"
                      [id]="'input-' + copMedicalAids[4].name" [formControl]="copMedicalAids[4].control"
                      [value]="copMedicalAids[4].name" (change)="handleCopdAidChange($event)" />
                    <label class="form-check-label ml-2" [for]="'input-' + copMedicalAids[4].name">
                      {{ "createPatientForm.copdCard.medicalAids." + copMedicalAids[4].name | translate }}
                    </label>
                  </div>
                </div>
              </div>

              <!-- Healthy actions -->
              <div class="form-group mt-4">
                <label class="font-weight-bold pr-2 mb-1">{{
                  "createPatientForm.copdCard.actions.title"
                  | translate
                  }}</label>
                <div>
                  <div *ngFor="let action of copdActions" class="form-check d-flex align-items-center mr-4">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.35rem;" type="checkbox"
                      [id]="'input-' + action.name" [formControl]="action.control" [value]="action.name"
                      (change)="handleCopdActionChange($event)" />
                    <label class="form-check-label ml-2 text-nowrap" [for]="'input-' + action.name">
                      {{ "createPatientForm.copdCard.actions." + action.name | translate }}
                    </label>
                  </div>
                </div>
              </div>

              <!-- Symptoms -->
              <div class="form-group mt-4">
                <label class="font-weight-bold pr-2 mb-1">{{
                  "createPatientForm.copdCard.symptoms.title"
                  | translate
                  }}</label>
                <div class="w-100 d-flex flex-row">
                  <div class="w-50">
                    <div *ngFor="let symptom of copdSymptoms let i = index" class="w-100">
                      <div *ngIf="i % 2 === 0 && i < 16 && symptom.name"
                        class="form-check d-flex align-items-start w-100">
                        <input class="form-check-input position-relative" style="margin-top: 0.38rem;" type="checkbox"
                          [id]="'input-' + symptom.name" [formControl]="symptom.control" [value]="symptom.name"
                          (change)="handleCopdSymptomChange($event)" />
                        <label class="form-check-label ml-2 text-wrap" style="width: 95%"
                          [for]="'input-' + symptom.name">
                          {{ "createPatientForm.copdCard.symptoms." + symptom.name | translate }}
                        </label>
                      </div>
                      <div *ngIf="i % 2 === 0 && i > 15 && symptom.name"
                        class="form-check d-flex align-items-start w-100">
                        <input class="form-check-input position-relative" style="margin-top: 0.38rem;" type="checkbox"
                          [id]="'input-symptom' + i" [formControl]="symptom.control" [value]="symptom.name"
                          (change)="handleCopdSymptomChange($event)" />
                        <label class="form-check-label ml-2 text-wrap" style="width: 95%" [for]="'input-symptom' + i">
                          {{ symptom.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="w-50">
                    <div *ngFor="let symptom of copdSymptoms let i = index" class="w-100">
                      <div *ngIf="i % 2 === 1 && i < 16 && symptom.name"
                        class="form-check d-flex align-items-start w-100">
                        <input class="form-check-input position-relative" style="margin-top: 0.38rem;" type="checkbox"
                          [id]="'input-' + symptom.name" [formControl]="symptom.control" [value]="symptom.name"
                          (change)="handleCopdSymptomChange($event)" />
                        <label class="form-check-label ml-2 text-wrap" style="width: 95%"
                          [for]="'input-' + symptom.name">
                          {{ "createPatientForm.copdCard.symptoms." + symptom.name | translate }}
                        </label>
                      </div>
                      <div *ngIf="i % 2 === 1 && i > 15 && symptom.name"
                        class="form-check d-flex align-items-start w-100">
                        <input class="form-check-input position-relative" style="margin-top: 0.38rem;" type="checkbox"
                          [id]="'input-symptom' + i" [formControl]="symptom.control" [value]="symptom.name"
                          (change)="handleCopdSymptomChange($event)" />
                        <label class="form-check-label ml-2 text-wrap" style="width: 95%" [for]="'input-symptom' + i">
                          {{ symptom.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex mt-3">
                  <input type="text" class="form-control py-2 px-3" style="width: 16rem; height: 2.5rem"
                    id="input-symptom" trim="blur" placeholder="{{ 'generalTerms.other' | translate }}"
                    formControlName="newSymptom" (keyup.enter)="addCopdSymptom()" />
                  <button class="btn btn-sm btn-primary ml-3" (click)="addCopdSymptom()">
                    {{ 'Add Symptom' }}
                  </button>
                </div>
              </div>


            </form>
            <div class="ml-auto mt-4">
              <button class="btn btn-sm btn-primary" (click)="copdNext()">
                {{ "generalTerms.next" | translate }}
              </button>
            </div>
          </div>


          <!-- OTHER -->
          <div *ngIf="checkCardExistence('otherCard', 'main-card')" class="card p-4">
            <div class="mt-3 mb-3 pl-4">
              <h3>
                {{
                "createPatientForm.otherCard.title"
                | translate
                }}
              </h3>
            </div>
            <form class="pl-4 pr-4" [formGroup]="otherGroup">

              <!-- Sensitive veins -->
              <div class="form-group">
                <label class="font-weight-bold pr-2">{{
                  "createPatientForm.otherCard.sensitiveVeins"
                  | translate
                  }}</label>
                <div class="d-flex">
                  <div class="form-check">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.3rem;" type="radio"
                      name="sensitiveVeins" id="input-sensitive-veins-yes" formControlName="sensitiveVeins"
                      [value]="true" />
                    <label class="form-check-label ml-2" for="input-sensitive-veins-yes">{{ "generalTerms.yes" |
                      translate }}</label>
                  </div>
                  <div class="form-check ml-5">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.3rem;" type="radio"
                      name="sensitiveVeins" id="input-sensitive-veins-no" formControlName="sensitiveVeins"
                      [value]="false" />
                    <label class="form-check-label ml-2" for="input-sensitive-veins-no">{{ "generalTerms.no" | translate
                      }}</label>
                  </div>
                </div>
                <app-error-messages *ngIf="checkOther"
                  [control]="otherGroup.get('sensitiveVeins')"></app-error-messages>
              </div>

              <!-- Cardiovascular disease -->
              <div class="form-group">
                <label class="font-weight-bold pr-2">{{
                  "createPatientForm.otherCard.cardiovascularDisease"
                  | translate
                  }}</label>
                <div class="d-flex">
                  <div class="form-check">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.3rem;" type="radio"
                      name="cardiovascularDisease" id="input-cardiovascular-disease-yes"
                      formControlName="cardiovascularDisease" [value]="true" />
                    <label class="form-check-label ml-2" for="input-cardiovascular-disease-yes">{{ "generalTerms.yes" |
                      translate }}</label>
                  </div>
                  <div class="form-check ml-5">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.3rem;" type="radio"
                      name="cardiovascularDisease" id="input-cardiovascular-disease-no"
                      formControlName="cardiovascularDisease" [value]="false" />
                    <label class="form-check-label ml-2" for="input-cardiovascular-disease-no">{{ "generalTerms.no" |
                      translate }}</label>
                  </div>
                </div>
                <app-error-messages *ngIf="checkOther"
                  [control]="otherGroup.get('cardiovascularDisease')"></app-error-messages>
              </div>

              <!-- Chronic disease -->
              <div class="form-group">
                <label class="font-weight-bold pr-2">{{
                  "createPatientForm.otherCard.chronicDisease"
                  | translate
                  }}</label>
                <div class="d-flex">
                  <div class="form-check">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.3rem;" type="radio"
                      name="chronicDisease" id="input-chronic-disease-yes" formControlName="chronicDisease"
                      [value]="true" />
                    <label class="form-check-label ml-2" for="input-chronic-disease-yes">{{ "generalTerms.yes" |
                      translate }}</label>
                  </div>
                  <div class="form-check ml-5">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.3rem;" type="radio"
                      name="chronicDisease" id="input-chronic-disease-no" formControlName="chronicDisease"
                      [value]="false" />
                    <label class="form-check-label ml-2" for="input-chronic-disease-no">{{ "generalTerms.no" | translate
                      }}</label>
                  </div>
                </div>
                <div *ngIf="otherGroup.get('chronicDisease').value" class="form-group d-flex">
                  <input type="text" class="form-control" id="input-chronic-disease-text"
                    name="chronicDiseaseOptionalText" formControlName="chronicDiseaseOptionalText" />
                </div>
                <app-error-messages *ngIf="checkOther"
                  [control]="otherGroup.get('chronicDisease')"></app-error-messages>
              </div>

              <!-- Recent surgery -->
              <div class="form-group">
                <label class="font-weight-bold pr-2">{{
                  "createPatientForm.otherCard.surgery" | translate
                  }}</label>
                <div class="d-flex">
                  <div class="form-check">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.3rem;" type="radio"
                      name="surgery" id="input-surgery-yes" formControlName="surgery" [value]="true" />
                    <label class="form-check-label ml-2" for="input-surgery-yes">{{
                      "generalTerms.yes" | translate
                      }}</label>
                  </div>
                  <div class="form-check ml-5">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.3rem;" type="radio"
                      name="surgery" id="input-surgery-no" formControlName="surgery" [value]="false" />
                    <label class="form-check-label ml-2" for="input-surgery-no">{{
                      "generalTerms.no" | translate
                      }}</label>
                  </div>
                </div>
                <app-error-messages *ngIf="checkOther" [control]="otherGroup.get('surgery')"></app-error-messages>
              </div>

              <!-- Difficult activities -->
              <div class="form-group mt-4">
                <label class="font-weight-bold pr-2 mb-1">{{
                  "createPatientForm.otherCard.activities.title"
                  | translate
                  }}</label>
                <div class="w-100 d-flex flex-row">
                  <div class="w-50">
                    <div *ngFor="let activity of otherActivities let i = index" class="w-100">
                      <div *ngIf="i % 2 === 0" class="form-check d-flex align-items-start w-100">
                        <input class="form-check-input position-relative" style="margin-top: 0.38rem;" type="checkbox"
                          [id]="'input-' + activity.name" [formControl]="activity.control" [value]="activity.name"
                          (change)="handleOtherActivityChange($event)" />
                        <label class="form-check-label ml-2 text-wrap" style="width: 95%"
                          [for]="'input-' + activity.name">
                          {{ "createPatientForm.otherCard.activities." + activity.name | translate }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="w-50">
                    <div *ngFor="let activity of otherActivities let i = index" class="w-100">
                      <div *ngIf="i % 2 === 1" class="form-check d-flex align-items-start w-100">
                        <input class="form-check-input position-relative" style="margin-top: 0.38rem;" type="checkbox"
                          [id]="'input-' + activity.name" [formControl]="activity.control" [value]="activity.name"
                          (change)="handleOtherActivityChange($event)" />
                        <label class="form-check-label ml-2 text-wrap" style="width: 95%"
                          [for]="'input-' + activity.name">
                          {{ "createPatientForm.otherCard.activities." + activity.name | translate }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Medical aids -->
              <div class="form-group mt-4">
                <label class="font-weight-bold pr-2 mb-1">{{
                  "createPatientForm.otherCard.medicalAids.title"
                  | translate
                  }}</label>
                <div class="w-100 d-flex flex-row">
                  <div class="w-50">
                    <div *ngFor="let aid of otherMedicalAids let i = index" class="w-100">
                      <div *ngIf="i % 2 === 0" class="form-check d-flex align-items-start w-100">
                        <input class="form-check-input position-relative" style="margin-top: 0.38rem;" type="checkbox"
                          [id]="'input-' + aid.name" [formControl]="aid.control" [value]="aid.name"
                          (change)="handleOtherAidChange($event)" />
                        <label class="form-check-label ml-2 text-wrap" style="width: 95%" [for]="'input-' + aid.name">
                          {{ "createPatientForm.otherCard.medicalAids." + aid.name | translate }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="w-50">
                    <div *ngFor="let aid of otherMedicalAids let i = index" class="w-100">
                      <div *ngIf="i % 2 === 1" class="form-check d-flex align-items-start w-100">
                        <input class="form-check-input position-relative" style="margin-top: 0.38rem;" type="checkbox"
                          [id]="'input-' + aid.name" [formControl]="aid.control" [value]="aid.name"
                          (change)="handleOtherAidChange($event)" />
                        <label class="form-check-label ml-2 text-wrap" style="width: 95%" [for]="'input-' + aid.name">
                          {{ "createPatientForm.otherCard.medicalAids." + aid.name | translate }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Fluid consumption -->
              <div class="form-group">
                <label class="font-weight-bold pr-2">{{
                  "createPatientForm.otherCard.fluids" | translate
                  }}</label>
                <div class="d-flex">
                  <div class="form-check">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.3rem;" type="radio"
                      name="fluidsRequirement" id="input-fluids-yes" formControlName="fluidsRequirement"
                      [value]="true" />
                    <label class="form-check-label ml-2" for="input-fluids-yes">{{
                      "generalTerms.yes" | translate
                      }}</label>
                  </div>
                  <div class="form-check ml-5">
                    <input class="form-check-input position-relative" style="margin-bottom: 0.3rem;" type="radio"
                      name="fluidsRequirement" id="input-fluids-no" formControlName="fluidsRequirement"
                      [value]="false" />
                    <label class="form-check-label ml-2" for="input-fluids-no">{{
                      "generalTerms.no" | translate
                      }}</label>
                  </div>
                </div>
                <app-error-messages *ngIf="checkOther"
                  [control]="otherGroup.get('fluidsRequirement')"></app-error-messages>
              </div>

              <!-- Personal goal -->
              <div class="form-group">
                <label for="input-personal-goal" class="font-weight-bold">{{
                  "createPatientForm.otherCard.personalGoal"
                  | translate
                  }}</label>
                <textarea type="text" class="form-control" id="personal-goal" name="personalGoal"
                  formControlName="personalGoal" placeholder="{{ 'generalTerms.optional' | translate }}" trim="blur"
                  rows="2"></textarea>
              </div>

            </form>
            <div class="ml-auto mt-4">
              <button class="btn btn-sm btn-primary" (click)="otherNext()">
                {{ "generalTerms.next" | translate }}
              </button>
            </div>
          </div>


        </div>
        <div class="col-lg-4 col-md-4" style="position: relative">
          <div class="col-lg-4 col-md-4" style="position: fixed">
            <div class="mt-5 mb-3 pl-4">
              <h3>{{ "generalTerms.outline" | translate }}</h3>
            </div>
            <ul *ngIf="allCards?.length>0">
              <li *ngFor="let c of allCards;let i=index;" class="h5" [ngClass]="{
                  'text-dark': activeCardIndex==i,
                  'text-muted':  activeCardIndex!=i,
                }">
                <a [ngClass]="{
                    'text-dark': activeCardIndex==i,
                  'text-muted':  activeCardIndex!=i,
                  }" (click)="outlineNavigationClick(i)">
                  {{ 
                  allCards[i]?.title?.[locale] ?? (('generalTerms.card' | translate) + ' ' + i)
                  }}
                  <span *ngIf="i<firstUnFilledCardIndex"><i class="fas fa-check-circle"></i></span>
                </a>
              </li>

              <!-- <li *ngIf="checkCardExistence('personalInformationCard', 'outline')" class="h5" [ngClass]="{
                  'text-dark': isActive('personalInformationCard'),
                  'text-muted': !isActive('personalInformationCard')
                }">
                <a [ngClass]="{
                    'text-dark': isActive('personalInformationCard'),
                    'text-muted': !isActive('personalInformationCard')
                  }" (click)="outlineNavigationClick('personalInformationCard')">
                  {{
                  "createPatientForm.personalInformationCard.personalInformation"
                  | translate
                  }}
                  <span *ngIf="isPersonalCardCompleted()"><i class="fas fa-check-circle"></i></span>
                </a>
              </li> -->
              <!-- <li *ngIf="checkCardExistence('copdCard', 'outline')" class="h5" [ngClass]="{
                  'text-dark': isActive('copdCard'),
                  'text-muted': !isActive('copdCard')
                }">
                <a [ngClass]="{
                    'text-dark': isActive('copdCard'),
                    'text-muted': !isActive('copdCard')
                  }" (click)="outlineNavigationClick('copdCard')">
                  {{
                  "createPatientForm.copdCard.title"
                  | translate
                  }}
                  <span *ngIf="isCopdCardCompleted()"><i class="fas fa-check-circle"></i></span>
                </a>
              </li>
              <li *ngIf="checkCardExistence('otherCard', 'outline')" class="h5" [ngClass]="{
                  'text-dark': isActive('otherCard'),
                  'text-muted': !isActive('otherCard')
                }">
                <a [ngClass]="{
                    'text-dark': isActive('otherCard'),
                    'text-muted': !isActive('otherCard')
                  }" (click)="outlineNavigationClick('otherCard')">
                  {{
                  "createPatientForm.otherCard.title"
                  | translate
                  }}
                  <span *ngIf="isOtherCardCompleted()"><i class="fas fa-check-circle"></i></span>
                </a>
              </li> -->

              <!-- <li
                *ngIf="checkCardExistence('personalStoryCard', 'outline')"
                class="h5"
                [ngClass]="{
                  'text-dark': isActive('personalStoryCard'),
                  'text-muted': !isActive('personalStoryCard')
                }"
              >
                <a
                  [ngClass]="{
                    'text-dark': isActive('personalStoryCard'),
                    'text-muted': !isActive('personalStoryCard')
                  }"
                  (click)="outlineNavigationClick('personalStoryCard')"
                >
                  {{ "createPatientForm.personalStoryCard.title" | translate }}
                  <span *ngIf="isPersonalStoryCardCompleted()"
                    ><i class="fas fa-check-circle"></i
                  ></span>
                </a>
              </li>
              <li
                *ngIf="checkCardExistence('diseaseHistoryCard', 'outline')"
                class="mt-3 h5"
                [ngClass]="{
                  'text-dark': isActive('diseaseHistoryCard'),
                  'text-muted': !isActive('diseaseHistoryCard')
                }"
              >
                <a
                  [ngClass]="{
                    'text-dark': isActive('diseaseHistoryCard'),
                    'text-muted': !isActive('diseaseHistoryCard')
                  }"
                  (click)="outlineNavigationClick('diseaseHistoryCard')"
                  >{{
                    "createPatientForm.diseaseHistoryCard.diseaseHistory"
                      | translate
                  }}
                  <span *ngIf="isDiseaseHistoryCardCompleted()"
                    ><i class="fas fa-check-circle"></i></span
                ></a>
              </li>
              <li
                *ngIf="checkCardExistence('lifeStyleCard', 'outline')"
                class="mt-3 h5"
                [ngClass]="{
                  'text-dark': isActive('lifeStyleCard'),
                  'text-muted': !isActive('lifeStyleCard')
                }"
              >
                <a
                  [ngClass]="{
                    'text-dark': isActive('lifeStyleCard'),
                    'text-muted': !isActive('lifeStyleCard')
                  }"
                  (click)="outlineNavigationClick('lifeStyleCard')"
                  >{{
                    "createPatientForm.lifeStyleCard.lifeStyle" | translate
                  }}
                  <span *ngIf="isLifestyleCardCompleted()"
                    ><i class="fas fa-check-circle"></i></span
                ></a>
              </li>
              <li
                *ngIf="checkCardExistence('medicationsCard', 'outline')"
                class="mt-3 h5"
                [ngClass]="{
                  'text-dark': isActive('medicationsCard'),
                  'text-muted': !isActive('medicationsCard')
                }"
              >
                <a
                  [ngClass]="{
                    'text-dark': isActive('medicationsCard'),
                    'text-muted': !isActive('medicationsCard')
                  }"
                  (click)="outlineNavigationClick('medicationsCard')"
                  >{{
                    "createPatientForm.medicationsCard.medications" | translate
                  }}
                  <span *ngIf="isMedicalCardCompleted()"
                    ><i class="fas fa-check-circle"></i></span
                ></a>
              </li>
              <li
                *ngIf="checkCardExistence('breathingCard', 'outline')"
                class="mt-3 h5"
                [ngClass]="{
                  'text-dark': isActive('breathingCard'),
                  'text-muted': !isActive('breathingCard')
                }"
              >
                <a
                  [ngClass]="{
                    'text-dark': isActive('breathingCard'),
                    'text-muted': !isActive('breathingCard')
                  }"
                  (click)="outlineNavigationClick('breathingCard')"
                >
                  {{ "createPatientForm.breathingCard.breathing" | translate
                  }}<span *ngIf="isBreathingCardCompleted()"
                    ><i class="fas fa-check-circle"></i></span
                ></a>
              </li>
              <li
                *ngIf="checkCardExistence('muscleStatusCard', 'outline')"
                class="mt-3 h5"
                [ngClass]="{
                  'text-dark': isActive('muscleStatusCard'),
                  'text-muted': !isActive('muscleStatusCard')
                }"
              >
                <a
                  [ngClass]="{
                    'text-dark': isActive('muscleStatusCard'),
                    'text-muted': !isActive('muscleStatusCard')
                  }"
                  >{{
                    "createPatientForm.muscleStatusCard.muscleStatus"
                      | translate
                  }}
                  <span *ngIf="isMuscleStatusCardCompleted()"><i class="fas fa-check-circle"></i></span>
                </a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>

<!--Cancel modal-->
<ng-template #cancelModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ "createPatientForm.cancelCreatePatientModal.title" | translate }}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      {{
      "createPatientForm.cancelCreatePatientModal.cancelQuestionText"
      | translate
      }}
    </p>
    <p>
      {{
      "createPatientForm.cancelCreatePatientModal.cancelExplanation1"
      | translate
      }}
      <span class="font-italic text-danger">{{
        "generalTerms.yes" | translate
        }}</span>{{
      "createPatientForm.cancelCreatePatientModal.cancelExplanation2"
      | translate
      }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-danger" (click)="modal.close(); cancelPatientCreation()">
      {{ "generalTerms.yes" | translate }}
    </button>
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
      {{ "generalTerms.no" | translate }}
    </button>
  </div>
</ng-template>

<!-- Create patient confirmation modal -->
<ng-template #createPatientConfirmationModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ "physiotherapist.createPatientConfirmationModal.title" | translate }}
    </h5>
    <!-- <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button> -->
  </div>
  <div class="modal-body">
    <p *ngIf="physiotherapist.medical">
      {{
      "physiotherapist.createPatientConfirmationModal.patientQuestion"
      | translate
      }}
    </p>
    <p *ngIf="!physiotherapist.medical">
      {{
      "physiotherapist.createPatientConfirmationModal.clientQuestion"
      | translate
      }}
    </p>
    <ul>
      <li>
        <a href="https://breathment.com/legal/terms.html" target="_blank">
          {{ "home.bottomNavigation.termsConditions" | translate }}
        </a>
      </li>
      <li>
        <a href="https://breathment.com/legal/privacy-policy/apps.html" target="_blank">
          {{ "home.bottomNavigation.privacyForApp" | translate }}
        </a>
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="onTermsAccepted(); modal.close()">
      {{ "generalTerms.yes" | translate }}
    </button>
    <button type="button" class="btn btn-sm btn-secondary" (click)="onTermsRejected(); modal.close()">
      {{ "generalTerms.no" | translate }}
    </button>
  </div>
</ng-template>
<ng-template #InformationModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title"><img class="mr-2" alt="Image placeholder"
        src="../../../assets/Logo/Breathment Logo_rev_white.jpg" style="height: 50px;" id="navbar-mobilr-logo"></h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">

    <p>{{'tutorial.createPatientPart1' | translate}} </p>
    <p>{{'tutorial.createPatientNote' | translate}} </p>
    <p>{{'tutorial.createPatientPart2' | translate}} </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close();">{{ 'generalTerms.close' | translate
      }}</button>
  </div>
</ng-template>