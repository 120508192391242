import {SurveyQuestionBase} from '@schemas/survey/survey-question-base';

export class YesNoQuestion extends SurveyQuestionBase<string> {
  controlType = 'yes/no';
  options: { value: string }[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options[`options`] || [];
  }
}
