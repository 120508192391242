
<div class=".mt-3" >
  <div class="row text-center justify-content-between">
    <div [ngClass]="{'col-lg-5 p-0': functionality !== 'dashboard', 'w-100 d-flex flex-nowrap justify-content-between align-items-center': functionality === 'dashboard'}">
      <div class="btn-group">
        <button class="btn btn-primary btn-sm" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()" (click)="changeViewClick();">
          {{ 'generalTerms.previous' | translate }}
        </button>
        <button class="btn btn-secondary btn-sm" mwlCalendarToday [(viewDate)]="viewDate" (click)="changeViewClick();">
          {{ 'generalTerms.today' | translate }}
        </button>
        <button class="btn btn-primary btn-sm" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()" (click)="changeViewClick();">
          {{ 'generalTerms.next' | translate }}
        </button>
      </div>
      <div *ngIf="functionality === 'dashboard'" class="btn-group">
        <div class="btn btn-secondary btn-sm" (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week">
          {{ 'generalTerms.week' | translate }}
        </div>
        <div class="btn btn-secondary btn-sm" (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month">
          {{ 'generalTerms.month' | translate }}
        </div>
      </div>
      <div *ngIf="functionality == 'dashboard'">
        <h6 class="m-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):( locale) }}</h6>
      </div>
    </div>
    <div class="col-lg-3 p-0" *ngIf="functionality !== 'dashboard'" >
      <h5>{{ viewDate | calendarDate:(view + 'ViewTitle'):( locale) }}</h5>
    </div>
    <div class="col-lg-3 p-0">
      <div *ngIf="functionality === 'patient-details'" class="btn-group">
        <div class="btn btn-secondary btn-sm" (click)="setView(CalendarView.Week)"
             [class.active]="view === CalendarView.Week">
          {{ 'generalTerms.week' | translate }}
        </div>
        <div class="btn btn-secondary btn-sm" (click)="setView(CalendarView.Month)"
             [class.active]="view === CalendarView.Month">
          {{ 'generalTerms.month' | translate }}
        </div>
      </div>
      <div *ngIf="functionality === 'program-creation'" class="btn-group">
        <div class="btn btn-secondary btn-sm" (click)="openModal(autoSelectionModal)">
          {{ 'createExerciseProgram.autoSelection' | translate }}
        </div>
      </div>
    </div>
  </div>
  <br />
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [locale]="locale"
      [activeDayIsOpen]="activeDayIsOpen"
      [cellTemplate]="functionality === 'patient-details' ? patientDetailsCell
      : functionality === 'appointment-selection' ? appointmentCell
      : functionality === 'dashboard' ? dashboardCell : null"
      [ngClass]="{'appointment-cell': functionality === 'appointment-selection' || functionality === 'dashboard' }"
      (eventClicked)="getExerciseDetails('Clicked', $event.event)"
      (dayClicked)="handleDayClick($event.day)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [eventTemplate]="functionality === 'dashboard' ? appointmentEvent : null"
      [headerTemplate]="functionality === 'patient-details' ? appointmentHeader : null"
      [locale]="locale"
      (eventClicked)="getExerciseDetails('Clicked', $event.event)"
    >
    </mwl-calendar-week-view>
<!--    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events">-->
<!--    </mwl-calendar-day-view>-->
  </div>
</div>


<!--Auto selection modal-->

<ng-template #autoSelectionModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ 'createExerciseProgram.autoSelection' | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'createExerciseProgram.selectDays' | translate }}</p>
    <div class="row mt-5">
      <div class="col-lg col-3">
        <button class="btn btn-xs" [ngClass]="{'btn-primary': isSelected(1),'btn-secondary': !isSelected(1)}" (click)="autoSelectDay(1)">{{ 'generalTerms.mondayAbv' | translate }}</button>
      </div>
      <div class="col-lg col-3">
        <button class="btn btn-xs" [ngClass]="{'btn-primary': isSelected(2), 'btn-secondary': !isSelected(2)}" (click)="autoSelectDay(2)">{{ 'generalTerms.tuesdayAbv' | translate }}</button>
      </div>
      <div class="col-lg col-3">
        <button class="btn btn-xs" [ngClass]="{'btn-primary': isSelected(3),'btn-secondary': !isSelected(3)}" (click)="autoSelectDay(3)">{{ 'generalTerms.wednesdayAbv' | translate }}</button>
      </div>
      <div class="col-lg col-3">
        <button class="btn btn-xs" [ngClass]="{'btn-primary': isSelected(4),'btn-secondary': !isSelected(4)}" (click)="autoSelectDay(4)">{{ 'generalTerms.thursdayAbv' | translate }}</button>
      </div>
      <div class="col-lg col-3 mt-lg-0 mt-3">
        <button class="btn btn-xs" [ngClass]="{'btn-primary': isSelected(5),'btn-secondary': !isSelected(5)}" (click)="autoSelectDay(5)">{{ 'generalTerms.fridayAbv' | translate }}</button>
      </div>
      <div class="col-lg col-3 mt-lg-0 mt-3">
        <button class="btn btn-xs" [ngClass]="{'btn-primary': isSelected(6),'btn-secondary': !isSelected(6)}" (click)="autoSelectDay(6)">{{ 'generalTerms.saturdayAbv' | translate }}</button>
      </div>
      <div class="col-lg col-3 mt-lg-0 mt-3">
        <button class="btn btn-xs" [ngClass]="{'btn-primary': isSelected(0),'btn-secondary': !isSelected(0)}" (click)="autoSelectDay(0)">{{ 'generalTerms.sundayAbv' | translate }}</button>
      </div>
    </div>
    <div class="mt-5">
      <label for="input-duration" class="font-weight-bold pr-2">{{ 'createExerciseProgram.duration' | translate }}</label>
      <select class="form-select btn btn-sm btn-secondary" name="input-duration" id="input-duration" [(ngModel)]="autoSelectionDuration">
        <option *ngFor="let option of autoSelectionOptions" [ngValue]="option">
          {{option}} {{ 'generalTerms.weeks' | translate }}
        </option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" [disabled]="autoSelectionList.length === 0 || !autoSelectionDuration" (click)="autoSelection(); modal.close();">{{ 'generalTerms.save' | translate }}</button>
    <!--    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">Cancel</button>-->
  </div>
</ng-template>

<ng-template #appointmentCell let-day="day" let-locale="locale" style="min-height: 0px">
  <div
    ngbDropdown
    class="cal-cell-top"
    style="min-height: 0px; background-color: #f3f3f3;"
    [ngClass]="{'active-appointment-cell': !day.isPast && !patientAppOnDay(day), 'selected-appointment-cell': (selectedAppointmentDate && selectedAppointmentDate.toDateString() === day.date.toDateString())}"
  >
    <div *ngIf="day.isPast || patientAppOnDay(day); else elseBlock" style="height:100%">
      <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">
        {{ day.badgeTotal }}
      </span>
      <span class="cal-day-number" style="font-size: 1.2rem; margin-top: 10px;">
        {{ day.date | calendarDate:'monthViewDayNumber':locale }}
      </span>
      <div *ngFor="let appointment of patientAppointments">
        <div *ngIf="appointment.date.toDateString() === day.date.toDateString()"
          class="d-flex align-items-center rounded text-white text-xs px-1"
          style="position: absolute; bottom: 0; left: 0; background-color: #4e5a68; max-width: fit-content; margin-left: 5px; margin-bottom: 0.2rem; padding-right: 0.125rem; padding-top: 0.18rem"
        >
          <img alt="Image placeholder" src="../../../../assets/img/svg/icons/appointment.svg"
            style="height: 1rem; margin-bottom: 0.18rem; margin-right: 0.2rem" class="img-fluid">
          <span>{{ appointment.formated_time }}</span>
        </div>
      </div>
    </div>
    <ng-template #elseBlock>
      <div id="active-cell" style="height:100%" ngbDropdownToggle>
        <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">
          {{ day.badgeTotal }}
        </span>
        <span class="cal-day-number" style="font-size: 1.2rem; margin-top: 10px;">
          {{ day.date | calendarDate:'monthViewDayNumber':locale }}
        </span>
        <div
          *ngIf="!selectedAppointmentDate || selectedAppointmentDate.toDateString() !== day.date.toDateString()"
          class="d-flex flex-wrap-reverse ml-1"
          style="position: absolute; bottom: 0; max-width: 80%;"
        >
          <div *ngFor="let appointment of allAppointments" style="line-height: 0.7">
            <div class="cal-event" *ngIf="appointment.date.toDateString() === day.date.toDateString()" [tippy]="appointment.name + ' - ' + appointment.formated_time"></div>
          </div>
        </div>
        <div
          *ngIf="selectedAppointmentDate && selectedAppointmentDate.toDateString() === day.date.toDateString()"
          class="rounded-pill text-white text-xs px-2"
          style="position:absolute; bottom: 0; left: 0; background-color: #384F6B; max-width: fit-content; margin-left: 0.3rem; margin-bottom: 0.2rem"
        >
          {{ selectedAppointmentTime }}
        </div>
      </div>
    </ng-template>
    <div
      *ngIf="day.isToday"
      ngbDropdownMenu
      style="max-width: fit-content; min-width: 0px; max-height: 11.5rem; overflow-y: auto;"
      aria-labelledby="active-cell"
    >
      <button
        ngbDropdownItem
        *ngFor="let time of todayAppointmentTimes; index as i"
        style="max-width: fit-content"
        class="px-4"
        [ngClass]="{'unavailable-option': appointmentExists(day, i)}"
        (click)="selectAppointmentDate(day, i)"
      >
        {{ time }}
      </button>
    </div>
    <div
      *ngIf="!day.isToday"
      ngbDropdownMenu
      style="max-width: fit-content; min-width: 0px; max-height: 11.5rem; overflow-y: auto;"
      aria-labelledby="active-cell"
    >
      <button
        ngbDropdownItem
        *ngFor="let time of appointmentTimes; index as i"
        style="max-width: fit-content"
        class="px-4"
        [ngClass]="{'unavailable-option': appointmentExists(day, i)}"
        (click)="selectAppointmentDate(day, i)"
      >
        {{ time }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #patientDetailsCell let-day="day" let-openDay="openDay" let-locale="locale"
  let-tooltipPlacement="tooltipPlacement" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay"
  let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody"
  let-tooltipDelay="tooltipDelay" let-trackByEventId="trackByEventId" let-validateDrag="validateDrag">
  <div class="app-cell d-flex flex-column flex-grow-1">
    <div class="cal-cell-top" style="position: relative;"
      [attr.aria-label]="{ day: day, locale: locale } | calendarA11y : 'monthCell'">
      <span aria-hidden="true">
        <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
          day.badgeTotal
          }}</span>
        <span class="cal-day-number">{{
          day.date | calendarDate : 'monthViewDayNumber' : locale
          }}</span>
      </span>
      <div *ngFor="let appointment of patientAppointments">
        <div ngbDropdown style="position:absolute; bottom: 0; left: 0;">
          <div *ngIf="appointment.date.toDateString() === day.date.toDateString()"
            class="d-flex align-items-center rounded text-white text-xs px-1"
            style="background-color: #384F6B; max-width: fit-content; margin-left: 10px; margin-bottom: 0.2rem; padding-right: 0.125rem; padding-top: 0.18rem; cursor: pointer;"
            ngbDropdownToggle (click)="$event.stopPropagation()">
            <img alt="Image placeholder" src="../../../../assets/img/svg/icons/appointment.svg"
              style="height: 1rem; margin-bottom: 0.18rem; margin-right: 0.2rem; pointer-events: none;" class="img-fluid">
            <span style="pointer-events: none;">{{ appointment.time }}</span>
          </div>
          <div ngbDropdownMenu style="max-width: fit-content; min-width: 0px">
            <button ngbDropdownItem style="max-width: fit-content" class="px-3"
              (click)="viewAppointment(appointment); $event.stopPropagation()">
              {{ "patientDetails.appointment.view" | translate }}
            </button>
            <button *ngIf="role === 'physiotherapist' && !day.isPast" ngbDropdownItem style="max-width: fit-content" class="px-3"
              (click)="updateAppointment(appointment); $event.stopPropagation()">
              {{ "patientDetails.appointment.update" | translate }}
            </button>
            <button *ngIf="role === 'physiotherapist' && !day.isPast" ngbDropdownItem style="max-width: fit-content"
              class="cancel-option px-3" (click)="cancelAppointment(appointment); $event.stopPropagation()">
              {{ "patientDetails.appointment.cancel" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="role === 'physiotherapist' && !day.isPast && !patientAppOnDay(day)"
        class="add-appointment align-items-center rounded text-white text-xs px-1"
        style="position: absolute; bottom: 0; left: 0; background-color: #abc7ea; max-width: fit-content; margin-left: 10px; margin-bottom: 0.2rem; padding-right: 0.125rem; padding-top: 0.18rem; cursor: pointer;"
        (click)="$event.stopPropagation(); createAppointment()"
      >
        <img alt="Image placeholder" src="../../../../assets/img/svg/icons/appointment.svg"
          style="height: 1rem; margin-bottom: 0.18rem; margin-right: 0.2rem; pointer-events: none;" class="img-fluid">
          <img alt="Image placeholder" src="../../../../assets/img/svg/icons/plus.svg"
          style="height: 1rem; margin-bottom: 0.18rem; margin-right: 0.2rem; pointer-events: none;" class="img-fluid">
      </div>
    </div>
    <div class="cal-events" *ngIf="day.events.length > 0">
      <div class="cal-event" *ngFor="let event of day.events; trackBy: trackByEventId"
        [ngStyle]="{ backgroundColor: event.color?.primary }" [ngClass]="event?.cssClass"
        (mouseenter)="highlightDay.emit({ event: event })" (mouseleave)="unhighlightDay.emit({ event: event })"
        [mwlCalendarTooltip]="
              event.title | calendarEventTitle : 'monthTooltip' : event
            " [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event" [tooltipTemplate]="tooltipTemplate"
        [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay" mwlDraggable
        [class.cal-draggable]="event.draggable" dragActiveClass="cal-drag-active"
        [dropData]="{ event: event, draggedFrom: day }" [dragAxis]="{ x: event.draggable, y: event.draggable }"
        [validateDrag]="validateDrag" [touchStartLongPress]="{ delay: 300, delta: 30 }"
        (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })"
        [attr.aria-hidden]="{} | calendarA11y : 'hideMonthCellEvents'"></div>
    </div>
  </div>
</ng-template>

<ng-template #dashboardCell let-day="day" let-locale="locale" style="min-height: 0px">
  <div class="cal-cell-top" style="min-height: 0px">
    <div style="height:100%; position: relative;">
      <span class="cal-day-number" style="font-size: 1.2rem; margin-top: 10px;">
        {{ day.date | calendarDate:'monthViewDayNumber':locale }}
      </span>
      <div class="d-flex flex-wrap-reverse ml-1" style="position: absolute; bottom: 0; max-width: 80%;">
        <div ngbDropdown *ngFor="let appointment of allAppointments" class="dashboard-appointment-cell" style="line-height: 0.7">
          <div class="cal-event" style="cursor: pointer" *ngIf="appointment.date.toDateString() === day.date.toDateString()"
            [tippy]="appointment.name + ' - ' + appointment.formated_time" ngbDropdownToggle></div>
          <div ngbDropdownMenu style="max-width: fit-content; min-width: 0px; line-height: 1.7;">
            <button ngbDropdownItem style="max-width: fit-content" class="px-3"
              (click)="viewAppointment(appointment); $event.stopPropagation()">
              {{ "patientDetails.appointment.view" | translate }}
            </button>
            <button *ngIf="role === 'physiotherapist'" ngbDropdownItem style="max-width: fit-content" class="px-3"
              (click)="updateAppointment(appointment); $event.stopPropagation()">
              {{ "patientDetails.appointment.update" | translate }}
            </button>
            <button *ngIf="role === 'physiotherapist'" ngbDropdownItem style="max-width: fit-content" class="cancel-option px-3"
              (click)="cancelAppointment(appointment); $event.stopPropagation()">
              {{ "patientDetails.appointment.cancel" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #appointmentEvent let-weekEvent="weekEvent" let-tooltipPlacement="tooltipPlacement"
  let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody"
  let-tooltipDisabled="tooltipDisabled" let-tooltipDelay="tooltipDelay" let-column="column" let-daysInWeek="daysInWeek">
  <div class="cal-event" [ngStyle]="{
          color: weekEvent.event.color?.secondaryText,
          backgroundColor: weekEvent.event.color?.secondary,
          borderColor: weekEvent.event.color?.primary
        }" [mwlCalendarTooltip]="
          !tooltipDisabled
            ? (weekEvent.event.title
              | calendarEventTitle
                : (daysInWeek === 1 ? 'dayTooltip' : 'weekTooltip')
                : weekEvent.tempEvent || weekEvent.event)
            : ''
        " [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="weekEvent.tempEvent || weekEvent.event"
    [tooltipTemplate]="tooltipTemplate" [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay"
    tabindex="0" role="application" [attr.aria-label]="
          { event: weekEvent.tempEvent || weekEvent.event, locale: locale }
            | calendarA11y : 'eventDescription'
        ">
    <div ngbDropdown class="w-100 position-absolute" style="left: 0; top: 0; display: flex; justify-content: center; align-items: center;">
      <div
        class="d-flex align-items-center rounded text-white text-xs px-1"
        style="cursor: pointer; background-color: #384F6B; max-width: fit-content; line-height: 1.7 !important; padding-right: 0.125rem; margin-top: 0.28rem"
        ngbDropdownToggle>
        <img alt="Image placeholder" src="../../../../assets/img/svg/icons/appointment.svg"
          style="height: 1rem; margin-bottom: 0.18rem; margin-right: 0.2rem" class="img-fluid">
        <span>{{ weekEvent.event.time }}</span>
      </div>
      <div ngbDropdownMenu style="max-width: fit-content; min-width: 0px">
        <button ngbDropdownItem style="max-width: fit-content" class="px-3"
          (click)="viewAppointment(weekEvent.event); $event.stopPropagation()">
          {{ "patientDetails.appointment.view" | translate }}
        </button>
        <button *ngIf="role === 'physiotherapist'" ngbDropdownItem style="max-width: fit-content" class="px-3"
          (click)="updateAppointment(weekEvent.event); $event.stopPropagation()">
          {{ "patientDetails.appointment.update" | translate }}
        </button>
        <button *ngIf="role === 'physiotherapist'" ngbDropdownItem style="max-width: fit-content" class="cancel-option px-3"
          (click)="cancelAppointment(weekEvent.event); $event.stopPropagation()">
          {{ "patientDetails.appointment.cancel" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #appointmentHeader let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked"
  let-eventDropped="eventDropped" let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate" let-dragEnter="dragEnter">
  <div class="cal-day-headers" role="row">
    <div class="cal-header" *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate" [class.cal-past]="day.isPast"
      [class.cal-today]="day.isToday" [class.cal-future]="day.isFuture" [class.cal-weekend]="day.isWeekend"
      [ngClass]="day.cssClass" (mwlClick)="dayHeaderClicked.emit({ day: day, sourceEvent: $event })" mwlDroppable
      dragOverClass="cal-drag-over" (drop)="
            eventDropped.emit({
              event: $event.dropData.event,
              newStart: day.date
            })
          " (dragEnter)="dragEnter.emit({ date: day.date })" tabindex="0" role="columnheader">
      <b>{{ day.date | calendarDate : 'weekViewColumnHeader' : locale }}</b><br />
      <span>{{
        day.date | calendarDate : 'weekViewColumnSubHeader' : locale
        }} </span>
      <div *ngFor="let appointment of patientAppointments">
        <div *ngIf="appointment.date.toDateString() === day.date.toDateString()" ngbDropdown class="w-100 position-static"
          style="display: flex; justify-content: center; align-items: center;">
          <div
            class="d-flex align-items-center rounded text-white text-xs px-1"
            style="cursor: pointer; background-color: #384F6B; max-width: fit-content; line-height: 1.7 !important; padding-right: 0.3rem; padding-left: 0.175rem; margin-top: 0.28rem"
            ngbDropdownToggle>
            <img alt="Image placeholder" src="../../../../assets/img/svg/icons/appointment.svg"
              style="height: 1rem; margin-bottom: 0.18rem; margin-right: 0.2rem" class="img-fluid">
            <span style="opacity: 1;">{{ appointment.formated_time }}</span>
          </div>
          <div ngbDropdownMenu style="max-width: fit-content; min-width: 0px">
            <button ngbDropdownItem style="max-width: fit-content" class="px-3"
              (click)="viewAppointment(appointment); $event.stopPropagation()">
              {{ "patientDetails.appointment.view" | translate }}
            </button>
            <button *ngIf="role === 'physiotherapist'" ngbDropdownItem style="max-width: fit-content" class="px-3"
              (click)="updateAppointment(appointment); $event.stopPropagation()">
              {{ "patientDetails.appointment.update" | translate }}
            </button>
            <button *ngIf="role === 'physiotherapist'" ngbDropdownItem style="max-width: fit-content" class="cancel-option px-3"
              (click)="cancelAppointment(appointment); $event.stopPropagation()">
              {{ "patientDetails.appointment.cancel" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

