import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { colors, getDateInString } from '@helpers/helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { ExerciseService } from '@services/exercise/exercise.service';
import { Http2Service } from '@services/http2.service';
import { date2string } from '@services/program-manager';
import { IExercise } from '@services/program-manager/IExercise';
import { ProgramManagerService } from '@services/program-manager/program-manager.service';
import { StoreService } from '@services/store.service';
import { UserService } from '@services/user.service';
import { CalendarEvent } from 'angular-calendar';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { IUserProfile } from 'src/app/resolvers';

@Component({
  selector: 'app-custom-calendar-tab',
  templateUrl: './custom-calendar-tab.component.html',
  styleUrls: ['./custom-calendar-tab.component.css'],
})
export class CustomCalendarTabComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('mainCalendar') mainCalendar: ElementRef;
  @ViewChild('createAppointmentModal') createAppointmentModal: ElementRef;

  role: any;
  me: IUserProfile;
  exerciseResultsLoaded: boolean;
  exerciseResultsError: boolean;
  breathingPlotError: boolean;
  exerciseFromDay: any;
  activeResultTab: number;
  exercise: any;
  exerciseDetailsDate: string;
  titleDate: string;
  patientId: any;
  exerciseResults: string;
  exerciseCategory: string;
  
  breathingPlotData: any;
  aiTrainerDisabled: boolean;
  aiTrainerDisabledReason: any;
  percentDisplayName: any;
  percentValue: any;
  breathingBarChartData: {
    type: string;
    data: { labels: string[]; datasets: { label: string; data: number[] }[] };
    options: {
      scales: {
        yAxes: { scaleLabel: { display: boolean; labelString: string } }[];
        xAxes: { scaleLabel: { display: boolean; labelString: string } }[];
      };
    };
  };
  shoulder: any;
  posture: any;
  inhale: any;
  exhale: any;
  overall: any;
  locale: any;
  public emotion: any[] = [];
  displayedColumns: string[] = ['instruction', 'FER', 'FERsec'];

  sets: number;
  setBreak: number;
  repetitions: number;
  count: number;
  countUnitMeanValue: number;
  public exerciseDetails = {
    inhale_duration: null,
    exhale_duration: null,
    sets: null,
    display: false,
  };
  countDisplayNames: any[];
  countTotal: any[];
  countValues: any[];
  countUnitDisplayNames: any[];
  countUnitValues: any[];
  degreeDisplayName: any[];
  degreeMaxValue: any[];
  degreeMeanValue: any[];
  dataSources: any[];
  problemCountValue: any[];
  problemDisplayName: any[];
  problemTotalValue: any[];
  problemCountValueNotNull: any[];
  problemDisplayNameNotNull: any[];
  problemTotalValueNotNull: any[];
  pieChartLabels: any[];
  pieChartData: any[];
  exercisesForDayEmpty: boolean;
  exercisesForDay: any;
  averageDegree: string;
  maximumDegree: string;
  labelsArray: any[];
  single: any[];
  DegreesArrays: any[];
  patientAppointments: any[] = [];
  allAppointments: any[] = [];
  appointmentDateSelected: boolean;
  selectedAppointmentSlot: any = null;
  appointmentNoteEdited: boolean;
  selectedAppointment: any;
  calendarEvents: CalendarEvent[] = [];
  sub_events_listener: any;
  newAppointmentNote: string;
  public expandedPeriodsObj: any = [
    { id: '0', name: 'patientDetails.morning' },
    { id: '1', name: 'patientDetails.afternoon' },
    { id: '2', name: 'patientDetails.evening' },
  ];
  public expandedPeriods: { [key: string]: boolean } = {
    '0': true,
    '1': true,
    '2': true,
  };
  formattedDate: string;
  loading: boolean = true;
  tempEventsForBackButton: any;
  exercises_list: IExercise[] = []
  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private exerciseService: ExerciseService,
    private storeService: StoreService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private http: Http2Service,
    private pm: ProgramManagerService,
    private toastr: ToastrService,
    private userService: UserService
  ) {
    this.me = this.authService.me;
    this.role = this.me.role;
    this.locale = this.translate.currentLang;
    pm.setLanguage(this.locale);
    storeService.getAllExercises().subscribe(list => {
      this.exercises_list = list
    })
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      pm.setLanguage(this.locale);
    });
  }
  ngAfterViewInit(): void {
    this.route.queryParamMap.subscribe((q) => {
      if (q?.get('action') === 'new-appointment') {
        // setTimeout(()=>{
        this.openCreateAppointmentModal2(this.createAppointmentModal);
        //},1000)
      }
    });
  }
  ngOnDestroy(): void {
    try {
      if (this.sub_events_listener) this.sub_events_listener.unsubscribe();
    } catch (error) { }
  }
  exerciseColor(performed, selectedDateStr, todayDateStr) {
    const selectedDate = new Date(
      selectedDateStr.split('.').reverse().join('-')
    );
    const todayDate = new Date(todayDateStr.split('.').reverse().join('-'));

    if (performed) {
      return { 'background-color': '#DEF1D6' };
    } else {
      if (selectedDate < todayDate) {
        return { 'background-color': '#FFD1DB' };
      } else if (selectedDate >= todayDate) {
        return { 'background-color': '#FFF1C8' };
      }
    }
  }
  handleBackToDay(date, exerciseContent, dayContent) {
    exerciseContent.dismiss('back to day');
    this.getDayDetails(
      { date: date, events: this.tempEventsForBackButton },
      dayContent
    );
  }

  handleExerciseFromDay(exercise, exerciseContent, dayContent) {
    dayContent.dismiss('exercise click');
    this.getExerciseDetails(exercise, exerciseContent, true);
  }

  appointmentSelect(selection) {
    this.appointmentDateSelected = true;
    this.selectedAppointmentSlot = selection;
  }
  private _counter: number = 0;
  IncreaseCounter() {
    this._counter++;
    if (this._counter == 2) {
      this.loading = false;
      this._counter = 0;
    }
  }
  loadTranslations() {
    this.translate
      .get('patientDetails.maximumDegree')
      .subscribe((res: string) => {
        this.maximumDegree = res;
      });
    this.translate
      .get('patientDetails.averageDegree')
      .subscribe((res: string) => {
        this.averageDegree = res;
      });

  }
  ngOnInit(): void {
    this.patientId = this.route.parent?.snapshot.paramMap.get('id');

    this.storeService.getAppointments({}).subscribe((data) => {
      this.allAppointments = data;
      this.IncreaseCounter();
      this.patientAppointments = this.allAppointments.filter(
        (a) => a.patient_id === this.patientId
      );
    });
    this.sub_events_listener = this.pm.all_events$.subscribe((list) => {
      this.calendarEvents = list;
    });
    this.http
      .fetch('get_exercise_program', {
        current_date: getDateInString(new Date()),
        patient_uid: this.patientId,
      })
      .pipe(map((res) => res?.data))
      .subscribe((data) => {
        this.pm.setPrograms(data);
        this.IncreaseCounter();
      });
    this.formattedDate = date2string(new Date());
  }

  openCreateAppointmentModal2(content) {
    this.appointmentDateSelected = false;
    this.selectedAppointmentSlot = null;
    this.modalService.open(content, {
      size: 'lg',
      windowClass: 'bubble-modal',
      centered: true,
    });
  }
  getDotColor(percentage) {
    if (percentage >= 0 && percentage < 40) {
      return '#823335';
    }
    if (percentage >= 40 && percentage < 70) {
      return '#f58602';
    } else {
      return '#2FC19A';
    }
  }
  handleSessionResults(returnData: any) {
    try {
      // if (exercise.title === 'Bauchatmung' || exercise.title === 'Lippenbremse') {
      this.exerciseResultsError = false;
      this.sets = 1;
      this.setBreak = 0;
      this.repetitions = -1;
      this.count = -1;

      if (returnData['data'].NotEnoughData) {

        this.exerciseResultsError = true;
        return;
      }

      if (returnData['data'].sets) {
        this.sets = returnData['data'].sets;
      }
      if (returnData['data'].set_break) {
        this.setBreak = returnData['data'].set_break;
      }
      if (returnData['data'].repetitions) {
        this.repetitions = returnData['data'].repetitions;
      }
      if (returnData['data'].squat) {
        this.count = returnData['data'].squat.total;
      }

      this.emotion = returnData['data']['emotion'];

      if (
        ['breathing', 'relaxation','breathing_time'].includes(
          this.exerciseCategory
        )
      ) {
        const sessionData = returnData['data'];
        if (
          this.exerciseCategory === 'breathing' 
        ) {
          this.inhale = sessionData.breathing_analysis.percz_analy_inhale.value;
          this.exhale = sessionData.breathing_analysis.percz_analy_exhale.value;
          this.overall =
            sessionData.breathing_analysis.percz_analy_combined.value;
        }
        this.posture = null;
        this.shoulder = null;
        if (
          ['breathing', 'breathing_time', 'relaxation'].includes(this.exerciseCategory)
        ) {
          this.posture = sessionData.posture_analysis.percz_analy.value;
          if (this.exerciseCategory == 'breathing') {
            this.shoulder = sessionData.shoulder_analysis?.value ?? 0;
          }
          const angleData =
            sessionData?.posture_analysis?.interval_count?.value ?? [];
          const totalAngleInstances: number = Object.values(angleData).reduce(
            (t: number, n: number) => t + n
          ) as number;
          const angleRanges = Object.keys(angleData).sort((a, b) => {
            if (Number(a.split('-')[0]) < Number(b.split('-')[0])) return -1;
            if (Number(a.split('-')[0]) > Number(b.split('-')[0])) return 1;
            return 0;
          });
          this.breathingBarChartData = {
            type: 'bar',
            data: {
              labels: angleRanges,
              datasets: [
                {
                  label: 'Posture Histogram',
                  data: angleRanges.map((range) =>
                    Math.round((angleData[range] / totalAngleInstances) * 100)
                  ),
                },
              ],
            },
            options: {
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: '%',
                    },
                  },
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Angle Range (°)',
                    },
                  },
                ],
              },
            },
          };
          this.translate
            .get('patientDetails.postureHistogram')
            .subscribe((res: string) => {
              this.breathingBarChartData.data.datasets[0].label = res;
            });
          this.translate
            .get('patientDetails.angleRange')
            .subscribe((res: string) => {
              this.breathingBarChartData.options.scales.xAxes[0].scaleLabel.labelString =
                res;
            });
        }

        if ('confidence_mean' in sessionData) {
          this.countUnitMeanValue =
            sessionData.confidence_mean.substring(1).split(',')[0] * 100;
          this.countUnitMeanValue = Math.round(this.countUnitMeanValue);
        }

        if (
          sessionData.inhale_duration &&
          sessionData.exhale_duration &&
          sessionData.sets
        ) {
          this.exerciseDetails.inhale_duration = sessionData.inhale_duration;
          this.exerciseDetails.exhale_duration = sessionData.exhale_duration;
          this.exerciseDetails.sets = sessionData.sets;
          this.exerciseDetails.display = true;
        } else {
          this.exerciseDetails.display = false;
        }
      }

      if (this.exerciseCategory=='physical') {
        this.aiTrainerDisabled = false;
        this.countDisplayNames = [];
        this.countTotal = [];
        this.countValues = [];
        this.countUnitDisplayNames = [];
        this.countUnitValues = [];
        this.countUnitMeanValue = 0;
        this.degreeDisplayName = [];
        this.degreeMaxValue = [];
        this.degreeMeanValue = [];
        this.dataSources = [];
        this.percentDisplayName = [];
        this.percentValue = [];
        this.problemCountValue = [];
        this.problemDisplayName = [];
        this.problemTotalValue = [];
        this.problemCountValueNotNull = [];
        this.problemDisplayNameNotNull = [];
        this.problemTotalValueNotNull = [];
        this.pieChartLabels = [];
        this.pieChartData = [];
        
        Object.entries(returnData['data']).forEach(([key, value]) => {
          if (key === 'problem') {
            Object.entries(value).forEach(([key2, value2]) => {
              this.problemCountValue.push(value2.value);
              this.problemDisplayName.push(value2.display_name);
              this.problemTotalValue.push(value2.total);
              this.pieChartLabels.push(value2.display_name);
              this.pieChartData.push(value2.value);
              if (value2.value > 0) {
                this.problemCountValueNotNull.push(value2.value);
                this.problemDisplayNameNotNull.push(value2.display_name);
                this.problemTotalValueNotNull.push(value2.total);
              }
            });
          }
        });
        for (let key in returnData['data']) {
          let value = returnData['data'][key];
          if (value.unit === 'count' && value.total) {
            this.countDisplayNames.push(value.display_name);
            this.countTotal.push(value.total);
            this.countValues.push(value.value);
          }
          if (key === 'confidence_mean') {
            this.countUnitMeanValue = value.substring(1).split(',')[0] * 100;
            this.countUnitMeanValue = Math.round(this.countUnitMeanValue);
          }
          if (value.unit === 'count' && !value.total) {
            this.countUnitDisplayNames.push(value.display_name);
            this.countUnitValues.push(value.value);
          }
          if (value.unit === 'degree') {
            this.degreeDisplayName.push(value.display_name);
            this.degreeMaxValue.push(value.max);
            this.degreeMeanValue.push(value.avg);
            this.preprocessData();
            this.evaluatingText();
            this.preprocessData2();
          }
          if (value.unit === 'percent') {
            this.percentDisplayName.push(value.display_name);
            this.percentValue.push(Math.round(value.value));
          }
        }
        if (
          returnData['data'] &&
          returnData['data']['ai_enabled'] !== true &&
          returnData['data']['ai_enabled'] !== 'true'
        ) {
          this.aiTrainerDisabled = true;
          this.aiTrainerDisabledReason =
            returnData['data']['ai_enabled_reason'] ?? 'aiTrainerWasDisabled';
        }
      }

      this.exerciseResultsError = false;
    } catch (error) {

      this.exerciseResultsError = true;
      this.exerciseResultsLoaded = true;
    }
  }

  preprocessData() {
    this.loadTranslations()
    this.single = [];
    this.DegreesArrays = [];
    for (let i = 0; i < this.degreeDisplayName.length; i++) {
      var avgDegree = {
        name:
          this.removeFirstWord(this.degreeDisplayName[i]) + ' Average degree',
        value: this.degreeMeanValue[i],
      };
      var maxDegree = {
        name: this.removeFirstWord(this.degreeDisplayName[i]) + ' Max degree',
        value: this.degreeMaxValue[i],
      };

      this.single.push(avgDegree);
      this.single.push(maxDegree);
      this.DegreesArrays.push(this.single);
      this.single = [];
    }
  }

  removeFirstWord(str) {
    const indexOfSpace = str.indexOf(' ');

    if (indexOfSpace === -1) {
      return '';
    }

    return str.substring(indexOfSpace + 1);
  }

  evaluatingText() {
    this.labelsArray = [];
    for (var i = 0; i < this.degreeMaxValue.length; i++) {
      var averageLabel = 'Average degree: ' + this.degreeMeanValue[i];
      var maxLabel = 'Max degree: ' + this.degreeMaxValue[i];
      var label = averageLabel + ' / ' + maxLabel;
      this.labelsArray.push(label);
    }
  }
  checkIfItIsRight(text) {
    if (text.includes('right')) {
      return true;
    } else {
      return false;
    }
  }
  preprocessData2() {
    this.loadTranslations()
    this.dataSources = [];
    for (let i = 0; i < this.degreeDisplayName.length; i++) {
      var color = [];
      var dial = [];
      if (!this.checkIfItIsRight(this.degreeDisplayName[i])) {
        color = [
          {
            minValue: '-90',
            maxValue: -this.degreeMaxValue[i],
            code: '#384f6b',
          },
          {
            minValue: -this.degreeMaxValue[i],
            maxValue: '0',
            code: '#2FC19A',
          },
          {
            minValue: '0',
            maxValue: '90',
            code: '#384f6b',
          },
        ];
        dial = [
          {
            value: 0 - this.degreeMeanValue[i],
            showValue: '0',
            valueX: '260',
            valueY: '220',
            tooltext: this.averageDegree + ' :' + this.degreeMeanValue[i],
            rearExtension: '15',
          },
        ];
      } else {
        color = [
          {
            minValue: '-90',
            maxValue: '0',
            code: '#384f6b',
          },
          {
            minValue: '0',
            maxValue: this.degreeMaxValue[i],
            code: '#2FC19A',
          },
          {
            minValue: this.degreeMaxValue[i],
            maxValue: '90',
            code: '#384f6b',
          },
        ];
        dial = [
          {
            value: this.degreeMeanValue[i],
            showValue: '0',
            valueX: '260',
            valueY: '220',
            tooltext: this.averageDegree + ' : $value',
            rearExtension: '15',
          },
        ];
      }

      var dataSource = {
        chart: {
          lowerLimit: '-90',
          upperLimit: '90',
          numberSuffix: '\xB0',
          theme: 'fusion',
          majorTMColor: '#384f6b',
        },
        colorRange: {
          color: color,
        },
        annotations: {
          origw: '450',
          origh: '300',
          autoscale: '1',
          showBelow: '0',
          groups: [
            {
              id: 'arcs',
              items: [
                {
                  id: 'national-cs-text',
                  type: 'Text',
                  color: '#000000',
                  label:
                    this.averageDegree +
                    ': ' +
                    Math.round(this.degreeMeanValue[i] * 10) / 10 +
                    '\xB0',
                  fontSize: '16',
                  align: 'left',
                  x: '$chartCenterX + 7',
                  y: '$chartEndY - 35',
                },
                {
                  id: 'state-cs-text',
                  type: 'Text',
                  color: '#2FC19A',
                  label:
                    this.maximumDegree +
                    ': ' +
                    Math.round(this.degreeMaxValue[i] * 10) / 10 +
                    '\xB0',
                  fontSize: '16',
                  align: 'right',
                  x: '$chartCenterX - 7',
                  y: '$chartEndY - 35',
                },
              ],
            },
          ],
        },
        dials: {
          dial: dial,
        },
      };
      this.dataSources.push(dataSource);
    }
  }

  groupExercisesByPeriod(exercises) {
    let exercisesForDayGrouped = {
      0: [],
      1: [],
      2: [],
    };

    for (const exercise of exercises) {
      const period = exercise.meta.period.toString();
      if (!exercisesForDayGrouped[period]) {
        exercisesForDayGrouped[period] = [];
      }
      exercisesForDayGrouped[period].push(exercise);
    }

    for (const period in exercisesForDayGrouped) {
      exercisesForDayGrouped[period].sort((a, b) => {
        if (a.performed && !b.performed) return -1;
        if (!a.performed && b.performed) return 1;
        return 0;
      });
    }

    return exercisesForDayGrouped;
  }
  getDayDetails({ date, events }, content) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const stringDate: string = day + '.' + month + '.' + year;
    this.titleDate = stringDate;

    if (events && events.length > 0) {
      this.exercisesForDayEmpty = false;
      this.exercisesForDay = this.groupExercisesByPeriod(events);
      this.tempEventsForBackButton = events;
    } else {
      this.exercisesForDayEmpty = true;
    }
    this.modalService.open(content, { size: 'lg' });
  }
  navigateTo(path) {
    this.router.navigate([path], { relativeTo: this.route.parent });
  }
  openAppointmentModal(appointment, modal) {
    this.appointmentDateSelected = false;
    this.appointmentNoteEdited = false;
    this.selectedAppointment = {
      ...appointment,
      formated_date: DateTime.fromISO(appointment.appointment_dt).toFormat(
        'yyyy-MM-dd'
      ),
      formated_time: DateTime.fromISO(appointment.appointment_dt).toFormat(
        'HH:mm'
      ),
    };
    this.modalService.open(modal, { size: 'lg' });
  }
  convertToPercentage(number, total) {
    var percentage = (number * 100) / total;
    return this.getDotColor(percentage);
  }
  getExerciseDetails(exercise, content, fromDay?) {
    const exercise_id = exercise.meta.exercise_id
    this.exerciseResultsLoaded = false;
    this.exerciseResultsError = false;
    this.breathingPlotError = false;

    this.exerciseFromDay = fromDay;
    this.activeResultTab = 1;
    this.exercise = exercise;
    const day = exercise.start.getDate();
    const month = exercise.start.getMonth() + 1;
    const year = exercise.start.getFullYear();
    const stringDate: string = day + '.' + month + '.' + year;
    this.exerciseDetailsDate = stringDate;

    this.titleDate = stringDate;

    const requestData = {
      patient_uid: this.patientId,
      exercise_id,
      date: stringDate,
      period: exercise.meta.period,
    };
    const ex = this.exercises_list.find(e => e._id === this.exercise.meta.exercise_id)
    this.exerciseCategory = ex.category
    if (['breathing', 'relaxation', 'breathing_time'].includes(
      this.exerciseCategory
    ) != true) {
      this.exerciseCategory = 'physical'
    }
    if (exercise.meta.performed) {
      this.exerciseResults = 'exists';


      this.exerciseService.getSessionResults(requestData).subscribe(
        (returnData) => {
          this.handleSessionResults(returnData);
        },
        (error) => {
          this.exerciseResultsError = true;
          this.exerciseResultsLoaded = true;
        },
        () => {
          this.exerciseResultsLoaded = true;
        }
      );
      if (
        ['breathing', 'relaxation', 'breathing_time'].includes(
          this.exerciseCategory
        )
      ) {
        this.exerciseService.getBreathingPlot(requestData).subscribe(
          (data) => {
            const obj = JSON.parse(data['data']);
            // obj.timestamp = this.removeBreakGap(obj.timestamp)
            this.breathingPlotData = obj;
            this.breathingPlotError = false;
          },
          (error) => {
            this.breathingPlotData = null;
            this.breathingPlotError = true;
          }
        );
      } else {
        this.breathingPlotData = null;
      }
    } else if (exercise.color.primary === '#823335') {
      this.exerciseResults = 'not-performed';
    } else if (exercise.color.primary === '#e3bc08') {
      if (exercise.start > new Date()) {
        this.exerciseResults = 'future-date';
      } else {
        this.exerciseResults = 'today-not-performed';
      }
    }

    this.modalService.open(content, { size: 'lg' });
  }
  updateAppointment() {
    const dto: any = {
      appointment_id: this.selectedAppointment._id,
      update_data: {
        appointment_dt: this.getISOString(
          this.selectedAppointmentSlot.date,
          this.selectedAppointmentSlot.time
        ),
      },
    };
    if (this.appointmentNoteEdited) {
      dto.update_data.note = this.selectedAppointment.note;
    }
    this.userService.updateAppointment(dto).subscribe({
      next: () => {
        this.userService
          .getAppointments({})
          .pipe(map((res) => res['data']))
          .subscribe((appointments_list) => {
            this.allAppointments = appointments_list
              ? structuredClone(
                appointments_list
                  .map((a) => {
                    return { ...a, date: new Date(a.appointment_dt) };
                  })
                  .map((a) => {
                    return {
                      ...a,
                      time: this.storeService.getLocalTimeString(a.date),
                    };
                  })
                  .sort((a, b) => {
                    if (
                      Number(a.time.slice(0, 2)) > Number(b.time.slice(0, 2))
                    )
                      return 1;
                    return -1;
                  })
              )
              : [];
            this.patientAppointments = this.allAppointments.filter(
              (a) => a.patient_id === this.patientId
            );
          });
        this.translate
          .get('patientDetails.appointment.updateSuccess')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
      },
      error: () => {
        this.translate
          .get('patientDetails.appointment.updateError')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
      },
      complete: () => {
        this.selectedAppointmentSlot = null;
        this.newAppointmentNote = '';
      },
    });
  }
  getISOString(dateInput, timeInput) {
    const dateVals = dateInput.split('-').map((str) => Number(str));
    const timeVals = timeInput.split(':').map((str) => Number(str));

    const date = new Date();
    date.setFullYear(dateVals[0], dateVals[1] - 1, dateVals[2]);
    date.setHours(timeVals[0], timeVals[1]);

    return date.toISOString();
  }
  createAppointment() {
    const dto: any = {
      patient_uid: this.patientId,
      appointment_dt: this.getISOString(
        this.selectedAppointmentSlot.date,
        this.selectedAppointmentSlot.time
      ),
    };
    if (this.newAppointmentNote) {
      dto.note = this.newAppointmentNote;
    }
    this.userService.createAppoinment(dto).subscribe({
      next: (resp) => {
        this.storeService
          .getAppointments({})
          //.pipe(map((res) => res['data']))
          .subscribe((appointments_list) => {
            this.allAppointments = appointments_list
              ? structuredClone(
                appointments_list
                  .map((a) => {
                    return { ...a, date: new Date(a.appointment_dt) };
                  })
                  .map((a) => {
                    return {
                      ...a,
                      time: this.storeService.getLocalTimeString(a.date),
                    };
                  })
                  .sort((a, b) => {
                    if (
                      Number(a.time.slice(0, 2)) > Number(b.time.slice(0, 2))
                    )
                      return 1;
                    return -1;
                  })
              )
              : [];
            this.patientAppointments = this.allAppointments.filter(
              (a) => a.patient_id === this.patientId
            );
          });
        this.translate
          .get('patientDetails.appointment.createSuccess')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
      },
      error: (err) => {
        this.translate
          .get('patientDetails.appointment.createError')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
      },
    });
    this.selectedAppointmentSlot = null;
    this.newAppointmentNote = '';
    this.appointmentDateSelected = false;
  }
  cancelAppointment() {
    this.userService
      .deleteAppointment({ appointment_id: this.selectedAppointment._id })
      .subscribe({
        next: () => {
          this.patientAppointments = this.patientAppointments.filter(
            (a) => a._id !== this.selectedAppointment._id
          );
          this.allAppointments = this.allAppointments.filter(
            (a) => a._id !== this.selectedAppointment._id
          );
          this.translate
            .get('patientDetails.appointment.cancelSuccess')
            .subscribe((res: string) => {
              this.toastr.success(res);
            });
        },
        error: () => {
          this.translate
            .get('patientDetails.appointment.cancelError')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        },
      });
  }

  getNameWithoutPeriod(str: string): string {
    if (!str) {
      return '';
    }
    const parts = str.split(' ')
    parts.splice(parts.length - 1)
    return parts.join(' ')
    // const result = str.replace(/\s*\(.*$/, '');
    // return result;
  }
}
