import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, concatMap, map, toArray } from 'rxjs/operators';
import { environment } from "../../../environments/environment";
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Http2Service } from '@services/http2.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  public baseUrl: string;

  public selectedRate: number = 0.85;

  public selectedVoice: SpeechSynthesisVoice | null;
  public voices: SpeechSynthesisVoice[];

  private defaultVoiceName = 'Google Deutsch';
  private language = 'de-DE';
  gifUrls: string[];

  constructor(
    private http: Http2Service,
    private storage: AngularFireStorage,
    private httpClient:HttpClient
    ) {


    this.baseUrl = environment.baseCpuUrl;
  }

  sendEmail(formData) {
    return this.http.fetch(this.baseUrl + 'send_email', formData).pipe();
  }

  sendPasswordResetEmail(requestData) {
    return this.http.fetch(this.baseUrl + 'send_reset_password_email', requestData).pipe();
  }
  changePassword(requestData){
    return this.http.fetch(this.baseUrl + 'change_password',requestData).pipe();
  }
  resetPassword(requestData){
    return this.http.fetch(this.baseUrl + 'reset_password',requestData).pipe();
  }
  
  getSurveyQuestions(language: string): Observable<any>  {
    if (!language) {
      language = 'de';
    }
    return this.http.fetch(`assets/surveys/${language}/survey.json`,{}).pipe(map(result => result));
  }

  public loadVoices() {
    const voices = speechSynthesis.getVoices();
    if (voices.length === 0) {
      setTimeout(() => this.loadVoices(), 100)
    } else {
      return voices;
    }
  }

  public getVoices(text: string, language: string) {
    let voiceName;
    if (language === 'en') {
      voiceName = 'Google US English';
    } else if (language === 'de') {
      voiceName = 'Google Deutsch';
    }
    if (this.isBrowserSafari()) {

      this.voices = this.loadVoices();
      if (language === 'en') {
        voiceName = 'Victoria';
        this.voices = this.voices.filter(voice => voice.name === voiceName);
      } else if (language === 'de') {
        let voiceLang = 'de-DE';
        this.voices = this.voices.filter(voice => voice.lang === voiceLang);
      }
      this.selectedVoice = ( this.voices[0] || null );
    } else {
      this.voices = (<any>window).speechSynthesis.getVoices();
      if (this.voices.length > 0) {
        this.voices = this.voices.filter(voice => voice.name === voiceName);
      }
    }


    if (!this.voices.length) {

      (<any>window).speechSynthesis.onvoiceschanged = () => {
        this.voices = (<any>window).speechSynthesis.getVoices();

        this.voices = this.voices.filter(voice => voice.name === voiceName);
        this.selectedVoice = ( this.voices[0] || null );

        this.speak(text);
        }

    } else {
      this.selectedVoice = ( this.voices[0] || null );

      this.speak(text);
    }
  }

  public speak(text: string) : void {
    if (!this.selectedVoice || !text) {
      return;
    }
    this.stop();
    this.synthesizeSpeechFromText(this.selectedVoice, this.selectedRate, text);
  }

  public stop() : void {
    if ( (<any>window).speechSynthesis.speaking ) {
      (<any>window).speechSynthesis.cancel();
    }
  }

  private synthesizeSpeechFromText(
    voice: SpeechSynthesisVoice,
    rate: number,
    text: string
  ) : void {

    let utterance = new SpeechSynthesisUtterance( text );
    utterance.voice = this.selectedVoice;
    utterance.rate = rate;
    (<any>window).speechSynthesis.speak(utterance);
  }

  public isBrowserSafari() {
    const chromeAgent = window.navigator.userAgent.indexOf('Chrome') > -1;
    const safariAgent = window.navigator.userAgent.indexOf('Safari') > -1;
    if (!chromeAgent && safariAgent) {
      return true;
    }
    return false;
  }

  public getGif(name: string): Observable<any>  {
    let videoRef;
    videoRef = this.storage.ref(`/small-gifs/${name}`);
    const videoUrl = videoRef.getDownloadURL();

    return videoUrl.pipe(map(result => {
      return result;
    }));
  }

  getAllGifs(): Observable<any[]> {
    const gifsRef = this.storage.ref('design/small-gifs');
    return from(gifsRef.listAll()).pipe(
      concatMap(result => {
        const gifUrlsObservables = result.items.map(item => item.getDownloadURL());
        return from(gifUrlsObservables).pipe(
          toArray()
        );
      })
    );
  }

  getGifByName(name: string): Observable<string | null> {
    const gifRef = this.storage.ref(`design/small-gifs/${name}`);

    return from(gifRef.getDownloadURL()).pipe(
      catchError(() => of(null))
    );
  }

  getInitialSilhouette(exercise: string, state: 'wrong' | 'correct', pose: string): Observable<string | null> {
    const imgRef = this.storage.ref(`design/silhouettes/initial-pose/${exercise}-${state}-${pose}.png`);

    return from(imgRef.getDownloadURL()).pipe(
      catchError(() => of(null))
    );
  }

  getCheckpointSilhouette(name: string): Observable<string | null> {
    const imgRef = this.storage.ref(`design/silhouettes/checkpoints/${name}.png`);

    return from(imgRef.getDownloadURL()).pipe(
      catchError(() => of(null))
    );
  }

  getCheckpointSilhouetteResized(name: string, exerciseName: string): Observable<string | null> {
    const imgRef = this.storage.ref(`design/silhouettes/checkpoints-resized/${exerciseName}/${name}.png`);

    return from(imgRef.getDownloadURL()).pipe(
      catchError(() => of(null))
    );
  }

  getLongTermVideo() {
    const vidRef = this.storage.ref('long-term-videos/0.mp4');

    return from(vidRef.getDownloadURL()).pipe(
      catchError(() => of(null))
    );
  }

  public getAudioInstructions(name: string, language: string, size: number) {
    let audioArray: any[] = [];
    for (let i = 0; i < size; i++) {
      let audioRef;
      audioRef = this.storage.ref(`/exercise-instructions/${name}/${language}/${language}_${i}_${name}.mp3`);
      const audioUrl = audioRef.getDownloadURL();
      audioArray.push(audioUrl.pipe(map(result => result)));
    }
    return forkJoin(audioArray);
  }

  getTextAudio(requestData) {
    return this.http.fetch(this.baseUrl + 'get_audio_warning', requestData);
  }

  getAudioTranslations(requestData) {
    return this.http.fetch(this.baseUrl + 'get_audio_translations', requestData);
  }

  getFaq(): Observable<any>  {
    return this.http.fetch(`assets/faq/faq.json`,{}).pipe(map(result => result));
  }

  unsubscribeFromNotifications(token) {
    return this.httpClient.get(this.baseUrl + `unsubscribe/${token}`,{}).pipe();
  }

  saveTimezone(requestData) {
    return this.http.fetch(this.baseUrl + 'save_timezone', requestData);
  }
}
