import {
  Component,
  ViewChild,
  OnInit,
  Input,
  TemplateRef,
  ElementRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ChartConfiguration,
  ChartData,
  ChartDataSets,
  ChartType,
} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  getDateFromString,
  getDateInString,
  getFirstWeekday,
} from '@helpers/helper';
import {
  CalendarEvent,
  CalendarView,
  CalendarDateFormatter,
} from 'angular-calendar';
import { ToastrService } from 'ngx-toastr';
import { CustomDateFormatter } from '@services/calendar/custom-date-formatter.provider';

// Services
import { UserService } from '@services/user.service';
import { StoreService } from '@services/store.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-performance-overview',
  templateUrl: './performance-overview.component.html',
  styleUrls: ['./performance-overview.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class PerformanceOverviewComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @ViewChild('surveyResultsModal', { read: TemplateRef })
  surveyResultsModal: TemplateRef<any>;
  @ViewChild('survey') survey: ElementRef;
  @ViewChild('overview') overview: ElementRef;
  @ViewChild('activity') activity: ElementRef;
  @ViewChild('surveyCard', { read: TemplateRef }) surveyCard: TemplateRef<any>;
  @ViewChild('perfOverviewCard', { read: TemplateRef })
  perfOverviewCard: TemplateRef<any>;
  @ViewChild('activityCard', { read: TemplateRef })
  activityCard: TemplateRef<any>;
  @Input() startDate: any[];
  @Input() activeTreatmentSummary: any;
  @Input() todayExercises: any;
  @Input() performanceOverview: any;
  @Input() adherence: any;
  @Input() patientId: string;
  @Input() stepGoal: number;
  @Output() viewReady = new EventEmitter<string>();

  public selectedDeviceStepCountWeekly: any = [];
  public addOptionText: boolean = true;
  public dataLoaded: boolean = false;
  public dataLoadedWeekly: boolean = false;
  public dataLoadedSurveys: boolean = false;
  public chosenSurvey: any = {};
  public chosenBeforeSurvey: any = {};
  public chosenAfterSurvey: any = {};
  public surveyAdditionalNotes: boolean[] = [];
  public averageWeeklySteps: number;
  public donePercentage: number;
  public subtitle: string;
  public missedExercises: number;
  public performedExercises: number;
  public problematicExercises: any[] = [];
  public language: string;
  public selectedDevice: 1;
  public devices: any[];
  // Calendar props
  viewDateSurvey: Date = new Date();
  viewDateActivity: Date = new Date();
  public activeSurveyTab: number = 1;
  public weeklySurvey: any = {};
  public activeDayIsOpen: boolean = false;
  public stepGoalCopy: number = 0;
  public stepEdit: boolean = false;
  view: CalendarView;

  viewDateMonthlySurvey: Date = new Date();
  public monthlyView: CalendarView = CalendarView.Month;
  public weeklySurveyResults: any[] = [];
  public monthlySurveyLoaded: boolean = false;
  public monthlySurveyTotal: number = 0;
  public monthlySurveyEmpty: boolean = false;

  public lineChartData: ChartDataSets[] = [];
  public lineChartDataCopy: ChartDataSets[] = [];
  public lineChartLabels: any[] ;
  public lineChartOptions: any = {
    responsive: true,
    aspectRatio: 2,
    legend: {
      display: false
    },
    elements: {
      line: {
        tension: 0
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          color: "transparent",
          // color: "white",

        },
        // display: false,
        ticks: {
          // fontColor: 'transparent',
          fontColor: '#718096',
        },
        scaleLabel: {
          labelString: '',
          display: true,
          fontColor: '#718096',
        }
      }],
      yAxes: [{
        ticks: {
          // fontColor: 'transparent',
          fontColor: '#718096',
        },
        scaleLabel: {
          labelString: '',
          display: true,
          fontColor: '#718096',
        }
        // display: false
      }]
    }
  };
  public lineChartColors: string[] = ['#e6194B', '#f58231', '#ffe119', '#bfef45', '#3cb44b', '#42d4f4', '#4363d8', '#911eb4'];
  public lineChartColorsCopy: string[] = ['#e6194B', '#f58231', '#ffe119', '#bfef45', '#3cb44b', '#42d4f4', '#4363d8', '#911eb4'];

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            offsetGridLines: true,
            color: 'rgba(0, 0, 0, 0)',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            offsetGridLines: true,
            color: 'rgba(0, 0, 0, 0)',
          },
          ticks: {
            max: 100,
            min: 0,
            autoSkip: false,
          },
        },
      ],
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx,
          ctx2 = chartInstance.ctx;
        ctx2.textAlign = 'center';
        ctx2.textBaseline = 'bottom';
        ctx2.font = '14px Nunito Sans, sans-serif';
        ctx2.fillStyle = '#ffffff';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            // var data = dataset.data[index];
            var data = i % 2 ? 'A' : 'B';
            const img = new Image();
            // img.src = 'assets/images/exclamation-mark.svg';
            // ctx.drawImage(img, bar._view.x - 10, bar._view.y - 30);
            ctx2.fillText(data, bar._view.x, bar._yScale.bottom - 5);
          });
        });
      },
    },
    legend: {
      display: false,
    },
  };
  public barChartType: ChartType = 'bar';
  public barChartData: ChartDataSets[] = [];
  public surveyResults: any[] = [];
  public weeklySteps: any[] = [];

  chartColors = [
    {
      backgroundColor: [],
    },
    {
      backgroundColor: [],
    },
  ];

  public barChartLabels: string[] = [];

  public vtData: any = { x: [], y: [] };
  public fvData: any = { x: [], y: [] };
  public processingSpirometry: boolean = false;

  public spirometryData1: any = {
    x: [
      0.0, 0.008, 0.016, 0.024, 0.032, 0.0400000028, 0.0480000041, 0.0560000055,
      0.064, 0.0720000044, 0.0800000057, 0.08800001, 0.09600001, 0.10400001,
      0.112000011, 0.120000012, 0.128, 0.136, 0.144000009, 0.15200001,
      0.160000011, 0.168000013, 0.176000014, 0.184000015, 0.192000017,
      0.200000018, 0.208000019, 0.216000021, 0.224000022, 0.232000023,
      0.240000024, 0.248000026, 0.256, 0.264, 0.271999985, 0.279999971,
      0.287999958, 0.295999944, 0.303999931, 0.311999917, 0.3199999, 0.3279999,
      0.335999876, 0.343999863, 0.351999849, 0.359999835, 0.367999822,
      0.3759998, 0.3839998, 0.391999781, 0.399999768, 0.407999754, 0.41599974,
      0.423999727, 0.4319997, 0.4399997, 0.4479997, 0.455999672, 0.463999659,
      0.471999645, 0.479999632, 0.487999618, 0.4959996, 0.5039996, 0.5119996,
      0.5199996, 0.52799964, 0.535999656, 0.5439997, 0.5519997, 0.5599997,
      0.5679997, 0.575999737, 0.583999753, 0.591999769, 0.5999998, 0.6079998,
      0.6159998, 0.623999834, 0.63199985, 0.639999866, 0.6479999, 0.6559999,
      0.6639999, 0.671999931, 0.679999948, 0.687999964, 0.696, 0.704, 0.712,
      0.72, 0.728000045, 0.736000061, 0.7440001, 0.7520001, 0.7600001,
      0.7680001, 0.776000142, 0.784000158, 0.7920002, 0.8000002, 0.8080002,
      0.8160002, 0.824000239, 0.832000256, 0.8400003, 0.8480003, 0.8560003,
      0.8640003, 0.872000337, 0.880000353, 0.888000369, 0.8960004, 0.9040004,
      0.9120004, 0.920000434, 0.92800045, 0.936000466, 0.9440005, 0.9520005,
      0.9600005, 0.968000531, 0.976000547, 0.984000564, 0.9920006, 1.0000006,
      1.00800061, 1.01600063, 1.02400064, 1.03200066, 1.04000068, 1.04800069,
      1.05600071, 1.06400073, 1.07200074, 1.08000076, 1.08800077, 1.09600079,
      1.10400081, 1.11200082, 1.12000084, 1.12800086, 1.13600087, 1.14400089,
      1.1520009, 1.16000092, 1.16800094, 1.176001, 1.184001, 1.192001, 1.200001,
      1.208001, 1.216001, 1.224001, 1.23200107, 1.24000108, 1.2480011,
      1.25600111, 1.26400113, 1.27200115, 1.28000116, 1.28800118, 1.2960012,
      1.30400121, 1.31200123, 1.32000124, 1.32800126, 1.33600128, 1.34400129,
      1.35200131, 1.36000133, 1.36800134, 1.37600136, 1.38400137, 1.39200139,
      1.40000141, 1.40800142, 1.41600144, 1.42400146, 1.43200147, 1.44000149,
      1.4480015, 1.45600152, 1.46400154, 1.47200155, 1.48000157, 1.48800159,
      1.4960016, 1.50400162, 1.51200163, 1.52000165, 1.52800167, 1.53600168,
      1.5440017, 1.55200171, 1.56000173, 1.56800175, 1.57600176, 1.58400178,
      1.5920018, 1.60000181, 1.60800183, 1.61600184, 1.62400186, 1.63200188,
      1.64000189, 1.64800191, 1.65600193, 1.664002, 1.672002, 1.680002,
      1.688002, 1.696002, 1.704002, 1.712002, 1.720002, 1.72800207, 1.73600209,
      1.7440021, 1.75200212, 1.76000214, 1.76800215, 1.77600217, 1.78400218,
      1.7920022, 1.80000222, 1.80800223, 1.81600225, 1.82400227, 1.83200228,
      1.8400023, 1.84800231, 1.85600233, 1.86400235, 1.87200236, 1.88000238,
      1.8880024, 1.89600241, 1.90400243, 1.91200244, 1.92000246, 1.92800248,
      1.93600249, 1.94400251, 1.95200253, 1.96000254, 1.96800256, 1.97600257,
      1.98400259, 1.99200261, 2.00000262, 2.00800252, 2.01600242, 2.02400231,
      2.03200221, 2.040002, 2.048002, 2.056002, 2.0640018, 2.0720017,
      2.08000159, 2.08800149, 2.09600139, 2.10400128, 2.11200118, 2.120001,
      2.128001, 2.13600087, 2.14400077, 2.15200067, 2.16000056, 2.16800046,
      2.17600036, 2.18400025, 2.19200015, 2.2, 2.208, 2.21599984, 2.22399974,
      2.23199964, 2.23999953, 2.24799943, 2.25599933, 2.26399922, 2.27199912,
      2.279999, 2.287999, 2.29599881, 2.30399871, 2.31199861, 2.3199985,
      2.3279984, 2.3359983, 2.34399819, 2.351998, 2.359998, 2.367998,
      2.37599778, 2.38399768, 2.39199758, 2.39999747, 2.40799737, 2.41599727,
      2.42399716, 2.431997, 2.439997, 2.44799685, 2.45599675, 2.46399665,
      2.47199655, 2.47999644, 2.48799634, 2.49599624, 2.50399613, 2.511996,
      2.519996, 2.52799582, 2.53599572, 2.54399562, 2.55199552, 2.55999541,
      2.56799531, 2.57599521, 2.583995, 2.591995, 2.599995, 2.60799479,
      2.61599469, 2.62399459, 2.63199449, 2.63999438, 2.64799428, 2.65599418,
      2.663994, 2.671994, 2.67999387, 2.68799376, 2.69599366, 2.70399356,
      2.71199346, 2.71999335, 2.72799325, 2.73599315, 2.743993, 2.751993,
      2.75999284, 2.76799273, 2.77599263, 2.78399253, 2.79199243, 2.79999232,
      2.80799222, 2.815992, 2.823992, 2.831992, 2.83999181, 2.8479917,
      2.8559916, 2.8639915, 2.8719914, 2.87999129, 2.88799119, 2.895991,
      2.903991, 2.911991, 2.91999078, 2.92799067, 2.93599057, 2.94399047,
      2.95199037, 2.95999026, 2.96799016, 2.97599, 2.98399, 2.99198985,
      2.99998975, 3.00798965, 3.01598954, 3.02398944, 3.03198934, 3.03998923,
      3.04798913, 3.055989, 3.063989, 3.07198882, 3.07998872, 3.08798862,
      3.09598851, 3.10398841, 3.11198831, 3.1199882, 3.127988, 3.135988,
      3.143988, 3.15198779, 3.15998769, 3.16798759, 3.17598748, 3.18398738,
      3.19198728, 3.19998717, 3.207987, 3.215987, 3.22398686, 3.23198676,
      3.23998666, 3.24798656, 3.25598645, 3.26398635, 3.27198625, 3.27998614,
      3.287986, 3.295986, 3.30398583, 3.31198573, 3.31998563, 3.32798553,
      3.33598542, 3.34398532, 3.35198522, 3.359985, 3.367985, 3.375985,
      3.3839848, 3.3919847, 3.3999846, 3.4079845, 3.41598439, 3.42398429,
      3.43198419, 3.439984, 3.447984, 3.45598388, 3.46398377, 3.47198367,
      3.47998357, 3.48798347, 3.49598336, 3.50398326, 3.51198316, 3.519983,
      3.527983, 3.53598285, 3.54398274, 3.55198264, 3.55998254, 3.56798244,
      3.57598233, 3.58398223, 3.59198213, 3.599982, 3.607982, 3.61598182,
      3.62398171, 3.63198161, 3.63998151, 3.64798141, 3.6559813, 3.6639812,
      3.671981, 3.679981, 3.687981, 3.69598079, 3.70398068, 3.71198058,
      3.71998048, 3.72798038, 3.73598027, 3.74398017, 3.75198, 3.75998,
      3.76797986, 3.77597976, 3.78397965, 3.79197955, 3.79997945, 3.80797935,
      3.81597924, 3.82397914, 3.831979, 3.839979, 3.84797883, 3.85597873,
      3.86397862, 3.87197852, 3.87997842, 3.88797832, 3.89597821, 3.903978,
      3.911978, 3.919978, 3.9279778, 3.9359777, 3.94397759, 3.95197749,
      3.95997739, 3.96797729, 3.97597718, 3.983977, 3.991977, 3.99997687,
      4.007977, 4.015977, 4.023977, 4.0319767, 4.0399766, 4.04797649,
      4.05597639, 4.06397629, 4.071976, 4.079976, 4.087976, 4.095976, 4.103976,
      4.11197567, 4.11997557, 4.12797546, 4.13597536, 4.14397526, 4.151975,
      4.159975, 4.167975, 4.175975, 4.18397474, 4.19197464, 4.19997454,
      4.20797443, 4.21597433, 4.223974, 4.231974, 4.239974, 4.247974, 4.255974,
      4.26397371, 4.27197361, 4.27997351, 4.2879734, 4.2959733, 4.303973,
      4.311973, 4.319973, 4.327973, 4.335973, 4.34397268, 4.35197258,
      4.35997248, 4.36797237, 4.37597227, 4.383972, 4.391972, 4.399972,
      4.407972, 4.41597176, 4.42397165, 4.43197155, 4.43997145, 4.44797134,
      4.45597124, 4.463971, 4.471971, 4.479971, 4.487971, 4.49597073,
      4.50397062, 4.51197052, 4.51997042, 4.52797031, 4.53597, 4.54397, 4.55197,
      4.55997, 4.56797, 4.5759697, 4.58396959, 4.59196949, 4.59996939,
      4.60796928, 4.615969, 4.623969, 4.631969, 4.639969, 4.647969, 4.65596867,
      4.66396856, 4.67196846, 4.67996836, 4.68796825, 4.695968, 4.703968,
      4.711968, 4.719968, 4.72796774, 4.73596764, 4.74396753, 4.75196743,
      4.75996733, 4.767967, 4.775967, 4.783967, 4.791967, 4.799967, 4.80796671,
      4.81596661, 4.8239665, 4.8319664, 4.8399663, 4.847966, 4.855966, 4.863966,
      4.871966, 4.879966, 4.88796568, 4.89596558, 4.90396547, 4.91196537,
      4.91996527, 4.927965, 4.935965, 4.943965, 4.951965, 4.95996475,
      4.96796465, 4.97596455, 4.98396444, 4.99196434, 4.999964, 5.007964,
      5.015964, 5.023964, 5.031964, 5.03996372, 5.04796362, 5.05596352,
      5.06396341, 5.07196331, 5.079963, 5.087963, 5.095963, 5.103963, 5.111963,
      5.11996269, 5.12796259, 5.13596249, 5.14396238, 5.15196228, 5.159962,
      5.167962, 5.175962, 5.183962, 5.191962, 5.19996166, 5.20796156,
      5.21596146, 5.22396135, 5.23196125, 5.239961, 5.247961, 5.255961,
      5.263961, 5.27196074, 5.27996063, 5.28796053, 5.29596043, 5.30396032,
      5.31196, 5.31996, 5.32796, 5.33596, 5.34396, 5.35195971, 5.3599596,
      5.3679595, 5.3759594, 5.38395929, 5.391959, 5.399959, 5.407959, 5.415959,
      5.423959, 5.43195868, 5.43995857, 5.44795847, 5.45595837, 5.46395826,
      5.471958, 5.479958, 5.487958, 5.495958, 5.50395775, 5.51195765,
      5.51995754, 5.52795744, 5.53595734, 5.543957, 5.551957, 5.559957,
      5.567957, 5.575957, 5.58395672, 5.59195662, 5.59995651, 5.60795641,
      5.61595631, 5.623956, 5.631956, 5.639956, 5.647956, 5.655956, 5.66395569,
      5.67195559, 5.67995548, 5.68795538, 5.69595528, 5.703955, 5.711955,
      5.719955, 5.727955, 5.73595476, 5.74395466, 5.75195456, 5.75995445,
      5.76795435, 5.77595425, 5.783954, 5.791954, 5.799954, 5.807954,
      5.81595373, 5.82395363, 5.83195353, 5.83995342, 5.84795332, 5.855953,
      5.863953, 5.871953, 5.879953, 5.887953, 5.8959527, 5.9039526, 5.9119525,
      5.91995239, 5.92795229, 5.935952, 5.943952, 5.951952, 5.959952, 5.967952,
      5.97595167, 5.98395157, 5.99195147, 5.99995136, 6.00795126, 6.015951,
      6.023951, 6.031951, 6.039951, 6.04795074, 6.05595064, 6.06395054,
      6.07195044, 6.07995033, 6.08795, 6.09595, 6.10395, 6.11195, 6.11995,
      6.12794971, 6.13594961, 6.14394951, 6.15194941, 6.1599493, 6.167949,
      6.175949, 6.183949, 6.191949, 6.199949, 6.20794868, 6.21594858,
      6.22394848, 6.23194838, 6.23994827, 6.247948, 6.255948, 6.263948,
      6.271948, 6.27994776, 6.28794765, 6.29594755, 6.30394745, 6.31194735,
      6.31994724, 6.327947, 6.335947, 6.343947, 6.351947, 6.35994673,
      6.36794662, 6.37594652, 6.38394642, 6.39194632, 6.399946, 6.407946,
      6.415946, 6.423946, 6.431946, 6.4399457, 6.44794559, 6.45594549,
      6.46394539, 6.47194529, 6.479945, 6.487945, 6.495945, 6.503945, 6.511945,
      6.51994467, 6.52794456, 6.53594446, 6.54394436, 6.55194426, 6.559944,
      6.567944, 6.575944, 6.583944, 6.59194374, 6.59994364, 6.60794353,
      6.61594343, 6.62394333, 6.631943, 6.639943, 6.647943, 6.655943, 6.663943,
      6.67194271, 6.67994261, 6.6879425, 6.6959424, 6.7039423, 6.711942,
      6.719942, 6.727942, 6.735942, 6.743942, 6.75194168, 6.75994158,
      6.76794147, 6.77594137, 6.78394127, 6.791941, 6.799941, 6.807941,
      6.815941, 6.82394075, 6.83194065, 6.83994055, 6.84794044, 6.85594034,
      6.86394024, 6.87194, 6.87994, 6.88794, 6.89594, 6.90393972, 6.91193962,
      6.91993952, 6.92793941, 6.93593931, 6.943939, 6.951939, 6.959939,
      6.967939, 6.975939, 6.98393869, 6.99193859, 6.99993849, 7.00793839,
      7.01593828, 7.023938, 7.031938, 7.039938, 7.047938, 7.055938, 7.06393766,
      7.07193756, 7.07993746, 7.08793736, 7.09593725, 7.103937, 7.111937,
      7.119937, 7.127937, 7.13593674, 7.14393663, 7.15193653, 7.15993643,
      7.16793633, 7.175936, 7.183936, 7.191936, 7.199936, 7.207936, 7.21593571,
      7.2239356, 7.2319355, 7.2399354, 7.2479353, 7.255935, 7.263935, 7.271935,
      7.279935, 7.287935, 7.29593468, 7.30393457, 7.31193447, 7.31993437,
      7.32793427, 7.335934, 7.343934, 7.351934, 7.359934, 7.36793375,
      7.37593365, 7.38393354, 7.39193344, 7.39993334, 7.407933, 7.415933,
      7.423933, 7.431933, 7.439933, 7.44793272, 7.45593262, 7.46393251,
      7.47193241, 7.47993231, 7.487932, 7.495932, 7.503932, 7.511932, 7.519932,
      7.52793169, 7.53593159, 7.54393148, 7.55193138, 7.55993128, 7.567931,
      7.575931, 7.583931, 7.591931, 7.599931, 7.60793066, 7.61593056,
      7.62393045, 7.63193035, 7.63993025, 7.64793, 7.65593, 7.66393, 7.67193,
      7.67992973, 7.68792963, 7.69592953, 7.70392942, 7.71192932, 7.719929,
      7.727929, 7.735929, 7.743929, 7.751929, 7.7599287, 7.7679286, 7.7759285,
      7.78392839, 7.79192829, 7.799928, 7.807928, 7.815928, 7.823928, 7.831928,
      7.83992767, 7.84792757, 7.85592747, 7.86392736, 7.87192726, 7.879927,
      7.887927, 7.895927, 7.903927, 7.91192675, 7.91992664, 7.92792654,
      7.93592644, 7.94392633, 7.951926, 7.959926, 7.967926, 7.975926, 7.983926,
      7.99192572, 7.99992561, 8.007926, 8.015926, 8.023927, 8.031927,
      8.03992748, 8.047928, 8.055928, 8.063929, 8.071929, 8.079929, 8.08793,
      8.09593, 8.10393, 8.111931, 8.119931, 8.127932, 8.135932, 8.143932,
      8.151933, 8.159933, 8.167933, 8.175934, 8.183934, 8.191935, 8.199935,
      8.207935, 8.215936, 8.223936, 8.231936, 8.239937, 8.247937, 8.255938,
      8.263938, 8.271938, 8.279939, 8.287939, 8.295939, 8.30394, 8.31194,
      8.319941, 8.327941, 8.335941, 8.343942, 8.351942, 8.359942, 8.367943,
      8.375943, 8.383944, 8.391944, 8.399944, 8.407945, 8.415945, 8.423945,
    ],
    y: [
      0.000313050026, 0.00102538185, 0.00195394154, 0.00320101157,
      0.00487214141, 0.007031583, 0.009674985, 0.012725221, 0.0160505362,
      0.0194999166, 0.0229507536, 0.0263560433, 0.0297793578, 0.0333978347,
      0.03746528, 0.0422469042, 0.0479527041, 0.05470381, 0.0625550151,
      0.07158655, 0.0820372254, 0.0944269449, 0.1096272, 0.128830656, 0.1533987,
      0.18463248, 0.223527759, 0.270575374, 0.325664043, 0.388104677,
      0.456759661, 0.530224562, 0.6070303, 0.6858122, 0.7654093, 0.8448907,
      0.9235107, 1.000638, 1.075693, 1.148133, 1.21750009, 1.28351569,
      1.34619033, 1.40587187, 1.46318, 1.51883662, 1.57345867, 1.62739909,
      1.680689, 1.73310411, 1.78433359, 1.834153, 1.88252544, 1.92960656,
      1.97566068, 2.020941, 2.06561232, 2.1097436, 2.153347, 2.196425, 2.238993,
      2.28107047, 2.32264066, 2.36362576, 2.40389776, 2.44331527, 2.48178,
      2.519265, 2.55581737, 2.59153676, 2.626536, 2.66090631, 2.69469857,
      2.72792649, 2.76057887, 2.79262829, 2.82403731, 2.854782, 2.8848753,
      2.91436648, 2.943331, 2.971856, 3.00001526, 3.02785087, 3.05538058,
      3.082613, 3.10956454, 3.13626814, 3.16276574, 3.18908548, 3.215222,
      3.24113178, 3.26675463, 3.29203725, 3.3169508, 3.34149313, 3.365672,
      3.38949037, 3.41293764, 3.435999, 3.4586823, 3.48103786, 3.50315976,
      3.525169, 3.54717422, 3.56923914, 3.591363, 3.61347842, 3.63547826,
      3.657251, 3.67870879, 3.6998086, 3.72055578, 3.74098659, 3.76114345,
      3.78105855, 3.80074668, 3.82020926, 3.83944821, 3.858479, 3.8773315,
      3.89604068, 3.914632, 3.93310666, 3.95143938, 3.96959138, 3.98752427,
      4.00520754, 4.02261734, 4.039731, 4.05652428, 4.07297945, 4.0891,
      4.104925, 4.120534, 4.136027, 4.151495, 4.16698837, 4.1825, 4.19797134,
      4.21332264, 4.228483, 4.243416, 4.25812531, 4.272643, 4.28700638,
      4.30124044, 4.31535244, 4.329337, 4.343189, 4.356912, 4.370522, 4.3840394,
      4.39748, 4.410844, 4.42412, 4.43729734, 4.450376, 4.46337128, 4.476308,
      4.48920774, 4.502078, 4.514904, 4.527653, 4.54028654, 4.55276871,
      4.565073, 4.57718, 4.58907557, 4.600753, 4.612216, 4.62348557, 4.634597,
      4.64559126, 4.65650034, 4.6673317, 4.67806625, 4.688667, 4.699094,
      4.709319, 4.7193327, 4.72913933, 4.73875141, 4.74818134, 4.75744057,
      4.766543, 4.7755084, 4.784361, 4.79312038, 4.80179358, 4.81037426,
      4.81884956, 4.82721043, 4.835462, 4.843625, 4.851728, 4.85979557,
      4.86784029, 4.875862, 4.8838563, 4.891823, 4.89977169, 4.90771675,
      4.915667, 4.92361832, 4.93155146, 4.93944, 4.947261, 4.95500565, 4.962682,
      4.9703064, 4.97789145, 4.985432, 4.99290133, 5.000255, 5.007447, 5.014445,
      5.021249, 5.02789354, 5.03444, 5.040957, 5.04749775, 5.05408525,
      5.06070566, 5.06731939, 5.073879, 5.08034658, 5.086708, 5.092971,
      5.09915447, 5.105281, 5.111366, 5.11741447, 5.12342644, 5.1294, 5.135334,
      5.14122963, 5.147089, 5.15291548, 5.15871239, 5.164484, 5.170235,
      5.17596865, 5.181683, 5.1873703, 5.19301653, 5.19860458, 5.20411539,
      5.209535, 5.214858, 5.220089, 5.22524166, 5.23033571, 5.23538971,
      5.240416, 5.24541664, 5.250382, 5.255295, 5.26013851, 5.26490259,
      5.26958752, 5.274203, 5.278766, 5.28329372, 5.28779554, 5.29227257,
      5.29671764, 5.30112171, 5.30548143, 5.30980635, 5.31411839, 5.318448,
      5.32282257, 5.327253, 5.33172846, 5.33622026, 5.340693, 5.34511662,
      5.34947634, 5.35377264, 5.35801458, 5.36221457, 5.366385, 5.37053442,
      5.37467051, 5.37879848, 5.3829174, 5.38701868, 5.39109, 5.395122,
      5.39911747, 5.403091, 5.407062, 5.411041, 5.415018, 5.41896772, 5.42286,
      5.42667961, 5.430436, 5.434157, 5.437867, 5.44156837, 5.445228, 5.448791,
      5.452204, 5.45544338, 5.45852757, 5.461512, 5.46446466, 5.46743774,
      5.47045755, 5.473527, 5.47664356, 5.47981262, 5.48304939, 5.486365,
      5.48975229, 5.493177, 5.49658, 5.49989843, 5.50308275, 5.50611353,
      5.509003, 5.51178265, 5.51448774, 5.517149, 5.51978874, 5.522422,
      5.52506351, 5.527728, 5.5304265, 5.533164, 5.53593826, 5.53874159,
      5.541561, 5.54437971, 5.54717541, 5.549924, 5.552604, 5.55520344,
      5.55772161, 5.560173, 5.56257963, 5.56496429, 5.567347, 5.569747,
      5.572184, 5.57468557, 5.57728148, 5.57999229, 5.5828166, 5.58572245,
      5.588657, 5.59156, 5.594384, 5.59711, 5.599744, 5.60230827, 5.6048255,
      5.607316, 5.6098, 5.61229753, 5.61483145, 5.61742, 5.62006569, 5.62274742,
      5.625425, 5.62805557, 5.63061857, 5.63312674, 5.63561964, 5.63814354,
      5.64072657, 5.64336538, 5.64602757, 5.648672, 5.651273, 5.65382671,
      5.656351, 5.65886831, 5.661387, 5.663896, 5.666367, 5.66876745, 5.671073,
      5.67327547, 5.675388, 5.67744064, 5.679476, 5.68154, 5.683677, 5.68592,
      5.68827629, 5.69072962, 5.693246, 5.69578552, 5.69831562, 5.70081425,
      5.703268, 5.7056675, 5.70800543, 5.71028042, 5.71250534, 5.714714,
      5.71695948, 5.719298, 5.72176552, 5.724363, 5.727048, 5.72975, 5.732392,
      5.734914, 5.73729038, 5.73952961, 5.74166059, 5.74371767, 5.74572468,
      5.74769, 5.749613, 5.75148964, 5.75332642, 5.75514269, 5.756971, 5.758846,
      5.7607913, 5.76280975, 5.76488, 5.76696348, 5.76901531, 5.77100039,
      5.772903, 5.774731, 5.77650738, 5.778253, 5.77997732, 5.78167343,
      5.783323, 5.784907, 5.78641939, 5.78787374, 5.7893033, 5.7907505,
      5.792255, 5.79384232, 5.79551744, 5.79726362, 5.799051, 5.800845,
      5.802617, 5.80434656, 5.80602932, 5.80767632, 5.80931, 5.81095648,
      5.81263971, 5.814372, 5.816152, 5.8179636, 5.81978035, 5.821575, 5.823328,
      5.82503271, 5.82670069, 5.8283596, 5.83004665, 5.831795, 5.833624,
      5.83553028, 5.83749056, 5.83946562, 5.84141541, 5.843313, 5.845153,
      5.84695, 5.848735, 5.850538, 5.852379, 5.854263, 5.85617733, 5.85809755,
      5.85999441, 5.86184, 5.86361074, 5.865291, 5.866873, 5.86835957, 5.869767,
      5.87112427, 5.87246752, 5.873832, 5.87524176, 5.87670135, 5.878197,
      5.87970448, 5.88120174, 5.882683, 5.88415766, 5.885643, 5.88715,
      5.88866949, 5.89017439, 5.8916297, 5.893012, 5.8943243, 5.8955965,
      5.896874, 5.89819241, 5.89956474, 5.9009757, 5.902393, 5.903785,
      5.90514326, 5.90648127, 5.907828, 5.909209, 5.910632, 5.912081, 5.913518,
      5.91490126, 5.9162035, 5.91742754, 5.918608, 5.919801, 5.921058, 5.922402,
      5.923811, 5.925224, 5.9265666, 5.92778349, 5.928865, 5.92985344,
      5.93082857, 5.93187666, 5.93305874, 5.934392, 5.935852, 5.937387,
      5.93893528, 5.940446, 5.94188833, 5.943256, 5.94456339, 5.94583845,
      5.94710636, 5.948375, 5.9496274, 5.950824, 5.95192337, 5.95290756,
      5.953795, 5.954644, 5.955532, 5.95652533, 5.957656, 5.95891237,
      5.96025324, 5.96163034, 5.96301174, 5.96439028, 5.96577358, 5.96716738,
      5.968563, 5.96993637, 5.971258, 5.97251034, 5.973697, 5.9748435,
      5.97597933, 5.977128, 5.97829962, 5.97949362, 5.98070765, 5.981942,
      5.983196, 5.98445654, 5.985688, 5.98683548, 5.987847, 5.98870564,
      5.98944855, 5.99016047, 5.99094248, 5.991863, 5.99292135, 5.99405146,
      5.99515247, 5.996137, 5.99697638, 5.997712, 5.99843836, 5.999259,
      6.00024557, 6.001412, 6.00271225, 6.00405836, 6.00535059, 6.00650024,
      6.00744152, 6.008139, 6.00858927, 6.008813, 6.00885057, 6.00876474,
      6.008639, 6.00857258, 6.00866747, 6.009001, 6.00960159, 6.01044226,
      6.01144361, 6.012505, 6.01354742, 6.01454, 6.01550627, 6.01649761,
      6.017565, 6.01872873, 6.019971, 6.021253, 6.022546, 6.02384663,
      6.02518034, 6.026576, 6.02804, 6.02953672, 6.03099346, 6.032328,
      6.03348064, 6.034441, 6.03525829, 6.03602362, 6.03683853, 6.03778124,
      6.038881, 6.040118, 6.04144335, 6.04279757, 6.044139, 6.045455,
      6.04675531, 6.04805231, 6.0493474, 6.050626, 6.05185556, 6.05299568,
      6.05400848, 6.054878, 6.055616, 6.05624962, 6.05681849, 6.05735874,
      6.05789375, 6.05843163, 6.05896759, 6.0594945, 6.060012, 6.06052876,
      6.061063, 6.0616293, 6.062235, 6.06287861, 6.063557, 6.06427431, 6.065044,
      6.065885, 6.06681633, 6.06784153, 6.06893873, 6.07006264, 6.0711565,
      6.07215929, 6.0730176, 6.07369947, 6.07420063, 6.07454348, 6.07477331,
      6.074949, 6.07513475, 6.07539558, 6.07578468, 6.07633924, 6.07708,
      6.078008, 6.079105, 6.080325, 6.081602, 6.08285475, 6.0840044, 6.08500147,
      6.08583975, 6.08656025, 6.0872426, 6.08797359, 6.088815, 6.089776,
      6.09080648, 6.09182262, 6.09274149, 6.0935235, 6.094195, 6.094835,
      6.095533, 6.09634542, 6.09726334, 6.098217, 6.09911776, 6.099908,
      6.100588, 6.10121441, 6.10186863, 6.1026063, 6.10342264, 6.10426,
      6.10504532, 6.10573244, 6.10633326, 6.106912, 6.10754776, 6.10828829,
      6.109129, 6.11001635, 6.110879, 6.111667, 6.11237431, 6.113034, 6.1137023,
      6.11443, 6.115242, 6.116132, 6.117068, 6.11801, 6.11892128, 6.11977434,
      6.12055826, 6.121274, 6.12193155, 6.12254953, 6.123149, 6.123747,
      6.12435627, 6.124991, 6.125659, 6.126354, 6.1270566, 6.12773275, 6.12835,
      6.128897, 6.12940025, 6.12991428, 6.130499, 6.13119364, 6.131999,
      6.132875, 6.13376427, 6.134623, 6.13544035, 6.136233, 6.137022, 6.137808,
      6.138555, 6.13920736, 6.13972139, 6.14009142, 6.14035749, 6.140593,
      6.140877, 6.14127, 6.141817, 6.14254427, 6.14346647, 6.14458036, 6.145852,
      6.147207, 6.14852953, 6.149698, 6.15062428, 6.15129042, 6.151754,
      6.1521225, 6.15251255, 6.15300274, 6.15361452, 6.15431643, 6.15504742,
      6.155747, 6.156379, 6.156938, 6.157436, 6.157896, 6.15834045, 6.158782,
      6.15922451, 6.159665, 6.160097, 6.16051626, 6.16092253, 6.161325,
      6.161746, 6.162218, 6.162774, 6.16343927, 6.164217, 6.16507959, 6.16597,
      6.16680956, 6.16752863, 6.16808939, 6.16849947, 6.16881275, 6.16911,
      6.169473, 6.169963, 6.170599, 6.17136049, 6.172199, 6.173055, 6.17387438,
      6.174612, 6.175243, 6.175767, 6.17620945, 6.17661428, 6.17703629,
      6.177529, 6.178125, 6.17882633, 6.179604, 6.18040466, 6.1811657, 6.181829,
      6.1823554, 6.18273544, 6.18299437, 6.183185, 6.18337536, 6.183633,
      6.184011, 6.184536, 6.185195, 6.185939, 6.18669462, 6.187388, 6.187965,
      6.188417, 6.188782, 6.18913555, 6.18956137, 6.1901207, 6.19082737,
      6.19164753, 6.192515, 6.19336367, 6.194158, 6.194907, 6.19565868,
      6.1964674, 6.19735956, 6.198312, 6.199253, 6.200095, 6.20077324,
      6.20127773, 6.201664, 6.20203, 6.20247173, 6.20303631, 6.20371, 6.204431,
      6.205124, 6.2057333, 6.206249, 6.20670557, 6.20715475, 6.20763969,
      6.208181, 6.208779, 6.20942259, 6.210101, 6.210807, 6.211528, 6.212242,
      6.21292162, 6.21353865, 6.21408272, 6.21456861, 6.21503353, 6.215518,
      6.21604347, 6.216599, 6.217151, 6.217668, 6.21814156, 6.21859837,
      6.219091, 6.21967, 6.22035027, 6.22109461, 6.221831, 6.222489, 6.223035,
      6.22349167, 6.22392845, 6.22442, 6.225001, 6.225645, 6.226276, 6.2268095,
      6.227199, 6.22746134, 6.22767353, 6.22793531, 6.22832966, 6.2288847,
      6.22956, 6.230265, 6.230898, 6.23138, 6.23168135, 6.23182344, 6.231868,
      6.23189449, 6.23197269, 6.23214054, 6.23240137, 6.232729, 6.233079,
      6.233407, 6.23368073, 6.23388863, 6.234044, 6.234179, 6.23433447, 6.23455,
      6.23485327, 6.23525333, 6.23573828, 6.236278, 6.23683357, 6.23736858,
      6.237858, 6.23829174, 6.23867559, 6.23902225, 6.23934746, 6.239666,
      6.239994, 6.24035263, 6.24076128, 6.241232, 6.24175835, 6.242312,
      6.242848, 6.24332142, 6.24371, 6.24403048, 6.2443347, 6.24469137,
      6.245158, 6.245757, 6.24647141, 6.247262, 6.248085, 6.248908, 6.24971056,
      6.25047445, 6.25117159, 6.25176334, 6.252214, 6.25251865, 6.252718,
      6.252888, 6.25311327, 6.25344753, 6.25388956, 6.25438643, 6.25485754,
      6.25522947, 6.25546646, 6.25558043, 6.25562763, 6.255687, 6.255837,
      6.25613546, 6.25660944, 6.25724363, 6.257982, 6.25873756, 6.2594223,
      6.25997925, 6.26040459, 6.260751, 6.26109838, 6.261512, 6.26200676,
      6.26254559, 6.26306152, 6.26349831, 6.26384, 6.264118, 6.264393,
      6.2647233, 6.265133, 6.265605, 6.26610136, 6.26658773, 6.26704931,
      6.26749563, 6.267948, 6.26842546, 6.26893139, 6.269458, 6.269992,
      6.27051973, 6.27103472, 6.2715354, 6.272025, 6.272509, 6.27299166,
      6.27347565, 6.27395868, 6.274431, 6.274882, 6.27529573, 6.27565861,
      6.2759614, 6.27620459, 6.276397, 6.276554, 6.27669144, 6.276828, 6.276983,
      6.277172, 6.27739763, 6.27764559, 6.27789, 6.27810049, 6.27825165,
      6.278333, 6.27835369, 6.278336, 6.27830553, 6.278282, 6.278274,
      6.27828074, 6.27829456, 6.278306, 6.27830935, 6.278306, 6.278301,
      6.27829838, 6.27830267, 6.278313, 6.278325, 6.27832747,
    ],
  };

  public spirometryData2: any = {
    x: [
      0.0, 0.00032043457, 0.0008625984, 0.00157499313, 0.0025036335,
      0.003750801, 0.005421877, 0.007581234, 0.0102245808, 0.0132749081,
      0.016600132, 0.020049572, 0.0235004425, 0.0269057751, 0.030328989,
      0.0339474678, 0.03801489, 0.04279661, 0.0485024452, 0.0552535057,
      0.06310463, 0.07213616, 0.0825867653, 0.0949764252, 0.1101768,
      0.129380226, 0.1539483, 0.1851821, 0.224077463, 0.271125078, 0.326213837,
      0.38865447, 0.457309484, 0.530774355, 0.607579947, 0.6863618, 0.765959,
      0.8454404, 0.924060345, 1.00118756, 1.07624269, 1.14868283, 1.21805,
      1.28406572, 1.34674048, 1.4064219, 1.46372986, 1.51938629, 1.57400846,
      1.62794876, 1.68123865, 1.73365355, 1.784883, 1.83470249, 1.88307476,
      1.93015575, 1.97620964, 2.02149, 2.06616116, 2.11029243, 2.15389585,
      2.1969738, 2.239542, 2.28161955, 2.32318974, 2.36417484, 2.404447,
      2.44386482, 2.48232937, 2.51981449, 2.556367, 2.59208632, 2.62708569,
      2.661456, 2.6952486, 2.72847652, 2.761129, 2.793178, 2.82458735, 2.855332,
      2.885425, 2.91491652, 2.943881, 2.972406, 3.000565, 3.028401, 3.05593061,
      3.08316326, 3.110115, 3.136819, 3.16331673, 3.18963671, 3.215773,
      3.241683, 3.26730585, 3.29258871, 3.3175025, 3.34204483, 3.36622381,
      3.3900423, 3.41348934, 3.43655062, 3.45923424, 3.48158979, 3.5037117,
      3.5257206, 3.54772568, 3.56979084, 3.59191465, 3.61403, 3.63602972,
      3.657802, 3.67925978, 3.70035982, 3.721107, 3.74153757, 3.76169443,
      3.78160954, 3.80129766, 3.82076025, 3.8399992, 3.85903025, 3.87788248,
      3.89659166, 3.915183, 3.93365765, 3.9519906, 3.97014284, 3.98807573,
      4.00575924, 4.023169, 4.04028273, 4.057076, 4.073531, 4.08965158,
      4.105477, 4.12108564, 4.13657856, 4.15204668, 4.16754, 4.18305159,
      4.198523, 4.21387434, 4.229035, 4.24396753, 4.258677, 4.273195, 4.287558,
      4.301792, 4.315904, 4.329889, 4.34374046, 4.357464, 4.37107372, 4.384591,
      4.39803171, 4.41139555, 4.42467165, 4.437849, 4.45092773, 4.463923,
      4.47685957, 4.48975945, 4.50262976, 4.51545572, 4.528205, 4.54083824,
      4.55332041, 4.56562471, 4.57773161, 4.58962727, 4.60130453, 4.6127677,
      4.62403727, 4.63514853, 4.646143, 4.657052, 4.6678834, 4.678618,
      4.68921852, 4.69964552, 4.709871, 4.7198844, 4.729691, 4.739303, 4.748733,
      4.75799227, 4.76709461, 4.77606, 4.78491259, 4.793672, 4.80234528,
      4.810926, 4.81940126, 4.827762, 4.836014, 4.844177, 4.85227966,
      4.86034727, 4.868392, 4.876414, 4.884408, 4.89237452, 4.90032339,
      4.90826845, 4.91621876, 4.92417, 4.932103, 4.93999147, 4.94781256,
      4.95555735, 4.96323347, 4.970858, 4.978443, 4.985984, 4.993453, 5.000807,
      5.00799847, 5.01499653, 5.02180052, 5.02844524, 5.03499174, 5.04150867,
      5.04804945, 5.054637, 5.06125736, 5.067871, 5.07443047, 5.08089828,
      5.08726, 5.09352255, 5.099706, 5.10583258, 5.1119175, 5.117966, 5.123978,
      5.12995148, 5.13588572, 5.14178133, 5.14764071, 5.153467, 5.159264,
      5.16503572, 5.170787, 5.17652035, 5.182235, 5.187922, 5.193568,
      5.19915628, 5.204667, 5.210087, 5.21540976, 5.22064066, 5.22579336,
      5.23088741, 5.23594141, 5.24096775, 5.24596834, 5.25093365, 5.2558465,
      5.26069, 5.26545429, 5.270139, 5.27475452, 5.279318, 5.28384542,
      5.28834724, 5.29282427, 5.29726934, 5.30167341, 5.306033, 5.310358,
      5.31467, 5.319, 5.32337427, 5.32780457, 5.33228, 5.336772, 5.3412447,
      5.34566832, 5.350028, 5.35432434, 5.35856628, 5.36276627, 5.36693668,
      5.371086, 5.375222, 5.37935, 5.383469, 5.38757038, 5.39164162, 5.39567375,
      5.399669, 5.40364265, 5.40761375, 5.41159248, 5.41557, 5.41951942,
      5.423412, 5.42723131, 5.430988, 5.4347086, 5.438419, 5.44212, 5.44578,
      5.44934273, 5.452756, 5.455995, 5.45907927, 5.462064, 5.46501637,
      5.46798944, 5.47100925, 5.47407866, 5.47719526, 5.48036432, 5.483601,
      5.48691654, 5.490304, 5.49372864, 5.497132, 5.50045, 5.50363445, 5.506665,
      5.509555, 5.512335, 5.51503944, 5.5177, 5.52034, 5.522973, 5.52561474,
      5.5282793, 5.530978, 5.53371525, 5.53648949, 5.53929234, 5.5421114,
      5.54493046, 5.54772568, 5.550474, 5.553154, 5.55575275, 5.55827045,
      5.56072235, 5.56312943, 5.56551361, 5.567897, 5.57029629, 5.572734,
      5.57523537, 5.57783127, 5.58054256, 5.58336639, 5.58627224, 5.5892067,
      5.59210968, 5.59493351, 5.597659, 5.600293, 5.60285759, 5.60537434,
      5.60786533, 5.61034966, 5.61284733, 5.61538124, 5.61796951, 5.620615,
      5.62329674, 5.6259737, 5.628604, 5.63116741, 5.63367558, 5.63616848,
      5.638692, 5.64127541, 5.643914, 5.646576, 5.64922047, 5.651821, 5.654375,
      5.65689945, 5.659416, 5.661935, 5.664444, 5.666915, 5.66931534,
      5.67162037, 5.67382336, 5.67593575, 5.677988, 5.680023, 5.682087,
      5.684224, 5.686467, 5.6888237, 5.6912775, 5.6937933, 5.696333, 5.698863,
      5.70136166, 5.70381546, 5.706215, 5.70855331, 5.710829, 5.7130537,
      5.71526241, 5.71750832, 5.71984673, 5.722315, 5.72491264, 5.727598,
      5.7303, 5.73294163, 5.735463, 5.7378397, 5.740079, 5.74221039, 5.74426746,
      5.746274, 5.74823952, 5.750162, 5.752039, 5.75387573, 5.75569153,
      5.75751972, 5.75939465, 5.76134, 5.763359, 5.7654295, 5.76751328,
      5.76956558, 5.77155, 5.77345276, 5.775281, 5.77705669, 5.778802, 5.780526,
      5.782222, 5.78387165, 5.7854557, 5.786968, 5.78842258, 5.789852, 5.7913,
      5.79280472, 5.79439163, 5.79606628, 5.79781246, 5.79959965, 5.80139446,
      5.80316639, 5.8048954, 5.80657768, 5.80822468, 5.80985832, 5.81150532,
      5.81318855, 5.81492138, 5.816701, 5.818512, 5.82032871, 5.82212353,
      5.82387638, 5.8255806, 5.82724857, 5.828908, 5.830595, 5.832343,
      5.83417225, 5.83607864, 5.83803844, 5.8400135, 5.841963, 5.84386063,
      5.84570026, 5.847497, 5.84928131, 5.85108471, 5.85292625, 5.85480976,
      5.85672474, 5.85864544, 5.8605423, 5.86238766, 5.86415863, 5.865839,
      5.867421, 5.868908, 5.87031555, 5.87167263, 5.87301636, 5.874381,
      5.8757906, 5.87724972, 5.878745, 5.880252, 5.881749, 5.88323, 5.88470459,
      5.88619041, 5.887697, 5.88921738, 5.89072227, 5.89217758, 5.89356041,
      5.89487267, 5.896145, 5.897422, 5.89874, 5.900112, 5.90152359, 5.90294075,
      5.904333, 5.905691, 5.907029, 5.90837574, 5.90975666, 5.91117954,
      5.912628, 5.91406536, 5.915448, 5.916751, 5.91797543, 5.919156, 5.920349,
      5.921606, 5.92295, 5.92435837, 5.92577171, 5.92711449, 5.92833138,
      5.929413, 5.930401, 5.9313755, 5.93242359, 5.933606, 5.93493938,
      5.93639946, 5.937935, 5.93948364, 5.94099426, 5.942436, 5.943804,
      5.94511127, 5.94638634, 5.94765472, 5.948924, 5.95017624, 5.951372,
      5.95247173, 5.953456, 5.954344, 5.95519257, 5.95608044, 5.957074,
      5.95820427, 5.959461, 5.960802, 5.962179, 5.963561, 5.964939, 5.966322,
      5.967716, 5.9691124, 5.97048569, 5.97180748, 5.97305965, 5.974247,
      5.9753933, 5.976529, 5.97767735, 5.97884846, 5.98004246, 5.98125648,
      5.98249054, 5.98374462, 5.98500538, 5.98623657, 5.987384, 5.98839569,
      5.989254, 5.989997, 5.9907093, 5.99149132, 5.99241161, 5.99347, 5.9946003,
      5.995701, 5.996686, 5.997525, 5.9982605, 5.99898624, 5.99980736,
      6.00079441, 6.00196075, 6.00326061, 6.00460625, 6.00589848, 6.00704861,
      6.00799, 6.008688, 6.009138, 6.00936127, 6.00939941, 6.00931358,
      6.0091877, 6.009121, 6.00921631, 6.009549, 6.01015, 6.01099, 6.0119915,
      6.013053, 6.01409531, 6.015088, 6.016054, 6.017045, 6.018112, 6.01927567,
      6.02051735, 6.021799, 6.02309132, 6.024392, 6.02572536, 6.02712059,
      6.02858448, 6.03008175, 6.031539, 6.032873, 6.034025, 6.03498554,
      6.035803, 6.03656864, 6.037384, 6.03832626, 6.039426, 6.040663,
      6.04198837, 6.04334259, 6.04468346, 6.04599953, 6.04729939, 6.04859638,
      6.04989147, 6.05117035, 6.05240059, 6.05354, 6.054553, 6.055423, 6.05616,
      6.056794, 6.05736351, 6.05790424, 6.05843925, 6.058977, 6.059513,
      6.06004047, 6.06055737, 6.06107426, 6.06160831, 6.062175, 6.06278038,
      6.063424, 6.064102, 6.06481934, 6.065589, 6.06643, 6.067362, 6.068387,
      6.06948376, 6.070608, 6.071702, 6.07270527, 6.07356358, 6.07424545,
      6.074746, 6.07508945, 6.07531929, 6.075495, 6.07568073, 6.075941, 6.07633,
      6.07688427, 6.07762432, 6.07855225, 6.079649, 6.08086872, 6.08214569,
      6.083398, 6.084548, 6.08554554, 6.086384, 6.087105, 6.08778667, 6.088518,
      6.08935928, 6.09031963, 6.09135056, 6.092366, 6.09328556, 6.09406757,
      6.094739, 6.095379, 6.096077, 6.0968895, 6.097808, 6.09876156, 6.099663,
      6.10045242, 6.10113239, 6.101759, 6.102413, 6.10315132, 6.10396767,
      6.104805, 6.10559, 6.10627747, 6.10687828, 6.107457, 6.10809326,
      6.10883331, 6.1096735, 6.11056137, 6.11142445, 6.112213, 6.11292,
      6.11357975, 6.11424828, 6.114976, 6.11578751, 6.11667728, 6.117613,
      6.118555, 6.119467, 6.12031937, 6.12110329, 6.1218195, 6.12247658,
      6.12309456, 6.12369442, 6.12429237, 6.124902, 6.125537, 6.12620449,
      6.12689972, 6.12760162, 6.128278, 6.128895, 6.129442, 6.12994576, 6.13046,
      6.13104439, 6.13173962, 6.13254452, 6.133421, 6.13431072, 6.13517,
      6.13598728, 6.13678, 6.13756943, 6.13835526, 6.139102, 6.1397543,
      6.14026833, 6.14063835, 6.14090443, 6.14114, 6.141423, 6.141817,
      6.14236355, 6.143091, 6.1440134, 6.1451273, 6.1463995, 6.14775372,
      6.14907646, 6.15024471, 6.15117168, 6.1518383, 6.152302, 6.152671,
      6.153061, 6.153551, 6.15416336, 6.15486526, 6.155596, 6.156295, 6.156927,
      6.157486, 6.157984, 6.1584444, 6.158889, 6.15933037, 6.159773, 6.16021347,
      6.16064548, 6.161064, 6.16147041, 6.161873, 6.16229439, 6.16276646,
      6.16332245, 6.163988, 6.16476536, 6.16562843, 6.166519, 6.16735935,
      6.16807842, 6.168639, 6.16904926, 6.169362, 6.16965866, 6.170022,
      6.170512, 6.1711483, 6.17190933, 6.17274761, 6.173604, 6.174423,
      6.17516136, 6.17579269, 6.176317, 6.17675972, 6.177165, 6.17758751,
      6.17808056, 6.17867661, 6.17937851, 6.18015671, 6.180957, 6.181718,
      6.18238163, 6.182908, 6.18328857, 6.183548, 6.18373871, 6.18392849,
      6.184186, 6.18456459, 6.185089, 6.185748, 6.186492, 6.187248, 6.18794155,
      6.18851852, 6.18897057, 6.189336, 6.18968964, 6.190116, 6.190675,
      6.19138145, 6.19220161, 6.1930685, 6.19391727, 6.19471169, 6.19546127,
      6.196213, 6.19702148, 6.197914, 6.198867, 6.199808, 6.20065, 6.20132828,
      6.201833, 6.202219, 6.202585, 6.203027, 6.20359135, 6.20426464,
      6.20498562, 6.205678, 6.20628738, 6.20680332, 6.207259, 6.20770836,
      6.208193, 6.20873356, 6.20933151, 6.20997524, 6.21065426, 6.21136,
      6.212081, 6.21279526, 6.213475, 6.21409225, 6.214636, 6.21512127,
      6.21558571, 6.21607, 6.21659565, 6.21715069, 6.217703, 6.21821976,
      6.21869373, 6.21915054, 6.21964359, 6.22022247, 6.22090244, 6.22164631,
      6.22238255, 6.22304058, 6.223586, 6.224043, 6.22447968, 6.224971,
      6.22555161, 6.22619534, 6.22682571, 6.22736, 6.227749, 6.228011, 6.228223,
      6.228485, 6.228879, 6.229434, 6.230109, 6.230814, 6.23144627, 6.231928,
      6.232229, 6.23237133, 6.232415, 6.232442, 6.23252, 6.232688, 6.23294926,
      6.23327732, 6.23362732, 6.23395538, 6.234229, 6.234437, 6.23459244,
      6.234728, 6.23488331, 6.235099, 6.235402, 6.2358017, 6.236287, 6.236827,
      6.237383, 6.237918, 6.238407, 6.238841, 6.23922539, 6.23957253,
      6.23989773, 6.24021626, 6.24054432, 6.240903, 6.241312, 6.241783,
      6.24230957, 6.24286366, 6.24339962, 6.24387264, 6.244261, 6.244581,
      6.24488544, 6.245242, 6.24570942, 6.24630833, 6.24702263, 6.247813,
      6.24863625, 6.24945927, 6.25026131, 6.251025, 6.25172234, 6.25231457,
      6.25276566, 6.253071, 6.25327, 6.25344, 6.253665, 6.25399876, 6.25444126,
      6.254938, 6.25540924, 6.255781, 6.25601768, 6.256132, 6.256179, 6.256238,
      6.25638771, 6.256686, 6.25716, 6.25779438, 6.25853252, 6.259288,
      6.25997257, 6.26052952, 6.260955, 6.261301, 6.261648, 6.262062, 6.262557,
      6.263096, 6.263612, 6.26404858, 6.264391, 6.26466846, 6.264943, 6.265273,
      6.265682, 6.26615429, 6.266651, 6.26713753, 6.267599, 6.26804543,
      6.26849842, 6.268976, 6.26948261, 6.270009, 6.270543, 6.27107048,
      6.27158546, 6.272086, 6.27257538, 6.273059, 6.27354145, 6.274026,
      6.27450848, 6.2749815, 6.27543163, 6.27584553, 6.276208, 6.276511,
      6.27675438, 6.276947, 6.27710342, 6.27724075, 6.277377, 6.27753258,
      6.27772141, 6.27794647, 6.27819443, 6.27843857, 6.27864933, 6.2788,
      6.278881, 6.278901, 6.278883, 6.27885246, 6.27882862, 6.278821,
      6.27882767, 6.278842, 6.27885342, 6.278857, 6.27885437, 6.2788496,
      6.27884769, 6.27885246, 6.278863, 6.2788744, 6.2788763, 6.2788763,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649, 4.191649,
      4.191649, 4.19125128, 4.19013357, 4.18829727, 4.18560457, 4.181919,
      4.17706251, 4.17077255, 4.162708, 4.152505, 4.139871, 4.12467146,
      4.10696745, 4.086972, 4.06495428, 4.041127, 4.01557875, 3.988275,
      3.95912123, 3.9280448, 3.89506531, 3.86030626, 3.82395887, 3.786233,
      3.747313, 3.70734787, 3.66646433, 3.624783, 3.582431, 3.53953457,
      3.49620056, 3.45249653, 3.40844822, 3.36405134, 3.31928873, 3.27415085,
      3.22865438, 3.18285131, 3.13682413, 3.09066963, 3.04447842, 2.9983108,
      2.95218539, 2.90607929, 2.85993242, 2.81366134, 2.76717615, 2.72038913,
      2.67321825, 2.62558866, 2.57744122, 2.52874112, 2.47948337, 2.42968869,
      2.37939239, 2.32863569, 2.27746463, 2.225936, 2.17412782, 2.122152,
      2.0701592, 2.018333, 1.96687484, 1.91597676, 1.86579347, 1.816417,
      1.76785982, 1.720064, 1.67292774, 1.626344, 1.58024144, 1.53461134,
      1.48950648, 1.44501734, 1.40123677, 1.35823357, 1.31604433, 1.27468145,
      1.234143, 1.19441342, 1.1554637, 1.11724925, 1.07971025, 1.04277754,
      1.00639033, 0.9705092, 0.9351246, 0.9002582, 0.8659615, 0.832312763,
      0.7994087, 0.767347157, 0.736203432, 0.706005156, 0.6767173, 0.6482496,
      0.6204891, 0.593342662, 0.5667726, 0.54080224, 0.5154948, 0.49091804,
      0.467114568, 0.444090843, 0.421823025, 0.400273442, 0.3794049,
      0.359184027, 0.339574456, 0.320531726, 0.302008152, 0.2839725, 0.2664287,
      0.249419808, 0.233014226, 0.217279673, 0.2022537, 0.187925577,
      0.174239278, 0.161112666, 0.148461461, 0.136217833, 0.1243366,
      0.112797976, 0.1016103, 0.09081948, 0.08051646, 0.07082987, 0.0618964434,
      0.05381155, 0.0465769768, 0.04007876, 0.034113884, 0.0284632444,
      0.02298367, 0.0176774263, 0.0127035379, 0.008320808, 0.00478756428,
      0.0022598505, 0.00073492527, 6.03199e-5, 0.0, 0.0,
    ],
    y: [
      0.0, 0.0572694726, 0.0782625, 0.09982044, 0.132319465, 0.179448009,
      0.238334388, 0.30152607, 0.359324247, 0.40323472, 0.428094119,
      0.434250772, 0.428458333, 0.422864079, 0.432964742, 0.4716547, 0.5452066,
      0.650198638, 0.7762511, 0.9115249, 1.051277, 1.20660639, 1.40606213,
      1.69136834, 2.108696, 2.69216752, 3.44984221, 4.35860538, 5.365215,
      6.39668846, 7.375477, 8.23468, 8.929062, 9.437164, 9.764257, 9.931217,
      9.968065, 9.902283, 9.752707, 9.529124, 9.234635, 8.875382, 8.466384,
      8.037521, 7.631142, 7.28923273, 7.0377717, 6.876385, 6.779115, 6.70598936,
      6.616492, 6.487286, 6.320094, 6.134771, 5.958333, 5.811948, 5.70157957,
      5.61847448, 5.54932, 5.48352671, 5.417323, 5.352181, 5.28981924,
      5.22956371, 5.16300344, 5.08326, 4.984754, 4.8696475, 4.746526, 4.624705,
      4.51340055, 4.41645861, 4.33333445, 4.25924873, 4.188841, 4.118111,
      4.04498053, 3.96737313, 3.88488626, 3.80128455, 3.72200418, 3.6508162,
      3.59031343, 3.5409646, 3.49879766, 3.46010542, 3.42231035, 3.385802,
      3.3520925, 3.32380652, 3.30061316, 3.27932334, 3.25479865, 3.2226975,
      3.1829958, 3.13768244, 3.09071827, 3.0448494, 2.99988484, 2.95470572,
      2.90709424, 2.85820937, 2.81264186, 2.7762382, 2.75421119, 2.74804378,
      2.75328231, 2.762971, 2.76796842, 2.76087141, 2.73910427, 2.70402622,
      2.660438, 2.61454058, 2.572235, 2.53543735, 2.50376487, 2.47501159,
      2.44701314, 2.4186213, 2.39112258, 2.36660671, 2.34650874, 2.3308115,
      2.31705856, 2.30156446, 2.28163123, 2.256397, 2.22680879, 2.19403982,
      2.15846777, 2.1199162, 2.078394, 2.03540421, 1.99473894, 1.96155417,
      1.94060278, 1.93264258, 1.93435049, 1.93898022, 1.93888032, 1.92901945,
      1.90880466, 1.881311, 1.85187113, 1.82543969, 1.80396342, 1.78681934,
      1.77169788, 1.75627184, 1.73985362, 1.72311091, 1.70768881, 1.69476235,
      1.68460691, 1.67551839, 1.66541469, 1.65360975, 1.640797, 1.62892461,
      1.61987913, 1.61422849, 1.61075509, 1.60681272, 1.599643, 1.58761537,
      1.57071471, 1.54985583, 1.5262, 1.50053418, 1.47343791, 1.44589269,
      1.41985965, 1.39749193, 1.38035941, 1.368277, 1.35895312, 1.34886181,
      1.33479106, 1.31536531, 1.29135191, 1.26491976, 1.23844206, 1.213262,
      1.18977118, 1.16774166, 1.14707148, 1.1284709, 1.11287248, 1.10030055,
      1.08952546, 1.07876825, 1.06643176, 1.05234885, 1.03782392, 1.025036,
      1.01566708, 1.0100317, 1.00687361, 1.004303, 1.00114536, 0.9973987,
      0.9942655, 0.9929176, 0.9933474, 0.9942651, 0.9935742, 0.989701748,
      0.982385337, 0.9728494, 0.9633463, 0.955732167, 0.9504001, 0.9458639,
      0.939340353, 0.927956641, 0.910436332, 0.8874754, 0.862081766,
      0.838916659, 0.8222844, 0.8143777, 0.8147969, 0.8203968, 0.8264386,
      0.8286484, 0.8247911, 0.815057933, 0.8018713, 0.788491547, 0.7771636,
      0.768765867, 0.7628572, 0.758339942, 0.753855, 0.7491424, 0.744235933,
      0.739292562, 0.734562755, 0.730258, 0.7263137, 0.7228616, 0.7200283,
      0.7177709, 0.71562016, 0.7129592, 0.7088495, 0.70273596, 0.694233239,
      0.683473468, 0.671447456, 0.659331739, 0.6483861, 0.6397438, 0.6337423,
      0.629753, 0.6267825, 0.6233345, 0.617974, 0.610234559, 0.600661933,
      0.5904163, 0.5807656, 0.5730441, 0.5677678, 0.5641339, 0.56127876,
      0.557958066, 0.5533641, 0.547699869, 0.5422736, 0.5389391, 0.5391099,
      0.5432911, 0.5503057, 0.557288, 0.5615865, 0.5613946, 0.55675,
      0.549145758, 0.5408051, 0.5332725, 0.5272221, 0.522811234, 0.5197406,
      0.517662346, 0.5164058, 0.515553951, 0.514129, 0.5112137, 0.506629646,
      0.5014565, 0.4974539, 0.4959484, 0.4967689, 0.497864574, 0.496468574,
      0.49097088, 0.48209542, 0.472770751, 0.4663067, 0.463857323, 0.463739246,
      0.461576849, 0.453322858, 0.437396377, 0.415889233, 0.393874049,
      0.3771162, 0.3690505, 0.369069636, 0.374216765, 0.3806953, 0.386624038,
      0.392473638, 0.399849683, 0.4093041, 0.4195249, 0.427326918, 0.428815663,
      0.422018439, 0.4075994, 0.3885008, 0.369190574, 0.3531878, 0.341693372,
      0.334533155, 0.330770254, 0.329123646, 0.329187185, 0.33118, 0.33492893,
      0.339688778, 0.344658077, 0.348921865, 0.351901531, 0.352961123,
      0.351687461, 0.347230166, 0.339839429, 0.330230355, 0.3195383, 0.3099724,
      0.3028898, 0.298793256, 0.297347367, 0.29834488, 0.301603645, 0.307710171,
      0.317685, 0.3312662, 0.346459568, 0.3595583, 0.366946727, 0.3666969,
      0.3590572, 0.347003132, 0.334413826, 0.324135333, 0.316926777, 0.3123357,
      0.310325027, 0.3106637, 0.3137303, 0.319751233, 0.327386975, 0.33403796,
      0.3364229, 0.3328994, 0.3247778, 0.3160263, 0.311065376, 0.312198132,
      0.318755448, 0.327049851, 0.332658231, 0.332830667, 0.328329384,
      0.3218726, 0.3166613, 0.314490885, 0.314756662, 0.314949483, 0.312280953,
      0.3054028, 0.294680625, 0.28164947, 0.269011855, 0.259127021, 0.254031956,
      0.254754752, 0.261276245, 0.273079157, 0.2876198, 0.301473349,
      0.311864942, 0.317153782, 0.317722023, 0.314814627, 0.30987975,
      0.303614318, 0.29628253, 0.28821376, 0.280586243, 0.2756925, 0.27650556,
      0.284895927, 0.299706072, 0.317255884, 0.332089126, 0.339213163,
      0.33628723, 0.324113935, 0.306358784, 0.287802041, 0.272007227,
      0.260775179, 0.2534711, 0.248257145, 0.243126228, 0.237487808, 0.2317361,
      0.227440953, 0.226580009, 0.230456129, 0.238302574, 0.248029724,
      0.256593049, 0.261045039, 0.259792775, 0.253196776, 0.243052244,
      0.232606232, 0.224470288, 0.219564661, 0.216829076, 0.214235514,
      0.209779322, 0.202587217, 0.193416461, 0.18466273, 0.178958744,
      0.178446651, 0.183396831, 0.19272168, 0.20412077, 0.214625254,
      0.221866116, 0.224877626, 0.223734349, 0.219225124, 0.2131232,
      0.207532138, 0.204272628, 0.204138428, 0.207535908, 0.213311836,
      0.219802126, 0.225157037, 0.227687463, 0.226510316, 0.222185835,
      0.215983242, 0.210175321, 0.206844091, 0.207911626, 0.213796616,
      0.223309532, 0.233895734, 0.242746159, 0.24727343, 0.246446013,
      0.24099718, 0.233438343, 0.226466835, 0.222806782, 0.223378211, 0.2273743,
      0.23295112, 0.238041431, 0.240604132, 0.239490911, 0.234674275, 0.2266318,
      0.216133758, 0.203963771, 0.191479862, 0.180171177, 0.1716887,
      0.167533249, 0.168283388, 0.172873184, 0.179497555, 0.185385674,
      0.188534975, 0.188253343, 0.186083391, 0.184204578, 0.18445906,
      0.186924547, 0.1897749, 0.190154269, 0.186104238, 0.177751645,
      0.167869121, 0.160161734, 0.15791671, 0.1613903, 0.1681981, 0.174858361,
      0.177916825, 0.17632167, 0.171797559, 0.16772157, 0.166777208, 0.16982159,
      0.175455377, 0.180375382, 0.181783482, 0.1774856, 0.16829069, 0.157307684,
      0.148744538, 0.146457985, 0.15179804, 0.162480012, 0.173497945,
      0.178761184, 0.174495876, 0.161145434, 0.143115923, 0.127259389,
      0.119829483, 0.123918228, 0.138127834, 0.157411426, 0.175873891,
      0.1891586, 0.194595248, 0.192490265, 0.185163811, 0.17540729, 0.166428432,
      0.160461962, 0.158301085, 0.158678621, 0.158544853, 0.1545112,
      0.144582212, 0.130368173, 0.115699589, 0.106206074, 0.1060488,
      0.115926765, 0.1324401, 0.150189742, 0.163952231, 0.1712904, 0.173029333,
      0.172357574, 0.172231421, 0.173559889, 0.174923837, 0.17404142,
      0.169306055, 0.16109398, 0.151907176, 0.144848958, 0.141763285,
      0.142216429, 0.1449075, 0.147973225, 0.150485143, 0.152990609,
      0.155588284, 0.157853425, 0.157345667, 0.150558978, 0.136218011,
      0.11666175, 0.09802124, 0.08766287, 0.09035875, 0.105150305, 0.12488845,
      0.139781982, 0.142788157, 0.132423848, 0.113761157, 0.0960000157,
      0.08790343, 0.09365194, 0.111546375, 0.13509877, 0.156482115, 0.16856128,
      0.167944774, 0.155109808, 0.132305309, 0.1029909, 0.0714396462,
      0.0411390252, 0.0147365406, -0.00527473073, -0.0161262285, -0.0153507814,
      -0.00123849791, 0.0249947123, 0.0583098531, 0.09192776, 0.118221857,
      0.132065281, 0.133342415, 0.127191857, 0.121044509, 0.120454513,
      0.127325416, 0.139498249, 0.15149112, 0.159018651, 0.1615006, 0.161625355,
      0.1635747, 0.16982086, 0.179074213, 0.186955646, 0.187286392, 0.176940247,
      0.156667382, 0.131440327, 0.108668491, 0.09566529, 0.09568239,
      0.108095951, 0.127575, 0.147293389, 0.162054643, 0.169234231, 0.169347778,
      0.165982217, 0.163020283, 0.162044913, 0.162190512, 0.161636457,
      0.157981217, 0.149485946, 0.1354877, 0.117702819, 0.09978992,
      0.0846052244, 0.07386278, 0.0683658645, 0.06670584, 0.0670538,
      0.0674474239, 0.06658877, 0.06514191, 0.06414888, 0.0650702044,
      0.06842356, 0.07318053, 0.0781951249, 0.0827147663, 0.08687321,
      0.09239652, 0.09998722, 0.110290237, 0.1225587, 0.133794367, 0.1404823,
      0.140553072, 0.132968888, 0.117760889, 0.09683782, 0.07363143,
      0.0516297743, 0.0341066755, 0.023404656, 0.02052059, 0.0259798523,
      0.0391985, 0.0580375232, 0.08057346, 0.104533873, 0.127550751,
      0.146579459, 0.15842995, 0.160789281, 0.152358577, 0.1350829, 0.11423441,
      0.0953931361, 0.0847907141, 0.08576253, 0.09703572, 0.113346137,
      0.12680383, 0.13086237, 0.123159826, 0.106601909, 0.08895816, 0.07894083,
      0.0809938163, 0.0935557038, 0.109636344, 0.119866051, 0.1185089,
      0.1066793, 0.09083871, 0.07913977, 0.0774848759, 0.0861245, 0.09834617,
      0.105707712, 0.103614263, 0.0926978, 0.07912453, 0.07107103, 0.07367607,
      0.08528468, 0.099824965, 0.11030601, 0.1115525, 0.104136445, 0.0929604,
      0.0837782249, 0.0811405852, 0.08596653, 0.09591717, 0.1070761, 0.1153665,
      0.118595652, 0.117007054, 0.110846765, 0.10236454, 0.09357303, 0.08541377,
      0.07896827, 0.0755092949, 0.0743804052, 0.07505062, 0.07731675,
      0.08139661, 0.08560767, 0.08821964, 0.0873340443, 0.08171005, 0.07258981,
      0.0642320141, 0.06153862, 0.06694282, 0.07924133, 0.09449435, 0.106842428,
      0.112159945, 0.110178433, 0.10456802, 0.09977023, 0.0983068645,
      0.09899255, 0.0974778, 0.08928391, 0.07377142, 0.0546815768, 0.0377719328,
      0.0287572742, 0.0301716682, 0.0407004431, 0.057697054, 0.07902457,
      0.102793716, 0.127752215, 0.150666565, 0.167302251, 0.171327248,
      0.159313053, 0.132780924, 0.09885489, 0.0676928, 0.04812278, 0.04407062,
      0.053398665, 0.06913121, 0.08384176, 0.09164008, 0.09108352, 0.08379163,
      0.07428762, 0.06544515, 0.05905643, 0.0559935458, 0.05511082, 0.05526799,
      0.05537758, 0.05473022, 0.0532913432, 0.0514776781, 0.0501090251,
      0.0505598374, 0.054747697, 0.06324399, 0.07576756, 0.09057174,
      0.103801206, 0.111891061, 0.110715196, 0.09926651, 0.08050991, 0.05965876,
      0.04291081, 0.03537416, 0.0388843156, 0.0520011224, 0.07044378,
      0.08863038, 0.101740316, 0.107797138, 0.106339306, 0.09843091, 0.08598872,
      0.07174003, 0.0592876934, 0.0513383932, 0.0498726778, 0.05568241,
      0.06748153, 0.08153575, 0.0938737541, 0.100557327, 0.0995782539,
      0.090623036, 0.07519982, 0.0563870519, 0.0386486165, 0.0260948762,
      0.02161333, 0.0259492546, 0.03839291, 0.05614527, 0.07505003, 0.08971885,
      0.0961905, 0.09277904, 0.080502905, 0.06377216, 0.04918819, 0.0421856456,
      0.0461669676, 0.0602978468, 0.07952065, 0.09719883, 0.107796475,
      0.109004319, 0.10319043, 0.09543685, 0.09187635, 0.09603325, 0.106111549,
      0.116934195, 0.121140063, 0.11422386, 0.09631251, 0.07321513,
      0.0529590137, 0.04354779, 0.04804227, 0.0623629577, 0.07873559,
      0.08966214, 0.0906384662, 0.0825282559, 0.06987248, 0.05913634, 0.0548996,
      0.05739593, 0.06379782, 0.07144937, 0.0780442, 0.0829291046, 0.08671986,
      0.0897119939, 0.09047479, 0.08813038, 0.08174982, 0.0725597963, 0.0634401,
      0.0579827949, 0.05821009, 0.06292367, 0.06841595, 0.0704130456,
      0.0676059946, 0.0616592281, 0.0567710623, 0.0574784875, 0.06568237,
      0.07903538, 0.09101868, 0.0950299948, 0.08903734, 0.07546192, 0.06097785,
      0.0531786457, 0.0560618825, 0.0668051243, 0.07842491, 0.0825841,
      0.07511072, 0.058291994, 0.0390601121, 0.0265581273, 0.02643255,
      0.03901745, 0.0595349, 0.07921144, 0.08960043, 0.08666172, 0.07147822,
      0.049004253, 0.02628558, 0.009214372, 0.00185466511, 0.004792527,
      0.0147667332, 0.0271905512, 0.038055975, 0.0438481681, 0.0436440781,
      0.03840989, 0.0299607161, 0.0219591353, 0.0169459078, 0.0168192573,
      0.0220015962, 0.03188318, 0.0439646728, 0.05605185, 0.06524298,
      0.0696988255, 0.06921094, 0.0645034462, 0.05776688, 0.050769832,
      0.0452267341, 0.0414397828, 0.0398124754, 0.0398259945, 0.0422342569,
      0.0473866835, 0.0548139773, 0.06285966, 0.0687761754, 0.0696627945,
      0.0643255338, 0.0540093668, 0.04313535, 0.0370016173, 0.0390993841,
      0.0500540845, 0.06665521, 0.08304261, 0.09556066, 0.102091357,
      0.103691995, 0.102018878, 0.09855966, 0.09239237, 0.08189913, 0.06604045,
      0.0466661453, 0.029539628, 0.020290602, 0.0222577266, 0.0339769572,
      0.04954816, 0.0610092431, 0.06320945, 0.0545368977, 0.0384375378,
      0.0207649246, 0.00775944535, 0.00400359742, 0.0108616045, 0.0265912227,
      0.04806128, 0.07037907, 0.08815742, 0.0964136, 0.0924861953, 0.07867591,
      0.06055089, 0.04577467, 0.0407509357, 0.0461482, 0.05725201, 0.06653994,
      0.0681732446, 0.0608070455, 0.04838443, 0.03711409, 0.03233596,
      0.0363503657, 0.0462055728, 0.0561541878, 0.06181558, 0.06233457,
      0.0592525527, 0.056189537, 0.055430416, 0.05772521, 0.06161953,
      0.064873606, 0.0667583644, 0.06669646, 0.0652455539, 0.06346794,
      0.0617380328, 0.0606462061, 0.06032959, 0.0603230968, 0.0606853031,
      0.06002081, 0.0581422038, 0.0544847362, 0.04895255, 0.0417626724,
      0.03395001, 0.0268686451, 0.02130911, 0.0178542454, 0.0164941512,
      0.0175960828, 0.0211885516, 0.0261140466, 0.0302543268, 0.0316951536,
      0.02942641, 0.0231736116, 0.0145886037, 0.0057882634, -0.0007170535,
      -0.0037248712, -0.00384827657, -0.00204266841, 7.510932e-5, 0.00158739183,
      0.00188411807, 0.0009983802, -0.0001467353, -0.0006590119,
      -0.000651321141, 9.1347e-5, 0.0009927149, 0.00160862668, 0.00133022387,
      -0.000759156246, 0.0, 0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0, -0.0,
      -0.0, -0.0, -0.0, -0.0, -0.0, -0.0994807258, -0.179961875, -0.27907303,
      -0.394171119, -0.52715975, -0.6869772, -0.8855205, -1.13074088,
      -1.41999841, -1.73846865, -2.06139421, -2.364682, -2.63413334, -2.8703084,
      -3.08648181, -3.30065727, -3.525255, -3.763242, -4.005863, -4.23895168,
      -4.450843, -4.635923, -4.79556036, -4.934469, -5.05675268, -5.16411734,
      -5.2562027, -5.331809, -5.392293, -5.441271, -5.48469257, -5.527346,
      -5.571919, -5.618738, -5.665724, -5.70839453, -5.74235, -5.76445627,
      -5.774129, -5.77366257, -5.76825762, -5.76309443, -5.763408, -5.77330875,
      -5.79449129, -5.82683468, -5.86988, -5.92284632, -5.98453665, -6.05235052,
      -6.12265348, -6.19175768, -6.25692844, -6.31716633, -6.37199068,
      -6.42075968, -6.461459, -6.490561, -6.50332737, -6.49491835, -6.4616313,
      -6.402888, -6.321646, -6.22416735, -6.119981, -6.01930428, -5.92962,
      -5.85446358, -5.79149628, -5.73410845, -5.67343664, -5.60275364,
      -5.51954842, -5.42560148, -5.325186, -5.22214031, -5.11857176, -5.01604,
      -4.916337, -4.821124, -4.732464, -4.65231, -4.580833, -4.51598024,
      -4.454311, -4.39184046, -4.324753, -4.2494235, -4.16275454, -4.063262,
      -3.95211577, -3.83381939, -3.71574926, -3.60622382, -3.51068544,
      -3.42944479, -3.3571682, -3.28536439, -3.20722866, -3.119631, -3.02456975,
      -2.92631269, -2.82961464, -2.73734236, -2.65006518, -2.56705832,
      -2.488168, -2.4142282, -2.34645534, -2.284428, -2.22449422, -2.16146135,
      -2.09077168, -2.01061845, -1.92301047, -1.83348131, -1.74855638,
      -1.6730063, -1.60863757, -1.55415487, -1.50676548, -1.46353066,
      -1.42113614, -1.37578583, -1.32192385, -1.253817, -1.16784286,
      -1.06551087, -0.9557107, -0.8529378, -0.7716256, -0.719580948,
      -0.693066835, -0.6768299, -0.6497323, -0.5937448, -0.501929343,
      -0.381393343, -0.2505254, -0.130719349, -0.03792363, 0.022834871, 0.0,
    ],
  };

  constructor(
    private translate: TranslateService,
    private modalService: NgbModal,
    private userService: UserService,
    private storeService: StoreService,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef
  ) {
    this.language = this.translate.currentLang;
    this.translate.onLangChange.subscribe(value => {
      this.language = value.lang;
      this.processMonthlySurveyResults();
    });
    this.generateWeeklyDates();
  }

  ngOnInit() {
    this.selectedDevice = 1;
    this.view = CalendarView.Week;
    this.stepGoalCopy = this.stepGoal;
    this.getSurveyResults(true);
    this.getMonthlySurveyResults();
    this.getWeeklySteps();
    this.processTreatmentSummary();
    this.processProblematicExercises();
  }

  public generateWeeklyDates(): void {
    const weekStartDate = getFirstWeekday(this.viewDateSurvey);
    const weekEndDate = new Date(weekStartDate);
    weekEndDate.setDate(weekEndDate.getDate() + 6);
    this.barChartLabels = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(weekStartDate);
      currentDate.setDate(currentDate.getDate() + i);
      const formattedDate = this.formatDate(currentDate);
      this.barChartLabels.push(formattedDate);
    }
  }

  formatDate(date) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString(options).replaceAll('/', '.');
  }

  getSurveyResults(isInit?) {
    this.barChartData = [];
    this.surveyResults = [];
    const weekStartDate = getFirstWeekday(this.viewDateSurvey);
    const weekStartDateString = getDateInString(weekStartDate);
    let weekEndDate = weekStartDate;
    weekEndDate.setDate(weekEndDate.getDate() + 6);
    const weekEndDateString = getDateInString(weekEndDate);
    const requestData: any = {
      patient_uid: this.patientId,
      start_date: weekStartDateString,
      end_date: weekEndDateString,
    };
    this.storeService.getSurveyResultsOverview(requestData).subscribe(
      (data) => {
        this.surveyResults = data['data'];
      },
      (error) => {},
      () => {
        this.processSurveyResults();
        if (isInit) {
          setTimeout(() => {
            this.viewReady.emit();
          }, 100);
        }
      }
    );
  }

  getMonthlySurveyResults() {
    this.monthlySurveyLoaded = false;
    this.weeklySurveyResults = [];
    const viewMonthIndex = this.viewDateMonthlySurvey.getMonth();
    // const viewDateOfMonth = this.viewDateMonthlySurvey.getDate();
    const referenceDate = new Date(this.viewDateMonthlySurvey);

    referenceDate.setHours(0,0,0,0);
    referenceDate.setDate(1);
    let i = 0;
    for (; i < 4; i++) {
      // if (weekStartDate.getDate() + 6 > viewDateOfMonth) {
      //   const weekEndDate = new Date(weekStartDate);
      //   weekEndDate.setDate(viewDateOfMonth);
      //   const requestData: any = {
      //     patient_uid: this.patientId,
      //     start_date: getDateInString(weekStartDate),
      //     end_date: getDateInString(weekEndDate),
      //   };
      //   this.storeService.getSurveyResultsOverview(requestData).subscribe(
      //     (data) => {
      //     },
      //     (error) => {},
      //     () => {}
      //   );
      //   break;
      // }
      if (i !== 3) {
        const weekStartDate = new Date(referenceDate);
        const weekEndDate = new Date(referenceDate);
        weekEndDate.setDate(weekStartDate.getDate() + 6);
        const requestData: any = {
          patient_uid: this.patientId,
          start_date: getDateInString(weekStartDate),
          end_date: getDateInString(weekEndDate),
        };
        this.storeService.getSurveyResultsOverview(requestData).subscribe(
          (data) => {
            const temp = {
              startDate: getDateInString(weekStartDate),
              endDate: getDateInString(weekEndDate),
              data: data['data'].find((element) => element.survey_type === 'weekly_survey'),
            }
            this.weeklySurveyResults.push(temp);
          },
          (error) => {},
          () => {}
        );
        referenceDate.setDate(referenceDate.getDate() + 7);
      } else {
        const weekStartDate = new Date(referenceDate);
        const weekEndDate = new Date(referenceDate);
        if (viewMonthIndex === 1) {
          if (this.viewDateMonthlySurvey.getFullYear() % 4 === 0) {
            weekEndDate.setDate(29);
          } else {
            weekEndDate.setDate(28);
          }
        } else if ([3, 5, 8, 10].includes(viewMonthIndex)) {
          weekEndDate.setDate(30);
        } else {
          weekEndDate.setDate(31);
        }
        const requestData: any = {
          patient_uid: this.patientId,
          start_date: getDateInString(weekStartDate),
          end_date: getDateInString(weekEndDate),
        };
        this.storeService.getSurveyResultsOverview(requestData).subscribe(
          (data) => {
            const temp = {
              startDate: getDateInString(weekStartDate),
              endDate: getDateInString(weekEndDate),
              data: data['data'].find((element) => element.survey_type === 'weekly_survey'),
            }
            this.weeklySurveyResults.push(temp);
          },
          (error) => {},
          () => {
            this.processMonthlySurveyResults()
          }
        );
      }
    }
  }

  processMonthlySurveyResults() {
    this.lineChartData = [];
    this.lineChartLabels = [];
    this.monthlySurveyEmpty = false;
    let questionCount = 0;

    this.weeklySurveyResults.sort((a, b) => {
      return Number(a.startDate.split('.')[0]) - Number(b.startDate.split('.')[0])
    })

    let someSurveyResult = false;
    const weeklyTotals = [];
    this.weeklySurveyResults.forEach((res) => {
      if (res.data) {
        someSurveyResult = true;
        this.lineChartLabels.push(res.startDate + ' - ' + res.endDate);
        questionCount = res.data.questions?.length;
        let weeklyTotal = 0;
        res.data.questions.forEach((question) => {
          weeklyTotal += question.answer;
        })
        weeklyTotals.push(weeklyTotal);
      } else {
        this.translate.get('patientDetails.performanceOverview.na').subscribe((resString: string) => {
          this.lineChartLabels.push([res.startDate + ' - ' + res.endDate, resString]);
        });
        weeklyTotals.push(0);
      }
    });
    if (someSurveyResult) {
      this.translate.get('patientDetails.performanceOverview.totalScore').subscribe((resString: string) => {
        this.lineChartData.push({ data: weeklyTotals, label: resString, order: questionCount + 1, showLine: true, backgroundColor: 'rgba(54, 162, 235, 0.4)', borderColor: 'rgb(54, 162, 235)' });
      });
    }
    this.monthlySurveyEmpty = !someSurveyResult;
    this.lineChartColorsCopy = cloneDeep(this.lineChartColors);
    for (let j = 0; j < questionCount; j++) {
      const data = [];
      let label = '';
      let order = 999;
      this.weeklySurveyResults.forEach((res) => {
        if (res.data) {
          label = res.data.questions[j].lang[this.language];
          order = res.data.questions[j].order;
          data.push(res.data.questions[j].answer);
        } else {
          data.push(0);
        }
      })
      const randomColor = this.lineChartColorsCopy.pop();
      this.lineChartData.push({ type: 'line', data, label, order, fill: false, showLine: true, backgroundColor: randomColor, borderColor: randomColor });
      this.lineChartDataCopy = cloneDeep(this.lineChartData);
      this.monthlySurveyTotal = 0;
      this.lineChartData.forEach((dataItem) => {
        dataItem.data.forEach((dataScore) => {
          this.monthlySurveyTotal += dataScore;
        })
      })
    }
    setTimeout(() => {
      this.monthlySurveyLoaded = true;
    }, 500);  
  }

  questionSelect(index) {
    this.lineChartDataCopy[index].showLine = !this.lineChartDataCopy[index].showLine;
    const order = this.lineChartDataCopy[index].order;
    const indexInData = this.lineChartData.findIndex((question) => question.order === order);
    if (this.lineChartDataCopy[index].showLine) {
      const randomColor = this.lineChartColorsCopy.pop();
      this.lineChartData.push(Object.assign({}, this.lineChartDataCopy[index], { backgroundColor: randomColor, borderColor: randomColor }));
    } else {
      this.lineChartColorsCopy.push(this.lineChartData[indexInData].backgroundColor.toString())
      this.lineChartData.splice(indexInData, 1);
    }
    this.monthlySurveyTotal = 0;
    this.lineChartData.forEach((dataItem) => {
      dataItem.data.forEach((dataScore) => {
        this.monthlySurveyTotal += dataScore;
      })
    })
  }

  processSurveyResults() {
    const firstWeekday: Date = getFirstWeekday(this.viewDateSurvey);
    this.surveyResults.sort((a, b) =>
      getDateFromString(a.date) > getDateFromString(b.date)
        ? 1
        : getDateFromString(b.date) > getDateFromString(a.date)
        ? -1
        : 0
    );
    let beforeResults: number[] = [];
    let afterResults: number[] = [];

    this.weeklySurvey = this.surveyResults.find(
      (element) => element.survey_type === 'weekly_survey'
    );

    let currentDate = firstWeekday;

    for (let i = 0; i < 7; i++) {
      let beforeFound,
        afterFound = false;
      this.surveyResults.forEach((survey, index) => {
        let barValue: number;
        if (
          getDateFromString(survey.date).toDateString() ===
          currentDate.toDateString()
        ) {
          barValue = survey.borg_scale;
          if (survey.survey_type === 'before') {
            beforeResults.push(barValue);
            beforeFound = true;
          } else if (survey.survey_type === 'after') {
            afterResults.push(barValue);
            afterFound = true;
          }
        } else if (index === this.surveyResults.length - 1) {
          barValue = 0;
          if (!afterFound) {
            afterResults.push(barValue);
          }
          if (!beforeFound) {
            beforeResults.push(barValue);
          }
        }
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    this.barChartData.push({
      data: beforeResults,
      label: 'B',
    });
    this.barChartData.push({
      data: afterResults,
      label: 'A',
    });

    this.barChartData.forEach((dataset, setIndex) => {
      dataset.data.forEach((value, index) => {
        if (value < 10) {
          this.chartColors[setIndex].backgroundColor[index] = '#ba2100';
        } else if (value <= 20) {
          this.chartColors[setIndex].backgroundColor[index] = '#ba3800';
        } else if (value <= 30) {
          this.chartColors[setIndex].backgroundColor[index] = '#ba4e00';
        } else if (value <= 40) {
          this.chartColors[setIndex].backgroundColor[index] = '#ba6400';
        } else if (value <= 50) {
          this.chartColors[setIndex].backgroundColor[index] = '#ba8600';
        } else if (value <= 60) {
          this.chartColors[setIndex].backgroundColor[index] = '#dbc822';
        } else if (value <= 70) {
          this.chartColors[setIndex].backgroundColor[index] = '#a6b44e';
        } else if (value <= 80) {
          this.chartColors[setIndex].backgroundColor[index] = '#75b44e';
        } else if (value <= 90) {
          this.chartColors[setIndex].backgroundColor[index] = '#62b44e';
        } else {
          this.chartColors[setIndex].backgroundColor[index] = '#469e44';
        }
      });
    });
    this.dataLoadedSurveys = true;
    this.dataLoaded = true;
  }
  getWeeklySteps() {
    this.weeklySteps = [];
    const weekStartDate = getFirstWeekday(this.viewDateActivity);
    const weekStartDateString = getDateInString(weekStartDate);
    let weekEndDate = weekStartDate;
    weekEndDate.setDate(weekEndDate.getDate() + 6);
    const weekEndDateString = getDateInString(weekEndDate);

    const requestData: any = {
      patient_uid: this.patientId,
      start_date: weekStartDateString,
      end_date: weekEndDateString,
    };
    this.storeService.getStepCountsByRange(requestData).subscribe(
      (data) => {
        if (data && typeof data === 'object') {
          this.devices = Object.values(data['data']);
          this.selectedDeviceStepCountWeekly = this.devices[this.selectedDevice - 1];
          this.weeklySteps = this.selectedDeviceStepCountWeekly;
        }
      },
      (error) => {},
      () => {
        this.getWeeklyStepAverage();
      }
    );
  }

  public chartClicked(e: any): void {
    const firstWeekday: Date = getFirstWeekday(this.viewDateSurvey);
    let chosenDate: Date = firstWeekday;
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        let value: number;
        let surveyType: string;
        if (activePoints[0]._view.datasetLabel === 'B') {
          value = chart.data.datasets[0].data[clickedElementIndex];
          surveyType = 'before';
        } else {
          value = chart.data.datasets[1].data[clickedElementIndex];
          surveyType = 'after';
        }
        chosenDate = new Date(
          chosenDate.setDate(chosenDate.getDate() + clickedElementIndex)
        );
        const chosenSurveys: any[] = this.surveyResults.filter(
          (element) =>
            getDateFromString(element.date).toDateString() ===
            chosenDate.toDateString()
        );
        this.chosenBeforeSurvey = chosenSurveys.find(
          (element) => element.survey_type === 'before'
        );
        this.chosenAfterSurvey = chosenSurveys.find(
          (element) => element.survey_type === 'after'
        );
        this.modalService.open(this.surveyResultsModal, { size: 'lg' });
      }
    }
  }

  getWeeklyStepAverage() {
    let totalSteps: number = 0;
    let totalDays: number = 0;
    if (this.weeklySteps && this.weeklySteps.length > 0) {
      this.weeklySteps.forEach((element) => {
        if (element.steps) {
          totalSteps += element.steps;
          totalDays++;
        }
      });
      if (totalDays === 0) {
        this.averageWeeklySteps = 0;
      } else {
        this.averageWeeklySteps = Math.round(totalSteps / totalDays);
      }
    } else {
      this.averageWeeklySteps = 0;
    }
    this.dataLoadedWeekly = true;
  }
  doTranslattions() {
    let translationString: string = 'patient.daysLeft';
    if (this.activeTreatmentSummary.days_left >= 0) {
      if (this.todayExercises.length > 0) {
        if (this.activeTreatmentSummary.days_left === 0) {
          translationString = 'patient.dayLeft';
          this.translate.get(translationString).subscribe((res: string) => {
            this.subtitle = '' + '1' + '\n' + res;
          });
        } else {
          translationString = 'patient.daysLeft';
          this.translate.get(translationString).subscribe((res: string) => {
            this.subtitle =
              '' + this.activeTreatmentSummary.days_left + '\n' + res;
          });
        }
      } else {
        if (this.activeTreatmentSummary.days_left === 0) {
          this.donePercentage = 100;
          this.translate.get('patient.done').subscribe((res: string) => {
            this.subtitle = res;
          });
        } else {
          translationString = 'patient.daysLeft';
          this.translate.get(translationString).subscribe((res: string) => {
            this.subtitle =
              '' + this.activeTreatmentSummary.days_left + '\n' + res;
          });
        }
      }
    }
  }
  processTreatmentSummary() {
    this.donePercentage =
      ((this.activeTreatmentSummary.total_days -
        this.activeTreatmentSummary.days_left) /
        this.activeTreatmentSummary.total_days) *
      100;
    this.doTranslattions();
    this.translate.onLangChange.subscribe((value) => {
      this.doTranslattions();
    });
  }

  processProblematicExercises() {
    this.performanceOverview?.exercises_with_low_performance?.forEach(
      (exercise) => {
        const found = this.problematicExercises.find(
          (element) => element.exercise_id === exercise.exercise_id
        );
        if (!found && this.problematicExercises.length < 3) {
          this.problematicExercises.push(exercise);
        }
      }
    );
  }

  openSurveyModal() {
    this.survey.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setTimeout(() => {
      this.modalService.open(this.surveyCard, {
        size: 'lg',
        windowClass: 'bubble-modal',
        centered: true,
      });
    }, 600);
  }

  openOverviewModal() {
    this.overview.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setTimeout(() => {
      this.modalService.open(this.perfOverviewCard, {
        size: 'lg',
        windowClass: 'bubble-modal',
        centered: true,
      });
    }, 600);
  }

  openActivityModal() {
    this.activity.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setTimeout(() => {
      this.modalService.open(this.activityCard, {
        size: 'lg',
        windowClass: 'bubble-modal',
        centered: true,
      });
    }, 600);
  }

  // Calendar functions

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  changeViewClickSurvey() {
    this.dataLoadedSurveys = false;
    this.getSurveyResults(false);
    this.generateWeeklyDates();
  }

  changeMonthlyViewClickSurvey() {
    this.getMonthlySurveyResults();
  }

  changeViewClickActivity() {
    this.dataLoadedWeekly = false;
    this.getWeeklySteps();
  }

  changeDevice() {
    this.selectedDeviceStepCountWeekly = this.devices[this.selectedDevice - 1];
    this.dataLoadedWeekly = false;
    setTimeout(() => { this.dataLoadedWeekly = true; }, 250);
    this.weeklySteps = this.selectedDeviceStepCountWeekly;
  }

  editStepGoal() {
    this.stepEdit = true;
  }

  cancelStepEdit() {
    this.stepGoalCopy = this.stepGoal;
    this.stepEdit = false;
  }

  saveStepEdit() {
    if (this.stepGoalCopy > 100000) {
      return;
    }
    const payload = {
      step_goal: this.stepGoalCopy,
      patient_uid: this.patientId,
    }
    this.userService.updateStepGoal(payload).subscribe(() => {
      this.stepGoal = this.stepGoalCopy;
      this.translate.get('toastr.stepGoalUpdateSuccess').subscribe((res: string) => {
        this.toastr.success(res);
      });
    },
    (err) => {
      this.stepGoalCopy = this.stepGoal;
      this.translate.get('toastr.stepGoalUpdateError').subscribe((res: string) => {
        this.toastr.error(res);
      });
    },
    () => {
      this.stepEdit = false;
    })
  }

  processSpirometry() {
    this.processingSpirometry = true;
    const reader = new FileReader();
    const file = (<HTMLInputElement>document.getElementById('spirometry-xml'))
      .files[0];
    reader.onload = (e) => {
      const readXml = e.target.result;
      const parser = new DOMParser();
      const doc = parser.parseFromString(<string>readXml, 'application/xml');
      const curves = doc.getElementsByTagName('CurveData');

      const fvX = [];
      const fvY = [];
      for (let i = 0; i < curves[0].children.length; i++) {
        const elem = curves[0].children.item(i);
        if (elem.nodeName === 'Points') {
          for (let j = 0; j < elem.children.length; j++) {
            const point = elem.children.item(j);
            fvX.push(
              Number(
                (<HTMLElement>point.children.item(0).childNodes[0]).textContent
              )
            );
            fvY.push(
              Number(
                (<HTMLElement>point.children.item(1).childNodes[0]).textContent
              )
            );
          }
        }
      }

      const vtX = [];
      const vtY = [];
      for (let i = 0; i < curves[1].children.length; i++) {
        const elem = curves[1].children.item(i);
        if (elem.nodeName === 'Points') {
          for (let j = 0; j < elem.children.length; j++) {
            const point = elem.children.item(j);
            vtX.push(
              Number(
                (<HTMLElement>point.children.item(0).childNodes[0]).textContent
              )
            );
            vtY.push(
              Number(
                (<HTMLElement>point.children.item(1).childNodes[0]).textContent
              )
            );
          }
        }
      }

      this.vtData = {
        x: vtX,
        y: vtY,
      };

      this.fvData = {
        x: fvX,
        y: fvY,
      };
    };
    try {
      if (file) reader.readAsText(file);
      this.processingSpirometry = false;
    } catch {
      this.processingSpirometry = false;
      this.translate.get('toastr.xmlProcessError').subscribe((res: string) => {
        this.toastr.error(res);
      });
    }
  }
}
