
<!--<div class="flex">-->
<!--  <div class="flex-item" fxLayout="column" fxLayoutGap="24px">-->
<!--    <canvas baseChart type="line"-->
<!--            [data]="lineChartData"-->
<!--            [options]="chartOptions">-->
<!--    </canvas>-->

<!--    <div class="actions-container" fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="16px">-->
<!--      <span class="title">-->
<!--        Actions-->
<!--      </span>-->
<!--      <div class="actions" fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">-->
<!--&lt;!&ndash;        <mat-checkbox [ngModel]="showBackgroundColors" color="primary" (change)="showBackgroundAction($event?.checked)" class="background-toggle">Show background colors</mat-checkbox>&ndash;&gt;-->

<!--&lt;!&ndash;        <button mat-flat-button color="primary" class="second-chart-action" (click)="drawSecondChartAction()">&ndash;&gt;-->
<!--&lt;!&ndash;          Draw second chart&ndash;&gt;-->
<!--&lt;!&ndash;        </button>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->



<!-- MAIN PART -->

<div *ngIf="plotData && dataLoaded" style="display: block; margin-top: 6%; ">
  <div *ngIf="plotArray.length > 1"   ngbDropdown  style="margin-left: 38px;">
    <button class="btn btn-xs btn-secondary" id="dropdownMenuButton" ngbDropdownToggle>
      {{plotSelected}}
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton" style="max-height: 12rem; overflow-y: auto;">
      <button
        ngbDropdownItem
        *ngFor="let option of plotOptions; let i = index;"
        (click)="plotSelected = option + ' ' + (i+1); changePlot(i);"
      >
        {{option}} {{i + 1}}
      </button>
    </div>
  </div>
  <div *ngIf="displayInstructionRanges" class="d-flex justify-content-center align-items-center my-auto mb-5">
    <p class="my-auto">{{ 'patientDetails.inhale' | translate }}</p>
    <div class="square-inhale ml-2"></div>
    <p class="ml-5 my-auto">{{ 'patientDetails.exhale' | translate }}</p>
    <div class="square-exhale ml-2"></div>
  </div>
  <div class="mt-3">
    <canvas baseChart width="800px" height="800px" [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"></canvas>
  </div>
  <div *ngIf="exerciseCategory !== 'breathing_time'" class="float-right my-auto">
    <input type="checkbox" class="form-check-input" [(ngModel)]="showBackgroundColors" (change)="showBackgroundAction(showBackgroundColors);">
    <label>{{ 'patientDetails.showBackgroundColors' | translate }}</label>
  </div>
</div>

<!-- MAIN PART   -->




<!--<div class="form-group" style="margin-left: 7%; margin-top: 3%;">-->
<!--    <div class="row">-->
<!--        <div class="col-6">-->
<!--            <label>Time Difference: </label>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--            <label>Compare with another exercise:  </label>-->
<!--          <form [formGroup]="graphInputs">-->
<!--            <input type="date" id="comparable-date" name="comparable-date" class="form-control" formControlName="comparisonDate">-->
<!--            <app-error-messages [control]="graphInputs.get('comparisonDate')">-->
<!--            </app-error-messages>-->
<!--            <div *ngIf="noExercise" class="errors-container">-->
<!--              <br>-->
<!--              <div class="alert alert-danger">-->
<!--                <div *ngIf="noExercise">-->
<!--                  No exercise has been performed on the selected date-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--          <div class="form-group">-->
<!--            <div class="form-check">-->
<!--              <input class="form-check-input" type="checkbox" name="inhale-exhale-range" id="inhale-exhale-range" (change)="displayInstructionRangeChanged()" [(ngModel)]="displayInstructionRanges">-->
<!--              <label class="form-check-label" for="inhale-exhale-range">Display inhale & exhale ranges</label>-->
<!--            </div>-->
<!--          </div>-->
<!--      &lt;!&ndash;            <input [(ngModel)]="timeDifference" (change)="changeTimeDifference($event)" type="number" id="time " name="time " placeholder="2 " trim="blur " class="form-control">&ndash;&gt;-->
<!--        </div>-->
<!--    </div>-->

<!--</div>-->
