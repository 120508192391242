import { Component, OnInit ,Input} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {Constants} from "../../../constants";



@Component({
  selector: 'app-error-messages',
  templateUrl: './error-messages.component.html',
  styleUrls: ['./error-messages.component.css']
})
export class ErrorMessagesComponent implements OnInit {
  @Input() control: UntypedFormControl;

  alphabetPattern: string = Constants.alphabetPattern;
  emailPattern: string = Constants.emailPattern;
  phonePattern: string = Constants.phonePattern;


  constructor() { }

  ngOnInit(): void {

  }

}
