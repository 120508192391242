<app-navbar  *ngIf="dataLoaded"></app-navbar>
<div *ngIf="!dataLoaded" class="full-screen-center">
    <div class="text-primary" role="status">
      <img src="../../../assets/Logo/spinner-border.gif" alt="Spinner" id="spinner">
    </div>
  </div>
<div *ngIf="dataLoaded" class="modal fade" tabindex="-1" role="dialog" id="modal-cookies" data-backdrop="false" aria-labelledby="modal-cookies" aria-hidden="true">
    <div class="modal-dialog modal-dialog-aside left-4 right-4 bottom-4">
        <div class="modal-content bg-dark-dark">
            <div class="modal-body">
                <!-- Text -->
                <p class="text-sm text-white mb-3">
                    We use cookies so that our themes work for you. By using our website, you agree to our use of cookies.
                </p>
                <!-- Buttons -->
                <a href="pages/utility/terms.html" class="btn btn-sm btn-white" target="_blank">Learn more</a>
                <button type="button" class="btn btn-sm btn-primary mr-2" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>
<!-- Main content -->
<section *ngIf="dataLoaded">
    <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-6 col-lg-5 col-xl-5 py-6 py-md-0">
                <div class="card shadow zindex-100 mb-0">
                    <div class="card-body px-md-5 py-5">
                        <div class="mb-5  text-center">
                            <img class="mb-3" src="assets\images\changeEmail\password_lock_480.png" alt="lockimage" style="width: 160px; height: auto;">
                            <h6 class="h3">{{ 'home.navigation.changePassword' | translate}}</h6>
                          <p class="text-muted mb-0">{{ 'home.navigation.newPassword' | translate }}</p>
                        </div>
                        <span class="clearfix"></span>
                        <form (keyup.enter)="resetPassword();">
                            <div class="form-group mt-4 mb-0">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                        <label class="form-control-label">{{ 'resetPassword.newPassword' | translate}}</label>
                                    </div>
                                    <!--                  <div class="mb-2">-->
                                    <!--<a href="#" class="small text-muted text-underline&#45;&#45;dashed border-primary" data-toggle="password-text" data-target="#input-password">Show password</a>-->
                                    <!--                  </div>-->
                                </div>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-key"></i></span>
                                    </div>
                                    <input type="password" class="form-control" id="input-password" name="password" [(ngModel)]="password">

                                    <!-- <app-password-strength-bar [passwordToCheck]="password" [barLabel]="barLabel"> </app-password-strength-bar> -->

                                </div>
                                <div class="mt-3">
                                    <label class="form-control-label">{{ 'resetPassword.confirmPassword' | translate}}</label>
                                </div>
                                <!--                  <div class="mb-2">-->
                                <!--<a href="#" class="small text-muted text-underline&#45;&#45;dashed border-primary" data-toggle="password-text" data-target="#input-password">Show password</a>-->
                                <!--                  </div>-->
                            </div>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-key"></i></span>
                                </div>
                                <input type="password" class="form-control" id="input-password-repetition" name="password"  [(ngModel)]="passwordRepetition">
                            </div>
                            <div class="mt-4 text-center">
                                <p *ngIf="passwordError" class="alert alert-danger">{{ 'resetPassword.passwordError' | translate}}</p>
                                <p *ngIf="passwordControl" class="alert alert-danger">{{ 'resetPassword.passwordRequirements' | translate}}</p>
                                <button type="button" class="btn btn-block btn-primary" [disabled]="!password || !passwordRepetition" (click)="resetPassword();">{{ 'home.navigation.confirm' | translate}}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
