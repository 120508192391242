import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

import {SurveyQuestionBase} from '@schemas/survey/survey-question-base';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.css'],
})

export class DynamicFormQuestionComponent {
  @Input() question: SurveyQuestionBase<any>;
  @Input() lang: string;
  @Input() form: UntypedFormGroup;
  @Input() editMode: boolean;

  get isValid() {
    if (!this.editMode) {
      if (this.form.controls.hasOwnProperty(this.question.key)) {
        return this.form.controls[this.question.key].valid;
      }
    }
    return false;
  }
}
