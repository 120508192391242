import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
  Notification,
} from '@services/notification/notification.service';
import { DateTime } from 'luxon';

type Language = 'en' | 'de' | 'it' | 'tr';
@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css'],
})
export class NotificationListComponent implements OnInit {
  @Input() public notificationsList: Notification[] = [];
  @Input() public buttons: boolean = false;
  @Input() public isListViewAll: boolean = false;
  @Input() public limit?: number;

  @Output() public toggleView = new EventEmitter<boolean>();
  locale: any;

  /**
   *
   */
  constructor(
    private translate:TranslateService
  ) {


  }
  public displayedNotifications: Notification[] = [];

  ngOnInit(): void {
    if (this.limit) {
      this.notificationsList = this.notificationsList.slice(0, this.limit);
    }
    this.locale = this.translate.currentLang;
    
    this.updateDisplayedNotifications();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.notificationsList || changes.isListViewAll) {
      this.updateDisplayedNotifications();
    }
  }

  updateDisplayedNotifications(): void {
    if (this.notificationsList) {
      let filteredNotifications = [...this.notificationsList]; // all
      if (!this.isListViewAll) {
        filteredNotifications = filteredNotifications.filter(
          (notification) => notification.status !== 'read' // olnly unRead
        );
      }
      this.displayedNotifications = filteredNotifications;
    }
  }

  togglerListView(showAll: boolean) {
    this.toggleView.emit(showAll);
  }

  

  relativeTime(givenDate: DateTime, lang: Language = 'en') {


    // Calculate the difference in relative terms using Luxon
    const diff = DateTime.now().diff(givenDate, ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years']).toObject();

    // Language-specific time unit labels
    const translations: { [key in Language]: { [key: string]: string } } = {
      en: {
        second: "second",
        minute: "minute",
        hour: "hour",
        day: "day",
        week: "week",
        month: "month",
        year: "year",
        justNow: "just now",
        ago: "ago",
      },
      de: {
        second: "Sekunde",
        minute: "Minute",
        hour: "Stunde",
        day: "Tag",
        week: "Woche",
        month: "Monat",
        year: "Jahr",
        justNow: "gerade eben",
        ago: "vor",
      },
      it: {
        second: "secondo",
        minute: "minuto",
        hour: "ora",
        day: "giorno",
        week: "settimana",
        month: "mese",
        year: "anno",
        justNow: "appena adesso",
        ago: "fa",
      },
      tr: {
        second: "saniye",
        minute: "dakika",
        hour: "saat",
        day: "gün",
        week: "hafta",
        month: "ay",
        year: "yıl",
        justNow: "şimdi",
        ago: "önce",
      }
    };

    const intervalLabels = translations[lang];

    // Check each unit starting from the largest unit (years) to seconds
    if (diff.years >= 1) {
      return diff.years <= 1
        ? `${Math.floor(diff.years)} ${intervalLabels.year} ${intervalLabels.ago}`
        : `${Math.floor(diff.years)} ${intervalLabels.year}s ${intervalLabels.ago}`;
    } else if (diff.months >= 1) {
      return diff.months <= 1
        ? `${Math.floor(diff.months)} ${intervalLabels.month} ${intervalLabels.ago}`
        : `${Math.floor(diff.months)} ${intervalLabels.month}s ${intervalLabels.ago}`;
    } else if (diff.weeks >= 1) {
      return diff.weeks <= 1
        ? `${Math.floor(diff.weeks)} ${intervalLabels.week} ${intervalLabels.ago}`
        : `${Math.floor(diff.weeks)} ${intervalLabels.week}s ${intervalLabels.ago}`;
    } else if (diff.days >= 1) {
      return diff.days <= 1
        ? `${Math.floor(diff.days)} ${intervalLabels.day} ${intervalLabels.ago}`
        : `${Math.floor(diff.days)} ${intervalLabels.day}s ${intervalLabels.ago}`;
    } else if (diff.hours >= 1) {
      return diff.hours <= 1
        ? `${Math.floor(diff.hours)} ${intervalLabels.hour} ${intervalLabels.ago}`
        : `${Math.floor(diff.hours)} ${intervalLabels.hour}s ${intervalLabels.ago}`;
    } else if (diff.minutes >= 1) {
      return diff.minutes <= 1
        ? `${Math.floor(diff.minutes)} ${intervalLabels.minute} ${intervalLabels.ago}`
        : `${Math.floor(diff.minutes)} ${intervalLabels.minute}s ${intervalLabels.ago}`;
    } else if (diff.seconds >= 1) {
      return diff.seconds <= 1
        ? `${Math.floor(diff.seconds)} ${intervalLabels.second} ${intervalLabels.ago}`
        : `${Math.floor(diff.seconds)} ${intervalLabels.second}s ${intervalLabels.ago}`;
    }

    return intervalLabels.justNow; // For cases where the difference is less than a second
  }
}
