import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { GeneralService } from '@services/general/general.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


  public loggingOut: boolean = false;
  urlChange: Subscription;

  //showContent: boolean = true;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private auth: AuthService, private generalService: GeneralService,) {
    this.urlChange = this.activatedRoute.url.subscribe(async urls => {
      if (urls[0]?.path === 'dashboard' && Object.keys(urls[0]?.parameters).length === 0) {
        if (await this.auth.isAuthenticated()) {
          if (this.activatedRoute.snapshot['_routerState'].url === '/dashboard') {
            this.router.navigateByUrl(`/dashboard/${this.auth.authenticatedUser.value.role}`);
          } else {
            if (!this.activatedRoute.snapshot['_routerState'].url.startsWith(`/dashboard/${this.auth.authenticatedUser.value.role}`)) {
              if(this.auth.me.role)
                this.router.navigate([this.auth.me.role]);
            }
            if (this.auth.me.role === 'physiotherapist') {
              const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              this.generalService.saveTimezone({ timezone }).subscribe(
                () => {},
                (error) => {
                  console.error('error saving timezone: ', error)
                }
              )
            }
          }
        }
      }
    });
  }

  ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.urlChange.unsubscribe();
  }

}
