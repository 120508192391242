import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import {
  NotificationService,
  Notification,
} from '@services/notification/notification.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit,OnDestroy{
  public notificationsList: Notification[] = [];
  public isListViewAll: boolean = false;
  locale: string;
  constructor(
    public auth: AuthService,
    private router: Router,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {
    this.locale = this.translate.currentLang
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      this.loadNotifications()
    });
  }
  ngOnDestroy(): void {
    //this.notificationService.onChange$.next(Date.now())
  }
  ngOnInit(): void {
    this.loadNotifications();
  }

  loadNotifications(): void {

    this.notificationService
      .getNotifications(this.locale)
      .subscribe(
        (notifications: Notification[]) => {
          this.notificationsList = notifications;

          // After receiving notifications, calling the endpoint to mark them as read
          this.notificationService.markAllAsRead('').subscribe(
            () => {
              
            },
            (error) => {
              
            }
          );
        },
        (error) => {
          
        }
      );

  }

  goBack(): void {
    this.router.navigate(['dashboard']);
  }

  togglerListView(showAll: boolean) {
    this.isListViewAll = showAll;
  }
}
